import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  addressLine1: Scalars['String']['output'];
  addressLine2: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tambon: Tambon;
  tambonId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AddressCountAggregate = {
  __typename?: 'AddressCountAggregate';
  _all: Scalars['Int']['output'];
  addressLine1: Scalars['Int']['output'];
  addressLine2: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  tambonId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type AddressCreateManyTambonInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AddressCreateManyTambonInputEnvelope = {
  data: Array<AddressCreateManyTambonInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AddressCreateNestedManyWithoutTambonInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AddressCreateOrConnectWithoutTambonInput>>;
  create?: InputMaybe<Array<AddressCreateWithoutTambonInput>>;
  createMany?: InputMaybe<AddressCreateManyTambonInputEnvelope>;
};

export type AddressCreateOrConnectWithoutTambonInput = {
  create: AddressCreateWithoutTambonInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateWithoutTambonInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AddressListRelationFilter = {
  every?: InputMaybe<AddressWhereInput>;
  none?: InputMaybe<AddressWhereInput>;
  some?: InputMaybe<AddressWhereInput>;
};

export type AddressMaxAggregate = {
  __typename?: 'AddressMaxAggregate';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tambonId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AddressMinAggregate = {
  __typename?: 'AddressMinAggregate';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tambonId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AddressScalarWhereInput = {
  AND?: InputMaybe<Array<AddressScalarWhereInput>>;
  NOT?: InputMaybe<Array<AddressScalarWhereInput>>;
  OR?: InputMaybe<Array<AddressScalarWhereInput>>;
  addressLine1?: InputMaybe<StringFilter>;
  addressLine2?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  tambonId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AddressUpdateManyMutationInput = {
  addressLine1?: InputMaybe<StringFieldUpdateOperationsInput>;
  addressLine2?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpdateManyWithWhereWithoutTambonInput = {
  data: AddressUpdateManyMutationInput;
  where: AddressScalarWhereInput;
};

export type AddressUpdateManyWithoutTambonNestedInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AddressCreateOrConnectWithoutTambonInput>>;
  create?: InputMaybe<Array<AddressCreateWithoutTambonInput>>;
  createMany?: InputMaybe<AddressCreateManyTambonInputEnvelope>;
  delete?: InputMaybe<Array<AddressWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AddressScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  set?: InputMaybe<Array<AddressWhereUniqueInput>>;
  update?: InputMaybe<Array<AddressUpdateWithWhereUniqueWithoutTambonInput>>;
  updateMany?: InputMaybe<Array<AddressUpdateManyWithWhereWithoutTambonInput>>;
  upsert?: InputMaybe<Array<AddressUpsertWithWhereUniqueWithoutTambonInput>>;
};

export type AddressUpdateWithWhereUniqueWithoutTambonInput = {
  data: AddressUpdateWithoutTambonInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpdateWithoutTambonInput = {
  addressLine1?: InputMaybe<StringFieldUpdateOperationsInput>;
  addressLine2?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpsertWithWhereUniqueWithoutTambonInput = {
  create: AddressCreateWithoutTambonInput;
  update: AddressUpdateWithoutTambonInput;
  where: AddressWhereUniqueInput;
};

export type AddressWhereInput = {
  AND?: InputMaybe<Array<AddressWhereInput>>;
  NOT?: InputMaybe<Array<AddressWhereInput>>;
  OR?: InputMaybe<Array<AddressWhereInput>>;
  addressLine1?: InputMaybe<StringFilter>;
  addressLine2?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  tambon?: InputMaybe<TambonRelationFilter>;
  tambonId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AddressWhereUniqueInput = {
  AND?: InputMaybe<Array<AddressWhereInput>>;
  NOT?: InputMaybe<Array<AddressWhereInput>>;
  OR?: InputMaybe<Array<AddressWhereInput>>;
  addressLine1?: InputMaybe<StringFilter>;
  addressLine2?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  tambon?: InputMaybe<TambonRelationFilter>;
  tambonId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Amphoe = {
  __typename?: 'Amphoe';
  _count: AmphoeCount;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  nameEn: Scalars['String']['output'];
  nameTh: Scalars['String']['output'];
  province?: Maybe<Province>;
  provinceId?: Maybe<Scalars['String']['output']>;
  tambons?: Maybe<Array<Tambon>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AmphoeCount = {
  __typename?: 'AmphoeCount';
  tambons: Scalars['Int']['output'];
};

export type AmphoeCountAggregate = {
  __typename?: 'AmphoeCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  nameEn: Scalars['Int']['output'];
  nameTh: Scalars['Int']['output'];
  provinceId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type AmphoeCreateNestedOneWithoutTambonsInput = {
  connect?: InputMaybe<AmphoeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AmphoeCreateOrConnectWithoutTambonsInput>;
  create?: InputMaybe<AmphoeCreateWithoutTambonsInput>;
};

export type AmphoeCreateOrConnectWithoutTambonsInput = {
  create: AmphoeCreateWithoutTambonsInput;
  where: AmphoeWhereUniqueInput;
};

export type AmphoeCreateWithoutTambonsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  nameEn: Scalars['String']['input'];
  nameTh: Scalars['String']['input'];
  province?: InputMaybe<ProvinceCreateNestedOneWithoutAmphoesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AmphoeListRelationFilter = {
  every?: InputMaybe<AmphoeWhereInput>;
  none?: InputMaybe<AmphoeWhereInput>;
  some?: InputMaybe<AmphoeWhereInput>;
};

export type AmphoeMaxAggregate = {
  __typename?: 'AmphoeMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
  nameTh?: Maybe<Scalars['String']['output']>;
  provinceId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AmphoeMinAggregate = {
  __typename?: 'AmphoeMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
  nameTh?: Maybe<Scalars['String']['output']>;
  provinceId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AmphoeNullableRelationFilter = {
  is?: InputMaybe<AmphoeWhereInput>;
  isNot?: InputMaybe<AmphoeWhereInput>;
};

export type AmphoeUpdateOneWithoutTambonsNestedInput = {
  connect?: InputMaybe<AmphoeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AmphoeCreateOrConnectWithoutTambonsInput>;
  create?: InputMaybe<AmphoeCreateWithoutTambonsInput>;
  delete?: InputMaybe<AmphoeWhereInput>;
  disconnect?: InputMaybe<AmphoeWhereInput>;
  update?: InputMaybe<AmphoeUpdateToOneWithWhereWithoutTambonsInput>;
  upsert?: InputMaybe<AmphoeUpsertWithoutTambonsInput>;
};

export type AmphoeUpdateToOneWithWhereWithoutTambonsInput = {
  data: AmphoeUpdateWithoutTambonsInput;
  where?: InputMaybe<AmphoeWhereInput>;
};

export type AmphoeUpdateWithoutTambonsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameEn?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameTh?: InputMaybe<StringFieldUpdateOperationsInput>;
  province?: InputMaybe<ProvinceUpdateOneWithoutAmphoesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AmphoeUpsertWithoutTambonsInput = {
  create: AmphoeCreateWithoutTambonsInput;
  update: AmphoeUpdateWithoutTambonsInput;
  where?: InputMaybe<AmphoeWhereInput>;
};

export type AmphoeWhereInput = {
  AND?: InputMaybe<Array<AmphoeWhereInput>>;
  NOT?: InputMaybe<Array<AmphoeWhereInput>>;
  OR?: InputMaybe<Array<AmphoeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  nameEn?: InputMaybe<StringFilter>;
  nameTh?: InputMaybe<StringFilter>;
  province?: InputMaybe<ProvinceNullableRelationFilter>;
  provinceId?: InputMaybe<StringNullableFilter>;
  tambons?: InputMaybe<TambonListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AmphoeWhereUniqueInput = {
  AND?: InputMaybe<Array<AmphoeWhereInput>>;
  NOT?: InputMaybe<Array<AmphoeWhereInput>>;
  OR?: InputMaybe<Array<AmphoeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  nameEn?: InputMaybe<StringFilter>;
  nameTh?: InputMaybe<StringFilter>;
  province?: InputMaybe<ProvinceNullableRelationFilter>;
  provinceId?: InputMaybe<StringNullableFilter>;
  tambons?: InputMaybe<TambonListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type ContactNumber = {
  __typename?: 'ContactNumber';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  tel: Scalars['String']['output'];
  travel?: Maybe<Travel>;
  travelId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ContactNumberCountAggregate = {
  __typename?: 'ContactNumberCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  tel: Scalars['Int']['output'];
  travelId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type ContactNumberCreateManyTravelInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  tel: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContactNumberCreateManyTravelInputEnvelope = {
  data: Array<ContactNumberCreateManyTravelInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContactNumberCreateNestedManyWithoutTravelInput = {
  connect?: InputMaybe<Array<ContactNumberWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactNumberCreateOrConnectWithoutTravelInput>>;
  create?: InputMaybe<Array<ContactNumberCreateWithoutTravelInput>>;
  createMany?: InputMaybe<ContactNumberCreateManyTravelInputEnvelope>;
};

export type ContactNumberCreateOrConnectWithoutTravelInput = {
  create: ContactNumberCreateWithoutTravelInput;
  where: ContactNumberWhereUniqueInput;
};

export type ContactNumberCreateWithoutTravelInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  tel: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContactNumberListRelationFilter = {
  every?: InputMaybe<ContactNumberWhereInput>;
  none?: InputMaybe<ContactNumberWhereInput>;
  some?: InputMaybe<ContactNumberWhereInput>;
};

export type ContactNumberMaxAggregate = {
  __typename?: 'ContactNumberMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  tel?: Maybe<Scalars['String']['output']>;
  travelId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContactNumberMinAggregate = {
  __typename?: 'ContactNumberMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  tel?: Maybe<Scalars['String']['output']>;
  travelId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContactNumberScalarWhereInput = {
  AND?: InputMaybe<Array<ContactNumberScalarWhereInput>>;
  NOT?: InputMaybe<Array<ContactNumberScalarWhereInput>>;
  OR?: InputMaybe<Array<ContactNumberScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  tel?: InputMaybe<StringFilter>;
  travelId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ContactNumberUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  tel?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContactNumberUpdateManyWithWhereWithoutTravelInput = {
  data: ContactNumberUpdateManyMutationInput;
  where: ContactNumberScalarWhereInput;
};

export type ContactNumberUpdateManyWithoutTravelNestedInput = {
  connect?: InputMaybe<Array<ContactNumberWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactNumberCreateOrConnectWithoutTravelInput>>;
  create?: InputMaybe<Array<ContactNumberCreateWithoutTravelInput>>;
  createMany?: InputMaybe<ContactNumberCreateManyTravelInputEnvelope>;
  delete?: InputMaybe<Array<ContactNumberWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContactNumberScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContactNumberWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactNumberWhereUniqueInput>>;
  update?: InputMaybe<Array<ContactNumberUpdateWithWhereUniqueWithoutTravelInput>>;
  updateMany?: InputMaybe<Array<ContactNumberUpdateManyWithWhereWithoutTravelInput>>;
  upsert?: InputMaybe<Array<ContactNumberUpsertWithWhereUniqueWithoutTravelInput>>;
};

export type ContactNumberUpdateWithWhereUniqueWithoutTravelInput = {
  data: ContactNumberUpdateWithoutTravelInput;
  where: ContactNumberWhereUniqueInput;
};

export type ContactNumberUpdateWithoutTravelInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  tel?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContactNumberUpsertWithWhereUniqueWithoutTravelInput = {
  create: ContactNumberCreateWithoutTravelInput;
  update: ContactNumberUpdateWithoutTravelInput;
  where: ContactNumberWhereUniqueInput;
};

export type ContactNumberWhereInput = {
  AND?: InputMaybe<Array<ContactNumberWhereInput>>;
  NOT?: InputMaybe<Array<ContactNumberWhereInput>>;
  OR?: InputMaybe<Array<ContactNumberWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  tel?: InputMaybe<StringFilter>;
  travel?: InputMaybe<TravelNullableRelationFilter>;
  travelId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ContactNumberWhereUniqueInput = {
  AND?: InputMaybe<Array<ContactNumberWhereInput>>;
  NOT?: InputMaybe<Array<ContactNumberWhereInput>>;
  OR?: InputMaybe<Array<ContactNumberWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  tel?: InputMaybe<StringFilter>;
  travel?: InputMaybe<TravelNullableRelationFilter>;
  travelId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type EnumHasOwnerFieldUpdateOperationsInput = {
  set?: InputMaybe<HasOwner>;
};

export type EnumHasOwnerFilter = {
  equals?: InputMaybe<HasOwner>;
  in?: InputMaybe<Array<HasOwner>>;
  not?: InputMaybe<NestedEnumHasOwnerFilter>;
  notIn?: InputMaybe<Array<HasOwner>>;
};

export type EnumHasParkingNullableFilter = {
  equals?: InputMaybe<HasParking>;
  in?: InputMaybe<Array<HasParking>>;
  not?: InputMaybe<NestedEnumHasParkingNullableFilter>;
  notIn?: InputMaybe<Array<HasParking>>;
};

export type EnumPatentedStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<PatentedStatus>;
};

export type EnumPatentedStatusFilter = {
  equals?: InputMaybe<PatentedStatus>;
  in?: InputMaybe<Array<PatentedStatus>>;
  not?: InputMaybe<NestedEnumPatentedStatusFilter>;
  notIn?: InputMaybe<Array<PatentedStatus>>;
};

export type EnumPlantImageKindFieldUpdateOperationsInput = {
  set?: InputMaybe<PlantImageKind>;
};

export type EnumPlantImageKindFilter = {
  equals?: InputMaybe<PlantImageKind>;
  in?: InputMaybe<Array<PlantImageKind>>;
  not?: InputMaybe<NestedEnumPlantImageKindFilter>;
  notIn?: InputMaybe<Array<PlantImageKind>>;
};

export type EnumRoleFieldUpdateOperationsInput = {
  set?: InputMaybe<Role>;
};

export type EnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type EnumWisdomPrFieldUpdateOperationsInput = {
  set?: InputMaybe<WisdomPr>;
};

export type EnumWisdomPrFilter = {
  equals?: InputMaybe<WisdomPr>;
  in?: InputMaybe<Array<WisdomPr>>;
  not?: InputMaybe<NestedEnumWisdomPrFilter>;
  notIn?: InputMaybe<Array<WisdomPr>>;
};

export type EnumWisdomStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<WisdomStatus>;
};

export type EnumWisdomStatusFilter = {
  equals?: InputMaybe<WisdomStatus>;
  in?: InputMaybe<Array<WisdomStatus>>;
  not?: InputMaybe<NestedEnumWisdomStatusFilter>;
  notIn?: InputMaybe<Array<WisdomStatus>>;
};

export type Facility = {
  __typename?: 'Facility';
  _count: FacilityCount;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  travelId?: Maybe<Scalars['String']['output']>;
  travels?: Maybe<Array<TravelsOnFacilities>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type FacilityCount = {
  __typename?: 'FacilityCount';
  travels: Scalars['Int']['output'];
};

export type FacilityCountAggregate = {
  __typename?: 'FacilityCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  travelId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type FacilityCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  travelId?: InputMaybe<Scalars['String']['input']>;
  travels?: InputMaybe<TravelsOnFacilitiesCreateNestedManyWithoutFacilityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FacilityCreateNestedOneWithoutTravelsInput = {
  connect?: InputMaybe<FacilityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FacilityCreateOrConnectWithoutTravelsInput>;
  create?: InputMaybe<FacilityCreateWithoutTravelsInput>;
};

export type FacilityCreateOrConnectWithoutTravelsInput = {
  create: FacilityCreateWithoutTravelsInput;
  where: FacilityWhereUniqueInput;
};

export type FacilityCreateWithoutTravelsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  travelId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FacilityMaxAggregate = {
  __typename?: 'FacilityMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  travelId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FacilityMinAggregate = {
  __typename?: 'FacilityMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  travelId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FacilityRelationFilter = {
  is?: InputMaybe<FacilityWhereInput>;
  isNot?: InputMaybe<FacilityWhereInput>;
};

export type FacilityUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  travelId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  travels?: InputMaybe<TravelsOnFacilitiesUpdateManyWithoutFacilityNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityUpdateOneRequiredWithoutTravelsNestedInput = {
  connect?: InputMaybe<FacilityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FacilityCreateOrConnectWithoutTravelsInput>;
  create?: InputMaybe<FacilityCreateWithoutTravelsInput>;
  update?: InputMaybe<FacilityUpdateToOneWithWhereWithoutTravelsInput>;
  upsert?: InputMaybe<FacilityUpsertWithoutTravelsInput>;
};

export type FacilityUpdateToOneWithWhereWithoutTravelsInput = {
  data: FacilityUpdateWithoutTravelsInput;
  where?: InputMaybe<FacilityWhereInput>;
};

export type FacilityUpdateWithoutTravelsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  travelId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FacilityUpsertWithoutTravelsInput = {
  create: FacilityCreateWithoutTravelsInput;
  update: FacilityUpdateWithoutTravelsInput;
  where?: InputMaybe<FacilityWhereInput>;
};

export type FacilityWhereInput = {
  AND?: InputMaybe<Array<FacilityWhereInput>>;
  NOT?: InputMaybe<Array<FacilityWhereInput>>;
  OR?: InputMaybe<Array<FacilityWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  travelId?: InputMaybe<StringNullableFilter>;
  travels?: InputMaybe<TravelsOnFacilitiesListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FacilityWhereUniqueInput = {
  AND?: InputMaybe<Array<FacilityWhereInput>>;
  NOT?: InputMaybe<Array<FacilityWhereInput>>;
  OR?: InputMaybe<Array<FacilityWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  travelId?: InputMaybe<StringNullableFilter>;
  travels?: InputMaybe<TravelsOnFacilitiesListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Filter = {
  keywords?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']['input']>;
  divide?: InputMaybe<Scalars['Float']['input']>;
  increment?: InputMaybe<Scalars['Float']['input']>;
  multiply?: InputMaybe<Scalars['Float']['input']>;
  set?: InputMaybe<Scalars['Float']['input']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type Food = {
  __typename?: 'Food';
  _count: FoodCount;
  createdAt: Scalars['DateTime']['output'];
  foodCategory?: Maybe<FoodCategory>;
  foodCategoryId?: Maybe<Scalars['String']['output']>;
  hasParking?: Maybe<HasParking>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<FoodImage>>;
  ingredients?: Maybe<Array<Ingredients>>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  productCapacity?: Maybe<Scalars['Int']['output']>;
  shop: Scalars['String']['output'];
  story?: Maybe<Scalars['String']['output']>;
  tambon: Tambon;
  tambonId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type FoodAvgAggregate = {
  __typename?: 'FoodAvgAggregate';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  productCapacity?: Maybe<Scalars['Float']['output']>;
};

export type FoodCategory = {
  __typename?: 'FoodCategory';
  _count: FoodCategoryCount;
  createdAt: Scalars['DateTime']['output'];
  food?: Maybe<Array<Food>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FoodCategoryCount = {
  __typename?: 'FoodCategoryCount';
  food: Scalars['Int']['output'];
};

export type FoodCategoryCountAggregate = {
  __typename?: 'FoodCategoryCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type FoodCategoryCreateNestedOneWithoutFoodInput = {
  connect?: InputMaybe<FoodCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FoodCategoryCreateOrConnectWithoutFoodInput>;
  create?: InputMaybe<FoodCategoryCreateWithoutFoodInput>;
};

export type FoodCategoryCreateOrConnectWithoutFoodInput = {
  create: FoodCategoryCreateWithoutFoodInput;
  where: FoodCategoryWhereUniqueInput;
};

export type FoodCategoryCreateWithoutFoodInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FoodCategoryMaxAggregate = {
  __typename?: 'FoodCategoryMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FoodCategoryMinAggregate = {
  __typename?: 'FoodCategoryMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FoodCategoryNullableRelationFilter = {
  is?: InputMaybe<FoodCategoryWhereInput>;
  isNot?: InputMaybe<FoodCategoryWhereInput>;
};

export type FoodCategoryUpdateOneWithoutFoodNestedInput = {
  connect?: InputMaybe<FoodCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FoodCategoryCreateOrConnectWithoutFoodInput>;
  create?: InputMaybe<FoodCategoryCreateWithoutFoodInput>;
  delete?: InputMaybe<FoodCategoryWhereInput>;
  disconnect?: InputMaybe<FoodCategoryWhereInput>;
  update?: InputMaybe<FoodCategoryUpdateToOneWithWhereWithoutFoodInput>;
  upsert?: InputMaybe<FoodCategoryUpsertWithoutFoodInput>;
};

export type FoodCategoryUpdateToOneWithWhereWithoutFoodInput = {
  data: FoodCategoryUpdateWithoutFoodInput;
  where?: InputMaybe<FoodCategoryWhereInput>;
};

export type FoodCategoryUpdateWithoutFoodInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FoodCategoryUpsertWithoutFoodInput = {
  create: FoodCategoryCreateWithoutFoodInput;
  update: FoodCategoryUpdateWithoutFoodInput;
  where?: InputMaybe<FoodCategoryWhereInput>;
};

export type FoodCategoryWhereInput = {
  AND?: InputMaybe<Array<FoodCategoryWhereInput>>;
  NOT?: InputMaybe<Array<FoodCategoryWhereInput>>;
  OR?: InputMaybe<Array<FoodCategoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  food?: InputMaybe<FoodListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FoodCategoryWhereUniqueInput = {
  AND?: InputMaybe<Array<FoodCategoryWhereInput>>;
  NOT?: InputMaybe<Array<FoodCategoryWhereInput>>;
  OR?: InputMaybe<Array<FoodCategoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  food?: InputMaybe<FoodListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FoodCount = {
  __typename?: 'FoodCount';
  images: Scalars['Int']['output'];
  ingredients: Scalars['Int']['output'];
};

export type FoodCountAggregate = {
  __typename?: 'FoodCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  foodCategoryId: Scalars['Int']['output'];
  hasParking: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  latitude: Scalars['Int']['output'];
  longitude: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  productCapacity: Scalars['Int']['output'];
  shop: Scalars['Int']['output'];
  story: Scalars['Int']['output'];
  tambonId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type FoodCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  foodCategory?: InputMaybe<FoodCategoryCreateNestedOneWithoutFoodInput>;
  hasParking?: InputMaybe<HasParking>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<FoodImageCreateNestedManyWithoutFoodInput>;
  ingredients?: InputMaybe<IngredientsCreateNestedManyWithoutFoodInput>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  productCapacity?: InputMaybe<Scalars['Int']['input']>;
  shop: Scalars['String']['input'];
  story?: InputMaybe<Scalars['String']['input']>;
  tambon: TambonCreateNestedOneWithoutFoodInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutFoodsInput;
};

export type FoodCreateManyTambonInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  foodCategoryId?: InputMaybe<Scalars['String']['input']>;
  hasParking?: InputMaybe<HasParking>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  productCapacity?: InputMaybe<Scalars['Int']['input']>;
  shop: Scalars['String']['input'];
  story?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type FoodCreateManyTambonInputEnvelope = {
  data: Array<FoodCreateManyTambonInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FoodCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  foodCategoryId?: InputMaybe<Scalars['String']['input']>;
  hasParking?: InputMaybe<HasParking>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  productCapacity?: InputMaybe<Scalars['Int']['input']>;
  shop: Scalars['String']['input'];
  story?: InputMaybe<Scalars['String']['input']>;
  tambonId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FoodCreateManyUserInputEnvelope = {
  data: Array<FoodCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FoodCreateNestedManyWithoutTambonInput = {
  connect?: InputMaybe<Array<FoodWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FoodCreateOrConnectWithoutTambonInput>>;
  create?: InputMaybe<Array<FoodCreateWithoutTambonInput>>;
  createMany?: InputMaybe<FoodCreateManyTambonInputEnvelope>;
};

export type FoodCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<FoodWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FoodCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<FoodCreateWithoutUserInput>>;
  createMany?: InputMaybe<FoodCreateManyUserInputEnvelope>;
};

export type FoodCreateNestedOneWithoutImagesInput = {
  connect?: InputMaybe<FoodWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FoodCreateOrConnectWithoutImagesInput>;
  create?: InputMaybe<FoodCreateWithoutImagesInput>;
};

export type FoodCreateOrConnectWithoutImagesInput = {
  create: FoodCreateWithoutImagesInput;
  where: FoodWhereUniqueInput;
};

export type FoodCreateOrConnectWithoutTambonInput = {
  create: FoodCreateWithoutTambonInput;
  where: FoodWhereUniqueInput;
};

export type FoodCreateOrConnectWithoutUserInput = {
  create: FoodCreateWithoutUserInput;
  where: FoodWhereUniqueInput;
};

export type FoodCreateWithoutImagesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  foodCategory?: InputMaybe<FoodCategoryCreateNestedOneWithoutFoodInput>;
  hasParking?: InputMaybe<HasParking>;
  id?: InputMaybe<Scalars['String']['input']>;
  ingredients?: InputMaybe<IngredientsCreateNestedManyWithoutFoodInput>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  productCapacity?: InputMaybe<Scalars['Int']['input']>;
  shop: Scalars['String']['input'];
  story?: InputMaybe<Scalars['String']['input']>;
  tambon: TambonCreateNestedOneWithoutFoodInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutFoodsInput;
};

export type FoodCreateWithoutTambonInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  foodCategory?: InputMaybe<FoodCategoryCreateNestedOneWithoutFoodInput>;
  hasParking?: InputMaybe<HasParking>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<FoodImageCreateNestedManyWithoutFoodInput>;
  ingredients?: InputMaybe<IngredientsCreateNestedManyWithoutFoodInput>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  productCapacity?: InputMaybe<Scalars['Int']['input']>;
  shop: Scalars['String']['input'];
  story?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutFoodsInput;
};

export type FoodCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  foodCategory?: InputMaybe<FoodCategoryCreateNestedOneWithoutFoodInput>;
  hasParking?: InputMaybe<HasParking>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<FoodImageCreateNestedManyWithoutFoodInput>;
  ingredients?: InputMaybe<IngredientsCreateNestedManyWithoutFoodInput>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  productCapacity?: InputMaybe<Scalars['Int']['input']>;
  shop: Scalars['String']['input'];
  story?: InputMaybe<Scalars['String']['input']>;
  tambon: TambonCreateNestedOneWithoutFoodInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FoodImage = {
  __typename?: 'FoodImage';
  createdAt: Scalars['DateTime']['output'];
  food: Food;
  foodId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type FoodImageCountAggregate = {
  __typename?: 'FoodImageCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  foodId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
};

export type FoodImageCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  food: FoodCreateNestedOneWithoutImagesInput;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type FoodImageCreateManyFoodInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type FoodImageCreateManyFoodInputEnvelope = {
  data: Array<FoodImageCreateManyFoodInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FoodImageCreateNestedManyWithoutFoodInput = {
  connect?: InputMaybe<Array<FoodImageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FoodImageCreateOrConnectWithoutFoodInput>>;
  create?: InputMaybe<Array<FoodImageCreateWithoutFoodInput>>;
  createMany?: InputMaybe<FoodImageCreateManyFoodInputEnvelope>;
};

export type FoodImageCreateOrConnectWithoutFoodInput = {
  create: FoodImageCreateWithoutFoodInput;
  where: FoodImageWhereUniqueInput;
};

export type FoodImageCreateWithoutFoodInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type FoodImageListRelationFilter = {
  every?: InputMaybe<FoodImageWhereInput>;
  none?: InputMaybe<FoodImageWhereInput>;
  some?: InputMaybe<FoodImageWhereInput>;
};

export type FoodImageMaxAggregate = {
  __typename?: 'FoodImageMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  foodId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FoodImageMinAggregate = {
  __typename?: 'FoodImageMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  foodId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FoodImageScalarWhereInput = {
  AND?: InputMaybe<Array<FoodImageScalarWhereInput>>;
  NOT?: InputMaybe<Array<FoodImageScalarWhereInput>>;
  OR?: InputMaybe<Array<FoodImageScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  foodId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type FoodImageUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type FoodImageUpdateManyWithWhereWithoutFoodInput = {
  data: FoodImageUpdateManyMutationInput;
  where: FoodImageScalarWhereInput;
};

export type FoodImageUpdateManyWithoutFoodNestedInput = {
  connect?: InputMaybe<Array<FoodImageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FoodImageCreateOrConnectWithoutFoodInput>>;
  create?: InputMaybe<Array<FoodImageCreateWithoutFoodInput>>;
  createMany?: InputMaybe<FoodImageCreateManyFoodInputEnvelope>;
  delete?: InputMaybe<Array<FoodImageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FoodImageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FoodImageWhereUniqueInput>>;
  set?: InputMaybe<Array<FoodImageWhereUniqueInput>>;
  update?: InputMaybe<Array<FoodImageUpdateWithWhereUniqueWithoutFoodInput>>;
  updateMany?: InputMaybe<Array<FoodImageUpdateManyWithWhereWithoutFoodInput>>;
  upsert?: InputMaybe<Array<FoodImageUpsertWithWhereUniqueWithoutFoodInput>>;
};

export type FoodImageUpdateWithWhereUniqueWithoutFoodInput = {
  data: FoodImageUpdateWithoutFoodInput;
  where: FoodImageWhereUniqueInput;
};

export type FoodImageUpdateWithoutFoodInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type FoodImageUpsertWithWhereUniqueWithoutFoodInput = {
  create: FoodImageCreateWithoutFoodInput;
  update: FoodImageUpdateWithoutFoodInput;
  where: FoodImageWhereUniqueInput;
};

export type FoodImageWhereInput = {
  AND?: InputMaybe<Array<FoodImageWhereInput>>;
  NOT?: InputMaybe<Array<FoodImageWhereInput>>;
  OR?: InputMaybe<Array<FoodImageWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  food?: InputMaybe<FoodRelationFilter>;
  foodId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type FoodImageWhereUniqueInput = {
  AND?: InputMaybe<Array<FoodImageWhereInput>>;
  NOT?: InputMaybe<Array<FoodImageWhereInput>>;
  OR?: InputMaybe<Array<FoodImageWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  food?: InputMaybe<FoodRelationFilter>;
  foodId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type FoodListRelationFilter = {
  every?: InputMaybe<FoodWhereInput>;
  none?: InputMaybe<FoodWhereInput>;
  some?: InputMaybe<FoodWhereInput>;
};

export type FoodMaxAggregate = {
  __typename?: 'FoodMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  foodCategoryId?: Maybe<Scalars['String']['output']>;
  hasParking?: Maybe<HasParking>;
  id?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productCapacity?: Maybe<Scalars['Int']['output']>;
  shop?: Maybe<Scalars['String']['output']>;
  story?: Maybe<Scalars['String']['output']>;
  tambonId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type FoodMinAggregate = {
  __typename?: 'FoodMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  foodCategoryId?: Maybe<Scalars['String']['output']>;
  hasParking?: Maybe<HasParking>;
  id?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productCapacity?: Maybe<Scalars['Int']['output']>;
  shop?: Maybe<Scalars['String']['output']>;
  story?: Maybe<Scalars['String']['output']>;
  tambonId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type FoodNullableRelationFilter = {
  is?: InputMaybe<FoodWhereInput>;
  isNot?: InputMaybe<FoodWhereInput>;
};

export type FoodRelationFilter = {
  is?: InputMaybe<FoodWhereInput>;
  isNot?: InputMaybe<FoodWhereInput>;
};

export type FoodScalarWhereInput = {
  AND?: InputMaybe<Array<FoodScalarWhereInput>>;
  NOT?: InputMaybe<Array<FoodScalarWhereInput>>;
  OR?: InputMaybe<Array<FoodScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  foodCategoryId?: InputMaybe<StringNullableFilter>;
  hasParking?: InputMaybe<EnumHasParkingNullableFilter>;
  id?: InputMaybe<StringFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  productCapacity?: InputMaybe<IntNullableFilter>;
  shop?: InputMaybe<StringFilter>;
  story?: InputMaybe<StringNullableFilter>;
  tambonId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type FoodSumAggregate = {
  __typename?: 'FoodSumAggregate';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  productCapacity?: Maybe<Scalars['Int']['output']>;
};

export type FoodUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  foodCategory?: InputMaybe<FoodCategoryUpdateOneWithoutFoodNestedInput>;
  hasParking?: InputMaybe<NullableEnumHasParkingFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  images?: InputMaybe<FoodImageUpdateManyWithoutFoodNestedInput>;
  ingredients?: InputMaybe<IngredientsUpdateManyWithoutFoodNestedInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productCapacity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  shop?: InputMaybe<StringFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tambon?: InputMaybe<TambonUpdateOneRequiredWithoutFoodNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutFoodsNestedInput>;
};

export type FoodUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hasParking?: InputMaybe<NullableEnumHasParkingFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productCapacity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  shop?: InputMaybe<StringFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FoodUpdateManyWithWhereWithoutTambonInput = {
  data: FoodUpdateManyMutationInput;
  where: FoodScalarWhereInput;
};

export type FoodUpdateManyWithWhereWithoutUserInput = {
  data: FoodUpdateManyMutationInput;
  where: FoodScalarWhereInput;
};

export type FoodUpdateManyWithoutTambonNestedInput = {
  connect?: InputMaybe<Array<FoodWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FoodCreateOrConnectWithoutTambonInput>>;
  create?: InputMaybe<Array<FoodCreateWithoutTambonInput>>;
  createMany?: InputMaybe<FoodCreateManyTambonInputEnvelope>;
  delete?: InputMaybe<Array<FoodWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FoodScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FoodWhereUniqueInput>>;
  set?: InputMaybe<Array<FoodWhereUniqueInput>>;
  update?: InputMaybe<Array<FoodUpdateWithWhereUniqueWithoutTambonInput>>;
  updateMany?: InputMaybe<Array<FoodUpdateManyWithWhereWithoutTambonInput>>;
  upsert?: InputMaybe<Array<FoodUpsertWithWhereUniqueWithoutTambonInput>>;
};

export type FoodUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<FoodWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FoodCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<FoodCreateWithoutUserInput>>;
  createMany?: InputMaybe<FoodCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<FoodWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FoodScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FoodWhereUniqueInput>>;
  set?: InputMaybe<Array<FoodWhereUniqueInput>>;
  update?: InputMaybe<Array<FoodUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<FoodUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<FoodUpsertWithWhereUniqueWithoutUserInput>>;
};

export type FoodUpdateWithWhereUniqueWithoutTambonInput = {
  data: FoodUpdateWithoutTambonInput;
  where: FoodWhereUniqueInput;
};

export type FoodUpdateWithWhereUniqueWithoutUserInput = {
  data: FoodUpdateWithoutUserInput;
  where: FoodWhereUniqueInput;
};

export type FoodUpdateWithoutTambonInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  foodCategory?: InputMaybe<FoodCategoryUpdateOneWithoutFoodNestedInput>;
  hasParking?: InputMaybe<NullableEnumHasParkingFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  images?: InputMaybe<FoodImageUpdateManyWithoutFoodNestedInput>;
  ingredients?: InputMaybe<IngredientsUpdateManyWithoutFoodNestedInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productCapacity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  shop?: InputMaybe<StringFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutFoodsNestedInput>;
};

export type FoodUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  foodCategory?: InputMaybe<FoodCategoryUpdateOneWithoutFoodNestedInput>;
  hasParking?: InputMaybe<NullableEnumHasParkingFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  images?: InputMaybe<FoodImageUpdateManyWithoutFoodNestedInput>;
  ingredients?: InputMaybe<IngredientsUpdateManyWithoutFoodNestedInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productCapacity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  shop?: InputMaybe<StringFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tambon?: InputMaybe<TambonUpdateOneRequiredWithoutFoodNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FoodUpsertWithWhereUniqueWithoutTambonInput = {
  create: FoodCreateWithoutTambonInput;
  update: FoodUpdateWithoutTambonInput;
  where: FoodWhereUniqueInput;
};

export type FoodUpsertWithWhereUniqueWithoutUserInput = {
  create: FoodCreateWithoutUserInput;
  update: FoodUpdateWithoutUserInput;
  where: FoodWhereUniqueInput;
};

export type FoodWhereInput = {
  AND?: InputMaybe<Array<FoodWhereInput>>;
  NOT?: InputMaybe<Array<FoodWhereInput>>;
  OR?: InputMaybe<Array<FoodWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  foodCategory?: InputMaybe<FoodCategoryNullableRelationFilter>;
  foodCategoryId?: InputMaybe<StringNullableFilter>;
  hasParking?: InputMaybe<EnumHasParkingNullableFilter>;
  id?: InputMaybe<StringFilter>;
  images?: InputMaybe<FoodImageListRelationFilter>;
  ingredients?: InputMaybe<IngredientsListRelationFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  productCapacity?: InputMaybe<IntNullableFilter>;
  shop?: InputMaybe<StringFilter>;
  story?: InputMaybe<StringNullableFilter>;
  tambon?: InputMaybe<TambonRelationFilter>;
  tambonId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type FoodWhereUniqueInput = {
  AND?: InputMaybe<Array<FoodWhereInput>>;
  NOT?: InputMaybe<Array<FoodWhereInput>>;
  OR?: InputMaybe<Array<FoodWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  foodCategory?: InputMaybe<FoodCategoryNullableRelationFilter>;
  foodCategoryId?: InputMaybe<StringNullableFilter>;
  hasParking?: InputMaybe<EnumHasParkingNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<FoodImageListRelationFilter>;
  ingredients?: InputMaybe<IngredientsListRelationFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  productCapacity?: InputMaybe<IntNullableFilter>;
  shop?: InputMaybe<StringFilter>;
  story?: InputMaybe<StringNullableFilter>;
  tambon?: InputMaybe<TambonRelationFilter>;
  tambonId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export enum HasOwner {
  No = 'NO',
  Unknown = 'UNKNOWN',
  Yes = 'YES'
}

export enum HasParking {
  No = 'NO',
  Unknown = 'UNKNOWN',
  Yes = 'YES'
}

export type Ingredients = {
  __typename?: 'Ingredients';
  createdAt: Scalars['DateTime']['output'];
  food?: Maybe<Food>;
  foodId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type IngredientsCountAggregate = {
  __typename?: 'IngredientsCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  foodId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type IngredientsCreateManyFoodInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IngredientsCreateManyFoodInputEnvelope = {
  data: Array<IngredientsCreateManyFoodInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IngredientsCreateNestedManyWithoutFoodInput = {
  connect?: InputMaybe<Array<IngredientsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientsCreateOrConnectWithoutFoodInput>>;
  create?: InputMaybe<Array<IngredientsCreateWithoutFoodInput>>;
  createMany?: InputMaybe<IngredientsCreateManyFoodInputEnvelope>;
};

export type IngredientsCreateOrConnectWithoutFoodInput = {
  create: IngredientsCreateWithoutFoodInput;
  where: IngredientsWhereUniqueInput;
};

export type IngredientsCreateWithoutFoodInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IngredientsListRelationFilter = {
  every?: InputMaybe<IngredientsWhereInput>;
  none?: InputMaybe<IngredientsWhereInput>;
  some?: InputMaybe<IngredientsWhereInput>;
};

export type IngredientsMaxAggregate = {
  __typename?: 'IngredientsMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  foodId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type IngredientsMinAggregate = {
  __typename?: 'IngredientsMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  foodId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type IngredientsScalarWhereInput = {
  AND?: InputMaybe<Array<IngredientsScalarWhereInput>>;
  NOT?: InputMaybe<Array<IngredientsScalarWhereInput>>;
  OR?: InputMaybe<Array<IngredientsScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  foodId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type IngredientsUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type IngredientsUpdateManyWithWhereWithoutFoodInput = {
  data: IngredientsUpdateManyMutationInput;
  where: IngredientsScalarWhereInput;
};

export type IngredientsUpdateManyWithoutFoodNestedInput = {
  connect?: InputMaybe<Array<IngredientsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IngredientsCreateOrConnectWithoutFoodInput>>;
  create?: InputMaybe<Array<IngredientsCreateWithoutFoodInput>>;
  createMany?: InputMaybe<IngredientsCreateManyFoodInputEnvelope>;
  delete?: InputMaybe<Array<IngredientsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IngredientsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IngredientsWhereUniqueInput>>;
  set?: InputMaybe<Array<IngredientsWhereUniqueInput>>;
  update?: InputMaybe<Array<IngredientsUpdateWithWhereUniqueWithoutFoodInput>>;
  updateMany?: InputMaybe<Array<IngredientsUpdateManyWithWhereWithoutFoodInput>>;
  upsert?: InputMaybe<Array<IngredientsUpsertWithWhereUniqueWithoutFoodInput>>;
};

export type IngredientsUpdateWithWhereUniqueWithoutFoodInput = {
  data: IngredientsUpdateWithoutFoodInput;
  where: IngredientsWhereUniqueInput;
};

export type IngredientsUpdateWithoutFoodInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type IngredientsUpsertWithWhereUniqueWithoutFoodInput = {
  create: IngredientsCreateWithoutFoodInput;
  update: IngredientsUpdateWithoutFoodInput;
  where: IngredientsWhereUniqueInput;
};

export type IngredientsWhereInput = {
  AND?: InputMaybe<Array<IngredientsWhereInput>>;
  NOT?: InputMaybe<Array<IngredientsWhereInput>>;
  OR?: InputMaybe<Array<IngredientsWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  food?: InputMaybe<FoodNullableRelationFilter>;
  foodId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type IngredientsWhereUniqueInput = {
  AND?: InputMaybe<Array<IngredientsWhereInput>>;
  NOT?: InputMaybe<Array<IngredientsWhereInput>>;
  OR?: InputMaybe<Array<IngredientsWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  food?: InputMaybe<FoodNullableRelationFilter>;
  foodId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InputAmphoeIdAndKeyword = {
  amphoeId: Scalars['String']['input'];
  keyword: Scalars['String']['input'];
};

export type InputProvinceIdAndKeyword = {
  keyword: Scalars['String']['input'];
  provinceId: Scalars['String']['input'];
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createFacility: Facility;
  createFood: Food;
  createFoodCategory: FoodCategory;
  createFoodImage: FoodImage;
  createPlant: Plant;
  createTravel: Travel;
  createTravelType: TravelType;
  createUser: User;
  createWisdom: Wisdom;
  createWisdomCategory: WisdomCategory;
  deleteFacility: Facility;
  deleteFood: Food;
  deleteFoodCategory: FoodCategory;
  deleteFoodImageByFoodId: Scalars['Boolean']['output'];
  deletePlant: Plant;
  deleteTravel: Travel;
  deleteTravelType: TravelType;
  deleteUser: User;
  deleteWisdom: Wisdom;
  deleteWisdomCategory: WisdomCategory;
  forgotPassword: User;
  login: Scalars['JSON']['output'];
  register: User;
  resetPassword: User;
  updateFacility: Facility;
  updateFood: Food;
  updateFoodCategory: FoodCategory;
  updatePlant: Plant;
  updateTravel: Travel;
  updateTravelType: TravelType;
  updateUser: User;
  updateWisdom: Wisdom;
  updateWisdomCategory: WisdomCategory;
};


export type MutationCreateFacilityArgs = {
  input: FacilityCreateInput;
};


export type MutationCreateFoodArgs = {
  input: FoodCreateInput;
};


export type MutationCreateFoodCategoryArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateFoodImageArgs = {
  input: FoodImageCreateInput;
};


export type MutationCreatePlantArgs = {
  input: PlantCreateInput;
};


export type MutationCreateTravelArgs = {
  input: TravelCreateInput;
};


export type MutationCreateTravelTypeArgs = {
  input: TravelTypeCreateInput;
};


export type MutationCreateUserArgs = {
  input: UserCreateInput;
};


export type MutationCreateWisdomArgs = {
  input: WisdomCreateInput;
};


export type MutationCreateWisdomCategoryArgs = {
  name: Scalars['String']['input'];
};


export type MutationDeleteFacilityArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteFoodArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteFoodCategoryArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteFoodImageByFoodIdArgs = {
  foodId: Scalars['String']['input'];
};


export type MutationDeletePlantArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteTravelArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteTravelTypeArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteWisdomArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteWisdomCategoryArgs = {
  id: Scalars['String']['input'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRegisterArgs = {
  userInput: UserCreateInput;
};


export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationUpdateFacilityArgs = {
  id: Scalars['String']['input'];
  input: FacilityUpdateInput;
};


export type MutationUpdateFoodArgs = {
  id: Scalars['String']['input'];
  input: FoodUpdateInput;
};


export type MutationUpdateFoodCategoryArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdatePlantArgs = {
  id: Scalars['String']['input'];
  input: PlantUpdateInput;
};


export type MutationUpdateTravelArgs = {
  id: Scalars['String']['input'];
  input: TravelUpdateInput;
};


export type MutationUpdateTravelTypeArgs = {
  id: Scalars['String']['input'];
  input: TravelTypeUpdateInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['String']['input'];
  input: UserUpdateInput;
};


export type MutationUpdateWisdomArgs = {
  id: Scalars['String']['input'];
  input: WisdomUpdateInput;
};


export type MutationUpdateWisdomCategoryArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedEnumHasOwnerFilter = {
  equals?: InputMaybe<HasOwner>;
  in?: InputMaybe<Array<HasOwner>>;
  not?: InputMaybe<NestedEnumHasOwnerFilter>;
  notIn?: InputMaybe<Array<HasOwner>>;
};

export type NestedEnumHasParkingNullableFilter = {
  equals?: InputMaybe<HasParking>;
  in?: InputMaybe<Array<HasParking>>;
  not?: InputMaybe<NestedEnumHasParkingNullableFilter>;
  notIn?: InputMaybe<Array<HasParking>>;
};

export type NestedEnumPatentedStatusFilter = {
  equals?: InputMaybe<PatentedStatus>;
  in?: InputMaybe<Array<PatentedStatus>>;
  not?: InputMaybe<NestedEnumPatentedStatusFilter>;
  notIn?: InputMaybe<Array<PatentedStatus>>;
};

export type NestedEnumPlantImageKindFilter = {
  equals?: InputMaybe<PlantImageKind>;
  in?: InputMaybe<Array<PlantImageKind>>;
  not?: InputMaybe<NestedEnumPlantImageKindFilter>;
  notIn?: InputMaybe<Array<PlantImageKind>>;
};

export type NestedEnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type NestedEnumWisdomPrFilter = {
  equals?: InputMaybe<WisdomPr>;
  in?: InputMaybe<Array<WisdomPr>>;
  not?: InputMaybe<NestedEnumWisdomPrFilter>;
  notIn?: InputMaybe<Array<WisdomPr>>;
};

export type NestedEnumWisdomStatusFilter = {
  equals?: InputMaybe<WisdomStatus>;
  in?: InputMaybe<Array<WisdomStatus>>;
  not?: InputMaybe<NestedEnumWisdomStatusFilter>;
  notIn?: InputMaybe<Array<WisdomStatus>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NullableEnumHasParkingFieldUpdateOperationsInput = {
  set?: InputMaybe<HasParking>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export enum PatentedStatus {
  No = 'NO',
  Unknown = 'UNKNOWN',
  Yes = 'YES'
}

export type Plant = {
  __typename?: 'Plant';
  _count: PlantCount;
  createdAt: Scalars['DateTime']['output'];
  foundFrequencyInMonthEnd?: Maybe<Scalars['Int']['output']>;
  foundFrequencyInMonthStart?: Maybe<Scalars['Int']['output']>;
  hasOwner: HasOwner;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<PlantImage>>;
  informant?: Maybe<Scalars['String']['output']>;
  kindOfUses?: Maybe<Array<PlantOnPlantKindOfUse>>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  ownerName?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  tambon: Tambon;
  tambonId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type PlantAvgAggregate = {
  __typename?: 'PlantAvgAggregate';
  foundFrequencyInMonthEnd?: Maybe<Scalars['Float']['output']>;
  foundFrequencyInMonthStart?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

export type PlantCount = {
  __typename?: 'PlantCount';
  images: Scalars['Int']['output'];
  kindOfUses: Scalars['Int']['output'];
};

export type PlantCountAggregate = {
  __typename?: 'PlantCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  foundFrequencyInMonthEnd: Scalars['Int']['output'];
  foundFrequencyInMonthStart: Scalars['Int']['output'];
  hasOwner: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  informant: Scalars['Int']['output'];
  latitude: Scalars['Int']['output'];
  longitude: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  ownerName: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  tambonId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type PlantCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  foundFrequencyInMonthEnd?: InputMaybe<Scalars['Int']['input']>;
  foundFrequencyInMonthStart?: InputMaybe<Scalars['Int']['input']>;
  hasOwner: HasOwner;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<PlantImageCreateNestedManyWithoutPlantInput>;
  informant?: InputMaybe<Scalars['String']['input']>;
  kindOfUses?: InputMaybe<PlantOnPlantKindOfUseCreateNestedManyWithoutPlantInput>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  ownerName?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  tambon: TambonCreateNestedOneWithoutPlantInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutPlantsInput;
};

export type PlantCreateManyTambonInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  foundFrequencyInMonthEnd?: InputMaybe<Scalars['Int']['input']>;
  foundFrequencyInMonthStart?: InputMaybe<Scalars['Int']['input']>;
  hasOwner: HasOwner;
  id?: InputMaybe<Scalars['String']['input']>;
  informant?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  ownerName?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type PlantCreateManyTambonInputEnvelope = {
  data: Array<PlantCreateManyTambonInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PlantCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  foundFrequencyInMonthEnd?: InputMaybe<Scalars['Int']['input']>;
  foundFrequencyInMonthStart?: InputMaybe<Scalars['Int']['input']>;
  hasOwner: HasOwner;
  id?: InputMaybe<Scalars['String']['input']>;
  informant?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  ownerName?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  tambonId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PlantCreateManyUserInputEnvelope = {
  data: Array<PlantCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PlantCreateNestedManyWithoutTambonInput = {
  connect?: InputMaybe<Array<PlantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlantCreateOrConnectWithoutTambonInput>>;
  create?: InputMaybe<Array<PlantCreateWithoutTambonInput>>;
  createMany?: InputMaybe<PlantCreateManyTambonInputEnvelope>;
};

export type PlantCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<PlantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlantCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PlantCreateWithoutUserInput>>;
  createMany?: InputMaybe<PlantCreateManyUserInputEnvelope>;
};

export type PlantCreateOrConnectWithoutTambonInput = {
  create: PlantCreateWithoutTambonInput;
  where: PlantWhereUniqueInput;
};

export type PlantCreateOrConnectWithoutUserInput = {
  create: PlantCreateWithoutUserInput;
  where: PlantWhereUniqueInput;
};

export type PlantCreateWithoutTambonInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  foundFrequencyInMonthEnd?: InputMaybe<Scalars['Int']['input']>;
  foundFrequencyInMonthStart?: InputMaybe<Scalars['Int']['input']>;
  hasOwner: HasOwner;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<PlantImageCreateNestedManyWithoutPlantInput>;
  informant?: InputMaybe<Scalars['String']['input']>;
  kindOfUses?: InputMaybe<PlantOnPlantKindOfUseCreateNestedManyWithoutPlantInput>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  ownerName?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutPlantsInput;
};

export type PlantCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  foundFrequencyInMonthEnd?: InputMaybe<Scalars['Int']['input']>;
  foundFrequencyInMonthStart?: InputMaybe<Scalars['Int']['input']>;
  hasOwner: HasOwner;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<PlantImageCreateNestedManyWithoutPlantInput>;
  informant?: InputMaybe<Scalars['String']['input']>;
  kindOfUses?: InputMaybe<PlantOnPlantKindOfUseCreateNestedManyWithoutPlantInput>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  ownerName?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  tambon: TambonCreateNestedOneWithoutPlantInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PlantImage = {
  __typename?: 'PlantImage';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  kind: PlantImageKind;
  plant: Plant;
  plantId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type PlantImageCountAggregate = {
  __typename?: 'PlantImageCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  kind: Scalars['Int']['output'];
  plantId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
};

export type PlantImageCreateManyPlantInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<PlantImageKind>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type PlantImageCreateManyPlantInputEnvelope = {
  data: Array<PlantImageCreateManyPlantInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PlantImageCreateNestedManyWithoutPlantInput = {
  connect?: InputMaybe<Array<PlantImageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlantImageCreateOrConnectWithoutPlantInput>>;
  create?: InputMaybe<Array<PlantImageCreateWithoutPlantInput>>;
  createMany?: InputMaybe<PlantImageCreateManyPlantInputEnvelope>;
};

export type PlantImageCreateOrConnectWithoutPlantInput = {
  create: PlantImageCreateWithoutPlantInput;
  where: PlantImageWhereUniqueInput;
};

export type PlantImageCreateWithoutPlantInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<PlantImageKind>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export enum PlantImageKind {
  Flower = 'FLOWER',
  Fruit = 'FRUIT',
  Leaf = 'LEAF',
  Overall = 'OVERALL',
  Stem = 'STEM'
}

export type PlantImageListRelationFilter = {
  every?: InputMaybe<PlantImageWhereInput>;
  none?: InputMaybe<PlantImageWhereInput>;
  some?: InputMaybe<PlantImageWhereInput>;
};

export type PlantImageMaxAggregate = {
  __typename?: 'PlantImageMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  kind?: Maybe<PlantImageKind>;
  plantId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type PlantImageMinAggregate = {
  __typename?: 'PlantImageMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  kind?: Maybe<PlantImageKind>;
  plantId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type PlantImageScalarWhereInput = {
  AND?: InputMaybe<Array<PlantImageScalarWhereInput>>;
  NOT?: InputMaybe<Array<PlantImageScalarWhereInput>>;
  OR?: InputMaybe<Array<PlantImageScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  kind?: InputMaybe<EnumPlantImageKindFilter>;
  plantId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type PlantImageUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  kind?: InputMaybe<EnumPlantImageKindFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PlantImageUpdateManyWithWhereWithoutPlantInput = {
  data: PlantImageUpdateManyMutationInput;
  where: PlantImageScalarWhereInput;
};

export type PlantImageUpdateManyWithoutPlantNestedInput = {
  connect?: InputMaybe<Array<PlantImageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlantImageCreateOrConnectWithoutPlantInput>>;
  create?: InputMaybe<Array<PlantImageCreateWithoutPlantInput>>;
  createMany?: InputMaybe<PlantImageCreateManyPlantInputEnvelope>;
  delete?: InputMaybe<Array<PlantImageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PlantImageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PlantImageWhereUniqueInput>>;
  set?: InputMaybe<Array<PlantImageWhereUniqueInput>>;
  update?: InputMaybe<Array<PlantImageUpdateWithWhereUniqueWithoutPlantInput>>;
  updateMany?: InputMaybe<Array<PlantImageUpdateManyWithWhereWithoutPlantInput>>;
  upsert?: InputMaybe<Array<PlantImageUpsertWithWhereUniqueWithoutPlantInput>>;
};

export type PlantImageUpdateWithWhereUniqueWithoutPlantInput = {
  data: PlantImageUpdateWithoutPlantInput;
  where: PlantImageWhereUniqueInput;
};

export type PlantImageUpdateWithoutPlantInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  kind?: InputMaybe<EnumPlantImageKindFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PlantImageUpsertWithWhereUniqueWithoutPlantInput = {
  create: PlantImageCreateWithoutPlantInput;
  update: PlantImageUpdateWithoutPlantInput;
  where: PlantImageWhereUniqueInput;
};

export type PlantImageWhereInput = {
  AND?: InputMaybe<Array<PlantImageWhereInput>>;
  NOT?: InputMaybe<Array<PlantImageWhereInput>>;
  OR?: InputMaybe<Array<PlantImageWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  kind?: InputMaybe<EnumPlantImageKindFilter>;
  plant?: InputMaybe<PlantRelationFilter>;
  plantId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type PlantImageWhereUniqueInput = {
  AND?: InputMaybe<Array<PlantImageWhereInput>>;
  NOT?: InputMaybe<Array<PlantImageWhereInput>>;
  OR?: InputMaybe<Array<PlantImageWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<EnumPlantImageKindFilter>;
  plant?: InputMaybe<PlantRelationFilter>;
  plantId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type PlantKindOfUse = {
  __typename?: 'PlantKindOfUse';
  _count: PlantKindOfUseCount;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  plantOnPlantKindOfUses?: Maybe<Array<PlantOnPlantKindOfUse>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PlantKindOfUseCount = {
  __typename?: 'PlantKindOfUseCount';
  plantOnPlantKindOfUses: Scalars['Int']['output'];
};

export type PlantKindOfUseCountAggregate = {
  __typename?: 'PlantKindOfUseCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type PlantKindOfUseCreateNestedOneWithoutPlantOnPlantKindOfUsesInput = {
  connect?: InputMaybe<PlantKindOfUseWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PlantKindOfUseCreateOrConnectWithoutPlantOnPlantKindOfUsesInput>;
  create?: InputMaybe<PlantKindOfUseCreateWithoutPlantOnPlantKindOfUsesInput>;
};

export type PlantKindOfUseCreateOrConnectWithoutPlantOnPlantKindOfUsesInput = {
  create: PlantKindOfUseCreateWithoutPlantOnPlantKindOfUsesInput;
  where: PlantKindOfUseWhereUniqueInput;
};

export type PlantKindOfUseCreateWithoutPlantOnPlantKindOfUsesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PlantKindOfUseMaxAggregate = {
  __typename?: 'PlantKindOfUseMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PlantKindOfUseMinAggregate = {
  __typename?: 'PlantKindOfUseMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PlantKindOfUseRelationFilter = {
  is?: InputMaybe<PlantKindOfUseWhereInput>;
  isNot?: InputMaybe<PlantKindOfUseWhereInput>;
};

export type PlantKindOfUseUpdateOneRequiredWithoutPlantOnPlantKindOfUsesNestedInput = {
  connect?: InputMaybe<PlantKindOfUseWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PlantKindOfUseCreateOrConnectWithoutPlantOnPlantKindOfUsesInput>;
  create?: InputMaybe<PlantKindOfUseCreateWithoutPlantOnPlantKindOfUsesInput>;
  update?: InputMaybe<PlantKindOfUseUpdateToOneWithWhereWithoutPlantOnPlantKindOfUsesInput>;
  upsert?: InputMaybe<PlantKindOfUseUpsertWithoutPlantOnPlantKindOfUsesInput>;
};

export type PlantKindOfUseUpdateToOneWithWhereWithoutPlantOnPlantKindOfUsesInput = {
  data: PlantKindOfUseUpdateWithoutPlantOnPlantKindOfUsesInput;
  where?: InputMaybe<PlantKindOfUseWhereInput>;
};

export type PlantKindOfUseUpdateWithoutPlantOnPlantKindOfUsesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PlantKindOfUseUpsertWithoutPlantOnPlantKindOfUsesInput = {
  create: PlantKindOfUseCreateWithoutPlantOnPlantKindOfUsesInput;
  update: PlantKindOfUseUpdateWithoutPlantOnPlantKindOfUsesInput;
  where?: InputMaybe<PlantKindOfUseWhereInput>;
};

export type PlantKindOfUseWhereInput = {
  AND?: InputMaybe<Array<PlantKindOfUseWhereInput>>;
  NOT?: InputMaybe<Array<PlantKindOfUseWhereInput>>;
  OR?: InputMaybe<Array<PlantKindOfUseWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  plantOnPlantKindOfUses?: InputMaybe<PlantOnPlantKindOfUseListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PlantKindOfUseWhereUniqueInput = {
  AND?: InputMaybe<Array<PlantKindOfUseWhereInput>>;
  NOT?: InputMaybe<Array<PlantKindOfUseWhereInput>>;
  OR?: InputMaybe<Array<PlantKindOfUseWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  plantOnPlantKindOfUses?: InputMaybe<PlantOnPlantKindOfUseListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PlantListRelationFilter = {
  every?: InputMaybe<PlantWhereInput>;
  none?: InputMaybe<PlantWhereInput>;
  some?: InputMaybe<PlantWhereInput>;
};

export type PlantMaxAggregate = {
  __typename?: 'PlantMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  foundFrequencyInMonthEnd?: Maybe<Scalars['Int']['output']>;
  foundFrequencyInMonthStart?: Maybe<Scalars['Int']['output']>;
  hasOwner?: Maybe<HasOwner>;
  id?: Maybe<Scalars['String']['output']>;
  informant?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  tambonId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type PlantMinAggregate = {
  __typename?: 'PlantMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  foundFrequencyInMonthEnd?: Maybe<Scalars['Int']['output']>;
  foundFrequencyInMonthStart?: Maybe<Scalars['Int']['output']>;
  hasOwner?: Maybe<HasOwner>;
  id?: Maybe<Scalars['String']['output']>;
  informant?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  tambonId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type PlantOnPlantKindOfUse = {
  __typename?: 'PlantOnPlantKindOfUse';
  createdAt: Scalars['DateTime']['output'];
  plant: Plant;
  plantId: Scalars['String']['output'];
  plantKind: PlantKindOfUse;
  plantKindId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PlantOnPlantKindOfUseCountAggregate = {
  __typename?: 'PlantOnPlantKindOfUseCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  plantId: Scalars['Int']['output'];
  plantKindId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type PlantOnPlantKindOfUseCreateManyPlantInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  plantKindId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PlantOnPlantKindOfUseCreateManyPlantInputEnvelope = {
  data: Array<PlantOnPlantKindOfUseCreateManyPlantInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PlantOnPlantKindOfUseCreateNestedManyWithoutPlantInput = {
  connect?: InputMaybe<Array<PlantOnPlantKindOfUseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlantOnPlantKindOfUseCreateOrConnectWithoutPlantInput>>;
  create?: InputMaybe<Array<PlantOnPlantKindOfUseCreateWithoutPlantInput>>;
  createMany?: InputMaybe<PlantOnPlantKindOfUseCreateManyPlantInputEnvelope>;
};

export type PlantOnPlantKindOfUseCreateOrConnectWithoutPlantInput = {
  create: PlantOnPlantKindOfUseCreateWithoutPlantInput;
  where: PlantOnPlantKindOfUseWhereUniqueInput;
};

export type PlantOnPlantKindOfUseCreateWithoutPlantInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  plantKind: PlantKindOfUseCreateNestedOneWithoutPlantOnPlantKindOfUsesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PlantOnPlantKindOfUseListRelationFilter = {
  every?: InputMaybe<PlantOnPlantKindOfUseWhereInput>;
  none?: InputMaybe<PlantOnPlantKindOfUseWhereInput>;
  some?: InputMaybe<PlantOnPlantKindOfUseWhereInput>;
};

export type PlantOnPlantKindOfUseMaxAggregate = {
  __typename?: 'PlantOnPlantKindOfUseMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  plantId?: Maybe<Scalars['String']['output']>;
  plantKindId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PlantOnPlantKindOfUseMinAggregate = {
  __typename?: 'PlantOnPlantKindOfUseMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  plantId?: Maybe<Scalars['String']['output']>;
  plantKindId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PlantOnPlantKindOfUsePlantIdPlantKindIdCompoundUniqueInput = {
  plantId: Scalars['String']['input'];
  plantKindId: Scalars['String']['input'];
};

export type PlantOnPlantKindOfUseScalarWhereInput = {
  AND?: InputMaybe<Array<PlantOnPlantKindOfUseScalarWhereInput>>;
  NOT?: InputMaybe<Array<PlantOnPlantKindOfUseScalarWhereInput>>;
  OR?: InputMaybe<Array<PlantOnPlantKindOfUseScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  plantId?: InputMaybe<StringFilter>;
  plantKindId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PlantOnPlantKindOfUseUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PlantOnPlantKindOfUseUpdateManyWithWhereWithoutPlantInput = {
  data: PlantOnPlantKindOfUseUpdateManyMutationInput;
  where: PlantOnPlantKindOfUseScalarWhereInput;
};

export type PlantOnPlantKindOfUseUpdateManyWithoutPlantNestedInput = {
  connect?: InputMaybe<Array<PlantOnPlantKindOfUseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlantOnPlantKindOfUseCreateOrConnectWithoutPlantInput>>;
  create?: InputMaybe<Array<PlantOnPlantKindOfUseCreateWithoutPlantInput>>;
  createMany?: InputMaybe<PlantOnPlantKindOfUseCreateManyPlantInputEnvelope>;
  delete?: InputMaybe<Array<PlantOnPlantKindOfUseWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PlantOnPlantKindOfUseScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PlantOnPlantKindOfUseWhereUniqueInput>>;
  set?: InputMaybe<Array<PlantOnPlantKindOfUseWhereUniqueInput>>;
  update?: InputMaybe<Array<PlantOnPlantKindOfUseUpdateWithWhereUniqueWithoutPlantInput>>;
  updateMany?: InputMaybe<Array<PlantOnPlantKindOfUseUpdateManyWithWhereWithoutPlantInput>>;
  upsert?: InputMaybe<Array<PlantOnPlantKindOfUseUpsertWithWhereUniqueWithoutPlantInput>>;
};

export type PlantOnPlantKindOfUseUpdateWithWhereUniqueWithoutPlantInput = {
  data: PlantOnPlantKindOfUseUpdateWithoutPlantInput;
  where: PlantOnPlantKindOfUseWhereUniqueInput;
};

export type PlantOnPlantKindOfUseUpdateWithoutPlantInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  plantKind?: InputMaybe<PlantKindOfUseUpdateOneRequiredWithoutPlantOnPlantKindOfUsesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PlantOnPlantKindOfUseUpsertWithWhereUniqueWithoutPlantInput = {
  create: PlantOnPlantKindOfUseCreateWithoutPlantInput;
  update: PlantOnPlantKindOfUseUpdateWithoutPlantInput;
  where: PlantOnPlantKindOfUseWhereUniqueInput;
};

export type PlantOnPlantKindOfUseWhereInput = {
  AND?: InputMaybe<Array<PlantOnPlantKindOfUseWhereInput>>;
  NOT?: InputMaybe<Array<PlantOnPlantKindOfUseWhereInput>>;
  OR?: InputMaybe<Array<PlantOnPlantKindOfUseWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  plant?: InputMaybe<PlantRelationFilter>;
  plantId?: InputMaybe<StringFilter>;
  plantKind?: InputMaybe<PlantKindOfUseRelationFilter>;
  plantKindId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PlantOnPlantKindOfUseWhereUniqueInput = {
  AND?: InputMaybe<Array<PlantOnPlantKindOfUseWhereInput>>;
  NOT?: InputMaybe<Array<PlantOnPlantKindOfUseWhereInput>>;
  OR?: InputMaybe<Array<PlantOnPlantKindOfUseWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  plant?: InputMaybe<PlantRelationFilter>;
  plantId?: InputMaybe<StringFilter>;
  plantId_plantKindId?: InputMaybe<PlantOnPlantKindOfUsePlantIdPlantKindIdCompoundUniqueInput>;
  plantKind?: InputMaybe<PlantKindOfUseRelationFilter>;
  plantKindId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PlantRelationFilter = {
  is?: InputMaybe<PlantWhereInput>;
  isNot?: InputMaybe<PlantWhereInput>;
};

export type PlantScalarWhereInput = {
  AND?: InputMaybe<Array<PlantScalarWhereInput>>;
  NOT?: InputMaybe<Array<PlantScalarWhereInput>>;
  OR?: InputMaybe<Array<PlantScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  foundFrequencyInMonthEnd?: InputMaybe<IntNullableFilter>;
  foundFrequencyInMonthStart?: InputMaybe<IntNullableFilter>;
  hasOwner?: InputMaybe<EnumHasOwnerFilter>;
  id?: InputMaybe<StringFilter>;
  informant?: InputMaybe<StringNullableFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  ownerName?: InputMaybe<StringNullableFilter>;
  quantity?: InputMaybe<IntNullableFilter>;
  tambonId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type PlantSumAggregate = {
  __typename?: 'PlantSumAggregate';
  foundFrequencyInMonthEnd?: Maybe<Scalars['Int']['output']>;
  foundFrequencyInMonthStart?: Maybe<Scalars['Int']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type PlantUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  foundFrequencyInMonthEnd?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  foundFrequencyInMonthStart?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hasOwner?: InputMaybe<EnumHasOwnerFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  images?: InputMaybe<PlantImageUpdateManyWithoutPlantNestedInput>;
  informant?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  kindOfUses?: InputMaybe<PlantOnPlantKindOfUseUpdateManyWithoutPlantNestedInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  quantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tambon?: InputMaybe<TambonUpdateOneRequiredWithoutPlantNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutPlantsNestedInput>;
};

export type PlantUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  foundFrequencyInMonthEnd?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  foundFrequencyInMonthStart?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hasOwner?: InputMaybe<EnumHasOwnerFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  informant?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  quantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PlantUpdateManyWithWhereWithoutTambonInput = {
  data: PlantUpdateManyMutationInput;
  where: PlantScalarWhereInput;
};

export type PlantUpdateManyWithWhereWithoutUserInput = {
  data: PlantUpdateManyMutationInput;
  where: PlantScalarWhereInput;
};

export type PlantUpdateManyWithoutTambonNestedInput = {
  connect?: InputMaybe<Array<PlantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlantCreateOrConnectWithoutTambonInput>>;
  create?: InputMaybe<Array<PlantCreateWithoutTambonInput>>;
  createMany?: InputMaybe<PlantCreateManyTambonInputEnvelope>;
  delete?: InputMaybe<Array<PlantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PlantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PlantWhereUniqueInput>>;
  set?: InputMaybe<Array<PlantWhereUniqueInput>>;
  update?: InputMaybe<Array<PlantUpdateWithWhereUniqueWithoutTambonInput>>;
  updateMany?: InputMaybe<Array<PlantUpdateManyWithWhereWithoutTambonInput>>;
  upsert?: InputMaybe<Array<PlantUpsertWithWhereUniqueWithoutTambonInput>>;
};

export type PlantUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<PlantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlantCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PlantCreateWithoutUserInput>>;
  createMany?: InputMaybe<PlantCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<PlantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PlantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PlantWhereUniqueInput>>;
  set?: InputMaybe<Array<PlantWhereUniqueInput>>;
  update?: InputMaybe<Array<PlantUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<PlantUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<PlantUpsertWithWhereUniqueWithoutUserInput>>;
};

export type PlantUpdateWithWhereUniqueWithoutTambonInput = {
  data: PlantUpdateWithoutTambonInput;
  where: PlantWhereUniqueInput;
};

export type PlantUpdateWithWhereUniqueWithoutUserInput = {
  data: PlantUpdateWithoutUserInput;
  where: PlantWhereUniqueInput;
};

export type PlantUpdateWithoutTambonInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  foundFrequencyInMonthEnd?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  foundFrequencyInMonthStart?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hasOwner?: InputMaybe<EnumHasOwnerFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  images?: InputMaybe<PlantImageUpdateManyWithoutPlantNestedInput>;
  informant?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  kindOfUses?: InputMaybe<PlantOnPlantKindOfUseUpdateManyWithoutPlantNestedInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  quantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutPlantsNestedInput>;
};

export type PlantUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  foundFrequencyInMonthEnd?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  foundFrequencyInMonthStart?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hasOwner?: InputMaybe<EnumHasOwnerFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  images?: InputMaybe<PlantImageUpdateManyWithoutPlantNestedInput>;
  informant?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  kindOfUses?: InputMaybe<PlantOnPlantKindOfUseUpdateManyWithoutPlantNestedInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  quantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tambon?: InputMaybe<TambonUpdateOneRequiredWithoutPlantNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PlantUpsertWithWhereUniqueWithoutTambonInput = {
  create: PlantCreateWithoutTambonInput;
  update: PlantUpdateWithoutTambonInput;
  where: PlantWhereUniqueInput;
};

export type PlantUpsertWithWhereUniqueWithoutUserInput = {
  create: PlantCreateWithoutUserInput;
  update: PlantUpdateWithoutUserInput;
  where: PlantWhereUniqueInput;
};

export type PlantWhereInput = {
  AND?: InputMaybe<Array<PlantWhereInput>>;
  NOT?: InputMaybe<Array<PlantWhereInput>>;
  OR?: InputMaybe<Array<PlantWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  foundFrequencyInMonthEnd?: InputMaybe<IntNullableFilter>;
  foundFrequencyInMonthStart?: InputMaybe<IntNullableFilter>;
  hasOwner?: InputMaybe<EnumHasOwnerFilter>;
  id?: InputMaybe<StringFilter>;
  images?: InputMaybe<PlantImageListRelationFilter>;
  informant?: InputMaybe<StringNullableFilter>;
  kindOfUses?: InputMaybe<PlantOnPlantKindOfUseListRelationFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  ownerName?: InputMaybe<StringNullableFilter>;
  quantity?: InputMaybe<IntNullableFilter>;
  tambon?: InputMaybe<TambonRelationFilter>;
  tambonId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type PlantWhereUniqueInput = {
  AND?: InputMaybe<Array<PlantWhereInput>>;
  NOT?: InputMaybe<Array<PlantWhereInput>>;
  OR?: InputMaybe<Array<PlantWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  foundFrequencyInMonthEnd?: InputMaybe<IntNullableFilter>;
  foundFrequencyInMonthStart?: InputMaybe<IntNullableFilter>;
  hasOwner?: InputMaybe<EnumHasOwnerFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<PlantImageListRelationFilter>;
  informant?: InputMaybe<StringNullableFilter>;
  kindOfUses?: InputMaybe<PlantOnPlantKindOfUseListRelationFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  ownerName?: InputMaybe<StringNullableFilter>;
  quantity?: InputMaybe<IntNullableFilter>;
  tambon?: InputMaybe<TambonRelationFilter>;
  tambonId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type Province = {
  __typename?: 'Province';
  _count: ProvinceCount;
  amphoes?: Maybe<Array<Amphoe>>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  nameEn: Scalars['String']['output'];
  nameTh: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProvinceCount = {
  __typename?: 'ProvinceCount';
  amphoes: Scalars['Int']['output'];
};

export type ProvinceCountAggregate = {
  __typename?: 'ProvinceCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  nameEn: Scalars['Int']['output'];
  nameTh: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type ProvinceCreateNestedOneWithoutAmphoesInput = {
  connect?: InputMaybe<ProvinceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProvinceCreateOrConnectWithoutAmphoesInput>;
  create?: InputMaybe<ProvinceCreateWithoutAmphoesInput>;
};

export type ProvinceCreateOrConnectWithoutAmphoesInput = {
  create: ProvinceCreateWithoutAmphoesInput;
  where: ProvinceWhereUniqueInput;
};

export type ProvinceCreateWithoutAmphoesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  nameEn: Scalars['String']['input'];
  nameTh: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProvinceMaxAggregate = {
  __typename?: 'ProvinceMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
  nameTh?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProvinceMinAggregate = {
  __typename?: 'ProvinceMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
  nameTh?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProvinceNullableRelationFilter = {
  is?: InputMaybe<ProvinceWhereInput>;
  isNot?: InputMaybe<ProvinceWhereInput>;
};

export type ProvinceUpdateOneWithoutAmphoesNestedInput = {
  connect?: InputMaybe<ProvinceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProvinceCreateOrConnectWithoutAmphoesInput>;
  create?: InputMaybe<ProvinceCreateWithoutAmphoesInput>;
  delete?: InputMaybe<ProvinceWhereInput>;
  disconnect?: InputMaybe<ProvinceWhereInput>;
  update?: InputMaybe<ProvinceUpdateToOneWithWhereWithoutAmphoesInput>;
  upsert?: InputMaybe<ProvinceUpsertWithoutAmphoesInput>;
};

export type ProvinceUpdateToOneWithWhereWithoutAmphoesInput = {
  data: ProvinceUpdateWithoutAmphoesInput;
  where?: InputMaybe<ProvinceWhereInput>;
};

export type ProvinceUpdateWithoutAmphoesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameEn?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameTh?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProvinceUpsertWithoutAmphoesInput = {
  create: ProvinceCreateWithoutAmphoesInput;
  update: ProvinceUpdateWithoutAmphoesInput;
  where?: InputMaybe<ProvinceWhereInput>;
};

export type ProvinceWhereInput = {
  AND?: InputMaybe<Array<ProvinceWhereInput>>;
  NOT?: InputMaybe<Array<ProvinceWhereInput>>;
  OR?: InputMaybe<Array<ProvinceWhereInput>>;
  amphoes?: InputMaybe<AmphoeListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  nameEn?: InputMaybe<StringFilter>;
  nameTh?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProvinceWhereUniqueInput = {
  AND?: InputMaybe<Array<ProvinceWhereInput>>;
  NOT?: InputMaybe<Array<ProvinceWhereInput>>;
  OR?: InputMaybe<Array<ProvinceWhereInput>>;
  amphoes?: InputMaybe<AmphoeListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  nameEn?: InputMaybe<StringFilter>;
  nameTh?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PublishableWisdom = {
  __typename?: 'PublishableWisdom';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tambonId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  wisdom?: Maybe<Wisdom>;
  wisdomId?: Maybe<Scalars['String']['output']>;
};

export type PublishableWisdomCountAggregate = {
  __typename?: 'PublishableWisdomCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  tambonId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  wisdomId: Scalars['Int']['output'];
};

export type PublishableWisdomCreateManyWisdomInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  tambonId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PublishableWisdomCreateManyWisdomInputEnvelope = {
  data: Array<PublishableWisdomCreateManyWisdomInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PublishableWisdomCreateNestedManyWithoutWisdomInput = {
  connect?: InputMaybe<Array<PublishableWisdomWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PublishableWisdomCreateOrConnectWithoutWisdomInput>>;
  create?: InputMaybe<Array<PublishableWisdomCreateWithoutWisdomInput>>;
  createMany?: InputMaybe<PublishableWisdomCreateManyWisdomInputEnvelope>;
};

export type PublishableWisdomCreateOrConnectWithoutWisdomInput = {
  create: PublishableWisdomCreateWithoutWisdomInput;
  where: PublishableWisdomWhereUniqueInput;
};

export type PublishableWisdomCreateWithoutWisdomInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  tambonId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PublishableWisdomListRelationFilter = {
  every?: InputMaybe<PublishableWisdomWhereInput>;
  none?: InputMaybe<PublishableWisdomWhereInput>;
  some?: InputMaybe<PublishableWisdomWhereInput>;
};

export type PublishableWisdomMaxAggregate = {
  __typename?: 'PublishableWisdomMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tambonId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  wisdomId?: Maybe<Scalars['String']['output']>;
};

export type PublishableWisdomMinAggregate = {
  __typename?: 'PublishableWisdomMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tambonId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  wisdomId?: Maybe<Scalars['String']['output']>;
};

export type PublishableWisdomScalarWhereInput = {
  AND?: InputMaybe<Array<PublishableWisdomScalarWhereInput>>;
  NOT?: InputMaybe<Array<PublishableWisdomScalarWhereInput>>;
  OR?: InputMaybe<Array<PublishableWisdomScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  tambonId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wisdomId?: InputMaybe<StringNullableFilter>;
};

export type PublishableWisdomUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  tambonId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PublishableWisdomUpdateManyWithWhereWithoutWisdomInput = {
  data: PublishableWisdomUpdateManyMutationInput;
  where: PublishableWisdomScalarWhereInput;
};

export type PublishableWisdomUpdateManyWithoutWisdomNestedInput = {
  connect?: InputMaybe<Array<PublishableWisdomWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PublishableWisdomCreateOrConnectWithoutWisdomInput>>;
  create?: InputMaybe<Array<PublishableWisdomCreateWithoutWisdomInput>>;
  createMany?: InputMaybe<PublishableWisdomCreateManyWisdomInputEnvelope>;
  delete?: InputMaybe<Array<PublishableWisdomWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PublishableWisdomScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PublishableWisdomWhereUniqueInput>>;
  set?: InputMaybe<Array<PublishableWisdomWhereUniqueInput>>;
  update?: InputMaybe<Array<PublishableWisdomUpdateWithWhereUniqueWithoutWisdomInput>>;
  updateMany?: InputMaybe<Array<PublishableWisdomUpdateManyWithWhereWithoutWisdomInput>>;
  upsert?: InputMaybe<Array<PublishableWisdomUpsertWithWhereUniqueWithoutWisdomInput>>;
};

export type PublishableWisdomUpdateWithWhereUniqueWithoutWisdomInput = {
  data: PublishableWisdomUpdateWithoutWisdomInput;
  where: PublishableWisdomWhereUniqueInput;
};

export type PublishableWisdomUpdateWithoutWisdomInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  tambonId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PublishableWisdomUpsertWithWhereUniqueWithoutWisdomInput = {
  create: PublishableWisdomCreateWithoutWisdomInput;
  update: PublishableWisdomUpdateWithoutWisdomInput;
  where: PublishableWisdomWhereUniqueInput;
};

export type PublishableWisdomWhereInput = {
  AND?: InputMaybe<Array<PublishableWisdomWhereInput>>;
  NOT?: InputMaybe<Array<PublishableWisdomWhereInput>>;
  OR?: InputMaybe<Array<PublishableWisdomWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  tambonId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wisdom?: InputMaybe<WisdomNullableRelationFilter>;
  wisdomId?: InputMaybe<StringNullableFilter>;
};

export type PublishableWisdomWhereUniqueInput = {
  AND?: InputMaybe<Array<PublishableWisdomWhereInput>>;
  NOT?: InputMaybe<Array<PublishableWisdomWhereInput>>;
  OR?: InputMaybe<Array<PublishableWisdomWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  tambonId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wisdom?: InputMaybe<WisdomNullableRelationFilter>;
  wisdomId?: InputMaybe<StringNullableFilter>;
};

export type Query = {
  __typename?: 'Query';
  amphoe: Amphoe;
  amphoes: Array<Amphoe>;
  amphoesByKeyword: Array<Amphoe>;
  amphoesByProvinceId: Array<Amphoe>;
  amphoesByProvinceIdAndKeyword: Array<Amphoe>;
  countAllUserInFood: Array<Food>;
  countAllUserInPlant: Array<Plant>;
  countAllUserInTravel: Scalars['Float']['output'];
  countAllUserInWisdom: Array<Wisdom>;
  facilities: Array<Facility>;
  facilitiesByKeyword: Array<Facility>;
  facility: Facility;
  food: Food;
  foodCategories: Array<FoodCategory>;
  foodCategory: FoodCategory;
  foodCategoryWithFoods: FoodCategory;
  foodImage: FoodImage;
  foodImages: Array<FoodImage>;
  foodWithFoodCategory: Food;
  foods: Array<Food>;
  foodsByFoodCategory: Array<Food>;
  foodsByUser: Array<Food>;
  foodsCount: Scalars['Float']['output'];
  hello: Scalars['String']['output'];
  me: User;
  plant: Plant;
  plants: Array<Plant>;
  plantsByUserId: Array<Plant>;
  plantsCount: Scalars['Float']['output'];
  province: Province;
  provinces: Array<Province>;
  provincesByKeyword: Array<Province>;
  tambolsByAmphoeIdAndKeyword: Array<Tambon>;
  tambolsByKeyword: Array<Tambon>;
  tambon: Tambon;
  tambonWithAmphoeAndProvince: TambonWithAmphoeAndProvinceArgs;
  tambons: Array<Tambon>;
  tambonsByAmphoeId: Array<Tambon>;
  travel: Travel;
  travelType: TravelType;
  travelTypes: Array<TravelType>;
  travels: Array<Travel>;
  travelsByUser: Travel;
  travelsCount: Scalars['Float']['output'];
  user: User;
  userByEmail: User;
  users: Array<User>;
  usersCount: Scalars['Float']['output'];
  wisdom: Wisdom;
  wisdomCategories: Array<WisdomCategory>;
  wisdomCategory: WisdomCategory;
  wisdomCount: Scalars['Float']['output'];
  wisdoms: Array<Wisdom>;
  wisdomsByCategory: Array<Wisdom>;
  wisdomsByUser: Array<Wisdom>;
};


export type QueryAmphoeArgs = {
  id: Scalars['String']['input'];
};


export type QueryAmphoesByKeywordArgs = {
  keyword: Scalars['String']['input'];
};


export type QueryAmphoesByProvinceIdArgs = {
  provinceId: Scalars['String']['input'];
};


export type QueryAmphoesByProvinceIdAndKeywordArgs = {
  input: InputProvinceIdAndKeyword;
};


export type QueryFacilitiesByKeywordArgs = {
  keyword: Scalars['String']['input'];
};


export type QueryFacilityArgs = {
  id: Scalars['String']['input'];
};


export type QueryFoodArgs = {
  id: Scalars['String']['input'];
};


export type QueryFoodCategoriesArgs = {
  filter: Filter;
};


export type QueryFoodCategoryArgs = {
  id: Scalars['String']['input'];
};


export type QueryFoodCategoryWithFoodsArgs = {
  id: Scalars['String']['input'];
};


export type QueryFoodImageArgs = {
  id: Scalars['String']['input'];
};


export type QueryFoodWithFoodCategoryArgs = {
  id: Scalars['String']['input'];
};


export type QueryFoodsArgs = {
  filter: Filter;
};


export type QueryFoodsByFoodCategoryArgs = {
  id: Scalars['String']['input'];
};


export type QueryFoodsByUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryFoodsCountArgs = {
  filter: Filter;
};


export type QueryPlantArgs = {
  id: Scalars['String']['input'];
};


export type QueryPlantsArgs = {
  filter: Filter;
};


export type QueryPlantsByUserIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryPlantsCountArgs = {
  filter: Filter;
};


export type QueryProvinceArgs = {
  id: Scalars['String']['input'];
};


export type QueryProvincesByKeywordArgs = {
  keyword: Scalars['String']['input'];
};


export type QueryTambolsByAmphoeIdAndKeywordArgs = {
  input: InputAmphoeIdAndKeyword;
};


export type QueryTambolsByKeywordArgs = {
  keyword: Scalars['String']['input'];
};


export type QueryTambonArgs = {
  id: Scalars['String']['input'];
};


export type QueryTambonWithAmphoeAndProvinceArgs = {
  id: Scalars['String']['input'];
};


export type QueryTambonsByAmphoeIdArgs = {
  amphoeId: Scalars['String']['input'];
};


export type QueryTravelArgs = {
  id: Scalars['String']['input'];
};


export type QueryTravelTypeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryTravelsArgs = {
  filter: Filter;
};


export type QueryTravelsByUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryTravelsCountArgs = {
  filter: Filter;
};


export type QueryUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryUserByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  filter: UserFilter;
};


export type QueryUsersCountArgs = {
  filter: UserFilter;
};


export type QueryWisdomArgs = {
  id: Scalars['String']['input'];
};


export type QueryWisdomCategoryArgs = {
  id: Scalars['String']['input'];
};


export type QueryWisdomCountArgs = {
  filter: Filter;
};


export type QueryWisdomsArgs = {
  filter: Filter;
};


export type QueryWisdomsByCategoryArgs = {
  id: Scalars['String']['input'];
};


export type QueryWisdomsByUserArgs = {
  id: Scalars['String']['input'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum Role {
  Admin = 'ADMIN',
  Staff = 'STAFF',
  User = 'USER'
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Tambon = {
  __typename?: 'Tambon';
  Address?: Maybe<Array<Address>>;
  Food?: Maybe<Array<Food>>;
  Plant?: Maybe<Array<Plant>>;
  Travel?: Maybe<Array<Travel>>;
  Wisdom?: Maybe<Array<Wisdom>>;
  WisdomCategory?: Maybe<Array<WisdomCategory>>;
  _count: TambonCount;
  amphoe?: Maybe<Amphoe>;
  amphoeId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  nameEn: Scalars['String']['output'];
  nameTh: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  zipCode: Scalars['String']['output'];
};

export type TambonCount = {
  __typename?: 'TambonCount';
  Address: Scalars['Int']['output'];
  Food: Scalars['Int']['output'];
  Plant: Scalars['Int']['output'];
  Travel: Scalars['Int']['output'];
  Wisdom: Scalars['Int']['output'];
  WisdomCategory: Scalars['Int']['output'];
};

export type TambonCountAggregate = {
  __typename?: 'TambonCountAggregate';
  _all: Scalars['Int']['output'];
  amphoeId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  nameEn: Scalars['Int']['output'];
  nameTh: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  zipCode: Scalars['Int']['output'];
};

export type TambonCreateNestedOneWithoutFoodInput = {
  connect?: InputMaybe<TambonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TambonCreateOrConnectWithoutFoodInput>;
  create?: InputMaybe<TambonCreateWithoutFoodInput>;
};

export type TambonCreateNestedOneWithoutPlantInput = {
  connect?: InputMaybe<TambonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TambonCreateOrConnectWithoutPlantInput>;
  create?: InputMaybe<TambonCreateWithoutPlantInput>;
};

export type TambonCreateNestedOneWithoutTravelInput = {
  connect?: InputMaybe<TambonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TambonCreateOrConnectWithoutTravelInput>;
  create?: InputMaybe<TambonCreateWithoutTravelInput>;
};

export type TambonCreateNestedOneWithoutWisdomCategoryInput = {
  connect?: InputMaybe<TambonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TambonCreateOrConnectWithoutWisdomCategoryInput>;
  create?: InputMaybe<TambonCreateWithoutWisdomCategoryInput>;
};

export type TambonCreateNestedOneWithoutWisdomInput = {
  connect?: InputMaybe<TambonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TambonCreateOrConnectWithoutWisdomInput>;
  create?: InputMaybe<TambonCreateWithoutWisdomInput>;
};

export type TambonCreateOrConnectWithoutFoodInput = {
  create: TambonCreateWithoutFoodInput;
  where: TambonWhereUniqueInput;
};

export type TambonCreateOrConnectWithoutPlantInput = {
  create: TambonCreateWithoutPlantInput;
  where: TambonWhereUniqueInput;
};

export type TambonCreateOrConnectWithoutTravelInput = {
  create: TambonCreateWithoutTravelInput;
  where: TambonWhereUniqueInput;
};

export type TambonCreateOrConnectWithoutWisdomCategoryInput = {
  create: TambonCreateWithoutWisdomCategoryInput;
  where: TambonWhereUniqueInput;
};

export type TambonCreateOrConnectWithoutWisdomInput = {
  create: TambonCreateWithoutWisdomInput;
  where: TambonWhereUniqueInput;
};

export type TambonCreateWithoutFoodInput = {
  Address?: InputMaybe<AddressCreateNestedManyWithoutTambonInput>;
  Plant?: InputMaybe<PlantCreateNestedManyWithoutTambonInput>;
  Travel?: InputMaybe<TravelCreateNestedManyWithoutTambonInput>;
  Wisdom?: InputMaybe<WisdomCreateNestedManyWithoutTambonInput>;
  WisdomCategory?: InputMaybe<WisdomCategoryCreateNestedManyWithoutTambonInput>;
  amphoe?: InputMaybe<AmphoeCreateNestedOneWithoutTambonsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  nameEn: Scalars['String']['input'];
  nameTh: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  zipCode: Scalars['String']['input'];
};

export type TambonCreateWithoutPlantInput = {
  Address?: InputMaybe<AddressCreateNestedManyWithoutTambonInput>;
  Food?: InputMaybe<FoodCreateNestedManyWithoutTambonInput>;
  Travel?: InputMaybe<TravelCreateNestedManyWithoutTambonInput>;
  Wisdom?: InputMaybe<WisdomCreateNestedManyWithoutTambonInput>;
  WisdomCategory?: InputMaybe<WisdomCategoryCreateNestedManyWithoutTambonInput>;
  amphoe?: InputMaybe<AmphoeCreateNestedOneWithoutTambonsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  nameEn: Scalars['String']['input'];
  nameTh: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  zipCode: Scalars['String']['input'];
};

export type TambonCreateWithoutTravelInput = {
  Address?: InputMaybe<AddressCreateNestedManyWithoutTambonInput>;
  Food?: InputMaybe<FoodCreateNestedManyWithoutTambonInput>;
  Plant?: InputMaybe<PlantCreateNestedManyWithoutTambonInput>;
  Wisdom?: InputMaybe<WisdomCreateNestedManyWithoutTambonInput>;
  WisdomCategory?: InputMaybe<WisdomCategoryCreateNestedManyWithoutTambonInput>;
  amphoe?: InputMaybe<AmphoeCreateNestedOneWithoutTambonsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  nameEn: Scalars['String']['input'];
  nameTh: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  zipCode: Scalars['String']['input'];
};

export type TambonCreateWithoutWisdomCategoryInput = {
  Address?: InputMaybe<AddressCreateNestedManyWithoutTambonInput>;
  Food?: InputMaybe<FoodCreateNestedManyWithoutTambonInput>;
  Plant?: InputMaybe<PlantCreateNestedManyWithoutTambonInput>;
  Travel?: InputMaybe<TravelCreateNestedManyWithoutTambonInput>;
  Wisdom?: InputMaybe<WisdomCreateNestedManyWithoutTambonInput>;
  amphoe?: InputMaybe<AmphoeCreateNestedOneWithoutTambonsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  nameEn: Scalars['String']['input'];
  nameTh: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  zipCode: Scalars['String']['input'];
};

export type TambonCreateWithoutWisdomInput = {
  Address?: InputMaybe<AddressCreateNestedManyWithoutTambonInput>;
  Food?: InputMaybe<FoodCreateNestedManyWithoutTambonInput>;
  Plant?: InputMaybe<PlantCreateNestedManyWithoutTambonInput>;
  Travel?: InputMaybe<TravelCreateNestedManyWithoutTambonInput>;
  WisdomCategory?: InputMaybe<WisdomCategoryCreateNestedManyWithoutTambonInput>;
  amphoe?: InputMaybe<AmphoeCreateNestedOneWithoutTambonsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  nameEn: Scalars['String']['input'];
  nameTh: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  zipCode: Scalars['String']['input'];
};

export type TambonListRelationFilter = {
  every?: InputMaybe<TambonWhereInput>;
  none?: InputMaybe<TambonWhereInput>;
  some?: InputMaybe<TambonWhereInput>;
};

export type TambonMaxAggregate = {
  __typename?: 'TambonMaxAggregate';
  amphoeId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
  nameTh?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type TambonMinAggregate = {
  __typename?: 'TambonMinAggregate';
  amphoeId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
  nameTh?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type TambonNullableRelationFilter = {
  is?: InputMaybe<TambonWhereInput>;
  isNot?: InputMaybe<TambonWhereInput>;
};

export type TambonRelationFilter = {
  is?: InputMaybe<TambonWhereInput>;
  isNot?: InputMaybe<TambonWhereInput>;
};

export type TambonUpdateOneRequiredWithoutFoodNestedInput = {
  connect?: InputMaybe<TambonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TambonCreateOrConnectWithoutFoodInput>;
  create?: InputMaybe<TambonCreateWithoutFoodInput>;
  update?: InputMaybe<TambonUpdateToOneWithWhereWithoutFoodInput>;
  upsert?: InputMaybe<TambonUpsertWithoutFoodInput>;
};

export type TambonUpdateOneRequiredWithoutPlantNestedInput = {
  connect?: InputMaybe<TambonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TambonCreateOrConnectWithoutPlantInput>;
  create?: InputMaybe<TambonCreateWithoutPlantInput>;
  update?: InputMaybe<TambonUpdateToOneWithWhereWithoutPlantInput>;
  upsert?: InputMaybe<TambonUpsertWithoutPlantInput>;
};

export type TambonUpdateOneRequiredWithoutTravelNestedInput = {
  connect?: InputMaybe<TambonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TambonCreateOrConnectWithoutTravelInput>;
  create?: InputMaybe<TambonCreateWithoutTravelInput>;
  update?: InputMaybe<TambonUpdateToOneWithWhereWithoutTravelInput>;
  upsert?: InputMaybe<TambonUpsertWithoutTravelInput>;
};

export type TambonUpdateOneRequiredWithoutWisdomNestedInput = {
  connect?: InputMaybe<TambonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TambonCreateOrConnectWithoutWisdomInput>;
  create?: InputMaybe<TambonCreateWithoutWisdomInput>;
  update?: InputMaybe<TambonUpdateToOneWithWhereWithoutWisdomInput>;
  upsert?: InputMaybe<TambonUpsertWithoutWisdomInput>;
};

export type TambonUpdateOneWithoutWisdomCategoryNestedInput = {
  connect?: InputMaybe<TambonWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TambonCreateOrConnectWithoutWisdomCategoryInput>;
  create?: InputMaybe<TambonCreateWithoutWisdomCategoryInput>;
  delete?: InputMaybe<TambonWhereInput>;
  disconnect?: InputMaybe<TambonWhereInput>;
  update?: InputMaybe<TambonUpdateToOneWithWhereWithoutWisdomCategoryInput>;
  upsert?: InputMaybe<TambonUpsertWithoutWisdomCategoryInput>;
};

export type TambonUpdateToOneWithWhereWithoutFoodInput = {
  data: TambonUpdateWithoutFoodInput;
  where?: InputMaybe<TambonWhereInput>;
};

export type TambonUpdateToOneWithWhereWithoutPlantInput = {
  data: TambonUpdateWithoutPlantInput;
  where?: InputMaybe<TambonWhereInput>;
};

export type TambonUpdateToOneWithWhereWithoutTravelInput = {
  data: TambonUpdateWithoutTravelInput;
  where?: InputMaybe<TambonWhereInput>;
};

export type TambonUpdateToOneWithWhereWithoutWisdomCategoryInput = {
  data: TambonUpdateWithoutWisdomCategoryInput;
  where?: InputMaybe<TambonWhereInput>;
};

export type TambonUpdateToOneWithWhereWithoutWisdomInput = {
  data: TambonUpdateWithoutWisdomInput;
  where?: InputMaybe<TambonWhereInput>;
};

export type TambonUpdateWithoutFoodInput = {
  Address?: InputMaybe<AddressUpdateManyWithoutTambonNestedInput>;
  Plant?: InputMaybe<PlantUpdateManyWithoutTambonNestedInput>;
  Travel?: InputMaybe<TravelUpdateManyWithoutTambonNestedInput>;
  Wisdom?: InputMaybe<WisdomUpdateManyWithoutTambonNestedInput>;
  WisdomCategory?: InputMaybe<WisdomCategoryUpdateManyWithoutTambonNestedInput>;
  amphoe?: InputMaybe<AmphoeUpdateOneWithoutTambonsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameEn?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameTh?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zipCode?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TambonUpdateWithoutPlantInput = {
  Address?: InputMaybe<AddressUpdateManyWithoutTambonNestedInput>;
  Food?: InputMaybe<FoodUpdateManyWithoutTambonNestedInput>;
  Travel?: InputMaybe<TravelUpdateManyWithoutTambonNestedInput>;
  Wisdom?: InputMaybe<WisdomUpdateManyWithoutTambonNestedInput>;
  WisdomCategory?: InputMaybe<WisdomCategoryUpdateManyWithoutTambonNestedInput>;
  amphoe?: InputMaybe<AmphoeUpdateOneWithoutTambonsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameEn?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameTh?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zipCode?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TambonUpdateWithoutTravelInput = {
  Address?: InputMaybe<AddressUpdateManyWithoutTambonNestedInput>;
  Food?: InputMaybe<FoodUpdateManyWithoutTambonNestedInput>;
  Plant?: InputMaybe<PlantUpdateManyWithoutTambonNestedInput>;
  Wisdom?: InputMaybe<WisdomUpdateManyWithoutTambonNestedInput>;
  WisdomCategory?: InputMaybe<WisdomCategoryUpdateManyWithoutTambonNestedInput>;
  amphoe?: InputMaybe<AmphoeUpdateOneWithoutTambonsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameEn?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameTh?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zipCode?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TambonUpdateWithoutWisdomCategoryInput = {
  Address?: InputMaybe<AddressUpdateManyWithoutTambonNestedInput>;
  Food?: InputMaybe<FoodUpdateManyWithoutTambonNestedInput>;
  Plant?: InputMaybe<PlantUpdateManyWithoutTambonNestedInput>;
  Travel?: InputMaybe<TravelUpdateManyWithoutTambonNestedInput>;
  Wisdom?: InputMaybe<WisdomUpdateManyWithoutTambonNestedInput>;
  amphoe?: InputMaybe<AmphoeUpdateOneWithoutTambonsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameEn?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameTh?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zipCode?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TambonUpdateWithoutWisdomInput = {
  Address?: InputMaybe<AddressUpdateManyWithoutTambonNestedInput>;
  Food?: InputMaybe<FoodUpdateManyWithoutTambonNestedInput>;
  Plant?: InputMaybe<PlantUpdateManyWithoutTambonNestedInput>;
  Travel?: InputMaybe<TravelUpdateManyWithoutTambonNestedInput>;
  WisdomCategory?: InputMaybe<WisdomCategoryUpdateManyWithoutTambonNestedInput>;
  amphoe?: InputMaybe<AmphoeUpdateOneWithoutTambonsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameEn?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameTh?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zipCode?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TambonUpsertWithoutFoodInput = {
  create: TambonCreateWithoutFoodInput;
  update: TambonUpdateWithoutFoodInput;
  where?: InputMaybe<TambonWhereInput>;
};

export type TambonUpsertWithoutPlantInput = {
  create: TambonCreateWithoutPlantInput;
  update: TambonUpdateWithoutPlantInput;
  where?: InputMaybe<TambonWhereInput>;
};

export type TambonUpsertWithoutTravelInput = {
  create: TambonCreateWithoutTravelInput;
  update: TambonUpdateWithoutTravelInput;
  where?: InputMaybe<TambonWhereInput>;
};

export type TambonUpsertWithoutWisdomCategoryInput = {
  create: TambonCreateWithoutWisdomCategoryInput;
  update: TambonUpdateWithoutWisdomCategoryInput;
  where?: InputMaybe<TambonWhereInput>;
};

export type TambonUpsertWithoutWisdomInput = {
  create: TambonCreateWithoutWisdomInput;
  update: TambonUpdateWithoutWisdomInput;
  where?: InputMaybe<TambonWhereInput>;
};

export type TambonWhereInput = {
  AND?: InputMaybe<Array<TambonWhereInput>>;
  Address?: InputMaybe<AddressListRelationFilter>;
  Food?: InputMaybe<FoodListRelationFilter>;
  NOT?: InputMaybe<Array<TambonWhereInput>>;
  OR?: InputMaybe<Array<TambonWhereInput>>;
  Plant?: InputMaybe<PlantListRelationFilter>;
  Travel?: InputMaybe<TravelListRelationFilter>;
  Wisdom?: InputMaybe<WisdomListRelationFilter>;
  WisdomCategory?: InputMaybe<WisdomCategoryListRelationFilter>;
  amphoe?: InputMaybe<AmphoeNullableRelationFilter>;
  amphoeId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  nameEn?: InputMaybe<StringFilter>;
  nameTh?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zipCode?: InputMaybe<StringFilter>;
};

export type TambonWhereUniqueInput = {
  AND?: InputMaybe<Array<TambonWhereInput>>;
  Address?: InputMaybe<AddressListRelationFilter>;
  Food?: InputMaybe<FoodListRelationFilter>;
  NOT?: InputMaybe<Array<TambonWhereInput>>;
  OR?: InputMaybe<Array<TambonWhereInput>>;
  Plant?: InputMaybe<PlantListRelationFilter>;
  Travel?: InputMaybe<TravelListRelationFilter>;
  Wisdom?: InputMaybe<WisdomListRelationFilter>;
  WisdomCategory?: InputMaybe<WisdomCategoryListRelationFilter>;
  amphoe?: InputMaybe<AmphoeNullableRelationFilter>;
  amphoeId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  nameEn?: InputMaybe<StringFilter>;
  nameTh?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zipCode?: InputMaybe<StringFilter>;
};

export type TambonWithAmphoeAndProvinceArgs = {
  __typename?: 'TambonWithAmphoeAndProvinceArgs';
  amphoe: Amphoe;
  id: Scalars['String']['output'];
  nameTh: Scalars['String']['output'];
  province: Province;
};

export type Travel = {
  __typename?: 'Travel';
  _count: TravelCount;
  averageTouristPerYear: Scalars['Float']['output'];
  contactNumbers?: Maybe<Array<ContactNumber>>;
  createdAt: Scalars['DateTime']['output'];
  facilities?: Maybe<Array<TravelsOnFacilities>>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<TravelImage>>;
  incomePerYear: Scalars['Float']['output'];
  informant: Scalars['String']['output'];
  isSeasonal: Scalars['Boolean']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  numberOfStaff: Scalars['Int']['output'];
  seasonStartMonth?: Maybe<Scalars['Int']['output']>;
  seasonalEndMonth?: Maybe<Scalars['Int']['output']>;
  tambon: Tambon;
  tambonId: Scalars['String']['output'];
  travelTypeId: Scalars['String']['output'];
  type: TravelType;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
  websites?: Maybe<Array<Website>>;
};

export type TravelAvgAggregate = {
  __typename?: 'TravelAvgAggregate';
  averageTouristPerYear?: Maybe<Scalars['Float']['output']>;
  incomePerYear?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  numberOfStaff?: Maybe<Scalars['Float']['output']>;
  seasonStartMonth?: Maybe<Scalars['Float']['output']>;
  seasonalEndMonth?: Maybe<Scalars['Float']['output']>;
};

export type TravelCount = {
  __typename?: 'TravelCount';
  contactNumbers: Scalars['Int']['output'];
  facilities: Scalars['Int']['output'];
  images: Scalars['Int']['output'];
  websites: Scalars['Int']['output'];
};

export type TravelCountAggregate = {
  __typename?: 'TravelCountAggregate';
  _all: Scalars['Int']['output'];
  averageTouristPerYear: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  incomePerYear: Scalars['Int']['output'];
  informant: Scalars['Int']['output'];
  isSeasonal: Scalars['Int']['output'];
  latitude: Scalars['Int']['output'];
  longitude: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  numberOfStaff: Scalars['Int']['output'];
  seasonStartMonth: Scalars['Int']['output'];
  seasonalEndMonth: Scalars['Int']['output'];
  tambonId: Scalars['Int']['output'];
  travelTypeId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type TravelCreateInput = {
  averageTouristPerYear: Scalars['Float']['input'];
  contactNumbers?: InputMaybe<ContactNumberCreateNestedManyWithoutTravelInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  facilities?: InputMaybe<TravelsOnFacilitiesCreateNestedManyWithoutTravelInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<TravelImageCreateNestedManyWithoutTravelInput>;
  incomePerYear: Scalars['Float']['input'];
  informant: Scalars['String']['input'];
  isSeasonal: Scalars['Boolean']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  numberOfStaff: Scalars['Int']['input'];
  seasonStartMonth?: InputMaybe<Scalars['Int']['input']>;
  seasonalEndMonth?: InputMaybe<Scalars['Int']['input']>;
  tambon: TambonCreateNestedOneWithoutTravelInput;
  type: TravelTypeCreateNestedOneWithoutTravelsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutTravelsInput;
  websites?: InputMaybe<WebsiteCreateNestedManyWithoutTravelInput>;
};

export type TravelCreateManyTambonInput = {
  averageTouristPerYear: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomePerYear: Scalars['Float']['input'];
  informant: Scalars['String']['input'];
  isSeasonal: Scalars['Boolean']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  numberOfStaff: Scalars['Int']['input'];
  seasonStartMonth?: InputMaybe<Scalars['Int']['input']>;
  seasonalEndMonth?: InputMaybe<Scalars['Int']['input']>;
  travelTypeId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type TravelCreateManyTambonInputEnvelope = {
  data: Array<TravelCreateManyTambonInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TravelCreateManyTypeInput = {
  averageTouristPerYear: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomePerYear: Scalars['Float']['input'];
  informant: Scalars['String']['input'];
  isSeasonal: Scalars['Boolean']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  numberOfStaff: Scalars['Int']['input'];
  seasonStartMonth?: InputMaybe<Scalars['Int']['input']>;
  seasonalEndMonth?: InputMaybe<Scalars['Int']['input']>;
  tambonId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type TravelCreateManyTypeInputEnvelope = {
  data: Array<TravelCreateManyTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TravelCreateManyUserInput = {
  averageTouristPerYear: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomePerYear: Scalars['Float']['input'];
  informant: Scalars['String']['input'];
  isSeasonal: Scalars['Boolean']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  numberOfStaff: Scalars['Int']['input'];
  seasonStartMonth?: InputMaybe<Scalars['Int']['input']>;
  seasonalEndMonth?: InputMaybe<Scalars['Int']['input']>;
  tambonId: Scalars['String']['input'];
  travelTypeId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TravelCreateManyUserInputEnvelope = {
  data: Array<TravelCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TravelCreateNestedManyWithoutTambonInput = {
  connect?: InputMaybe<Array<TravelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TravelCreateOrConnectWithoutTambonInput>>;
  create?: InputMaybe<Array<TravelCreateWithoutTambonInput>>;
  createMany?: InputMaybe<TravelCreateManyTambonInputEnvelope>;
};

export type TravelCreateNestedManyWithoutTypeInput = {
  connect?: InputMaybe<Array<TravelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TravelCreateOrConnectWithoutTypeInput>>;
  create?: InputMaybe<Array<TravelCreateWithoutTypeInput>>;
  createMany?: InputMaybe<TravelCreateManyTypeInputEnvelope>;
};

export type TravelCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<TravelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TravelCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<TravelCreateWithoutUserInput>>;
  createMany?: InputMaybe<TravelCreateManyUserInputEnvelope>;
};

export type TravelCreateNestedOneWithoutFacilitiesInput = {
  connect?: InputMaybe<TravelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TravelCreateOrConnectWithoutFacilitiesInput>;
  create?: InputMaybe<TravelCreateWithoutFacilitiesInput>;
};

export type TravelCreateOrConnectWithoutFacilitiesInput = {
  create: TravelCreateWithoutFacilitiesInput;
  where: TravelWhereUniqueInput;
};

export type TravelCreateOrConnectWithoutTambonInput = {
  create: TravelCreateWithoutTambonInput;
  where: TravelWhereUniqueInput;
};

export type TravelCreateOrConnectWithoutTypeInput = {
  create: TravelCreateWithoutTypeInput;
  where: TravelWhereUniqueInput;
};

export type TravelCreateOrConnectWithoutUserInput = {
  create: TravelCreateWithoutUserInput;
  where: TravelWhereUniqueInput;
};

export type TravelCreateWithoutFacilitiesInput = {
  averageTouristPerYear: Scalars['Float']['input'];
  contactNumbers?: InputMaybe<ContactNumberCreateNestedManyWithoutTravelInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<TravelImageCreateNestedManyWithoutTravelInput>;
  incomePerYear: Scalars['Float']['input'];
  informant: Scalars['String']['input'];
  isSeasonal: Scalars['Boolean']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  numberOfStaff: Scalars['Int']['input'];
  seasonStartMonth?: InputMaybe<Scalars['Int']['input']>;
  seasonalEndMonth?: InputMaybe<Scalars['Int']['input']>;
  tambon: TambonCreateNestedOneWithoutTravelInput;
  type: TravelTypeCreateNestedOneWithoutTravelsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutTravelsInput;
  websites?: InputMaybe<WebsiteCreateNestedManyWithoutTravelInput>;
};

export type TravelCreateWithoutTambonInput = {
  averageTouristPerYear: Scalars['Float']['input'];
  contactNumbers?: InputMaybe<ContactNumberCreateNestedManyWithoutTravelInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  facilities?: InputMaybe<TravelsOnFacilitiesCreateNestedManyWithoutTravelInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<TravelImageCreateNestedManyWithoutTravelInput>;
  incomePerYear: Scalars['Float']['input'];
  informant: Scalars['String']['input'];
  isSeasonal: Scalars['Boolean']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  numberOfStaff: Scalars['Int']['input'];
  seasonStartMonth?: InputMaybe<Scalars['Int']['input']>;
  seasonalEndMonth?: InputMaybe<Scalars['Int']['input']>;
  type: TravelTypeCreateNestedOneWithoutTravelsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutTravelsInput;
  websites?: InputMaybe<WebsiteCreateNestedManyWithoutTravelInput>;
};

export type TravelCreateWithoutTypeInput = {
  averageTouristPerYear: Scalars['Float']['input'];
  contactNumbers?: InputMaybe<ContactNumberCreateNestedManyWithoutTravelInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  facilities?: InputMaybe<TravelsOnFacilitiesCreateNestedManyWithoutTravelInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<TravelImageCreateNestedManyWithoutTravelInput>;
  incomePerYear: Scalars['Float']['input'];
  informant: Scalars['String']['input'];
  isSeasonal: Scalars['Boolean']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  numberOfStaff: Scalars['Int']['input'];
  seasonStartMonth?: InputMaybe<Scalars['Int']['input']>;
  seasonalEndMonth?: InputMaybe<Scalars['Int']['input']>;
  tambon: TambonCreateNestedOneWithoutTravelInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutTravelsInput;
  websites?: InputMaybe<WebsiteCreateNestedManyWithoutTravelInput>;
};

export type TravelCreateWithoutUserInput = {
  averageTouristPerYear: Scalars['Float']['input'];
  contactNumbers?: InputMaybe<ContactNumberCreateNestedManyWithoutTravelInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  facilities?: InputMaybe<TravelsOnFacilitiesCreateNestedManyWithoutTravelInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<TravelImageCreateNestedManyWithoutTravelInput>;
  incomePerYear: Scalars['Float']['input'];
  informant: Scalars['String']['input'];
  isSeasonal: Scalars['Boolean']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  numberOfStaff: Scalars['Int']['input'];
  seasonStartMonth?: InputMaybe<Scalars['Int']['input']>;
  seasonalEndMonth?: InputMaybe<Scalars['Int']['input']>;
  tambon: TambonCreateNestedOneWithoutTravelInput;
  type: TravelTypeCreateNestedOneWithoutTravelsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  websites?: InputMaybe<WebsiteCreateNestedManyWithoutTravelInput>;
};

export type TravelImage = {
  __typename?: 'TravelImage';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  travel?: Maybe<Travel>;
  travelId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type TravelImageCountAggregate = {
  __typename?: 'TravelImageCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  travelId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
};

export type TravelImageCreateManyTravelInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type TravelImageCreateManyTravelInputEnvelope = {
  data: Array<TravelImageCreateManyTravelInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TravelImageCreateNestedManyWithoutTravelInput = {
  connect?: InputMaybe<Array<TravelImageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TravelImageCreateOrConnectWithoutTravelInput>>;
  create?: InputMaybe<Array<TravelImageCreateWithoutTravelInput>>;
  createMany?: InputMaybe<TravelImageCreateManyTravelInputEnvelope>;
};

export type TravelImageCreateOrConnectWithoutTravelInput = {
  create: TravelImageCreateWithoutTravelInput;
  where: TravelImageWhereUniqueInput;
};

export type TravelImageCreateWithoutTravelInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type TravelImageListRelationFilter = {
  every?: InputMaybe<TravelImageWhereInput>;
  none?: InputMaybe<TravelImageWhereInput>;
  some?: InputMaybe<TravelImageWhereInput>;
};

export type TravelImageMaxAggregate = {
  __typename?: 'TravelImageMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  travelId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type TravelImageMinAggregate = {
  __typename?: 'TravelImageMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  travelId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type TravelImageScalarWhereInput = {
  AND?: InputMaybe<Array<TravelImageScalarWhereInput>>;
  NOT?: InputMaybe<Array<TravelImageScalarWhereInput>>;
  OR?: InputMaybe<Array<TravelImageScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  travelId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type TravelImageUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TravelImageUpdateManyWithWhereWithoutTravelInput = {
  data: TravelImageUpdateManyMutationInput;
  where: TravelImageScalarWhereInput;
};

export type TravelImageUpdateManyWithoutTravelNestedInput = {
  connect?: InputMaybe<Array<TravelImageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TravelImageCreateOrConnectWithoutTravelInput>>;
  create?: InputMaybe<Array<TravelImageCreateWithoutTravelInput>>;
  createMany?: InputMaybe<TravelImageCreateManyTravelInputEnvelope>;
  delete?: InputMaybe<Array<TravelImageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TravelImageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TravelImageWhereUniqueInput>>;
  set?: InputMaybe<Array<TravelImageWhereUniqueInput>>;
  update?: InputMaybe<Array<TravelImageUpdateWithWhereUniqueWithoutTravelInput>>;
  updateMany?: InputMaybe<Array<TravelImageUpdateManyWithWhereWithoutTravelInput>>;
  upsert?: InputMaybe<Array<TravelImageUpsertWithWhereUniqueWithoutTravelInput>>;
};

export type TravelImageUpdateWithWhereUniqueWithoutTravelInput = {
  data: TravelImageUpdateWithoutTravelInput;
  where: TravelImageWhereUniqueInput;
};

export type TravelImageUpdateWithoutTravelInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TravelImageUpsertWithWhereUniqueWithoutTravelInput = {
  create: TravelImageCreateWithoutTravelInput;
  update: TravelImageUpdateWithoutTravelInput;
  where: TravelImageWhereUniqueInput;
};

export type TravelImageWhereInput = {
  AND?: InputMaybe<Array<TravelImageWhereInput>>;
  NOT?: InputMaybe<Array<TravelImageWhereInput>>;
  OR?: InputMaybe<Array<TravelImageWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  travel?: InputMaybe<TravelNullableRelationFilter>;
  travelId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type TravelImageWhereUniqueInput = {
  AND?: InputMaybe<Array<TravelImageWhereInput>>;
  NOT?: InputMaybe<Array<TravelImageWhereInput>>;
  OR?: InputMaybe<Array<TravelImageWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  travel?: InputMaybe<TravelNullableRelationFilter>;
  travelId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type TravelListRelationFilter = {
  every?: InputMaybe<TravelWhereInput>;
  none?: InputMaybe<TravelWhereInput>;
  some?: InputMaybe<TravelWhereInput>;
};

export type TravelMaxAggregate = {
  __typename?: 'TravelMaxAggregate';
  averageTouristPerYear?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  incomePerYear?: Maybe<Scalars['Float']['output']>;
  informant?: Maybe<Scalars['String']['output']>;
  isSeasonal?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfStaff?: Maybe<Scalars['Int']['output']>;
  seasonStartMonth?: Maybe<Scalars['Int']['output']>;
  seasonalEndMonth?: Maybe<Scalars['Int']['output']>;
  tambonId?: Maybe<Scalars['String']['output']>;
  travelTypeId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type TravelMinAggregate = {
  __typename?: 'TravelMinAggregate';
  averageTouristPerYear?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  incomePerYear?: Maybe<Scalars['Float']['output']>;
  informant?: Maybe<Scalars['String']['output']>;
  isSeasonal?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfStaff?: Maybe<Scalars['Int']['output']>;
  seasonStartMonth?: Maybe<Scalars['Int']['output']>;
  seasonalEndMonth?: Maybe<Scalars['Int']['output']>;
  tambonId?: Maybe<Scalars['String']['output']>;
  travelTypeId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type TravelNullableRelationFilter = {
  is?: InputMaybe<TravelWhereInput>;
  isNot?: InputMaybe<TravelWhereInput>;
};

export type TravelRelationFilter = {
  is?: InputMaybe<TravelWhereInput>;
  isNot?: InputMaybe<TravelWhereInput>;
};

export type TravelScalarWhereInput = {
  AND?: InputMaybe<Array<TravelScalarWhereInput>>;
  NOT?: InputMaybe<Array<TravelScalarWhereInput>>;
  OR?: InputMaybe<Array<TravelScalarWhereInput>>;
  averageTouristPerYear?: InputMaybe<FloatFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  incomePerYear?: InputMaybe<FloatFilter>;
  informant?: InputMaybe<StringFilter>;
  isSeasonal?: InputMaybe<BoolFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  numberOfStaff?: InputMaybe<IntFilter>;
  seasonStartMonth?: InputMaybe<IntNullableFilter>;
  seasonalEndMonth?: InputMaybe<IntNullableFilter>;
  tambonId?: InputMaybe<StringFilter>;
  travelTypeId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type TravelSumAggregate = {
  __typename?: 'TravelSumAggregate';
  averageTouristPerYear?: Maybe<Scalars['Float']['output']>;
  incomePerYear?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  numberOfStaff?: Maybe<Scalars['Int']['output']>;
  seasonStartMonth?: Maybe<Scalars['Int']['output']>;
  seasonalEndMonth?: Maybe<Scalars['Int']['output']>;
};

export type TravelType = {
  __typename?: 'TravelType';
  _count: TravelTypeCount;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  travels?: Maybe<Array<Travel>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type TravelTypeCount = {
  __typename?: 'TravelTypeCount';
  travels: Scalars['Int']['output'];
};

export type TravelTypeCountAggregate = {
  __typename?: 'TravelTypeCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type TravelTypeCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  travels?: InputMaybe<TravelCreateNestedManyWithoutTypeInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TravelTypeCreateNestedOneWithoutTravelsInput = {
  connect?: InputMaybe<TravelTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TravelTypeCreateOrConnectWithoutTravelsInput>;
  create?: InputMaybe<TravelTypeCreateWithoutTravelsInput>;
};

export type TravelTypeCreateOrConnectWithoutTravelsInput = {
  create: TravelTypeCreateWithoutTravelsInput;
  where: TravelTypeWhereUniqueInput;
};

export type TravelTypeCreateWithoutTravelsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TravelTypeMaxAggregate = {
  __typename?: 'TravelTypeMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TravelTypeMinAggregate = {
  __typename?: 'TravelTypeMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TravelTypeRelationFilter = {
  is?: InputMaybe<TravelTypeWhereInput>;
  isNot?: InputMaybe<TravelTypeWhereInput>;
};

export type TravelTypeUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  travels?: InputMaybe<TravelUpdateManyWithoutTypeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TravelTypeUpdateOneRequiredWithoutTravelsNestedInput = {
  connect?: InputMaybe<TravelTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TravelTypeCreateOrConnectWithoutTravelsInput>;
  create?: InputMaybe<TravelTypeCreateWithoutTravelsInput>;
  update?: InputMaybe<TravelTypeUpdateToOneWithWhereWithoutTravelsInput>;
  upsert?: InputMaybe<TravelTypeUpsertWithoutTravelsInput>;
};

export type TravelTypeUpdateToOneWithWhereWithoutTravelsInput = {
  data: TravelTypeUpdateWithoutTravelsInput;
  where?: InputMaybe<TravelTypeWhereInput>;
};

export type TravelTypeUpdateWithoutTravelsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TravelTypeUpsertWithoutTravelsInput = {
  create: TravelTypeCreateWithoutTravelsInput;
  update: TravelTypeUpdateWithoutTravelsInput;
  where?: InputMaybe<TravelTypeWhereInput>;
};

export type TravelTypeWhereInput = {
  AND?: InputMaybe<Array<TravelTypeWhereInput>>;
  NOT?: InputMaybe<Array<TravelTypeWhereInput>>;
  OR?: InputMaybe<Array<TravelTypeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  travels?: InputMaybe<TravelListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TravelTypeWhereUniqueInput = {
  AND?: InputMaybe<Array<TravelTypeWhereInput>>;
  NOT?: InputMaybe<Array<TravelTypeWhereInput>>;
  OR?: InputMaybe<Array<TravelTypeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  travels?: InputMaybe<TravelListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TravelUpdateInput = {
  averageTouristPerYear?: InputMaybe<FloatFieldUpdateOperationsInput>;
  contactNumbers?: InputMaybe<ContactNumberUpdateManyWithoutTravelNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  facilities?: InputMaybe<TravelsOnFacilitiesUpdateManyWithoutTravelNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  images?: InputMaybe<TravelImageUpdateManyWithoutTravelNestedInput>;
  incomePerYear?: InputMaybe<FloatFieldUpdateOperationsInput>;
  informant?: InputMaybe<StringFieldUpdateOperationsInput>;
  isSeasonal?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfStaff?: InputMaybe<IntFieldUpdateOperationsInput>;
  seasonStartMonth?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  seasonalEndMonth?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tambon?: InputMaybe<TambonUpdateOneRequiredWithoutTravelNestedInput>;
  type?: InputMaybe<TravelTypeUpdateOneRequiredWithoutTravelsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutTravelsNestedInput>;
  websites?: InputMaybe<WebsiteUpdateManyWithoutTravelNestedInput>;
};

export type TravelUpdateManyMutationInput = {
  averageTouristPerYear?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  incomePerYear?: InputMaybe<FloatFieldUpdateOperationsInput>;
  informant?: InputMaybe<StringFieldUpdateOperationsInput>;
  isSeasonal?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfStaff?: InputMaybe<IntFieldUpdateOperationsInput>;
  seasonStartMonth?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  seasonalEndMonth?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TravelUpdateManyWithWhereWithoutTambonInput = {
  data: TravelUpdateManyMutationInput;
  where: TravelScalarWhereInput;
};

export type TravelUpdateManyWithWhereWithoutTypeInput = {
  data: TravelUpdateManyMutationInput;
  where: TravelScalarWhereInput;
};

export type TravelUpdateManyWithWhereWithoutUserInput = {
  data: TravelUpdateManyMutationInput;
  where: TravelScalarWhereInput;
};

export type TravelUpdateManyWithoutTambonNestedInput = {
  connect?: InputMaybe<Array<TravelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TravelCreateOrConnectWithoutTambonInput>>;
  create?: InputMaybe<Array<TravelCreateWithoutTambonInput>>;
  createMany?: InputMaybe<TravelCreateManyTambonInputEnvelope>;
  delete?: InputMaybe<Array<TravelWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TravelScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TravelWhereUniqueInput>>;
  set?: InputMaybe<Array<TravelWhereUniqueInput>>;
  update?: InputMaybe<Array<TravelUpdateWithWhereUniqueWithoutTambonInput>>;
  updateMany?: InputMaybe<Array<TravelUpdateManyWithWhereWithoutTambonInput>>;
  upsert?: InputMaybe<Array<TravelUpsertWithWhereUniqueWithoutTambonInput>>;
};

export type TravelUpdateManyWithoutTypeNestedInput = {
  connect?: InputMaybe<Array<TravelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TravelCreateOrConnectWithoutTypeInput>>;
  create?: InputMaybe<Array<TravelCreateWithoutTypeInput>>;
  createMany?: InputMaybe<TravelCreateManyTypeInputEnvelope>;
  delete?: InputMaybe<Array<TravelWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TravelScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TravelWhereUniqueInput>>;
  set?: InputMaybe<Array<TravelWhereUniqueInput>>;
  update?: InputMaybe<Array<TravelUpdateWithWhereUniqueWithoutTypeInput>>;
  updateMany?: InputMaybe<Array<TravelUpdateManyWithWhereWithoutTypeInput>>;
  upsert?: InputMaybe<Array<TravelUpsertWithWhereUniqueWithoutTypeInput>>;
};

export type TravelUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<TravelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TravelCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<TravelCreateWithoutUserInput>>;
  createMany?: InputMaybe<TravelCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<TravelWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TravelScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TravelWhereUniqueInput>>;
  set?: InputMaybe<Array<TravelWhereUniqueInput>>;
  update?: InputMaybe<Array<TravelUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<TravelUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<TravelUpsertWithWhereUniqueWithoutUserInput>>;
};

export type TravelUpdateOneRequiredWithoutFacilitiesNestedInput = {
  connect?: InputMaybe<TravelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TravelCreateOrConnectWithoutFacilitiesInput>;
  create?: InputMaybe<TravelCreateWithoutFacilitiesInput>;
  update?: InputMaybe<TravelUpdateToOneWithWhereWithoutFacilitiesInput>;
  upsert?: InputMaybe<TravelUpsertWithoutFacilitiesInput>;
};

export type TravelUpdateToOneWithWhereWithoutFacilitiesInput = {
  data: TravelUpdateWithoutFacilitiesInput;
  where?: InputMaybe<TravelWhereInput>;
};

export type TravelUpdateWithWhereUniqueWithoutTambonInput = {
  data: TravelUpdateWithoutTambonInput;
  where: TravelWhereUniqueInput;
};

export type TravelUpdateWithWhereUniqueWithoutTypeInput = {
  data: TravelUpdateWithoutTypeInput;
  where: TravelWhereUniqueInput;
};

export type TravelUpdateWithWhereUniqueWithoutUserInput = {
  data: TravelUpdateWithoutUserInput;
  where: TravelWhereUniqueInput;
};

export type TravelUpdateWithoutFacilitiesInput = {
  averageTouristPerYear?: InputMaybe<FloatFieldUpdateOperationsInput>;
  contactNumbers?: InputMaybe<ContactNumberUpdateManyWithoutTravelNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  images?: InputMaybe<TravelImageUpdateManyWithoutTravelNestedInput>;
  incomePerYear?: InputMaybe<FloatFieldUpdateOperationsInput>;
  informant?: InputMaybe<StringFieldUpdateOperationsInput>;
  isSeasonal?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfStaff?: InputMaybe<IntFieldUpdateOperationsInput>;
  seasonStartMonth?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  seasonalEndMonth?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tambon?: InputMaybe<TambonUpdateOneRequiredWithoutTravelNestedInput>;
  type?: InputMaybe<TravelTypeUpdateOneRequiredWithoutTravelsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutTravelsNestedInput>;
  websites?: InputMaybe<WebsiteUpdateManyWithoutTravelNestedInput>;
};

export type TravelUpdateWithoutTambonInput = {
  averageTouristPerYear?: InputMaybe<FloatFieldUpdateOperationsInput>;
  contactNumbers?: InputMaybe<ContactNumberUpdateManyWithoutTravelNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  facilities?: InputMaybe<TravelsOnFacilitiesUpdateManyWithoutTravelNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  images?: InputMaybe<TravelImageUpdateManyWithoutTravelNestedInput>;
  incomePerYear?: InputMaybe<FloatFieldUpdateOperationsInput>;
  informant?: InputMaybe<StringFieldUpdateOperationsInput>;
  isSeasonal?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfStaff?: InputMaybe<IntFieldUpdateOperationsInput>;
  seasonStartMonth?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  seasonalEndMonth?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  type?: InputMaybe<TravelTypeUpdateOneRequiredWithoutTravelsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutTravelsNestedInput>;
  websites?: InputMaybe<WebsiteUpdateManyWithoutTravelNestedInput>;
};

export type TravelUpdateWithoutTypeInput = {
  averageTouristPerYear?: InputMaybe<FloatFieldUpdateOperationsInput>;
  contactNumbers?: InputMaybe<ContactNumberUpdateManyWithoutTravelNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  facilities?: InputMaybe<TravelsOnFacilitiesUpdateManyWithoutTravelNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  images?: InputMaybe<TravelImageUpdateManyWithoutTravelNestedInput>;
  incomePerYear?: InputMaybe<FloatFieldUpdateOperationsInput>;
  informant?: InputMaybe<StringFieldUpdateOperationsInput>;
  isSeasonal?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfStaff?: InputMaybe<IntFieldUpdateOperationsInput>;
  seasonStartMonth?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  seasonalEndMonth?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tambon?: InputMaybe<TambonUpdateOneRequiredWithoutTravelNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutTravelsNestedInput>;
  websites?: InputMaybe<WebsiteUpdateManyWithoutTravelNestedInput>;
};

export type TravelUpdateWithoutUserInput = {
  averageTouristPerYear?: InputMaybe<FloatFieldUpdateOperationsInput>;
  contactNumbers?: InputMaybe<ContactNumberUpdateManyWithoutTravelNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  facilities?: InputMaybe<TravelsOnFacilitiesUpdateManyWithoutTravelNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  images?: InputMaybe<TravelImageUpdateManyWithoutTravelNestedInput>;
  incomePerYear?: InputMaybe<FloatFieldUpdateOperationsInput>;
  informant?: InputMaybe<StringFieldUpdateOperationsInput>;
  isSeasonal?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfStaff?: InputMaybe<IntFieldUpdateOperationsInput>;
  seasonStartMonth?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  seasonalEndMonth?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tambon?: InputMaybe<TambonUpdateOneRequiredWithoutTravelNestedInput>;
  type?: InputMaybe<TravelTypeUpdateOneRequiredWithoutTravelsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  websites?: InputMaybe<WebsiteUpdateManyWithoutTravelNestedInput>;
};

export type TravelUpsertWithWhereUniqueWithoutTambonInput = {
  create: TravelCreateWithoutTambonInput;
  update: TravelUpdateWithoutTambonInput;
  where: TravelWhereUniqueInput;
};

export type TravelUpsertWithWhereUniqueWithoutTypeInput = {
  create: TravelCreateWithoutTypeInput;
  update: TravelUpdateWithoutTypeInput;
  where: TravelWhereUniqueInput;
};

export type TravelUpsertWithWhereUniqueWithoutUserInput = {
  create: TravelCreateWithoutUserInput;
  update: TravelUpdateWithoutUserInput;
  where: TravelWhereUniqueInput;
};

export type TravelUpsertWithoutFacilitiesInput = {
  create: TravelCreateWithoutFacilitiesInput;
  update: TravelUpdateWithoutFacilitiesInput;
  where?: InputMaybe<TravelWhereInput>;
};

export type TravelWhereInput = {
  AND?: InputMaybe<Array<TravelWhereInput>>;
  NOT?: InputMaybe<Array<TravelWhereInput>>;
  OR?: InputMaybe<Array<TravelWhereInput>>;
  averageTouristPerYear?: InputMaybe<FloatFilter>;
  contactNumbers?: InputMaybe<ContactNumberListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  facilities?: InputMaybe<TravelsOnFacilitiesListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  images?: InputMaybe<TravelImageListRelationFilter>;
  incomePerYear?: InputMaybe<FloatFilter>;
  informant?: InputMaybe<StringFilter>;
  isSeasonal?: InputMaybe<BoolFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  numberOfStaff?: InputMaybe<IntFilter>;
  seasonStartMonth?: InputMaybe<IntNullableFilter>;
  seasonalEndMonth?: InputMaybe<IntNullableFilter>;
  tambon?: InputMaybe<TambonRelationFilter>;
  tambonId?: InputMaybe<StringFilter>;
  travelTypeId?: InputMaybe<StringFilter>;
  type?: InputMaybe<TravelTypeRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  websites?: InputMaybe<WebsiteListRelationFilter>;
};

export type TravelWhereUniqueInput = {
  AND?: InputMaybe<Array<TravelWhereInput>>;
  NOT?: InputMaybe<Array<TravelWhereInput>>;
  OR?: InputMaybe<Array<TravelWhereInput>>;
  averageTouristPerYear?: InputMaybe<FloatFilter>;
  contactNumbers?: InputMaybe<ContactNumberListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  facilities?: InputMaybe<TravelsOnFacilitiesListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<TravelImageListRelationFilter>;
  incomePerYear?: InputMaybe<FloatFilter>;
  informant?: InputMaybe<StringFilter>;
  isSeasonal?: InputMaybe<BoolFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  numberOfStaff?: InputMaybe<IntFilter>;
  seasonStartMonth?: InputMaybe<IntNullableFilter>;
  seasonalEndMonth?: InputMaybe<IntNullableFilter>;
  tambon?: InputMaybe<TambonRelationFilter>;
  tambonId?: InputMaybe<StringFilter>;
  travelTypeId?: InputMaybe<StringFilter>;
  type?: InputMaybe<TravelTypeRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  websites?: InputMaybe<WebsiteListRelationFilter>;
};

export type TravelsOnFacilities = {
  __typename?: 'TravelsOnFacilities';
  assignedAt: Scalars['DateTime']['output'];
  facility: Facility;
  facilityId: Scalars['String']['output'];
  travel: Travel;
  travelId: Scalars['String']['output'];
};

export type TravelsOnFacilitiesCountAggregate = {
  __typename?: 'TravelsOnFacilitiesCountAggregate';
  _all: Scalars['Int']['output'];
  assignedAt: Scalars['Int']['output'];
  facilityId: Scalars['Int']['output'];
  travelId: Scalars['Int']['output'];
};

export type TravelsOnFacilitiesCreateManyFacilityInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  travelId: Scalars['String']['input'];
};

export type TravelsOnFacilitiesCreateManyFacilityInputEnvelope = {
  data: Array<TravelsOnFacilitiesCreateManyFacilityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TravelsOnFacilitiesCreateManyTravelInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  facilityId: Scalars['String']['input'];
};

export type TravelsOnFacilitiesCreateManyTravelInputEnvelope = {
  data: Array<TravelsOnFacilitiesCreateManyTravelInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TravelsOnFacilitiesCreateNestedManyWithoutFacilityInput = {
  connect?: InputMaybe<Array<TravelsOnFacilitiesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TravelsOnFacilitiesCreateOrConnectWithoutFacilityInput>>;
  create?: InputMaybe<Array<TravelsOnFacilitiesCreateWithoutFacilityInput>>;
  createMany?: InputMaybe<TravelsOnFacilitiesCreateManyFacilityInputEnvelope>;
};

export type TravelsOnFacilitiesCreateNestedManyWithoutTravelInput = {
  connect?: InputMaybe<Array<TravelsOnFacilitiesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TravelsOnFacilitiesCreateOrConnectWithoutTravelInput>>;
  create?: InputMaybe<Array<TravelsOnFacilitiesCreateWithoutTravelInput>>;
  createMany?: InputMaybe<TravelsOnFacilitiesCreateManyTravelInputEnvelope>;
};

export type TravelsOnFacilitiesCreateOrConnectWithoutFacilityInput = {
  create: TravelsOnFacilitiesCreateWithoutFacilityInput;
  where: TravelsOnFacilitiesWhereUniqueInput;
};

export type TravelsOnFacilitiesCreateOrConnectWithoutTravelInput = {
  create: TravelsOnFacilitiesCreateWithoutTravelInput;
  where: TravelsOnFacilitiesWhereUniqueInput;
};

export type TravelsOnFacilitiesCreateWithoutFacilityInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  travel: TravelCreateNestedOneWithoutFacilitiesInput;
};

export type TravelsOnFacilitiesCreateWithoutTravelInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  facility: FacilityCreateNestedOneWithoutTravelsInput;
};

export type TravelsOnFacilitiesListRelationFilter = {
  every?: InputMaybe<TravelsOnFacilitiesWhereInput>;
  none?: InputMaybe<TravelsOnFacilitiesWhereInput>;
  some?: InputMaybe<TravelsOnFacilitiesWhereInput>;
};

export type TravelsOnFacilitiesMaxAggregate = {
  __typename?: 'TravelsOnFacilitiesMaxAggregate';
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  facilityId?: Maybe<Scalars['String']['output']>;
  travelId?: Maybe<Scalars['String']['output']>;
};

export type TravelsOnFacilitiesMinAggregate = {
  __typename?: 'TravelsOnFacilitiesMinAggregate';
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  facilityId?: Maybe<Scalars['String']['output']>;
  travelId?: Maybe<Scalars['String']['output']>;
};

export type TravelsOnFacilitiesScalarWhereInput = {
  AND?: InputMaybe<Array<TravelsOnFacilitiesScalarWhereInput>>;
  NOT?: InputMaybe<Array<TravelsOnFacilitiesScalarWhereInput>>;
  OR?: InputMaybe<Array<TravelsOnFacilitiesScalarWhereInput>>;
  assignedAt?: InputMaybe<DateTimeFilter>;
  facilityId?: InputMaybe<StringFilter>;
  travelId?: InputMaybe<StringFilter>;
};

export type TravelsOnFacilitiesTravelIdFacilityIdCompoundUniqueInput = {
  facilityId: Scalars['String']['input'];
  travelId: Scalars['String']['input'];
};

export type TravelsOnFacilitiesUpdateManyMutationInput = {
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TravelsOnFacilitiesUpdateManyWithWhereWithoutFacilityInput = {
  data: TravelsOnFacilitiesUpdateManyMutationInput;
  where: TravelsOnFacilitiesScalarWhereInput;
};

export type TravelsOnFacilitiesUpdateManyWithWhereWithoutTravelInput = {
  data: TravelsOnFacilitiesUpdateManyMutationInput;
  where: TravelsOnFacilitiesScalarWhereInput;
};

export type TravelsOnFacilitiesUpdateManyWithoutFacilityNestedInput = {
  connect?: InputMaybe<Array<TravelsOnFacilitiesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TravelsOnFacilitiesCreateOrConnectWithoutFacilityInput>>;
  create?: InputMaybe<Array<TravelsOnFacilitiesCreateWithoutFacilityInput>>;
  createMany?: InputMaybe<TravelsOnFacilitiesCreateManyFacilityInputEnvelope>;
  delete?: InputMaybe<Array<TravelsOnFacilitiesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TravelsOnFacilitiesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TravelsOnFacilitiesWhereUniqueInput>>;
  set?: InputMaybe<Array<TravelsOnFacilitiesWhereUniqueInput>>;
  update?: InputMaybe<Array<TravelsOnFacilitiesUpdateWithWhereUniqueWithoutFacilityInput>>;
  updateMany?: InputMaybe<Array<TravelsOnFacilitiesUpdateManyWithWhereWithoutFacilityInput>>;
  upsert?: InputMaybe<Array<TravelsOnFacilitiesUpsertWithWhereUniqueWithoutFacilityInput>>;
};

export type TravelsOnFacilitiesUpdateManyWithoutTravelNestedInput = {
  connect?: InputMaybe<Array<TravelsOnFacilitiesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TravelsOnFacilitiesCreateOrConnectWithoutTravelInput>>;
  create?: InputMaybe<Array<TravelsOnFacilitiesCreateWithoutTravelInput>>;
  createMany?: InputMaybe<TravelsOnFacilitiesCreateManyTravelInputEnvelope>;
  delete?: InputMaybe<Array<TravelsOnFacilitiesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TravelsOnFacilitiesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TravelsOnFacilitiesWhereUniqueInput>>;
  set?: InputMaybe<Array<TravelsOnFacilitiesWhereUniqueInput>>;
  update?: InputMaybe<Array<TravelsOnFacilitiesUpdateWithWhereUniqueWithoutTravelInput>>;
  updateMany?: InputMaybe<Array<TravelsOnFacilitiesUpdateManyWithWhereWithoutTravelInput>>;
  upsert?: InputMaybe<Array<TravelsOnFacilitiesUpsertWithWhereUniqueWithoutTravelInput>>;
};

export type TravelsOnFacilitiesUpdateWithWhereUniqueWithoutFacilityInput = {
  data: TravelsOnFacilitiesUpdateWithoutFacilityInput;
  where: TravelsOnFacilitiesWhereUniqueInput;
};

export type TravelsOnFacilitiesUpdateWithWhereUniqueWithoutTravelInput = {
  data: TravelsOnFacilitiesUpdateWithoutTravelInput;
  where: TravelsOnFacilitiesWhereUniqueInput;
};

export type TravelsOnFacilitiesUpdateWithoutFacilityInput = {
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  travel?: InputMaybe<TravelUpdateOneRequiredWithoutFacilitiesNestedInput>;
};

export type TravelsOnFacilitiesUpdateWithoutTravelInput = {
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  facility?: InputMaybe<FacilityUpdateOneRequiredWithoutTravelsNestedInput>;
};

export type TravelsOnFacilitiesUpsertWithWhereUniqueWithoutFacilityInput = {
  create: TravelsOnFacilitiesCreateWithoutFacilityInput;
  update: TravelsOnFacilitiesUpdateWithoutFacilityInput;
  where: TravelsOnFacilitiesWhereUniqueInput;
};

export type TravelsOnFacilitiesUpsertWithWhereUniqueWithoutTravelInput = {
  create: TravelsOnFacilitiesCreateWithoutTravelInput;
  update: TravelsOnFacilitiesUpdateWithoutTravelInput;
  where: TravelsOnFacilitiesWhereUniqueInput;
};

export type TravelsOnFacilitiesWhereInput = {
  AND?: InputMaybe<Array<TravelsOnFacilitiesWhereInput>>;
  NOT?: InputMaybe<Array<TravelsOnFacilitiesWhereInput>>;
  OR?: InputMaybe<Array<TravelsOnFacilitiesWhereInput>>;
  assignedAt?: InputMaybe<DateTimeFilter>;
  facility?: InputMaybe<FacilityRelationFilter>;
  facilityId?: InputMaybe<StringFilter>;
  travel?: InputMaybe<TravelRelationFilter>;
  travelId?: InputMaybe<StringFilter>;
};

export type TravelsOnFacilitiesWhereUniqueInput = {
  AND?: InputMaybe<Array<TravelsOnFacilitiesWhereInput>>;
  NOT?: InputMaybe<Array<TravelsOnFacilitiesWhereInput>>;
  OR?: InputMaybe<Array<TravelsOnFacilitiesWhereInput>>;
  assignedAt?: InputMaybe<DateTimeFilter>;
  facility?: InputMaybe<FacilityRelationFilter>;
  facilityId?: InputMaybe<StringFilter>;
  travel?: InputMaybe<TravelRelationFilter>;
  travelId?: InputMaybe<StringFilter>;
  travelId_facilityId?: InputMaybe<TravelsOnFacilitiesTravelIdFacilityIdCompoundUniqueInput>;
};

export type UniqueWisdom = {
  __typename?: 'UniqueWisdom';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  wisdom: Wisdom;
  wisdomId: Scalars['String']['output'];
};

export type UniqueWisdomCountAggregate = {
  __typename?: 'UniqueWisdomCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  wisdomId: Scalars['Int']['output'];
};

export type UniqueWisdomCreateManyWisdomInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UniqueWisdomCreateManyWisdomInputEnvelope = {
  data: Array<UniqueWisdomCreateManyWisdomInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UniqueWisdomCreateNestedManyWithoutWisdomInput = {
  connect?: InputMaybe<Array<UniqueWisdomWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UniqueWisdomCreateOrConnectWithoutWisdomInput>>;
  create?: InputMaybe<Array<UniqueWisdomCreateWithoutWisdomInput>>;
  createMany?: InputMaybe<UniqueWisdomCreateManyWisdomInputEnvelope>;
};

export type UniqueWisdomCreateOrConnectWithoutWisdomInput = {
  create: UniqueWisdomCreateWithoutWisdomInput;
  where: UniqueWisdomWhereUniqueInput;
};

export type UniqueWisdomCreateWithoutWisdomInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UniqueWisdomListRelationFilter = {
  every?: InputMaybe<UniqueWisdomWhereInput>;
  none?: InputMaybe<UniqueWisdomWhereInput>;
  some?: InputMaybe<UniqueWisdomWhereInput>;
};

export type UniqueWisdomMaxAggregate = {
  __typename?: 'UniqueWisdomMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  wisdomId?: Maybe<Scalars['String']['output']>;
};

export type UniqueWisdomMinAggregate = {
  __typename?: 'UniqueWisdomMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  wisdomId?: Maybe<Scalars['String']['output']>;
};

export type UniqueWisdomScalarWhereInput = {
  AND?: InputMaybe<Array<UniqueWisdomScalarWhereInput>>;
  NOT?: InputMaybe<Array<UniqueWisdomScalarWhereInput>>;
  OR?: InputMaybe<Array<UniqueWisdomScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wisdomId?: InputMaybe<StringFilter>;
};

export type UniqueWisdomUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UniqueWisdomUpdateManyWithWhereWithoutWisdomInput = {
  data: UniqueWisdomUpdateManyMutationInput;
  where: UniqueWisdomScalarWhereInput;
};

export type UniqueWisdomUpdateManyWithoutWisdomNestedInput = {
  connect?: InputMaybe<Array<UniqueWisdomWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UniqueWisdomCreateOrConnectWithoutWisdomInput>>;
  create?: InputMaybe<Array<UniqueWisdomCreateWithoutWisdomInput>>;
  createMany?: InputMaybe<UniqueWisdomCreateManyWisdomInputEnvelope>;
  delete?: InputMaybe<Array<UniqueWisdomWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UniqueWisdomScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UniqueWisdomWhereUniqueInput>>;
  set?: InputMaybe<Array<UniqueWisdomWhereUniqueInput>>;
  update?: InputMaybe<Array<UniqueWisdomUpdateWithWhereUniqueWithoutWisdomInput>>;
  updateMany?: InputMaybe<Array<UniqueWisdomUpdateManyWithWhereWithoutWisdomInput>>;
  upsert?: InputMaybe<Array<UniqueWisdomUpsertWithWhereUniqueWithoutWisdomInput>>;
};

export type UniqueWisdomUpdateWithWhereUniqueWithoutWisdomInput = {
  data: UniqueWisdomUpdateWithoutWisdomInput;
  where: UniqueWisdomWhereUniqueInput;
};

export type UniqueWisdomUpdateWithoutWisdomInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UniqueWisdomUpsertWithWhereUniqueWithoutWisdomInput = {
  create: UniqueWisdomCreateWithoutWisdomInput;
  update: UniqueWisdomUpdateWithoutWisdomInput;
  where: UniqueWisdomWhereUniqueInput;
};

export type UniqueWisdomWhereInput = {
  AND?: InputMaybe<Array<UniqueWisdomWhereInput>>;
  NOT?: InputMaybe<Array<UniqueWisdomWhereInput>>;
  OR?: InputMaybe<Array<UniqueWisdomWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wisdom?: InputMaybe<WisdomRelationFilter>;
  wisdomId?: InputMaybe<StringFilter>;
};

export type UniqueWisdomWhereUniqueInput = {
  AND?: InputMaybe<Array<UniqueWisdomWhereInput>>;
  NOT?: InputMaybe<Array<UniqueWisdomWhereInput>>;
  OR?: InputMaybe<Array<UniqueWisdomWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wisdom?: InputMaybe<WisdomRelationFilter>;
  wisdomId?: InputMaybe<StringFilter>;
};

export type User = {
  __typename?: 'User';
  _count: UserCount;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  emailVerified?: Maybe<Scalars['DateTime']['output']>;
  emailVerifyToken?: Maybe<Scalars['String']['output']>;
  foods?: Maybe<Array<Food>>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  password: Scalars['String']['output'];
  plants?: Maybe<Array<Plant>>;
  role: Role;
  travels?: Maybe<Array<Travel>>;
  updatedAt: Scalars['DateTime']['output'];
  wisdoms?: Maybe<Array<Wisdom>>;
};

export type UserCount = {
  __typename?: 'UserCount';
  foods: Scalars['Int']['output'];
  plants: Scalars['Int']['output'];
  travels: Scalars['Int']['output'];
  wisdoms: Scalars['Int']['output'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  emailVerified: Scalars['Int']['output'];
  emailVerifyToken: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  password: Scalars['Int']['output'];
  role: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type UserCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['DateTime']['input']>;
  emailVerifyToken?: InputMaybe<Scalars['String']['input']>;
  foods?: InputMaybe<FoodCreateNestedManyWithoutUserInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  plants?: InputMaybe<PlantCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  travels?: InputMaybe<TravelCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wisdoms?: InputMaybe<WisdomCreateNestedManyWithoutUserInput>;
};

export type UserCreateNestedOneWithoutFoodsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFoodsInput>;
  create?: InputMaybe<UserCreateWithoutFoodsInput>;
};

export type UserCreateNestedOneWithoutPlantsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPlantsInput>;
  create?: InputMaybe<UserCreateWithoutPlantsInput>;
};

export type UserCreateNestedOneWithoutTravelsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTravelsInput>;
  create?: InputMaybe<UserCreateWithoutTravelsInput>;
};

export type UserCreateNestedOneWithoutWisdomsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutWisdomsInput>;
  create?: InputMaybe<UserCreateWithoutWisdomsInput>;
};

export type UserCreateOrConnectWithoutFoodsInput = {
  create: UserCreateWithoutFoodsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPlantsInput = {
  create: UserCreateWithoutPlantsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTravelsInput = {
  create: UserCreateWithoutTravelsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutWisdomsInput = {
  create: UserCreateWithoutWisdomsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutFoodsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['DateTime']['input']>;
  emailVerifyToken?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  plants?: InputMaybe<PlantCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  travels?: InputMaybe<TravelCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wisdoms?: InputMaybe<WisdomCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutPlantsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['DateTime']['input']>;
  emailVerifyToken?: InputMaybe<Scalars['String']['input']>;
  foods?: InputMaybe<FoodCreateNestedManyWithoutUserInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  role?: InputMaybe<Role>;
  travels?: InputMaybe<TravelCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wisdoms?: InputMaybe<WisdomCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutTravelsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['DateTime']['input']>;
  emailVerifyToken?: InputMaybe<Scalars['String']['input']>;
  foods?: InputMaybe<FoodCreateNestedManyWithoutUserInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  plants?: InputMaybe<PlantCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wisdoms?: InputMaybe<WisdomCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutWisdomsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['DateTime']['input']>;
  emailVerifyToken?: InputMaybe<Scalars['String']['input']>;
  foods?: InputMaybe<FoodCreateNestedManyWithoutUserInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  plants?: InputMaybe<PlantCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  travels?: InputMaybe<TravelCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserFilter = {
  keywords?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified?: Maybe<Scalars['DateTime']['output']>;
  emailVerifyToken?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified?: Maybe<Scalars['DateTime']['output']>;
  emailVerifyToken?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export type UserUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerifyToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  foods?: InputMaybe<FoodUpdateManyWithoutUserNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  plants?: InputMaybe<PlantUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  travels?: InputMaybe<TravelUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  wisdoms?: InputMaybe<WisdomUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateOneRequiredWithoutFoodsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFoodsInput>;
  create?: InputMaybe<UserCreateWithoutFoodsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutFoodsInput>;
  upsert?: InputMaybe<UserUpsertWithoutFoodsInput>;
};

export type UserUpdateOneRequiredWithoutPlantsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPlantsInput>;
  create?: InputMaybe<UserCreateWithoutPlantsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutPlantsInput>;
  upsert?: InputMaybe<UserUpsertWithoutPlantsInput>;
};

export type UserUpdateOneRequiredWithoutTravelsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTravelsInput>;
  create?: InputMaybe<UserCreateWithoutTravelsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutTravelsInput>;
  upsert?: InputMaybe<UserUpsertWithoutTravelsInput>;
};

export type UserUpdateOneRequiredWithoutWisdomsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutWisdomsInput>;
  create?: InputMaybe<UserCreateWithoutWisdomsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutWisdomsInput>;
  upsert?: InputMaybe<UserUpsertWithoutWisdomsInput>;
};

export type UserUpdateToOneWithWhereWithoutFoodsInput = {
  data: UserUpdateWithoutFoodsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutPlantsInput = {
  data: UserUpdateWithoutPlantsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutTravelsInput = {
  data: UserUpdateWithoutTravelsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutWisdomsInput = {
  data: UserUpdateWithoutWisdomsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateWithoutFoodsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerifyToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  plants?: InputMaybe<PlantUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  travels?: InputMaybe<TravelUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  wisdoms?: InputMaybe<WisdomUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutPlantsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerifyToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  foods?: InputMaybe<FoodUpdateManyWithoutUserNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  travels?: InputMaybe<TravelUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  wisdoms?: InputMaybe<WisdomUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutTravelsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerifyToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  foods?: InputMaybe<FoodUpdateManyWithoutUserNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  plants?: InputMaybe<PlantUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  wisdoms?: InputMaybe<WisdomUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutWisdomsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  emailVerifyToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  foods?: InputMaybe<FoodUpdateManyWithoutUserNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  plants?: InputMaybe<PlantUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  travels?: InputMaybe<TravelUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpsertWithoutFoodsInput = {
  create: UserCreateWithoutFoodsInput;
  update: UserUpdateWithoutFoodsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutPlantsInput = {
  create: UserCreateWithoutPlantsInput;
  update: UserUpdateWithoutPlantsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutTravelsInput = {
  create: UserCreateWithoutTravelsInput;
  update: UserUpdateWithoutTravelsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutWisdomsInput = {
  create: UserCreateWithoutWisdomsInput;
  update: UserUpdateWithoutWisdomsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  emailVerified?: InputMaybe<DateTimeNullableFilter>;
  emailVerifyToken?: InputMaybe<StringNullableFilter>;
  foods?: InputMaybe<FoodListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  image?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  plants?: InputMaybe<PlantListRelationFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  travels?: InputMaybe<TravelListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wisdoms?: InputMaybe<WisdomListRelationFilter>;
};

export type UserWhereUniqueInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailVerified?: InputMaybe<DateTimeNullableFilter>;
  emailVerifyToken?: InputMaybe<StringNullableFilter>;
  foods?: InputMaybe<FoodListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  plants?: InputMaybe<PlantListRelationFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  travels?: InputMaybe<TravelListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wisdoms?: InputMaybe<WisdomListRelationFilter>;
};

export type VerificationTokenCountAggregate = {
  __typename?: 'VerificationTokenCountAggregate';
  _all: Scalars['Int']['output'];
  expires: Scalars['Int']['output'];
  identifier: Scalars['Int']['output'];
  token: Scalars['Int']['output'];
};

export type VerificationTokenMaxAggregate = {
  __typename?: 'VerificationTokenMaxAggregate';
  expires?: Maybe<Scalars['DateTime']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type VerificationTokenMinAggregate = {
  __typename?: 'VerificationTokenMinAggregate';
  expires?: Maybe<Scalars['DateTime']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type Website = {
  __typename?: 'Website';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  travel?: Maybe<Travel>;
  travelId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type WebsiteCountAggregate = {
  __typename?: 'WebsiteCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  travelId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
};

export type WebsiteCreateManyTravelInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type WebsiteCreateManyTravelInputEnvelope = {
  data: Array<WebsiteCreateManyTravelInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WebsiteCreateNestedManyWithoutTravelInput = {
  connect?: InputMaybe<Array<WebsiteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WebsiteCreateOrConnectWithoutTravelInput>>;
  create?: InputMaybe<Array<WebsiteCreateWithoutTravelInput>>;
  createMany?: InputMaybe<WebsiteCreateManyTravelInputEnvelope>;
};

export type WebsiteCreateOrConnectWithoutTravelInput = {
  create: WebsiteCreateWithoutTravelInput;
  where: WebsiteWhereUniqueInput;
};

export type WebsiteCreateWithoutTravelInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type WebsiteListRelationFilter = {
  every?: InputMaybe<WebsiteWhereInput>;
  none?: InputMaybe<WebsiteWhereInput>;
  some?: InputMaybe<WebsiteWhereInput>;
};

export type WebsiteMaxAggregate = {
  __typename?: 'WebsiteMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  travelId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type WebsiteMinAggregate = {
  __typename?: 'WebsiteMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  travelId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type WebsiteScalarWhereInput = {
  AND?: InputMaybe<Array<WebsiteScalarWhereInput>>;
  NOT?: InputMaybe<Array<WebsiteScalarWhereInput>>;
  OR?: InputMaybe<Array<WebsiteScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  travelId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type WebsiteUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type WebsiteUpdateManyWithWhereWithoutTravelInput = {
  data: WebsiteUpdateManyMutationInput;
  where: WebsiteScalarWhereInput;
};

export type WebsiteUpdateManyWithoutTravelNestedInput = {
  connect?: InputMaybe<Array<WebsiteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WebsiteCreateOrConnectWithoutTravelInput>>;
  create?: InputMaybe<Array<WebsiteCreateWithoutTravelInput>>;
  createMany?: InputMaybe<WebsiteCreateManyTravelInputEnvelope>;
  delete?: InputMaybe<Array<WebsiteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WebsiteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WebsiteWhereUniqueInput>>;
  set?: InputMaybe<Array<WebsiteWhereUniqueInput>>;
  update?: InputMaybe<Array<WebsiteUpdateWithWhereUniqueWithoutTravelInput>>;
  updateMany?: InputMaybe<Array<WebsiteUpdateManyWithWhereWithoutTravelInput>>;
  upsert?: InputMaybe<Array<WebsiteUpsertWithWhereUniqueWithoutTravelInput>>;
};

export type WebsiteUpdateWithWhereUniqueWithoutTravelInput = {
  data: WebsiteUpdateWithoutTravelInput;
  where: WebsiteWhereUniqueInput;
};

export type WebsiteUpdateWithoutTravelInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type WebsiteUpsertWithWhereUniqueWithoutTravelInput = {
  create: WebsiteCreateWithoutTravelInput;
  update: WebsiteUpdateWithoutTravelInput;
  where: WebsiteWhereUniqueInput;
};

export type WebsiteWhereInput = {
  AND?: InputMaybe<Array<WebsiteWhereInput>>;
  NOT?: InputMaybe<Array<WebsiteWhereInput>>;
  OR?: InputMaybe<Array<WebsiteWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  travel?: InputMaybe<TravelNullableRelationFilter>;
  travelId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type WebsiteWhereUniqueInput = {
  AND?: InputMaybe<Array<WebsiteWhereInput>>;
  NOT?: InputMaybe<Array<WebsiteWhereInput>>;
  OR?: InputMaybe<Array<WebsiteWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  travel?: InputMaybe<TravelNullableRelationFilter>;
  travelId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type Wisdom = {
  __typename?: 'Wisdom';
  _count: WisdomCount;
  category: WisdomCategory;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  images?: Maybe<Array<WisdomImage>>;
  informant?: Maybe<Scalars['String']['output']>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  patentedStatus: PatentedStatus;
  pr: WisdomPr;
  publishable?: Maybe<Array<PublishableWisdom>>;
  status: WisdomStatus;
  tambon: Tambon;
  tambonId: Scalars['String']['output'];
  tools?: Maybe<Array<WisdomTool>>;
  uniqueWisdom?: Maybe<Array<UniqueWisdom>>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
  wisdomCategoryId: Scalars['String']['output'];
};

export type WisdomAvgAggregate = {
  __typename?: 'WisdomAvgAggregate';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

export type WisdomCategory = {
  __typename?: 'WisdomCategory';
  _count: WisdomCategoryCount;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tambon?: Maybe<Tambon>;
  tambonId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  wisdoms?: Maybe<Array<Wisdom>>;
};

export type WisdomCategoryCount = {
  __typename?: 'WisdomCategoryCount';
  wisdoms: Scalars['Int']['output'];
};

export type WisdomCategoryCountAggregate = {
  __typename?: 'WisdomCategoryCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  tambonId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type WisdomCategoryCreateManyTambonInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WisdomCategoryCreateManyTambonInputEnvelope = {
  data: Array<WisdomCategoryCreateManyTambonInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WisdomCategoryCreateNestedManyWithoutTambonInput = {
  connect?: InputMaybe<Array<WisdomCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WisdomCategoryCreateOrConnectWithoutTambonInput>>;
  create?: InputMaybe<Array<WisdomCategoryCreateWithoutTambonInput>>;
  createMany?: InputMaybe<WisdomCategoryCreateManyTambonInputEnvelope>;
};

export type WisdomCategoryCreateNestedOneWithoutWisdomsInput = {
  connect?: InputMaybe<WisdomCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WisdomCategoryCreateOrConnectWithoutWisdomsInput>;
  create?: InputMaybe<WisdomCategoryCreateWithoutWisdomsInput>;
};

export type WisdomCategoryCreateOrConnectWithoutTambonInput = {
  create: WisdomCategoryCreateWithoutTambonInput;
  where: WisdomCategoryWhereUniqueInput;
};

export type WisdomCategoryCreateOrConnectWithoutWisdomsInput = {
  create: WisdomCategoryCreateWithoutWisdomsInput;
  where: WisdomCategoryWhereUniqueInput;
};

export type WisdomCategoryCreateWithoutTambonInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wisdoms?: InputMaybe<WisdomCreateNestedManyWithoutCategoryInput>;
};

export type WisdomCategoryCreateWithoutWisdomsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  tambon?: InputMaybe<TambonCreateNestedOneWithoutWisdomCategoryInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WisdomCategoryListRelationFilter = {
  every?: InputMaybe<WisdomCategoryWhereInput>;
  none?: InputMaybe<WisdomCategoryWhereInput>;
  some?: InputMaybe<WisdomCategoryWhereInput>;
};

export type WisdomCategoryMaxAggregate = {
  __typename?: 'WisdomCategoryMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tambonId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type WisdomCategoryMinAggregate = {
  __typename?: 'WisdomCategoryMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tambonId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type WisdomCategoryRelationFilter = {
  is?: InputMaybe<WisdomCategoryWhereInput>;
  isNot?: InputMaybe<WisdomCategoryWhereInput>;
};

export type WisdomCategoryScalarWhereInput = {
  AND?: InputMaybe<Array<WisdomCategoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<WisdomCategoryScalarWhereInput>>;
  OR?: InputMaybe<Array<WisdomCategoryScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  tambonId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type WisdomCategoryUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type WisdomCategoryUpdateManyWithWhereWithoutTambonInput = {
  data: WisdomCategoryUpdateManyMutationInput;
  where: WisdomCategoryScalarWhereInput;
};

export type WisdomCategoryUpdateManyWithoutTambonNestedInput = {
  connect?: InputMaybe<Array<WisdomCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WisdomCategoryCreateOrConnectWithoutTambonInput>>;
  create?: InputMaybe<Array<WisdomCategoryCreateWithoutTambonInput>>;
  createMany?: InputMaybe<WisdomCategoryCreateManyTambonInputEnvelope>;
  delete?: InputMaybe<Array<WisdomCategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WisdomCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WisdomCategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<WisdomCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<WisdomCategoryUpdateWithWhereUniqueWithoutTambonInput>>;
  updateMany?: InputMaybe<Array<WisdomCategoryUpdateManyWithWhereWithoutTambonInput>>;
  upsert?: InputMaybe<Array<WisdomCategoryUpsertWithWhereUniqueWithoutTambonInput>>;
};

export type WisdomCategoryUpdateOneRequiredWithoutWisdomsNestedInput = {
  connect?: InputMaybe<WisdomCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WisdomCategoryCreateOrConnectWithoutWisdomsInput>;
  create?: InputMaybe<WisdomCategoryCreateWithoutWisdomsInput>;
  update?: InputMaybe<WisdomCategoryUpdateToOneWithWhereWithoutWisdomsInput>;
  upsert?: InputMaybe<WisdomCategoryUpsertWithoutWisdomsInput>;
};

export type WisdomCategoryUpdateToOneWithWhereWithoutWisdomsInput = {
  data: WisdomCategoryUpdateWithoutWisdomsInput;
  where?: InputMaybe<WisdomCategoryWhereInput>;
};

export type WisdomCategoryUpdateWithWhereUniqueWithoutTambonInput = {
  data: WisdomCategoryUpdateWithoutTambonInput;
  where: WisdomCategoryWhereUniqueInput;
};

export type WisdomCategoryUpdateWithoutTambonInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  wisdoms?: InputMaybe<WisdomUpdateManyWithoutCategoryNestedInput>;
};

export type WisdomCategoryUpdateWithoutWisdomsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  tambon?: InputMaybe<TambonUpdateOneWithoutWisdomCategoryNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type WisdomCategoryUpsertWithWhereUniqueWithoutTambonInput = {
  create: WisdomCategoryCreateWithoutTambonInput;
  update: WisdomCategoryUpdateWithoutTambonInput;
  where: WisdomCategoryWhereUniqueInput;
};

export type WisdomCategoryUpsertWithoutWisdomsInput = {
  create: WisdomCategoryCreateWithoutWisdomsInput;
  update: WisdomCategoryUpdateWithoutWisdomsInput;
  where?: InputMaybe<WisdomCategoryWhereInput>;
};

export type WisdomCategoryWhereInput = {
  AND?: InputMaybe<Array<WisdomCategoryWhereInput>>;
  NOT?: InputMaybe<Array<WisdomCategoryWhereInput>>;
  OR?: InputMaybe<Array<WisdomCategoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  tambon?: InputMaybe<TambonNullableRelationFilter>;
  tambonId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wisdoms?: InputMaybe<WisdomListRelationFilter>;
};

export type WisdomCategoryWhereUniqueInput = {
  AND?: InputMaybe<Array<WisdomCategoryWhereInput>>;
  NOT?: InputMaybe<Array<WisdomCategoryWhereInput>>;
  OR?: InputMaybe<Array<WisdomCategoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tambon?: InputMaybe<TambonNullableRelationFilter>;
  tambonId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wisdoms?: InputMaybe<WisdomListRelationFilter>;
};

export type WisdomCount = {
  __typename?: 'WisdomCount';
  images: Scalars['Int']['output'];
  publishable: Scalars['Int']['output'];
  tools: Scalars['Int']['output'];
  uniqueWisdom: Scalars['Int']['output'];
};

export type WisdomCountAggregate = {
  __typename?: 'WisdomCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  informant: Scalars['Int']['output'];
  latitude: Scalars['Int']['output'];
  longitude: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  patentedStatus: Scalars['Int']['output'];
  pr: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  tambonId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
  wisdomCategoryId: Scalars['Int']['output'];
};

export type WisdomCreateInput = {
  category: WisdomCategoryCreateNestedOneWithoutWisdomsInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<WisdomImageCreateNestedManyWithoutWisdomInput>;
  informant?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  patentedStatus: PatentedStatus;
  pr: WisdomPr;
  publishable?: InputMaybe<PublishableWisdomCreateNestedManyWithoutWisdomInput>;
  status: WisdomStatus;
  tambon: TambonCreateNestedOneWithoutWisdomInput;
  tools?: InputMaybe<WisdomToolCreateNestedManyWithoutWisdomInput>;
  uniqueWisdom?: InputMaybe<UniqueWisdomCreateNestedManyWithoutWisdomInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutWisdomsInput;
};

export type WisdomCreateManyCategoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  informant?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  patentedStatus: PatentedStatus;
  pr: WisdomPr;
  status: WisdomStatus;
  tambonId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type WisdomCreateManyCategoryInputEnvelope = {
  data: Array<WisdomCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WisdomCreateManyTambonInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  informant?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  patentedStatus: PatentedStatus;
  pr: WisdomPr;
  status: WisdomStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  wisdomCategoryId: Scalars['String']['input'];
};

export type WisdomCreateManyTambonInputEnvelope = {
  data: Array<WisdomCreateManyTambonInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WisdomCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  informant?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  patentedStatus: PatentedStatus;
  pr: WisdomPr;
  status: WisdomStatus;
  tambonId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wisdomCategoryId: Scalars['String']['input'];
};

export type WisdomCreateManyUserInputEnvelope = {
  data: Array<WisdomCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WisdomCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<WisdomWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WisdomCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<WisdomCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<WisdomCreateManyCategoryInputEnvelope>;
};

export type WisdomCreateNestedManyWithoutTambonInput = {
  connect?: InputMaybe<Array<WisdomWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WisdomCreateOrConnectWithoutTambonInput>>;
  create?: InputMaybe<Array<WisdomCreateWithoutTambonInput>>;
  createMany?: InputMaybe<WisdomCreateManyTambonInputEnvelope>;
};

export type WisdomCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<WisdomWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WisdomCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<WisdomCreateWithoutUserInput>>;
  createMany?: InputMaybe<WisdomCreateManyUserInputEnvelope>;
};

export type WisdomCreateOrConnectWithoutCategoryInput = {
  create: WisdomCreateWithoutCategoryInput;
  where: WisdomWhereUniqueInput;
};

export type WisdomCreateOrConnectWithoutTambonInput = {
  create: WisdomCreateWithoutTambonInput;
  where: WisdomWhereUniqueInput;
};

export type WisdomCreateOrConnectWithoutUserInput = {
  create: WisdomCreateWithoutUserInput;
  where: WisdomWhereUniqueInput;
};

export type WisdomCreateWithoutCategoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<WisdomImageCreateNestedManyWithoutWisdomInput>;
  informant?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  patentedStatus: PatentedStatus;
  pr: WisdomPr;
  publishable?: InputMaybe<PublishableWisdomCreateNestedManyWithoutWisdomInput>;
  status: WisdomStatus;
  tambon: TambonCreateNestedOneWithoutWisdomInput;
  tools?: InputMaybe<WisdomToolCreateNestedManyWithoutWisdomInput>;
  uniqueWisdom?: InputMaybe<UniqueWisdomCreateNestedManyWithoutWisdomInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutWisdomsInput;
};

export type WisdomCreateWithoutTambonInput = {
  category: WisdomCategoryCreateNestedOneWithoutWisdomsInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<WisdomImageCreateNestedManyWithoutWisdomInput>;
  informant?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  patentedStatus: PatentedStatus;
  pr: WisdomPr;
  publishable?: InputMaybe<PublishableWisdomCreateNestedManyWithoutWisdomInput>;
  status: WisdomStatus;
  tools?: InputMaybe<WisdomToolCreateNestedManyWithoutWisdomInput>;
  uniqueWisdom?: InputMaybe<UniqueWisdomCreateNestedManyWithoutWisdomInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutWisdomsInput;
};

export type WisdomCreateWithoutUserInput = {
  category: WisdomCategoryCreateNestedOneWithoutWisdomsInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<WisdomImageCreateNestedManyWithoutWisdomInput>;
  informant?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  patentedStatus: PatentedStatus;
  pr: WisdomPr;
  publishable?: InputMaybe<PublishableWisdomCreateNestedManyWithoutWisdomInput>;
  status: WisdomStatus;
  tambon: TambonCreateNestedOneWithoutWisdomInput;
  tools?: InputMaybe<WisdomToolCreateNestedManyWithoutWisdomInput>;
  uniqueWisdom?: InputMaybe<UniqueWisdomCreateNestedManyWithoutWisdomInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WisdomImage = {
  __typename?: 'WisdomImage';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
  wisdom: Wisdom;
  wisdomId: Scalars['String']['output'];
};

export type WisdomImageCountAggregate = {
  __typename?: 'WisdomImageCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
  wisdomId: Scalars['Int']['output'];
};

export type WisdomImageCreateManyWisdomInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type WisdomImageCreateManyWisdomInputEnvelope = {
  data: Array<WisdomImageCreateManyWisdomInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WisdomImageCreateNestedManyWithoutWisdomInput = {
  connect?: InputMaybe<Array<WisdomImageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WisdomImageCreateOrConnectWithoutWisdomInput>>;
  create?: InputMaybe<Array<WisdomImageCreateWithoutWisdomInput>>;
  createMany?: InputMaybe<WisdomImageCreateManyWisdomInputEnvelope>;
};

export type WisdomImageCreateOrConnectWithoutWisdomInput = {
  create: WisdomImageCreateWithoutWisdomInput;
  where: WisdomImageWhereUniqueInput;
};

export type WisdomImageCreateWithoutWisdomInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type WisdomImageListRelationFilter = {
  every?: InputMaybe<WisdomImageWhereInput>;
  none?: InputMaybe<WisdomImageWhereInput>;
  some?: InputMaybe<WisdomImageWhereInput>;
};

export type WisdomImageMaxAggregate = {
  __typename?: 'WisdomImageMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  wisdomId?: Maybe<Scalars['String']['output']>;
};

export type WisdomImageMinAggregate = {
  __typename?: 'WisdomImageMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  wisdomId?: Maybe<Scalars['String']['output']>;
};

export type WisdomImageScalarWhereInput = {
  AND?: InputMaybe<Array<WisdomImageScalarWhereInput>>;
  NOT?: InputMaybe<Array<WisdomImageScalarWhereInput>>;
  OR?: InputMaybe<Array<WisdomImageScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
  wisdomId?: InputMaybe<StringFilter>;
};

export type WisdomImageUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type WisdomImageUpdateManyWithWhereWithoutWisdomInput = {
  data: WisdomImageUpdateManyMutationInput;
  where: WisdomImageScalarWhereInput;
};

export type WisdomImageUpdateManyWithoutWisdomNestedInput = {
  connect?: InputMaybe<Array<WisdomImageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WisdomImageCreateOrConnectWithoutWisdomInput>>;
  create?: InputMaybe<Array<WisdomImageCreateWithoutWisdomInput>>;
  createMany?: InputMaybe<WisdomImageCreateManyWisdomInputEnvelope>;
  delete?: InputMaybe<Array<WisdomImageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WisdomImageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WisdomImageWhereUniqueInput>>;
  set?: InputMaybe<Array<WisdomImageWhereUniqueInput>>;
  update?: InputMaybe<Array<WisdomImageUpdateWithWhereUniqueWithoutWisdomInput>>;
  updateMany?: InputMaybe<Array<WisdomImageUpdateManyWithWhereWithoutWisdomInput>>;
  upsert?: InputMaybe<Array<WisdomImageUpsertWithWhereUniqueWithoutWisdomInput>>;
};

export type WisdomImageUpdateWithWhereUniqueWithoutWisdomInput = {
  data: WisdomImageUpdateWithoutWisdomInput;
  where: WisdomImageWhereUniqueInput;
};

export type WisdomImageUpdateWithoutWisdomInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type WisdomImageUpsertWithWhereUniqueWithoutWisdomInput = {
  create: WisdomImageCreateWithoutWisdomInput;
  update: WisdomImageUpdateWithoutWisdomInput;
  where: WisdomImageWhereUniqueInput;
};

export type WisdomImageWhereInput = {
  AND?: InputMaybe<Array<WisdomImageWhereInput>>;
  NOT?: InputMaybe<Array<WisdomImageWhereInput>>;
  OR?: InputMaybe<Array<WisdomImageWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
  wisdom?: InputMaybe<WisdomRelationFilter>;
  wisdomId?: InputMaybe<StringFilter>;
};

export type WisdomImageWhereUniqueInput = {
  AND?: InputMaybe<Array<WisdomImageWhereInput>>;
  NOT?: InputMaybe<Array<WisdomImageWhereInput>>;
  OR?: InputMaybe<Array<WisdomImageWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
  wisdom?: InputMaybe<WisdomRelationFilter>;
  wisdomId?: InputMaybe<StringFilter>;
};

export type WisdomListRelationFilter = {
  every?: InputMaybe<WisdomWhereInput>;
  none?: InputMaybe<WisdomWhereInput>;
  some?: InputMaybe<WisdomWhereInput>;
};

export type WisdomMaxAggregate = {
  __typename?: 'WisdomMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  informant?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  patentedStatus?: Maybe<PatentedStatus>;
  pr?: Maybe<WisdomPr>;
  status?: Maybe<WisdomStatus>;
  tambonId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  wisdomCategoryId?: Maybe<Scalars['String']['output']>;
};

export type WisdomMinAggregate = {
  __typename?: 'WisdomMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  informant?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  patentedStatus?: Maybe<PatentedStatus>;
  pr?: Maybe<WisdomPr>;
  status?: Maybe<WisdomStatus>;
  tambonId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  wisdomCategoryId?: Maybe<Scalars['String']['output']>;
};

export type WisdomNullableRelationFilter = {
  is?: InputMaybe<WisdomWhereInput>;
  isNot?: InputMaybe<WisdomWhereInput>;
};

export enum WisdomPr {
  Never = 'NEVER',
  PublishedExternal = 'PUBLISHED_EXTERNAL',
  PublishedLocal = 'PUBLISHED_LOCAL'
}

export type WisdomRelationFilter = {
  is?: InputMaybe<WisdomWhereInput>;
  isNot?: InputMaybe<WisdomWhereInput>;
};

export type WisdomScalarWhereInput = {
  AND?: InputMaybe<Array<WisdomScalarWhereInput>>;
  NOT?: InputMaybe<Array<WisdomScalarWhereInput>>;
  OR?: InputMaybe<Array<WisdomScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  informant?: InputMaybe<StringNullableFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  patentedStatus?: InputMaybe<EnumPatentedStatusFilter>;
  pr?: InputMaybe<EnumWisdomPrFilter>;
  status?: InputMaybe<EnumWisdomStatusFilter>;
  tambonId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  wisdomCategoryId?: InputMaybe<StringFilter>;
};

export enum WisdomStatus {
  Developed = 'DEVELOPED',
  New = 'NEW',
  Old = 'OLD'
}

export type WisdomSumAggregate = {
  __typename?: 'WisdomSumAggregate';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

export type WisdomTool = {
  __typename?: 'WisdomTool';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  wisdom: Wisdom;
  wisdomId: Scalars['String']['output'];
};

export type WisdomToolCountAggregate = {
  __typename?: 'WisdomToolCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  wisdomId: Scalars['Int']['output'];
};

export type WisdomToolCreateManyWisdomInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WisdomToolCreateManyWisdomInputEnvelope = {
  data: Array<WisdomToolCreateManyWisdomInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WisdomToolCreateNestedManyWithoutWisdomInput = {
  connect?: InputMaybe<Array<WisdomToolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WisdomToolCreateOrConnectWithoutWisdomInput>>;
  create?: InputMaybe<Array<WisdomToolCreateWithoutWisdomInput>>;
  createMany?: InputMaybe<WisdomToolCreateManyWisdomInputEnvelope>;
};

export type WisdomToolCreateOrConnectWithoutWisdomInput = {
  create: WisdomToolCreateWithoutWisdomInput;
  where: WisdomToolWhereUniqueInput;
};

export type WisdomToolCreateWithoutWisdomInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WisdomToolListRelationFilter = {
  every?: InputMaybe<WisdomToolWhereInput>;
  none?: InputMaybe<WisdomToolWhereInput>;
  some?: InputMaybe<WisdomToolWhereInput>;
};

export type WisdomToolMaxAggregate = {
  __typename?: 'WisdomToolMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  wisdomId?: Maybe<Scalars['String']['output']>;
};

export type WisdomToolMinAggregate = {
  __typename?: 'WisdomToolMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  wisdomId?: Maybe<Scalars['String']['output']>;
};

export type WisdomToolScalarWhereInput = {
  AND?: InputMaybe<Array<WisdomToolScalarWhereInput>>;
  NOT?: InputMaybe<Array<WisdomToolScalarWhereInput>>;
  OR?: InputMaybe<Array<WisdomToolScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wisdomId?: InputMaybe<StringFilter>;
};

export type WisdomToolUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type WisdomToolUpdateManyWithWhereWithoutWisdomInput = {
  data: WisdomToolUpdateManyMutationInput;
  where: WisdomToolScalarWhereInput;
};

export type WisdomToolUpdateManyWithoutWisdomNestedInput = {
  connect?: InputMaybe<Array<WisdomToolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WisdomToolCreateOrConnectWithoutWisdomInput>>;
  create?: InputMaybe<Array<WisdomToolCreateWithoutWisdomInput>>;
  createMany?: InputMaybe<WisdomToolCreateManyWisdomInputEnvelope>;
  delete?: InputMaybe<Array<WisdomToolWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WisdomToolScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WisdomToolWhereUniqueInput>>;
  set?: InputMaybe<Array<WisdomToolWhereUniqueInput>>;
  update?: InputMaybe<Array<WisdomToolUpdateWithWhereUniqueWithoutWisdomInput>>;
  updateMany?: InputMaybe<Array<WisdomToolUpdateManyWithWhereWithoutWisdomInput>>;
  upsert?: InputMaybe<Array<WisdomToolUpsertWithWhereUniqueWithoutWisdomInput>>;
};

export type WisdomToolUpdateWithWhereUniqueWithoutWisdomInput = {
  data: WisdomToolUpdateWithoutWisdomInput;
  where: WisdomToolWhereUniqueInput;
};

export type WisdomToolUpdateWithoutWisdomInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type WisdomToolUpsertWithWhereUniqueWithoutWisdomInput = {
  create: WisdomToolCreateWithoutWisdomInput;
  update: WisdomToolUpdateWithoutWisdomInput;
  where: WisdomToolWhereUniqueInput;
};

export type WisdomToolWhereInput = {
  AND?: InputMaybe<Array<WisdomToolWhereInput>>;
  NOT?: InputMaybe<Array<WisdomToolWhereInput>>;
  OR?: InputMaybe<Array<WisdomToolWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wisdom?: InputMaybe<WisdomRelationFilter>;
  wisdomId?: InputMaybe<StringFilter>;
};

export type WisdomToolWhereUniqueInput = {
  AND?: InputMaybe<Array<WisdomToolWhereInput>>;
  NOT?: InputMaybe<Array<WisdomToolWhereInput>>;
  OR?: InputMaybe<Array<WisdomToolWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wisdom?: InputMaybe<WisdomRelationFilter>;
  wisdomId?: InputMaybe<StringFilter>;
};

export type WisdomUpdateInput = {
  category?: InputMaybe<WisdomCategoryUpdateOneRequiredWithoutWisdomsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  images?: InputMaybe<WisdomImageUpdateManyWithoutWisdomNestedInput>;
  informant?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  patentedStatus?: InputMaybe<EnumPatentedStatusFieldUpdateOperationsInput>;
  pr?: InputMaybe<EnumWisdomPrFieldUpdateOperationsInput>;
  publishable?: InputMaybe<PublishableWisdomUpdateManyWithoutWisdomNestedInput>;
  status?: InputMaybe<EnumWisdomStatusFieldUpdateOperationsInput>;
  tambon?: InputMaybe<TambonUpdateOneRequiredWithoutWisdomNestedInput>;
  tools?: InputMaybe<WisdomToolUpdateManyWithoutWisdomNestedInput>;
  uniqueWisdom?: InputMaybe<UniqueWisdomUpdateManyWithoutWisdomNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutWisdomsNestedInput>;
};

export type WisdomUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  informant?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  patentedStatus?: InputMaybe<EnumPatentedStatusFieldUpdateOperationsInput>;
  pr?: InputMaybe<EnumWisdomPrFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumWisdomStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type WisdomUpdateManyWithWhereWithoutCategoryInput = {
  data: WisdomUpdateManyMutationInput;
  where: WisdomScalarWhereInput;
};

export type WisdomUpdateManyWithWhereWithoutTambonInput = {
  data: WisdomUpdateManyMutationInput;
  where: WisdomScalarWhereInput;
};

export type WisdomUpdateManyWithWhereWithoutUserInput = {
  data: WisdomUpdateManyMutationInput;
  where: WisdomScalarWhereInput;
};

export type WisdomUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<WisdomWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WisdomCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<WisdomCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<WisdomCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<WisdomWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WisdomScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WisdomWhereUniqueInput>>;
  set?: InputMaybe<Array<WisdomWhereUniqueInput>>;
  update?: InputMaybe<Array<WisdomUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<WisdomUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<WisdomUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type WisdomUpdateManyWithoutTambonNestedInput = {
  connect?: InputMaybe<Array<WisdomWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WisdomCreateOrConnectWithoutTambonInput>>;
  create?: InputMaybe<Array<WisdomCreateWithoutTambonInput>>;
  createMany?: InputMaybe<WisdomCreateManyTambonInputEnvelope>;
  delete?: InputMaybe<Array<WisdomWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WisdomScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WisdomWhereUniqueInput>>;
  set?: InputMaybe<Array<WisdomWhereUniqueInput>>;
  update?: InputMaybe<Array<WisdomUpdateWithWhereUniqueWithoutTambonInput>>;
  updateMany?: InputMaybe<Array<WisdomUpdateManyWithWhereWithoutTambonInput>>;
  upsert?: InputMaybe<Array<WisdomUpsertWithWhereUniqueWithoutTambonInput>>;
};

export type WisdomUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<WisdomWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WisdomCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<WisdomCreateWithoutUserInput>>;
  createMany?: InputMaybe<WisdomCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<WisdomWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WisdomScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WisdomWhereUniqueInput>>;
  set?: InputMaybe<Array<WisdomWhereUniqueInput>>;
  update?: InputMaybe<Array<WisdomUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<WisdomUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<WisdomUpsertWithWhereUniqueWithoutUserInput>>;
};

export type WisdomUpdateWithWhereUniqueWithoutCategoryInput = {
  data: WisdomUpdateWithoutCategoryInput;
  where: WisdomWhereUniqueInput;
};

export type WisdomUpdateWithWhereUniqueWithoutTambonInput = {
  data: WisdomUpdateWithoutTambonInput;
  where: WisdomWhereUniqueInput;
};

export type WisdomUpdateWithWhereUniqueWithoutUserInput = {
  data: WisdomUpdateWithoutUserInput;
  where: WisdomWhereUniqueInput;
};

export type WisdomUpdateWithoutCategoryInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  images?: InputMaybe<WisdomImageUpdateManyWithoutWisdomNestedInput>;
  informant?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  patentedStatus?: InputMaybe<EnumPatentedStatusFieldUpdateOperationsInput>;
  pr?: InputMaybe<EnumWisdomPrFieldUpdateOperationsInput>;
  publishable?: InputMaybe<PublishableWisdomUpdateManyWithoutWisdomNestedInput>;
  status?: InputMaybe<EnumWisdomStatusFieldUpdateOperationsInput>;
  tambon?: InputMaybe<TambonUpdateOneRequiredWithoutWisdomNestedInput>;
  tools?: InputMaybe<WisdomToolUpdateManyWithoutWisdomNestedInput>;
  uniqueWisdom?: InputMaybe<UniqueWisdomUpdateManyWithoutWisdomNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutWisdomsNestedInput>;
};

export type WisdomUpdateWithoutTambonInput = {
  category?: InputMaybe<WisdomCategoryUpdateOneRequiredWithoutWisdomsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  images?: InputMaybe<WisdomImageUpdateManyWithoutWisdomNestedInput>;
  informant?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  patentedStatus?: InputMaybe<EnumPatentedStatusFieldUpdateOperationsInput>;
  pr?: InputMaybe<EnumWisdomPrFieldUpdateOperationsInput>;
  publishable?: InputMaybe<PublishableWisdomUpdateManyWithoutWisdomNestedInput>;
  status?: InputMaybe<EnumWisdomStatusFieldUpdateOperationsInput>;
  tools?: InputMaybe<WisdomToolUpdateManyWithoutWisdomNestedInput>;
  uniqueWisdom?: InputMaybe<UniqueWisdomUpdateManyWithoutWisdomNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutWisdomsNestedInput>;
};

export type WisdomUpdateWithoutUserInput = {
  category?: InputMaybe<WisdomCategoryUpdateOneRequiredWithoutWisdomsNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  images?: InputMaybe<WisdomImageUpdateManyWithoutWisdomNestedInput>;
  informant?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  patentedStatus?: InputMaybe<EnumPatentedStatusFieldUpdateOperationsInput>;
  pr?: InputMaybe<EnumWisdomPrFieldUpdateOperationsInput>;
  publishable?: InputMaybe<PublishableWisdomUpdateManyWithoutWisdomNestedInput>;
  status?: InputMaybe<EnumWisdomStatusFieldUpdateOperationsInput>;
  tambon?: InputMaybe<TambonUpdateOneRequiredWithoutWisdomNestedInput>;
  tools?: InputMaybe<WisdomToolUpdateManyWithoutWisdomNestedInput>;
  uniqueWisdom?: InputMaybe<UniqueWisdomUpdateManyWithoutWisdomNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type WisdomUpsertWithWhereUniqueWithoutCategoryInput = {
  create: WisdomCreateWithoutCategoryInput;
  update: WisdomUpdateWithoutCategoryInput;
  where: WisdomWhereUniqueInput;
};

export type WisdomUpsertWithWhereUniqueWithoutTambonInput = {
  create: WisdomCreateWithoutTambonInput;
  update: WisdomUpdateWithoutTambonInput;
  where: WisdomWhereUniqueInput;
};

export type WisdomUpsertWithWhereUniqueWithoutUserInput = {
  create: WisdomCreateWithoutUserInput;
  update: WisdomUpdateWithoutUserInput;
  where: WisdomWhereUniqueInput;
};

export type WisdomWhereInput = {
  AND?: InputMaybe<Array<WisdomWhereInput>>;
  NOT?: InputMaybe<Array<WisdomWhereInput>>;
  OR?: InputMaybe<Array<WisdomWhereInput>>;
  category?: InputMaybe<WisdomCategoryRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  images?: InputMaybe<WisdomImageListRelationFilter>;
  informant?: InputMaybe<StringNullableFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  patentedStatus?: InputMaybe<EnumPatentedStatusFilter>;
  pr?: InputMaybe<EnumWisdomPrFilter>;
  publishable?: InputMaybe<PublishableWisdomListRelationFilter>;
  status?: InputMaybe<EnumWisdomStatusFilter>;
  tambon?: InputMaybe<TambonRelationFilter>;
  tambonId?: InputMaybe<StringFilter>;
  tools?: InputMaybe<WisdomToolListRelationFilter>;
  uniqueWisdom?: InputMaybe<UniqueWisdomListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  wisdomCategoryId?: InputMaybe<StringFilter>;
};

export type WisdomWhereUniqueInput = {
  AND?: InputMaybe<Array<WisdomWhereInput>>;
  NOT?: InputMaybe<Array<WisdomWhereInput>>;
  OR?: InputMaybe<Array<WisdomWhereInput>>;
  category?: InputMaybe<WisdomCategoryRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<WisdomImageListRelationFilter>;
  informant?: InputMaybe<StringNullableFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  patentedStatus?: InputMaybe<EnumPatentedStatusFilter>;
  pr?: InputMaybe<EnumWisdomPrFilter>;
  publishable?: InputMaybe<PublishableWisdomListRelationFilter>;
  status?: InputMaybe<EnumWisdomStatusFilter>;
  tambon?: InputMaybe<TambonRelationFilter>;
  tambonId?: InputMaybe<StringFilter>;
  tools?: InputMaybe<WisdomToolListRelationFilter>;
  uniqueWisdom?: InputMaybe<UniqueWisdomListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  wisdomCategoryId?: InputMaybe<StringFilter>;
};

export type AddressFragmentFragment = { __typename?: 'Address', addressLine1: string, addressLine2: string, createdAt: any, id: string, name: string, tambonId: string, updatedAt: any };

export type AddressCountAggregateFragmentFragment = { __typename?: 'AddressCountAggregate', _all: number, addressLine1: number, addressLine2: number, createdAt: number, id: number, name: number, tambonId: number, updatedAt: number };

export type AddressMaxAggregateFragmentFragment = { __typename?: 'AddressMaxAggregate', addressLine1?: string | null, addressLine2?: string | null, createdAt?: any | null, id?: string | null, name?: string | null, tambonId?: string | null, updatedAt?: any | null };

export type AddressMinAggregateFragmentFragment = { __typename?: 'AddressMinAggregate', addressLine1?: string | null, addressLine2?: string | null, createdAt?: any | null, id?: string | null, name?: string | null, tambonId?: string | null, updatedAt?: any | null };

export type AmphoeFragmentFragment = { __typename?: 'Amphoe', createdAt: any, id: string, nameEn: string, nameTh: string, provinceId?: string | null, updatedAt: any };

export type AmphoeCountFragmentFragment = { __typename?: 'AmphoeCount', tambons: number };

export type AmphoeCountAggregateFragmentFragment = { __typename?: 'AmphoeCountAggregate', _all: number, createdAt: number, id: number, nameEn: number, nameTh: number, provinceId: number, updatedAt: number };

export type AmphoeMaxAggregateFragmentFragment = { __typename?: 'AmphoeMaxAggregate', createdAt?: any | null, id?: string | null, nameEn?: string | null, nameTh?: string | null, provinceId?: string | null, updatedAt?: any | null };

export type AmphoeMinAggregateFragmentFragment = { __typename?: 'AmphoeMinAggregate', createdAt?: any | null, id?: string | null, nameEn?: string | null, nameTh?: string | null, provinceId?: string | null, updatedAt?: any | null };

export type ContactNumberFragmentFragment = { __typename?: 'ContactNumber', createdAt: any, id: string, tel: string, travelId?: string | null, updatedAt: any };

export type ContactNumberCountAggregateFragmentFragment = { __typename?: 'ContactNumberCountAggregate', _all: number, createdAt: number, id: number, tel: number, travelId: number, updatedAt: number };

export type ContactNumberMaxAggregateFragmentFragment = { __typename?: 'ContactNumberMaxAggregate', createdAt?: any | null, id?: string | null, tel?: string | null, travelId?: string | null, updatedAt?: any | null };

export type ContactNumberMinAggregateFragmentFragment = { __typename?: 'ContactNumberMinAggregate', createdAt?: any | null, id?: string | null, tel?: string | null, travelId?: string | null, updatedAt?: any | null };

export type FacilityFragmentFragment = { __typename?: 'Facility', createdAt: any, id: string, name: string, travelId?: string | null, updatedAt: any };

export type FacilityCountFragmentFragment = { __typename?: 'FacilityCount', travels: number };

export type FacilityCountAggregateFragmentFragment = { __typename?: 'FacilityCountAggregate', _all: number, createdAt: number, id: number, name: number, travelId: number, updatedAt: number };

export type FacilityMaxAggregateFragmentFragment = { __typename?: 'FacilityMaxAggregate', createdAt?: any | null, id?: string | null, name?: string | null, travelId?: string | null, updatedAt?: any | null };

export type FacilityMinAggregateFragmentFragment = { __typename?: 'FacilityMinAggregate', createdAt?: any | null, id?: string | null, name?: string | null, travelId?: string | null, updatedAt?: any | null };

export type FoodFragmentFragment = { __typename?: 'Food', createdAt: any, foodCategoryId?: string | null, hasParking?: HasParking | null, id: string, latitude: number, longitude: number, name: string, productCapacity?: number | null, shop: string, story?: string | null, tambonId: string, updatedAt: any, userId: string, foodCategory?: { __typename?: 'FoodCategory', createdAt: any, id: string, name: string, updatedAt: any } | null, images?: Array<{ __typename?: 'FoodImage', createdAt: any, foodId: string, id: string, updatedAt: any, url: string }> | null, ingredients?: Array<{ __typename?: 'Ingredients', createdAt: any, foodId?: string | null, id: string, name: string, updatedAt: any }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } };

export type FoodAvgAggregateFragmentFragment = { __typename?: 'FoodAvgAggregate', latitude?: number | null, longitude?: number | null, productCapacity?: number | null };

export type FoodCategoryFragmentFragment = { __typename?: 'FoodCategory', createdAt: any, id: string, name: string, updatedAt: any };

export type FoodCategoryCountFragmentFragment = { __typename?: 'FoodCategoryCount', food: number };

export type FoodCategoryCountAggregateFragmentFragment = { __typename?: 'FoodCategoryCountAggregate', _all: number, createdAt: number, id: number, name: number, updatedAt: number };

export type FoodCategoryMaxAggregateFragmentFragment = { __typename?: 'FoodCategoryMaxAggregate', createdAt?: any | null, id?: string | null, name?: string | null, updatedAt?: any | null };

export type FoodCategoryMinAggregateFragmentFragment = { __typename?: 'FoodCategoryMinAggregate', createdAt?: any | null, id?: string | null, name?: string | null, updatedAt?: any | null };

export type FoodCountFragmentFragment = { __typename?: 'FoodCount', images: number, ingredients: number };

export type FoodCountAggregateFragmentFragment = { __typename?: 'FoodCountAggregate', _all: number, createdAt: number, foodCategoryId: number, hasParking: number, id: number, latitude: number, longitude: number, name: number, productCapacity: number, shop: number, story: number, tambonId: number, updatedAt: number, userId: number };

export type FoodImageFragmentFragment = { __typename?: 'FoodImage', createdAt: any, foodId: string, id: string, updatedAt: any, url: string };

export type FoodImageCountAggregateFragmentFragment = { __typename?: 'FoodImageCountAggregate', _all: number, createdAt: number, foodId: number, id: number, updatedAt: number, url: number };

export type FoodImageMaxAggregateFragmentFragment = { __typename?: 'FoodImageMaxAggregate', createdAt?: any | null, foodId?: string | null, id?: string | null, updatedAt?: any | null, url?: string | null };

export type FoodImageMinAggregateFragmentFragment = { __typename?: 'FoodImageMinAggregate', createdAt?: any | null, foodId?: string | null, id?: string | null, updatedAt?: any | null, url?: string | null };

export type FoodMaxAggregateFragmentFragment = { __typename?: 'FoodMaxAggregate', createdAt?: any | null, foodCategoryId?: string | null, hasParking?: HasParking | null, id?: string | null, latitude?: number | null, longitude?: number | null, name?: string | null, productCapacity?: number | null, shop?: string | null, story?: string | null, tambonId?: string | null, updatedAt?: any | null, userId?: string | null };

export type FoodMinAggregateFragmentFragment = { __typename?: 'FoodMinAggregate', createdAt?: any | null, foodCategoryId?: string | null, hasParking?: HasParking | null, id?: string | null, latitude?: number | null, longitude?: number | null, name?: string | null, productCapacity?: number | null, shop?: string | null, story?: string | null, tambonId?: string | null, updatedAt?: any | null, userId?: string | null };

export type FoodSumAggregateFragmentFragment = { __typename?: 'FoodSumAggregate', latitude?: number | null, longitude?: number | null, productCapacity?: number | null };

export type IngredientsFragmentFragment = { __typename?: 'Ingredients', createdAt: any, foodId?: string | null, id: string, name: string, updatedAt: any };

export type IngredientsCountAggregateFragmentFragment = { __typename?: 'IngredientsCountAggregate', _all: number, createdAt: number, foodId: number, id: number, name: number, updatedAt: number };

export type IngredientsMaxAggregateFragmentFragment = { __typename?: 'IngredientsMaxAggregate', createdAt?: any | null, foodId?: string | null, id?: string | null, name?: string | null, updatedAt?: any | null };

export type IngredientsMinAggregateFragmentFragment = { __typename?: 'IngredientsMinAggregate', createdAt?: any | null, foodId?: string | null, id?: string | null, name?: string | null, updatedAt?: any | null };

export type PlantFragmentFragment = { __typename?: 'Plant', createdAt: any, foundFrequencyInMonthEnd?: number | null, foundFrequencyInMonthStart?: number | null, hasOwner: HasOwner, id: string, informant?: string | null, latitude: number, longitude: number, name: string, ownerName?: string | null, quantity?: number | null, tambonId: string, updatedAt: any, userId: string, images?: Array<{ __typename?: 'PlantImage', createdAt: any, id: string, kind: PlantImageKind, plantId: string, updatedAt: any, url: string }> | null, kindOfUses?: Array<{ __typename?: 'PlantOnPlantKindOfUse', createdAt: any, plantId: string, plantKindId: string, updatedAt: any, plantKind: { __typename?: 'PlantKindOfUse', createdAt: any, id: string, name: string, updatedAt: any } }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } };

export type PlantAvgAggregateFragmentFragment = { __typename?: 'PlantAvgAggregate', foundFrequencyInMonthEnd?: number | null, foundFrequencyInMonthStart?: number | null, latitude?: number | null, longitude?: number | null, quantity?: number | null };

export type PlantCountFragmentFragment = { __typename?: 'PlantCount', images: number, kindOfUses: number };

export type PlantCountAggregateFragmentFragment = { __typename?: 'PlantCountAggregate', _all: number, createdAt: number, foundFrequencyInMonthEnd: number, foundFrequencyInMonthStart: number, hasOwner: number, id: number, informant: number, latitude: number, longitude: number, name: number, ownerName: number, quantity: number, tambonId: number, updatedAt: number, userId: number };

export type PlantImageFragmentFragment = { __typename?: 'PlantImage', createdAt: any, id: string, kind: PlantImageKind, plantId: string, updatedAt: any, url: string };

export type PlantImageCountAggregateFragmentFragment = { __typename?: 'PlantImageCountAggregate', _all: number, createdAt: number, id: number, kind: number, plantId: number, updatedAt: number, url: number };

export type PlantImageMaxAggregateFragmentFragment = { __typename?: 'PlantImageMaxAggregate', createdAt?: any | null, id?: string | null, kind?: PlantImageKind | null, plantId?: string | null, updatedAt?: any | null, url?: string | null };

export type PlantImageMinAggregateFragmentFragment = { __typename?: 'PlantImageMinAggregate', createdAt?: any | null, id?: string | null, kind?: PlantImageKind | null, plantId?: string | null, updatedAt?: any | null, url?: string | null };

export type PlantKindOfUseFragmentFragment = { __typename?: 'PlantKindOfUse', createdAt: any, id: string, name: string, updatedAt: any };

export type PlantKindOfUseCountFragmentFragment = { __typename?: 'PlantKindOfUseCount', plantOnPlantKindOfUses: number };

export type PlantKindOfUseCountAggregateFragmentFragment = { __typename?: 'PlantKindOfUseCountAggregate', _all: number, createdAt: number, id: number, name: number, updatedAt: number };

export type PlantKindOfUseMaxAggregateFragmentFragment = { __typename?: 'PlantKindOfUseMaxAggregate', createdAt?: any | null, id?: string | null, name?: string | null, updatedAt?: any | null };

export type PlantKindOfUseMinAggregateFragmentFragment = { __typename?: 'PlantKindOfUseMinAggregate', createdAt?: any | null, id?: string | null, name?: string | null, updatedAt?: any | null };

export type PlantMaxAggregateFragmentFragment = { __typename?: 'PlantMaxAggregate', createdAt?: any | null, foundFrequencyInMonthEnd?: number | null, foundFrequencyInMonthStart?: number | null, hasOwner?: HasOwner | null, id?: string | null, informant?: string | null, latitude?: number | null, longitude?: number | null, name?: string | null, ownerName?: string | null, quantity?: number | null, tambonId?: string | null, updatedAt?: any | null, userId?: string | null };

export type PlantMinAggregateFragmentFragment = { __typename?: 'PlantMinAggregate', createdAt?: any | null, foundFrequencyInMonthEnd?: number | null, foundFrequencyInMonthStart?: number | null, hasOwner?: HasOwner | null, id?: string | null, informant?: string | null, latitude?: number | null, longitude?: number | null, name?: string | null, ownerName?: string | null, quantity?: number | null, tambonId?: string | null, updatedAt?: any | null, userId?: string | null };

export type PlantOnPlantKindOfUseFragmentFragment = { __typename?: 'PlantOnPlantKindOfUse', createdAt: any, plantId: string, plantKindId: string, updatedAt: any, plantKind: { __typename?: 'PlantKindOfUse', createdAt: any, id: string, name: string, updatedAt: any } };

export type PlantOnPlantKindOfUseCountAggregateFragmentFragment = { __typename?: 'PlantOnPlantKindOfUseCountAggregate', _all: number, createdAt: number, plantId: number, plantKindId: number, updatedAt: number };

export type PlantOnPlantKindOfUseMaxAggregateFragmentFragment = { __typename?: 'PlantOnPlantKindOfUseMaxAggregate', createdAt?: any | null, plantId?: string | null, plantKindId?: string | null, updatedAt?: any | null };

export type PlantOnPlantKindOfUseMinAggregateFragmentFragment = { __typename?: 'PlantOnPlantKindOfUseMinAggregate', createdAt?: any | null, plantId?: string | null, plantKindId?: string | null, updatedAt?: any | null };

export type PlantSumAggregateFragmentFragment = { __typename?: 'PlantSumAggregate', foundFrequencyInMonthEnd?: number | null, foundFrequencyInMonthStart?: number | null, latitude?: number | null, longitude?: number | null, quantity?: number | null };

export type ProvinceFragmentFragment = { __typename?: 'Province', createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any };

export type ProvinceCountFragmentFragment = { __typename?: 'ProvinceCount', amphoes: number };

export type ProvinceCountAggregateFragmentFragment = { __typename?: 'ProvinceCountAggregate', _all: number, createdAt: number, id: number, nameEn: number, nameTh: number, updatedAt: number };

export type ProvinceMaxAggregateFragmentFragment = { __typename?: 'ProvinceMaxAggregate', createdAt?: any | null, id?: string | null, nameEn?: string | null, nameTh?: string | null, updatedAt?: any | null };

export type ProvinceMinAggregateFragmentFragment = { __typename?: 'ProvinceMinAggregate', createdAt?: any | null, id?: string | null, nameEn?: string | null, nameTh?: string | null, updatedAt?: any | null };

export type PublishableWisdomFragmentFragment = { __typename?: 'PublishableWisdom', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any, wisdomId?: string | null };

export type PublishableWisdomCountAggregateFragmentFragment = { __typename?: 'PublishableWisdomCountAggregate', _all: number, createdAt: number, id: number, name: number, tambonId: number, updatedAt: number, wisdomId: number };

export type PublishableWisdomMaxAggregateFragmentFragment = { __typename?: 'PublishableWisdomMaxAggregate', createdAt?: any | null, id?: string | null, name?: string | null, tambonId?: string | null, updatedAt?: any | null, wisdomId?: string | null };

export type PublishableWisdomMinAggregateFragmentFragment = { __typename?: 'PublishableWisdomMinAggregate', createdAt?: any | null, id?: string | null, name?: string | null, tambonId?: string | null, updatedAt?: any | null, wisdomId?: string | null };

export type TambonFragmentFragment = { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string };

export type TambonCountFragmentFragment = { __typename?: 'TambonCount', Address: number, Food: number, Plant: number, Travel: number, Wisdom: number, WisdomCategory: number };

export type TambonCountAggregateFragmentFragment = { __typename?: 'TambonCountAggregate', _all: number, amphoeId: number, createdAt: number, id: number, nameEn: number, nameTh: number, updatedAt: number, zipCode: number };

export type TambonMaxAggregateFragmentFragment = { __typename?: 'TambonMaxAggregate', amphoeId?: string | null, createdAt?: any | null, id?: string | null, nameEn?: string | null, nameTh?: string | null, updatedAt?: any | null, zipCode?: string | null };

export type TambonMinAggregateFragmentFragment = { __typename?: 'TambonMinAggregate', amphoeId?: string | null, createdAt?: any | null, id?: string | null, nameEn?: string | null, nameTh?: string | null, updatedAt?: any | null, zipCode?: string | null };

export type TambonWithAmphoeAndProvinceArgsFragmentFragment = { __typename?: 'TambonWithAmphoeAndProvinceArgs', id: string, nameTh: string, amphoe: { __typename?: 'Amphoe', createdAt: any, id: string, nameEn: string, nameTh: string, provinceId?: string | null, updatedAt: any }, province: { __typename?: 'Province', createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any } };

export type TravelFragmentFragment = { __typename?: 'Travel', averageTouristPerYear: number, createdAt: any, id: string, incomePerYear: number, informant: string, isSeasonal: boolean, latitude: number, longitude: number, name: string, numberOfStaff: number, seasonStartMonth?: number | null, seasonalEndMonth?: number | null, tambonId: string, travelTypeId: string, updatedAt: any, userId: string, contactNumbers?: Array<{ __typename?: 'ContactNumber', createdAt: any, id: string, tel: string, travelId?: string | null, updatedAt: any }> | null, facilities?: Array<{ __typename?: 'TravelsOnFacilities', assignedAt: any, facilityId: string, travelId: string, facility: { __typename?: 'Facility', createdAt: any, id: string, name: string, travelId?: string | null, updatedAt: any } }> | null, images?: Array<{ __typename?: 'TravelImage', createdAt: any, id: string, travelId?: string | null, updatedAt: any, url: string }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, type: { __typename?: 'TravelType', createdAt: any, id: string, name: string, updatedAt: any }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any }, websites?: Array<{ __typename?: 'Website', createdAt: any, id: string, travelId?: string | null, updatedAt: any, url: string }> | null };

export type TravelAvgAggregateFragmentFragment = { __typename?: 'TravelAvgAggregate', averageTouristPerYear?: number | null, incomePerYear?: number | null, latitude?: number | null, longitude?: number | null, numberOfStaff?: number | null, seasonStartMonth?: number | null, seasonalEndMonth?: number | null };

export type TravelCountFragmentFragment = { __typename?: 'TravelCount', contactNumbers: number, facilities: number, images: number, websites: number };

export type TravelCountAggregateFragmentFragment = { __typename?: 'TravelCountAggregate', _all: number, averageTouristPerYear: number, createdAt: number, id: number, incomePerYear: number, informant: number, isSeasonal: number, latitude: number, longitude: number, name: number, numberOfStaff: number, seasonStartMonth: number, seasonalEndMonth: number, tambonId: number, travelTypeId: number, updatedAt: number, userId: number };

export type TravelImageFragmentFragment = { __typename?: 'TravelImage', createdAt: any, id: string, travelId?: string | null, updatedAt: any, url: string };

export type TravelImageCountAggregateFragmentFragment = { __typename?: 'TravelImageCountAggregate', _all: number, createdAt: number, id: number, travelId: number, updatedAt: number, url: number };

export type TravelImageMaxAggregateFragmentFragment = { __typename?: 'TravelImageMaxAggregate', createdAt?: any | null, id?: string | null, travelId?: string | null, updatedAt?: any | null, url?: string | null };

export type TravelImageMinAggregateFragmentFragment = { __typename?: 'TravelImageMinAggregate', createdAt?: any | null, id?: string | null, travelId?: string | null, updatedAt?: any | null, url?: string | null };

export type TravelMaxAggregateFragmentFragment = { __typename?: 'TravelMaxAggregate', averageTouristPerYear?: number | null, createdAt?: any | null, id?: string | null, incomePerYear?: number | null, informant?: string | null, isSeasonal?: boolean | null, latitude?: number | null, longitude?: number | null, name?: string | null, numberOfStaff?: number | null, seasonStartMonth?: number | null, seasonalEndMonth?: number | null, tambonId?: string | null, travelTypeId?: string | null, updatedAt?: any | null, userId?: string | null };

export type TravelMinAggregateFragmentFragment = { __typename?: 'TravelMinAggregate', averageTouristPerYear?: number | null, createdAt?: any | null, id?: string | null, incomePerYear?: number | null, informant?: string | null, isSeasonal?: boolean | null, latitude?: number | null, longitude?: number | null, name?: string | null, numberOfStaff?: number | null, seasonStartMonth?: number | null, seasonalEndMonth?: number | null, tambonId?: string | null, travelTypeId?: string | null, updatedAt?: any | null, userId?: string | null };

export type TravelSumAggregateFragmentFragment = { __typename?: 'TravelSumAggregate', averageTouristPerYear?: number | null, incomePerYear?: number | null, latitude?: number | null, longitude?: number | null, numberOfStaff?: number | null, seasonStartMonth?: number | null, seasonalEndMonth?: number | null };

export type TravelTypeFragmentFragment = { __typename?: 'TravelType', createdAt: any, id: string, name: string, updatedAt: any };

export type TravelTypeCountFragmentFragment = { __typename?: 'TravelTypeCount', travels: number };

export type TravelTypeCountAggregateFragmentFragment = { __typename?: 'TravelTypeCountAggregate', _all: number, createdAt: number, id: number, name: number, updatedAt: number };

export type TravelTypeMaxAggregateFragmentFragment = { __typename?: 'TravelTypeMaxAggregate', createdAt?: any | null, id?: string | null, name?: string | null, updatedAt?: any | null };

export type TravelTypeMinAggregateFragmentFragment = { __typename?: 'TravelTypeMinAggregate', createdAt?: any | null, id?: string | null, name?: string | null, updatedAt?: any | null };

export type TravelsOnFacilitiesFragmentFragment = { __typename?: 'TravelsOnFacilities', assignedAt: any, facilityId: string, travelId: string, facility: { __typename?: 'Facility', createdAt: any, id: string, name: string, travelId?: string | null, updatedAt: any } };

export type TravelsOnFacilitiesCountAggregateFragmentFragment = { __typename?: 'TravelsOnFacilitiesCountAggregate', _all: number, assignedAt: number, facilityId: number, travelId: number };

export type TravelsOnFacilitiesMaxAggregateFragmentFragment = { __typename?: 'TravelsOnFacilitiesMaxAggregate', assignedAt?: any | null, facilityId?: string | null, travelId?: string | null };

export type TravelsOnFacilitiesMinAggregateFragmentFragment = { __typename?: 'TravelsOnFacilitiesMinAggregate', assignedAt?: any | null, facilityId?: string | null, travelId?: string | null };

export type UniqueWisdomFragmentFragment = { __typename?: 'UniqueWisdom', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string };

export type UniqueWisdomCountAggregateFragmentFragment = { __typename?: 'UniqueWisdomCountAggregate', _all: number, createdAt: number, id: number, name: number, updatedAt: number, wisdomId: number };

export type UniqueWisdomMaxAggregateFragmentFragment = { __typename?: 'UniqueWisdomMaxAggregate', createdAt?: any | null, id?: string | null, name?: string | null, updatedAt?: any | null, wisdomId?: string | null };

export type UniqueWisdomMinAggregateFragmentFragment = { __typename?: 'UniqueWisdomMinAggregate', createdAt?: any | null, id?: string | null, name?: string | null, updatedAt?: any | null, wisdomId?: string | null };

export type UserFragmentFragment = { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any };

export type UserCountFragmentFragment = { __typename?: 'UserCount', foods: number, plants: number, travels: number, wisdoms: number };

export type UserCountAggregateFragmentFragment = { __typename?: 'UserCountAggregate', _all: number, createdAt: number, email: number, emailVerified: number, emailVerifyToken: number, id: number, image: number, name: number, password: number, role: number, updatedAt: number };

export type UserMaxAggregateFragmentFragment = { __typename?: 'UserMaxAggregate', createdAt?: any | null, email?: string | null, emailVerified?: any | null, emailVerifyToken?: string | null, id?: string | null, image?: string | null, name?: string | null, password?: string | null, role?: Role | null, updatedAt?: any | null };

export type UserMinAggregateFragmentFragment = { __typename?: 'UserMinAggregate', createdAt?: any | null, email?: string | null, emailVerified?: any | null, emailVerifyToken?: string | null, id?: string | null, image?: string | null, name?: string | null, password?: string | null, role?: Role | null, updatedAt?: any | null };

export type VerificationTokenCountAggregateFragmentFragment = { __typename?: 'VerificationTokenCountAggregate', _all: number, expires: number, identifier: number, token: number };

export type VerificationTokenMaxAggregateFragmentFragment = { __typename?: 'VerificationTokenMaxAggregate', expires?: any | null, identifier?: string | null, token?: string | null };

export type VerificationTokenMinAggregateFragmentFragment = { __typename?: 'VerificationTokenMinAggregate', expires?: any | null, identifier?: string | null, token?: string | null };

export type WebsiteFragmentFragment = { __typename?: 'Website', createdAt: any, id: string, travelId?: string | null, updatedAt: any, url: string };

export type WebsiteCountAggregateFragmentFragment = { __typename?: 'WebsiteCountAggregate', _all: number, createdAt: number, id: number, travelId: number, updatedAt: number, url: number };

export type WebsiteMaxAggregateFragmentFragment = { __typename?: 'WebsiteMaxAggregate', createdAt?: any | null, id?: string | null, travelId?: string | null, updatedAt?: any | null, url?: string | null };

export type WebsiteMinAggregateFragmentFragment = { __typename?: 'WebsiteMinAggregate', createdAt?: any | null, id?: string | null, travelId?: string | null, updatedAt?: any | null, url?: string | null };

export type WisdomFragmentFragment = { __typename?: 'Wisdom', createdAt: any, id: string, informant?: string | null, latitude: number, longitude: number, name: string, patentedStatus: PatentedStatus, pr: WisdomPr, status: WisdomStatus, tambonId: string, updatedAt: any, userId: string, wisdomCategoryId: string, category: { __typename?: 'WisdomCategory', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any }, images?: Array<{ __typename?: 'WisdomImage', createdAt: any, id: string, updatedAt: any, url: string, wisdomId: string }> | null, publishable?: Array<{ __typename?: 'PublishableWisdom', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any, wisdomId?: string | null }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, tools?: Array<{ __typename?: 'WisdomTool', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, uniqueWisdom?: Array<{ __typename?: 'UniqueWisdom', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } };

export type WisdomAvgAggregateFragmentFragment = { __typename?: 'WisdomAvgAggregate', latitude?: number | null, longitude?: number | null };

export type WisdomCategoryFragmentFragment = { __typename?: 'WisdomCategory', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any };

export type WisdomCategoryCountFragmentFragment = { __typename?: 'WisdomCategoryCount', wisdoms: number };

export type WisdomCategoryCountAggregateFragmentFragment = { __typename?: 'WisdomCategoryCountAggregate', _all: number, createdAt: number, id: number, name: number, tambonId: number, updatedAt: number };

export type WisdomCategoryMaxAggregateFragmentFragment = { __typename?: 'WisdomCategoryMaxAggregate', createdAt?: any | null, id?: string | null, name?: string | null, tambonId?: string | null, updatedAt?: any | null };

export type WisdomCategoryMinAggregateFragmentFragment = { __typename?: 'WisdomCategoryMinAggregate', createdAt?: any | null, id?: string | null, name?: string | null, tambonId?: string | null, updatedAt?: any | null };

export type WisdomCountFragmentFragment = { __typename?: 'WisdomCount', images: number, publishable: number, tools: number, uniqueWisdom: number };

export type WisdomCountAggregateFragmentFragment = { __typename?: 'WisdomCountAggregate', _all: number, createdAt: number, id: number, informant: number, latitude: number, longitude: number, name: number, patentedStatus: number, pr: number, status: number, tambonId: number, updatedAt: number, userId: number, wisdomCategoryId: number };

export type WisdomImageFragmentFragment = { __typename?: 'WisdomImage', createdAt: any, id: string, updatedAt: any, url: string, wisdomId: string };

export type WisdomImageCountAggregateFragmentFragment = { __typename?: 'WisdomImageCountAggregate', _all: number, createdAt: number, id: number, updatedAt: number, url: number, wisdomId: number };

export type WisdomImageMaxAggregateFragmentFragment = { __typename?: 'WisdomImageMaxAggregate', createdAt?: any | null, id?: string | null, updatedAt?: any | null, url?: string | null, wisdomId?: string | null };

export type WisdomImageMinAggregateFragmentFragment = { __typename?: 'WisdomImageMinAggregate', createdAt?: any | null, id?: string | null, updatedAt?: any | null, url?: string | null, wisdomId?: string | null };

export type WisdomMaxAggregateFragmentFragment = { __typename?: 'WisdomMaxAggregate', createdAt?: any | null, id?: string | null, informant?: string | null, latitude?: number | null, longitude?: number | null, name?: string | null, patentedStatus?: PatentedStatus | null, pr?: WisdomPr | null, status?: WisdomStatus | null, tambonId?: string | null, updatedAt?: any | null, userId?: string | null, wisdomCategoryId?: string | null };

export type WisdomMinAggregateFragmentFragment = { __typename?: 'WisdomMinAggregate', createdAt?: any | null, id?: string | null, informant?: string | null, latitude?: number | null, longitude?: number | null, name?: string | null, patentedStatus?: PatentedStatus | null, pr?: WisdomPr | null, status?: WisdomStatus | null, tambonId?: string | null, updatedAt?: any | null, userId?: string | null, wisdomCategoryId?: string | null };

export type WisdomSumAggregateFragmentFragment = { __typename?: 'WisdomSumAggregate', latitude?: number | null, longitude?: number | null };

export type WisdomToolFragmentFragment = { __typename?: 'WisdomTool', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string };

export type WisdomToolCountAggregateFragmentFragment = { __typename?: 'WisdomToolCountAggregate', _all: number, createdAt: number, id: number, name: number, updatedAt: number, wisdomId: number };

export type WisdomToolMaxAggregateFragmentFragment = { __typename?: 'WisdomToolMaxAggregate', createdAt?: any | null, id?: string | null, name?: string | null, updatedAt?: any | null, wisdomId?: string | null };

export type WisdomToolMinAggregateFragmentFragment = { __typename?: 'WisdomToolMinAggregate', createdAt?: any | null, id?: string | null, name?: string | null, updatedAt?: any | null, wisdomId?: string | null };

export type AmphoeQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type AmphoeQuery = { __typename?: 'Query', amphoe: { __typename?: 'Amphoe', createdAt: any, id: string, nameEn: string, nameTh: string, provinceId?: string | null, updatedAt: any } };

export type AmphoesQueryVariables = Exact<{ [key: string]: never; }>;


export type AmphoesQuery = { __typename?: 'Query', amphoes: Array<{ __typename?: 'Amphoe', createdAt: any, id: string, nameEn: string, nameTh: string, provinceId?: string | null, updatedAt: any }> };

export type AmphoesByKeywordQueryVariables = Exact<{
  keyword: Scalars['String']['input'];
}>;


export type AmphoesByKeywordQuery = { __typename?: 'Query', amphoesByKeyword: Array<{ __typename?: 'Amphoe', createdAt: any, id: string, nameEn: string, nameTh: string, provinceId?: string | null, updatedAt: any }> };

export type AmphoesByProvinceIdQueryVariables = Exact<{
  provinceId: Scalars['String']['input'];
}>;


export type AmphoesByProvinceIdQuery = { __typename?: 'Query', amphoesByProvinceId: Array<{ __typename?: 'Amphoe', createdAt: any, id: string, nameEn: string, nameTh: string, provinceId?: string | null, updatedAt: any }> };

export type AmphoesByProvinceIdAndKeywordQueryVariables = Exact<{
  input: InputProvinceIdAndKeyword;
}>;


export type AmphoesByProvinceIdAndKeywordQuery = { __typename?: 'Query', amphoesByProvinceIdAndKeyword: Array<{ __typename?: 'Amphoe', createdAt: any, id: string, nameEn: string, nameTh: string, provinceId?: string | null, updatedAt: any }> };

export type CountAllUserInFoodQueryVariables = Exact<{ [key: string]: never; }>;


export type CountAllUserInFoodQuery = { __typename?: 'Query', countAllUserInFood: Array<{ __typename?: 'Food', createdAt: any, foodCategoryId?: string | null, hasParking?: HasParking | null, id: string, latitude: number, longitude: number, name: string, productCapacity?: number | null, shop: string, story?: string | null, tambonId: string, updatedAt: any, userId: string, foodCategory?: { __typename?: 'FoodCategory', createdAt: any, id: string, name: string, updatedAt: any } | null, images?: Array<{ __typename?: 'FoodImage', createdAt: any, foodId: string, id: string, updatedAt: any, url: string }> | null, ingredients?: Array<{ __typename?: 'Ingredients', createdAt: any, foodId?: string | null, id: string, name: string, updatedAt: any }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } }> };

export type CountAllUserInPlantQueryVariables = Exact<{ [key: string]: never; }>;


export type CountAllUserInPlantQuery = { __typename?: 'Query', countAllUserInPlant: Array<{ __typename?: 'Plant', createdAt: any, foundFrequencyInMonthEnd?: number | null, foundFrequencyInMonthStart?: number | null, hasOwner: HasOwner, id: string, informant?: string | null, latitude: number, longitude: number, name: string, ownerName?: string | null, quantity?: number | null, tambonId: string, updatedAt: any, userId: string, images?: Array<{ __typename?: 'PlantImage', createdAt: any, id: string, kind: PlantImageKind, plantId: string, updatedAt: any, url: string }> | null, kindOfUses?: Array<{ __typename?: 'PlantOnPlantKindOfUse', createdAt: any, plantId: string, plantKindId: string, updatedAt: any, plantKind: { __typename?: 'PlantKindOfUse', createdAt: any, id: string, name: string, updatedAt: any } }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } }> };

export type CountAllUserInTravelQueryVariables = Exact<{ [key: string]: never; }>;


export type CountAllUserInTravelQuery = { __typename?: 'Query', countAllUserInTravel: number };

export type CountAllUserInWisdomQueryVariables = Exact<{ [key: string]: never; }>;


export type CountAllUserInWisdomQuery = { __typename?: 'Query', countAllUserInWisdom: Array<{ __typename?: 'Wisdom', createdAt: any, id: string, informant?: string | null, latitude: number, longitude: number, name: string, patentedStatus: PatentedStatus, pr: WisdomPr, status: WisdomStatus, tambonId: string, updatedAt: any, userId: string, wisdomCategoryId: string, category: { __typename?: 'WisdomCategory', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any }, images?: Array<{ __typename?: 'WisdomImage', createdAt: any, id: string, updatedAt: any, url: string, wisdomId: string }> | null, publishable?: Array<{ __typename?: 'PublishableWisdom', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any, wisdomId?: string | null }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, tools?: Array<{ __typename?: 'WisdomTool', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, uniqueWisdom?: Array<{ __typename?: 'UniqueWisdom', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } }> };

export type FacilitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type FacilitiesQuery = { __typename?: 'Query', facilities: Array<{ __typename?: 'Facility', createdAt: any, id: string, name: string, travelId?: string | null, updatedAt: any }> };

export type FacilitiesByKeywordQueryVariables = Exact<{
  keyword: Scalars['String']['input'];
}>;


export type FacilitiesByKeywordQuery = { __typename?: 'Query', facilitiesByKeyword: Array<{ __typename?: 'Facility', createdAt: any, id: string, name: string, travelId?: string | null, updatedAt: any }> };

export type FacilityQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type FacilityQuery = { __typename?: 'Query', facility: { __typename?: 'Facility', createdAt: any, id: string, name: string, travelId?: string | null, updatedAt: any } };

export type FoodQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type FoodQuery = { __typename?: 'Query', food: { __typename?: 'Food', createdAt: any, foodCategoryId?: string | null, hasParking?: HasParking | null, id: string, latitude: number, longitude: number, name: string, productCapacity?: number | null, shop: string, story?: string | null, tambonId: string, updatedAt: any, userId: string, foodCategory?: { __typename?: 'FoodCategory', createdAt: any, id: string, name: string, updatedAt: any } | null, images?: Array<{ __typename?: 'FoodImage', createdAt: any, foodId: string, id: string, updatedAt: any, url: string }> | null, ingredients?: Array<{ __typename?: 'Ingredients', createdAt: any, foodId?: string | null, id: string, name: string, updatedAt: any }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } } };

export type FoodCategoriesQueryVariables = Exact<{
  filter: Filter;
}>;


export type FoodCategoriesQuery = { __typename?: 'Query', foodCategories: Array<{ __typename?: 'FoodCategory', createdAt: any, id: string, name: string, updatedAt: any }> };

export type FoodCategoryQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type FoodCategoryQuery = { __typename?: 'Query', foodCategory: { __typename?: 'FoodCategory', createdAt: any, id: string, name: string, updatedAt: any } };

export type FoodCategoryWithFoodsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type FoodCategoryWithFoodsQuery = { __typename?: 'Query', foodCategoryWithFoods: { __typename?: 'FoodCategory', createdAt: any, id: string, name: string, updatedAt: any } };

export type FoodImageQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type FoodImageQuery = { __typename?: 'Query', foodImage: { __typename?: 'FoodImage', createdAt: any, foodId: string, id: string, updatedAt: any, url: string } };

export type FoodImagesQueryVariables = Exact<{ [key: string]: never; }>;


export type FoodImagesQuery = { __typename?: 'Query', foodImages: Array<{ __typename?: 'FoodImage', createdAt: any, foodId: string, id: string, updatedAt: any, url: string }> };

export type FoodWithFoodCategoryQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type FoodWithFoodCategoryQuery = { __typename?: 'Query', foodWithFoodCategory: { __typename?: 'Food', createdAt: any, foodCategoryId?: string | null, hasParking?: HasParking | null, id: string, latitude: number, longitude: number, name: string, productCapacity?: number | null, shop: string, story?: string | null, tambonId: string, updatedAt: any, userId: string, foodCategory?: { __typename?: 'FoodCategory', createdAt: any, id: string, name: string, updatedAt: any } | null, images?: Array<{ __typename?: 'FoodImage', createdAt: any, foodId: string, id: string, updatedAt: any, url: string }> | null, ingredients?: Array<{ __typename?: 'Ingredients', createdAt: any, foodId?: string | null, id: string, name: string, updatedAt: any }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } } };

export type FoodsQueryVariables = Exact<{
  filter: Filter;
}>;


export type FoodsQuery = { __typename?: 'Query', foods: Array<{ __typename?: 'Food', createdAt: any, foodCategoryId?: string | null, hasParking?: HasParking | null, id: string, latitude: number, longitude: number, name: string, productCapacity?: number | null, shop: string, story?: string | null, tambonId: string, updatedAt: any, userId: string, foodCategory?: { __typename?: 'FoodCategory', createdAt: any, id: string, name: string, updatedAt: any } | null, images?: Array<{ __typename?: 'FoodImage', createdAt: any, foodId: string, id: string, updatedAt: any, url: string }> | null, ingredients?: Array<{ __typename?: 'Ingredients', createdAt: any, foodId?: string | null, id: string, name: string, updatedAt: any }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } }> };

export type FoodsByFoodCategoryQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type FoodsByFoodCategoryQuery = { __typename?: 'Query', foodsByFoodCategory: Array<{ __typename?: 'Food', createdAt: any, foodCategoryId?: string | null, hasParking?: HasParking | null, id: string, latitude: number, longitude: number, name: string, productCapacity?: number | null, shop: string, story?: string | null, tambonId: string, updatedAt: any, userId: string, foodCategory?: { __typename?: 'FoodCategory', createdAt: any, id: string, name: string, updatedAt: any } | null, images?: Array<{ __typename?: 'FoodImage', createdAt: any, foodId: string, id: string, updatedAt: any, url: string }> | null, ingredients?: Array<{ __typename?: 'Ingredients', createdAt: any, foodId?: string | null, id: string, name: string, updatedAt: any }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } }> };

export type FoodsByUserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type FoodsByUserQuery = { __typename?: 'Query', foodsByUser: Array<{ __typename?: 'Food', createdAt: any, foodCategoryId?: string | null, hasParking?: HasParking | null, id: string, latitude: number, longitude: number, name: string, productCapacity?: number | null, shop: string, story?: string | null, tambonId: string, updatedAt: any, userId: string, foodCategory?: { __typename?: 'FoodCategory', createdAt: any, id: string, name: string, updatedAt: any } | null, images?: Array<{ __typename?: 'FoodImage', createdAt: any, foodId: string, id: string, updatedAt: any, url: string }> | null, ingredients?: Array<{ __typename?: 'Ingredients', createdAt: any, foodId?: string | null, id: string, name: string, updatedAt: any }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } }> };

export type FoodsCountQueryVariables = Exact<{
  filter: Filter;
}>;


export type FoodsCountQuery = { __typename?: 'Query', foodsCount: number };

export type HelloQueryVariables = Exact<{ [key: string]: never; }>;


export type HelloQuery = { __typename?: 'Query', hello: string };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } };

export type PlantQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type PlantQuery = { __typename?: 'Query', plant: { __typename?: 'Plant', createdAt: any, foundFrequencyInMonthEnd?: number | null, foundFrequencyInMonthStart?: number | null, hasOwner: HasOwner, id: string, informant?: string | null, latitude: number, longitude: number, name: string, ownerName?: string | null, quantity?: number | null, tambonId: string, updatedAt: any, userId: string, images?: Array<{ __typename?: 'PlantImage', createdAt: any, id: string, kind: PlantImageKind, plantId: string, updatedAt: any, url: string }> | null, kindOfUses?: Array<{ __typename?: 'PlantOnPlantKindOfUse', createdAt: any, plantId: string, plantKindId: string, updatedAt: any, plantKind: { __typename?: 'PlantKindOfUse', createdAt: any, id: string, name: string, updatedAt: any } }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } } };

export type PlantsQueryVariables = Exact<{
  filter: Filter;
}>;


export type PlantsQuery = { __typename?: 'Query', plants: Array<{ __typename?: 'Plant', createdAt: any, foundFrequencyInMonthEnd?: number | null, foundFrequencyInMonthStart?: number | null, hasOwner: HasOwner, id: string, informant?: string | null, latitude: number, longitude: number, name: string, ownerName?: string | null, quantity?: number | null, tambonId: string, updatedAt: any, userId: string, images?: Array<{ __typename?: 'PlantImage', createdAt: any, id: string, kind: PlantImageKind, plantId: string, updatedAt: any, url: string }> | null, kindOfUses?: Array<{ __typename?: 'PlantOnPlantKindOfUse', createdAt: any, plantId: string, plantKindId: string, updatedAt: any, plantKind: { __typename?: 'PlantKindOfUse', createdAt: any, id: string, name: string, updatedAt: any } }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } }> };

export type PlantsByUserIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type PlantsByUserIdQuery = { __typename?: 'Query', plantsByUserId: Array<{ __typename?: 'Plant', createdAt: any, foundFrequencyInMonthEnd?: number | null, foundFrequencyInMonthStart?: number | null, hasOwner: HasOwner, id: string, informant?: string | null, latitude: number, longitude: number, name: string, ownerName?: string | null, quantity?: number | null, tambonId: string, updatedAt: any, userId: string, images?: Array<{ __typename?: 'PlantImage', createdAt: any, id: string, kind: PlantImageKind, plantId: string, updatedAt: any, url: string }> | null, kindOfUses?: Array<{ __typename?: 'PlantOnPlantKindOfUse', createdAt: any, plantId: string, plantKindId: string, updatedAt: any, plantKind: { __typename?: 'PlantKindOfUse', createdAt: any, id: string, name: string, updatedAt: any } }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } }> };

export type PlantsCountQueryVariables = Exact<{
  filter: Filter;
}>;


export type PlantsCountQuery = { __typename?: 'Query', plantsCount: number };

export type ProvinceQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ProvinceQuery = { __typename?: 'Query', province: { __typename?: 'Province', createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any } };

export type ProvincesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProvincesQuery = { __typename?: 'Query', provinces: Array<{ __typename?: 'Province', createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any }> };

export type ProvincesByKeywordQueryVariables = Exact<{
  keyword: Scalars['String']['input'];
}>;


export type ProvincesByKeywordQuery = { __typename?: 'Query', provincesByKeyword: Array<{ __typename?: 'Province', createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any }> };

export type TambolsByAmphoeIdAndKeywordQueryVariables = Exact<{
  input: InputAmphoeIdAndKeyword;
}>;


export type TambolsByAmphoeIdAndKeywordQuery = { __typename?: 'Query', tambolsByAmphoeIdAndKeyword: Array<{ __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }> };

export type TambolsByKeywordQueryVariables = Exact<{
  keyword: Scalars['String']['input'];
}>;


export type TambolsByKeywordQuery = { __typename?: 'Query', tambolsByKeyword: Array<{ __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }> };

export type TambonQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type TambonQuery = { __typename?: 'Query', tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string } };

export type TambonWithAmphoeAndProvinceQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type TambonWithAmphoeAndProvinceQuery = { __typename?: 'Query', tambonWithAmphoeAndProvince: { __typename?: 'TambonWithAmphoeAndProvinceArgs', id: string, nameTh: string, amphoe: { __typename?: 'Amphoe', createdAt: any, id: string, nameEn: string, nameTh: string, provinceId?: string | null, updatedAt: any }, province: { __typename?: 'Province', createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any } } };

export type TambonsQueryVariables = Exact<{ [key: string]: never; }>;


export type TambonsQuery = { __typename?: 'Query', tambons: Array<{ __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }> };

export type TambonsByAmphoeIdQueryVariables = Exact<{
  amphoeId: Scalars['String']['input'];
}>;


export type TambonsByAmphoeIdQuery = { __typename?: 'Query', tambonsByAmphoeId: Array<{ __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }> };

export type TravelQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type TravelQuery = { __typename?: 'Query', travel: { __typename?: 'Travel', averageTouristPerYear: number, createdAt: any, id: string, incomePerYear: number, informant: string, isSeasonal: boolean, latitude: number, longitude: number, name: string, numberOfStaff: number, seasonStartMonth?: number | null, seasonalEndMonth?: number | null, tambonId: string, travelTypeId: string, updatedAt: any, userId: string, contactNumbers?: Array<{ __typename?: 'ContactNumber', createdAt: any, id: string, tel: string, travelId?: string | null, updatedAt: any }> | null, facilities?: Array<{ __typename?: 'TravelsOnFacilities', assignedAt: any, facilityId: string, travelId: string, facility: { __typename?: 'Facility', createdAt: any, id: string, name: string, travelId?: string | null, updatedAt: any } }> | null, images?: Array<{ __typename?: 'TravelImage', createdAt: any, id: string, travelId?: string | null, updatedAt: any, url: string }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, type: { __typename?: 'TravelType', createdAt: any, id: string, name: string, updatedAt: any }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any }, websites?: Array<{ __typename?: 'Website', createdAt: any, id: string, travelId?: string | null, updatedAt: any, url: string }> | null } };

export type TravelTypeQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type TravelTypeQuery = { __typename?: 'Query', travelType: { __typename?: 'TravelType', createdAt: any, id: string, name: string, updatedAt: any } };

export type TravelTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type TravelTypesQuery = { __typename?: 'Query', travelTypes: Array<{ __typename?: 'TravelType', createdAt: any, id: string, name: string, updatedAt: any }> };

export type TravelsQueryVariables = Exact<{
  filter: Filter;
}>;


export type TravelsQuery = { __typename?: 'Query', travels: Array<{ __typename?: 'Travel', averageTouristPerYear: number, createdAt: any, id: string, incomePerYear: number, informant: string, isSeasonal: boolean, latitude: number, longitude: number, name: string, numberOfStaff: number, seasonStartMonth?: number | null, seasonalEndMonth?: number | null, tambonId: string, travelTypeId: string, updatedAt: any, userId: string, contactNumbers?: Array<{ __typename?: 'ContactNumber', createdAt: any, id: string, tel: string, travelId?: string | null, updatedAt: any }> | null, facilities?: Array<{ __typename?: 'TravelsOnFacilities', assignedAt: any, facilityId: string, travelId: string, facility: { __typename?: 'Facility', createdAt: any, id: string, name: string, travelId?: string | null, updatedAt: any } }> | null, images?: Array<{ __typename?: 'TravelImage', createdAt: any, id: string, travelId?: string | null, updatedAt: any, url: string }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, type: { __typename?: 'TravelType', createdAt: any, id: string, name: string, updatedAt: any }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any }, websites?: Array<{ __typename?: 'Website', createdAt: any, id: string, travelId?: string | null, updatedAt: any, url: string }> | null }> };

export type TravelsByUserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type TravelsByUserQuery = { __typename?: 'Query', travelsByUser: { __typename?: 'Travel', averageTouristPerYear: number, createdAt: any, id: string, incomePerYear: number, informant: string, isSeasonal: boolean, latitude: number, longitude: number, name: string, numberOfStaff: number, seasonStartMonth?: number | null, seasonalEndMonth?: number | null, tambonId: string, travelTypeId: string, updatedAt: any, userId: string, contactNumbers?: Array<{ __typename?: 'ContactNumber', createdAt: any, id: string, tel: string, travelId?: string | null, updatedAt: any }> | null, facilities?: Array<{ __typename?: 'TravelsOnFacilities', assignedAt: any, facilityId: string, travelId: string, facility: { __typename?: 'Facility', createdAt: any, id: string, name: string, travelId?: string | null, updatedAt: any } }> | null, images?: Array<{ __typename?: 'TravelImage', createdAt: any, id: string, travelId?: string | null, updatedAt: any, url: string }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, type: { __typename?: 'TravelType', createdAt: any, id: string, name: string, updatedAt: any }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any }, websites?: Array<{ __typename?: 'Website', createdAt: any, id: string, travelId?: string | null, updatedAt: any, url: string }> | null } };

export type TravelsCountQueryVariables = Exact<{
  filter: Filter;
}>;


export type TravelsCountQuery = { __typename?: 'Query', travelsCount: number };

export type UserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } };

export type UserByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type UserByEmailQuery = { __typename?: 'Query', userByEmail: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } };

export type UsersQueryVariables = Exact<{
  filter: UserFilter;
}>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any }> };

export type UsersCountQueryVariables = Exact<{
  filter: UserFilter;
}>;


export type UsersCountQuery = { __typename?: 'Query', usersCount: number };

export type WisdomQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type WisdomQuery = { __typename?: 'Query', wisdom: { __typename?: 'Wisdom', createdAt: any, id: string, informant?: string | null, latitude: number, longitude: number, name: string, patentedStatus: PatentedStatus, pr: WisdomPr, status: WisdomStatus, tambonId: string, updatedAt: any, userId: string, wisdomCategoryId: string, category: { __typename?: 'WisdomCategory', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any }, images?: Array<{ __typename?: 'WisdomImage', createdAt: any, id: string, updatedAt: any, url: string, wisdomId: string }> | null, publishable?: Array<{ __typename?: 'PublishableWisdom', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any, wisdomId?: string | null }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, tools?: Array<{ __typename?: 'WisdomTool', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, uniqueWisdom?: Array<{ __typename?: 'UniqueWisdom', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } } };

export type WisdomCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type WisdomCategoriesQuery = { __typename?: 'Query', wisdomCategories: Array<{ __typename?: 'WisdomCategory', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any }> };

export type WisdomCategoryQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type WisdomCategoryQuery = { __typename?: 'Query', wisdomCategory: { __typename?: 'WisdomCategory', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any } };

export type WisdomCountQueryVariables = Exact<{
  filter: Filter;
}>;


export type WisdomCountQuery = { __typename?: 'Query', wisdomCount: number };

export type WisdomsQueryVariables = Exact<{
  filter: Filter;
}>;


export type WisdomsQuery = { __typename?: 'Query', wisdoms: Array<{ __typename?: 'Wisdom', createdAt: any, id: string, informant?: string | null, latitude: number, longitude: number, name: string, patentedStatus: PatentedStatus, pr: WisdomPr, status: WisdomStatus, tambonId: string, updatedAt: any, userId: string, wisdomCategoryId: string, category: { __typename?: 'WisdomCategory', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any }, images?: Array<{ __typename?: 'WisdomImage', createdAt: any, id: string, updatedAt: any, url: string, wisdomId: string }> | null, publishable?: Array<{ __typename?: 'PublishableWisdom', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any, wisdomId?: string | null }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, tools?: Array<{ __typename?: 'WisdomTool', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, uniqueWisdom?: Array<{ __typename?: 'UniqueWisdom', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } }> };

export type WisdomsByCategoryQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type WisdomsByCategoryQuery = { __typename?: 'Query', wisdomsByCategory: Array<{ __typename?: 'Wisdom', createdAt: any, id: string, informant?: string | null, latitude: number, longitude: number, name: string, patentedStatus: PatentedStatus, pr: WisdomPr, status: WisdomStatus, tambonId: string, updatedAt: any, userId: string, wisdomCategoryId: string, category: { __typename?: 'WisdomCategory', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any }, images?: Array<{ __typename?: 'WisdomImage', createdAt: any, id: string, updatedAt: any, url: string, wisdomId: string }> | null, publishable?: Array<{ __typename?: 'PublishableWisdom', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any, wisdomId?: string | null }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, tools?: Array<{ __typename?: 'WisdomTool', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, uniqueWisdom?: Array<{ __typename?: 'UniqueWisdom', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } }> };

export type WisdomsByUserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type WisdomsByUserQuery = { __typename?: 'Query', wisdomsByUser: Array<{ __typename?: 'Wisdom', createdAt: any, id: string, informant?: string | null, latitude: number, longitude: number, name: string, patentedStatus: PatentedStatus, pr: WisdomPr, status: WisdomStatus, tambonId: string, updatedAt: any, userId: string, wisdomCategoryId: string, category: { __typename?: 'WisdomCategory', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any }, images?: Array<{ __typename?: 'WisdomImage', createdAt: any, id: string, updatedAt: any, url: string, wisdomId: string }> | null, publishable?: Array<{ __typename?: 'PublishableWisdom', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any, wisdomId?: string | null }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, tools?: Array<{ __typename?: 'WisdomTool', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, uniqueWisdom?: Array<{ __typename?: 'UniqueWisdom', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } }> };

export type CreateFacilityMutationVariables = Exact<{
  input: FacilityCreateInput;
}>;


export type CreateFacilityMutation = { __typename?: 'Mutation', createFacility: { __typename?: 'Facility', createdAt: any, id: string, name: string, travelId?: string | null, updatedAt: any } };

export type CreateFoodMutationVariables = Exact<{
  input: FoodCreateInput;
}>;


export type CreateFoodMutation = { __typename?: 'Mutation', createFood: { __typename?: 'Food', createdAt: any, foodCategoryId?: string | null, hasParking?: HasParking | null, id: string, latitude: number, longitude: number, name: string, productCapacity?: number | null, shop: string, story?: string | null, tambonId: string, updatedAt: any, userId: string, foodCategory?: { __typename?: 'FoodCategory', createdAt: any, id: string, name: string, updatedAt: any } | null, images?: Array<{ __typename?: 'FoodImage', createdAt: any, foodId: string, id: string, updatedAt: any, url: string }> | null, ingredients?: Array<{ __typename?: 'Ingredients', createdAt: any, foodId?: string | null, id: string, name: string, updatedAt: any }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } } };

export type CreateFoodCategoryMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type CreateFoodCategoryMutation = { __typename?: 'Mutation', createFoodCategory: { __typename?: 'FoodCategory', createdAt: any, id: string, name: string, updatedAt: any } };

export type CreateFoodImageMutationVariables = Exact<{
  input: FoodImageCreateInput;
}>;


export type CreateFoodImageMutation = { __typename?: 'Mutation', createFoodImage: { __typename?: 'FoodImage', createdAt: any, foodId: string, id: string, updatedAt: any, url: string } };

export type CreatePlantMutationVariables = Exact<{
  input: PlantCreateInput;
}>;


export type CreatePlantMutation = { __typename?: 'Mutation', createPlant: { __typename?: 'Plant', createdAt: any, foundFrequencyInMonthEnd?: number | null, foundFrequencyInMonthStart?: number | null, hasOwner: HasOwner, id: string, informant?: string | null, latitude: number, longitude: number, name: string, ownerName?: string | null, quantity?: number | null, tambonId: string, updatedAt: any, userId: string, images?: Array<{ __typename?: 'PlantImage', createdAt: any, id: string, kind: PlantImageKind, plantId: string, updatedAt: any, url: string }> | null, kindOfUses?: Array<{ __typename?: 'PlantOnPlantKindOfUse', createdAt: any, plantId: string, plantKindId: string, updatedAt: any, plantKind: { __typename?: 'PlantKindOfUse', createdAt: any, id: string, name: string, updatedAt: any } }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } } };

export type CreateTravelMutationVariables = Exact<{
  input: TravelCreateInput;
}>;


export type CreateTravelMutation = { __typename?: 'Mutation', createTravel: { __typename?: 'Travel', averageTouristPerYear: number, createdAt: any, id: string, incomePerYear: number, informant: string, isSeasonal: boolean, latitude: number, longitude: number, name: string, numberOfStaff: number, seasonStartMonth?: number | null, seasonalEndMonth?: number | null, tambonId: string, travelTypeId: string, updatedAt: any, userId: string, contactNumbers?: Array<{ __typename?: 'ContactNumber', createdAt: any, id: string, tel: string, travelId?: string | null, updatedAt: any }> | null, facilities?: Array<{ __typename?: 'TravelsOnFacilities', assignedAt: any, facilityId: string, travelId: string, facility: { __typename?: 'Facility', createdAt: any, id: string, name: string, travelId?: string | null, updatedAt: any } }> | null, images?: Array<{ __typename?: 'TravelImage', createdAt: any, id: string, travelId?: string | null, updatedAt: any, url: string }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, type: { __typename?: 'TravelType', createdAt: any, id: string, name: string, updatedAt: any }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any }, websites?: Array<{ __typename?: 'Website', createdAt: any, id: string, travelId?: string | null, updatedAt: any, url: string }> | null } };

export type CreateTravelTypeMutationVariables = Exact<{
  input: TravelTypeCreateInput;
}>;


export type CreateTravelTypeMutation = { __typename?: 'Mutation', createTravelType: { __typename?: 'TravelType', createdAt: any, id: string, name: string, updatedAt: any } };

export type CreateUserMutationVariables = Exact<{
  input: UserCreateInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } };

export type CreateWisdomMutationVariables = Exact<{
  input: WisdomCreateInput;
}>;


export type CreateWisdomMutation = { __typename?: 'Mutation', createWisdom: { __typename?: 'Wisdom', createdAt: any, id: string, informant?: string | null, latitude: number, longitude: number, name: string, patentedStatus: PatentedStatus, pr: WisdomPr, status: WisdomStatus, tambonId: string, updatedAt: any, userId: string, wisdomCategoryId: string, category: { __typename?: 'WisdomCategory', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any }, images?: Array<{ __typename?: 'WisdomImage', createdAt: any, id: string, updatedAt: any, url: string, wisdomId: string }> | null, publishable?: Array<{ __typename?: 'PublishableWisdom', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any, wisdomId?: string | null }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, tools?: Array<{ __typename?: 'WisdomTool', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, uniqueWisdom?: Array<{ __typename?: 'UniqueWisdom', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } } };

export type CreateWisdomCategoryMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type CreateWisdomCategoryMutation = { __typename?: 'Mutation', createWisdomCategory: { __typename?: 'WisdomCategory', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any } };

export type DeleteFacilityMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteFacilityMutation = { __typename?: 'Mutation', deleteFacility: { __typename?: 'Facility', createdAt: any, id: string, name: string, travelId?: string | null, updatedAt: any } };

export type DeleteFoodMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteFoodMutation = { __typename?: 'Mutation', deleteFood: { __typename?: 'Food', createdAt: any, foodCategoryId?: string | null, hasParking?: HasParking | null, id: string, latitude: number, longitude: number, name: string, productCapacity?: number | null, shop: string, story?: string | null, tambonId: string, updatedAt: any, userId: string, foodCategory?: { __typename?: 'FoodCategory', createdAt: any, id: string, name: string, updatedAt: any } | null, images?: Array<{ __typename?: 'FoodImage', createdAt: any, foodId: string, id: string, updatedAt: any, url: string }> | null, ingredients?: Array<{ __typename?: 'Ingredients', createdAt: any, foodId?: string | null, id: string, name: string, updatedAt: any }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } } };

export type DeleteFoodCategoryMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteFoodCategoryMutation = { __typename?: 'Mutation', deleteFoodCategory: { __typename?: 'FoodCategory', createdAt: any, id: string, name: string, updatedAt: any } };

export type DeleteFoodImageByFoodIdMutationVariables = Exact<{
  foodId: Scalars['String']['input'];
}>;


export type DeleteFoodImageByFoodIdMutation = { __typename?: 'Mutation', deleteFoodImageByFoodId: boolean };

export type DeletePlantMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeletePlantMutation = { __typename?: 'Mutation', deletePlant: { __typename?: 'Plant', createdAt: any, foundFrequencyInMonthEnd?: number | null, foundFrequencyInMonthStart?: number | null, hasOwner: HasOwner, id: string, informant?: string | null, latitude: number, longitude: number, name: string, ownerName?: string | null, quantity?: number | null, tambonId: string, updatedAt: any, userId: string, images?: Array<{ __typename?: 'PlantImage', createdAt: any, id: string, kind: PlantImageKind, plantId: string, updatedAt: any, url: string }> | null, kindOfUses?: Array<{ __typename?: 'PlantOnPlantKindOfUse', createdAt: any, plantId: string, plantKindId: string, updatedAt: any, plantKind: { __typename?: 'PlantKindOfUse', createdAt: any, id: string, name: string, updatedAt: any } }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } } };

export type DeleteTravelMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteTravelMutation = { __typename?: 'Mutation', deleteTravel: { __typename?: 'Travel', averageTouristPerYear: number, createdAt: any, id: string, incomePerYear: number, informant: string, isSeasonal: boolean, latitude: number, longitude: number, name: string, numberOfStaff: number, seasonStartMonth?: number | null, seasonalEndMonth?: number | null, tambonId: string, travelTypeId: string, updatedAt: any, userId: string, contactNumbers?: Array<{ __typename?: 'ContactNumber', createdAt: any, id: string, tel: string, travelId?: string | null, updatedAt: any }> | null, facilities?: Array<{ __typename?: 'TravelsOnFacilities', assignedAt: any, facilityId: string, travelId: string, facility: { __typename?: 'Facility', createdAt: any, id: string, name: string, travelId?: string | null, updatedAt: any } }> | null, images?: Array<{ __typename?: 'TravelImage', createdAt: any, id: string, travelId?: string | null, updatedAt: any, url: string }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, type: { __typename?: 'TravelType', createdAt: any, id: string, name: string, updatedAt: any }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any }, websites?: Array<{ __typename?: 'Website', createdAt: any, id: string, travelId?: string | null, updatedAt: any, url: string }> | null } };

export type DeleteTravelTypeMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteTravelTypeMutation = { __typename?: 'Mutation', deleteTravelType: { __typename?: 'TravelType', createdAt: any, id: string, name: string, updatedAt: any } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } };

export type DeleteWisdomMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteWisdomMutation = { __typename?: 'Mutation', deleteWisdom: { __typename?: 'Wisdom', createdAt: any, id: string, informant?: string | null, latitude: number, longitude: number, name: string, patentedStatus: PatentedStatus, pr: WisdomPr, status: WisdomStatus, tambonId: string, updatedAt: any, userId: string, wisdomCategoryId: string, category: { __typename?: 'WisdomCategory', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any }, images?: Array<{ __typename?: 'WisdomImage', createdAt: any, id: string, updatedAt: any, url: string, wisdomId: string }> | null, publishable?: Array<{ __typename?: 'PublishableWisdom', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any, wisdomId?: string | null }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, tools?: Array<{ __typename?: 'WisdomTool', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, uniqueWisdom?: Array<{ __typename?: 'UniqueWisdom', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } } };

export type DeleteWisdomCategoryMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteWisdomCategoryMutation = { __typename?: 'Mutation', deleteWisdomCategory: { __typename?: 'WisdomCategory', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any } };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: any };

export type RegisterMutationVariables = Exact<{
  userInput: UserCreateInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } };

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } };

export type UpdateFacilityMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: FacilityUpdateInput;
}>;


export type UpdateFacilityMutation = { __typename?: 'Mutation', updateFacility: { __typename?: 'Facility', createdAt: any, id: string, name: string, travelId?: string | null, updatedAt: any } };

export type UpdateFoodMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: FoodUpdateInput;
}>;


export type UpdateFoodMutation = { __typename?: 'Mutation', updateFood: { __typename?: 'Food', createdAt: any, foodCategoryId?: string | null, hasParking?: HasParking | null, id: string, latitude: number, longitude: number, name: string, productCapacity?: number | null, shop: string, story?: string | null, tambonId: string, updatedAt: any, userId: string, foodCategory?: { __typename?: 'FoodCategory', createdAt: any, id: string, name: string, updatedAt: any } | null, images?: Array<{ __typename?: 'FoodImage', createdAt: any, foodId: string, id: string, updatedAt: any, url: string }> | null, ingredients?: Array<{ __typename?: 'Ingredients', createdAt: any, foodId?: string | null, id: string, name: string, updatedAt: any }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } } };

export type UpdateFoodCategoryMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type UpdateFoodCategoryMutation = { __typename?: 'Mutation', updateFoodCategory: { __typename?: 'FoodCategory', createdAt: any, id: string, name: string, updatedAt: any } };

export type UpdatePlantMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: PlantUpdateInput;
}>;


export type UpdatePlantMutation = { __typename?: 'Mutation', updatePlant: { __typename?: 'Plant', createdAt: any, foundFrequencyInMonthEnd?: number | null, foundFrequencyInMonthStart?: number | null, hasOwner: HasOwner, id: string, informant?: string | null, latitude: number, longitude: number, name: string, ownerName?: string | null, quantity?: number | null, tambonId: string, updatedAt: any, userId: string, images?: Array<{ __typename?: 'PlantImage', createdAt: any, id: string, kind: PlantImageKind, plantId: string, updatedAt: any, url: string }> | null, kindOfUses?: Array<{ __typename?: 'PlantOnPlantKindOfUse', createdAt: any, plantId: string, plantKindId: string, updatedAt: any, plantKind: { __typename?: 'PlantKindOfUse', createdAt: any, id: string, name: string, updatedAt: any } }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } } };

export type UpdateTravelMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: TravelUpdateInput;
}>;


export type UpdateTravelMutation = { __typename?: 'Mutation', updateTravel: { __typename?: 'Travel', averageTouristPerYear: number, createdAt: any, id: string, incomePerYear: number, informant: string, isSeasonal: boolean, latitude: number, longitude: number, name: string, numberOfStaff: number, seasonStartMonth?: number | null, seasonalEndMonth?: number | null, tambonId: string, travelTypeId: string, updatedAt: any, userId: string, contactNumbers?: Array<{ __typename?: 'ContactNumber', createdAt: any, id: string, tel: string, travelId?: string | null, updatedAt: any }> | null, facilities?: Array<{ __typename?: 'TravelsOnFacilities', assignedAt: any, facilityId: string, travelId: string, facility: { __typename?: 'Facility', createdAt: any, id: string, name: string, travelId?: string | null, updatedAt: any } }> | null, images?: Array<{ __typename?: 'TravelImage', createdAt: any, id: string, travelId?: string | null, updatedAt: any, url: string }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, type: { __typename?: 'TravelType', createdAt: any, id: string, name: string, updatedAt: any }, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any }, websites?: Array<{ __typename?: 'Website', createdAt: any, id: string, travelId?: string | null, updatedAt: any, url: string }> | null } };

export type UpdateTravelTypeMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: TravelTypeUpdateInput;
}>;


export type UpdateTravelTypeMutation = { __typename?: 'Mutation', updateTravelType: { __typename?: 'TravelType', createdAt: any, id: string, name: string, updatedAt: any } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } };

export type UpdateWisdomMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: WisdomUpdateInput;
}>;


export type UpdateWisdomMutation = { __typename?: 'Mutation', updateWisdom: { __typename?: 'Wisdom', createdAt: any, id: string, informant?: string | null, latitude: number, longitude: number, name: string, patentedStatus: PatentedStatus, pr: WisdomPr, status: WisdomStatus, tambonId: string, updatedAt: any, userId: string, wisdomCategoryId: string, category: { __typename?: 'WisdomCategory', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any }, images?: Array<{ __typename?: 'WisdomImage', createdAt: any, id: string, updatedAt: any, url: string, wisdomId: string }> | null, publishable?: Array<{ __typename?: 'PublishableWisdom', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any, wisdomId?: string | null }> | null, tambon: { __typename?: 'Tambon', amphoeId?: string | null, createdAt: any, id: string, nameEn: string, nameTh: string, updatedAt: any, zipCode: string }, tools?: Array<{ __typename?: 'WisdomTool', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, uniqueWisdom?: Array<{ __typename?: 'UniqueWisdom', createdAt: any, id: string, name: string, updatedAt: any, wisdomId: string }> | null, user: { __typename?: 'User', createdAt: any, email: string, emailVerified?: any | null, emailVerifyToken?: string | null, id: string, image?: string | null, name: string, password: string, role: Role, updatedAt: any } } };

export type UpdateWisdomCategoryMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type UpdateWisdomCategoryMutation = { __typename?: 'Mutation', updateWisdomCategory: { __typename?: 'WisdomCategory', createdAt: any, id: string, name: string, tambonId?: string | null, updatedAt: any } };



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Address: ResolverTypeWrapper<Address>;
  AddressCountAggregate: ResolverTypeWrapper<AddressCountAggregate>;
  AddressCreateManyTambonInput: AddressCreateManyTambonInput;
  AddressCreateManyTambonInputEnvelope: AddressCreateManyTambonInputEnvelope;
  AddressCreateNestedManyWithoutTambonInput: AddressCreateNestedManyWithoutTambonInput;
  AddressCreateOrConnectWithoutTambonInput: AddressCreateOrConnectWithoutTambonInput;
  AddressCreateWithoutTambonInput: AddressCreateWithoutTambonInput;
  AddressListRelationFilter: AddressListRelationFilter;
  AddressMaxAggregate: ResolverTypeWrapper<AddressMaxAggregate>;
  AddressMinAggregate: ResolverTypeWrapper<AddressMinAggregate>;
  AddressScalarWhereInput: AddressScalarWhereInput;
  AddressUpdateManyMutationInput: AddressUpdateManyMutationInput;
  AddressUpdateManyWithWhereWithoutTambonInput: AddressUpdateManyWithWhereWithoutTambonInput;
  AddressUpdateManyWithoutTambonNestedInput: AddressUpdateManyWithoutTambonNestedInput;
  AddressUpdateWithWhereUniqueWithoutTambonInput: AddressUpdateWithWhereUniqueWithoutTambonInput;
  AddressUpdateWithoutTambonInput: AddressUpdateWithoutTambonInput;
  AddressUpsertWithWhereUniqueWithoutTambonInput: AddressUpsertWithWhereUniqueWithoutTambonInput;
  AddressWhereInput: AddressWhereInput;
  AddressWhereUniqueInput: AddressWhereUniqueInput;
  Amphoe: ResolverTypeWrapper<Amphoe>;
  AmphoeCount: ResolverTypeWrapper<AmphoeCount>;
  AmphoeCountAggregate: ResolverTypeWrapper<AmphoeCountAggregate>;
  AmphoeCreateNestedOneWithoutTambonsInput: AmphoeCreateNestedOneWithoutTambonsInput;
  AmphoeCreateOrConnectWithoutTambonsInput: AmphoeCreateOrConnectWithoutTambonsInput;
  AmphoeCreateWithoutTambonsInput: AmphoeCreateWithoutTambonsInput;
  AmphoeListRelationFilter: AmphoeListRelationFilter;
  AmphoeMaxAggregate: ResolverTypeWrapper<AmphoeMaxAggregate>;
  AmphoeMinAggregate: ResolverTypeWrapper<AmphoeMinAggregate>;
  AmphoeNullableRelationFilter: AmphoeNullableRelationFilter;
  AmphoeUpdateOneWithoutTambonsNestedInput: AmphoeUpdateOneWithoutTambonsNestedInput;
  AmphoeUpdateToOneWithWhereWithoutTambonsInput: AmphoeUpdateToOneWithWhereWithoutTambonsInput;
  AmphoeUpdateWithoutTambonsInput: AmphoeUpdateWithoutTambonsInput;
  AmphoeUpsertWithoutTambonsInput: AmphoeUpsertWithoutTambonsInput;
  AmphoeWhereInput: AmphoeWhereInput;
  AmphoeWhereUniqueInput: AmphoeWhereUniqueInput;
  BoolFieldUpdateOperationsInput: BoolFieldUpdateOperationsInput;
  BoolFilter: BoolFilter;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  ContactNumber: ResolverTypeWrapper<ContactNumber>;
  ContactNumberCountAggregate: ResolverTypeWrapper<ContactNumberCountAggregate>;
  ContactNumberCreateManyTravelInput: ContactNumberCreateManyTravelInput;
  ContactNumberCreateManyTravelInputEnvelope: ContactNumberCreateManyTravelInputEnvelope;
  ContactNumberCreateNestedManyWithoutTravelInput: ContactNumberCreateNestedManyWithoutTravelInput;
  ContactNumberCreateOrConnectWithoutTravelInput: ContactNumberCreateOrConnectWithoutTravelInput;
  ContactNumberCreateWithoutTravelInput: ContactNumberCreateWithoutTravelInput;
  ContactNumberListRelationFilter: ContactNumberListRelationFilter;
  ContactNumberMaxAggregate: ResolverTypeWrapper<ContactNumberMaxAggregate>;
  ContactNumberMinAggregate: ResolverTypeWrapper<ContactNumberMinAggregate>;
  ContactNumberScalarWhereInput: ContactNumberScalarWhereInput;
  ContactNumberUpdateManyMutationInput: ContactNumberUpdateManyMutationInput;
  ContactNumberUpdateManyWithWhereWithoutTravelInput: ContactNumberUpdateManyWithWhereWithoutTravelInput;
  ContactNumberUpdateManyWithoutTravelNestedInput: ContactNumberUpdateManyWithoutTravelNestedInput;
  ContactNumberUpdateWithWhereUniqueWithoutTravelInput: ContactNumberUpdateWithWhereUniqueWithoutTravelInput;
  ContactNumberUpdateWithoutTravelInput: ContactNumberUpdateWithoutTravelInput;
  ContactNumberUpsertWithWhereUniqueWithoutTravelInput: ContactNumberUpsertWithWhereUniqueWithoutTravelInput;
  ContactNumberWhereInput: ContactNumberWhereInput;
  ContactNumberWhereUniqueInput: ContactNumberWhereUniqueInput;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DateTimeFieldUpdateOperationsInput: DateTimeFieldUpdateOperationsInput;
  DateTimeFilter: DateTimeFilter;
  DateTimeNullableFilter: DateTimeNullableFilter;
  EnumHasOwnerFieldUpdateOperationsInput: EnumHasOwnerFieldUpdateOperationsInput;
  EnumHasOwnerFilter: EnumHasOwnerFilter;
  EnumHasParkingNullableFilter: EnumHasParkingNullableFilter;
  EnumPatentedStatusFieldUpdateOperationsInput: EnumPatentedStatusFieldUpdateOperationsInput;
  EnumPatentedStatusFilter: EnumPatentedStatusFilter;
  EnumPlantImageKindFieldUpdateOperationsInput: EnumPlantImageKindFieldUpdateOperationsInput;
  EnumPlantImageKindFilter: EnumPlantImageKindFilter;
  EnumRoleFieldUpdateOperationsInput: EnumRoleFieldUpdateOperationsInput;
  EnumRoleFilter: EnumRoleFilter;
  EnumWisdomPRFieldUpdateOperationsInput: EnumWisdomPrFieldUpdateOperationsInput;
  EnumWisdomPRFilter: EnumWisdomPrFilter;
  EnumWisdomStatusFieldUpdateOperationsInput: EnumWisdomStatusFieldUpdateOperationsInput;
  EnumWisdomStatusFilter: EnumWisdomStatusFilter;
  Facility: ResolverTypeWrapper<Facility>;
  FacilityCount: ResolverTypeWrapper<FacilityCount>;
  FacilityCountAggregate: ResolverTypeWrapper<FacilityCountAggregate>;
  FacilityCreateInput: FacilityCreateInput;
  FacilityCreateNestedOneWithoutTravelsInput: FacilityCreateNestedOneWithoutTravelsInput;
  FacilityCreateOrConnectWithoutTravelsInput: FacilityCreateOrConnectWithoutTravelsInput;
  FacilityCreateWithoutTravelsInput: FacilityCreateWithoutTravelsInput;
  FacilityMaxAggregate: ResolverTypeWrapper<FacilityMaxAggregate>;
  FacilityMinAggregate: ResolverTypeWrapper<FacilityMinAggregate>;
  FacilityRelationFilter: FacilityRelationFilter;
  FacilityUpdateInput: FacilityUpdateInput;
  FacilityUpdateOneRequiredWithoutTravelsNestedInput: FacilityUpdateOneRequiredWithoutTravelsNestedInput;
  FacilityUpdateToOneWithWhereWithoutTravelsInput: FacilityUpdateToOneWithWhereWithoutTravelsInput;
  FacilityUpdateWithoutTravelsInput: FacilityUpdateWithoutTravelsInput;
  FacilityUpsertWithoutTravelsInput: FacilityUpsertWithoutTravelsInput;
  FacilityWhereInput: FacilityWhereInput;
  FacilityWhereUniqueInput: FacilityWhereUniqueInput;
  Filter: Filter;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  FloatFieldUpdateOperationsInput: FloatFieldUpdateOperationsInput;
  FloatFilter: FloatFilter;
  Food: ResolverTypeWrapper<Food>;
  FoodAvgAggregate: ResolverTypeWrapper<FoodAvgAggregate>;
  FoodCategory: ResolverTypeWrapper<FoodCategory>;
  FoodCategoryCount: ResolverTypeWrapper<FoodCategoryCount>;
  FoodCategoryCountAggregate: ResolverTypeWrapper<FoodCategoryCountAggregate>;
  FoodCategoryCreateNestedOneWithoutFoodInput: FoodCategoryCreateNestedOneWithoutFoodInput;
  FoodCategoryCreateOrConnectWithoutFoodInput: FoodCategoryCreateOrConnectWithoutFoodInput;
  FoodCategoryCreateWithoutFoodInput: FoodCategoryCreateWithoutFoodInput;
  FoodCategoryMaxAggregate: ResolverTypeWrapper<FoodCategoryMaxAggregate>;
  FoodCategoryMinAggregate: ResolverTypeWrapper<FoodCategoryMinAggregate>;
  FoodCategoryNullableRelationFilter: FoodCategoryNullableRelationFilter;
  FoodCategoryUpdateOneWithoutFoodNestedInput: FoodCategoryUpdateOneWithoutFoodNestedInput;
  FoodCategoryUpdateToOneWithWhereWithoutFoodInput: FoodCategoryUpdateToOneWithWhereWithoutFoodInput;
  FoodCategoryUpdateWithoutFoodInput: FoodCategoryUpdateWithoutFoodInput;
  FoodCategoryUpsertWithoutFoodInput: FoodCategoryUpsertWithoutFoodInput;
  FoodCategoryWhereInput: FoodCategoryWhereInput;
  FoodCategoryWhereUniqueInput: FoodCategoryWhereUniqueInput;
  FoodCount: ResolverTypeWrapper<FoodCount>;
  FoodCountAggregate: ResolverTypeWrapper<FoodCountAggregate>;
  FoodCreateInput: FoodCreateInput;
  FoodCreateManyTambonInput: FoodCreateManyTambonInput;
  FoodCreateManyTambonInputEnvelope: FoodCreateManyTambonInputEnvelope;
  FoodCreateManyUserInput: FoodCreateManyUserInput;
  FoodCreateManyUserInputEnvelope: FoodCreateManyUserInputEnvelope;
  FoodCreateNestedManyWithoutTambonInput: FoodCreateNestedManyWithoutTambonInput;
  FoodCreateNestedManyWithoutUserInput: FoodCreateNestedManyWithoutUserInput;
  FoodCreateNestedOneWithoutImagesInput: FoodCreateNestedOneWithoutImagesInput;
  FoodCreateOrConnectWithoutImagesInput: FoodCreateOrConnectWithoutImagesInput;
  FoodCreateOrConnectWithoutTambonInput: FoodCreateOrConnectWithoutTambonInput;
  FoodCreateOrConnectWithoutUserInput: FoodCreateOrConnectWithoutUserInput;
  FoodCreateWithoutImagesInput: FoodCreateWithoutImagesInput;
  FoodCreateWithoutTambonInput: FoodCreateWithoutTambonInput;
  FoodCreateWithoutUserInput: FoodCreateWithoutUserInput;
  FoodImage: ResolverTypeWrapper<FoodImage>;
  FoodImageCountAggregate: ResolverTypeWrapper<FoodImageCountAggregate>;
  FoodImageCreateInput: FoodImageCreateInput;
  FoodImageCreateManyFoodInput: FoodImageCreateManyFoodInput;
  FoodImageCreateManyFoodInputEnvelope: FoodImageCreateManyFoodInputEnvelope;
  FoodImageCreateNestedManyWithoutFoodInput: FoodImageCreateNestedManyWithoutFoodInput;
  FoodImageCreateOrConnectWithoutFoodInput: FoodImageCreateOrConnectWithoutFoodInput;
  FoodImageCreateWithoutFoodInput: FoodImageCreateWithoutFoodInput;
  FoodImageListRelationFilter: FoodImageListRelationFilter;
  FoodImageMaxAggregate: ResolverTypeWrapper<FoodImageMaxAggregate>;
  FoodImageMinAggregate: ResolverTypeWrapper<FoodImageMinAggregate>;
  FoodImageScalarWhereInput: FoodImageScalarWhereInput;
  FoodImageUpdateManyMutationInput: FoodImageUpdateManyMutationInput;
  FoodImageUpdateManyWithWhereWithoutFoodInput: FoodImageUpdateManyWithWhereWithoutFoodInput;
  FoodImageUpdateManyWithoutFoodNestedInput: FoodImageUpdateManyWithoutFoodNestedInput;
  FoodImageUpdateWithWhereUniqueWithoutFoodInput: FoodImageUpdateWithWhereUniqueWithoutFoodInput;
  FoodImageUpdateWithoutFoodInput: FoodImageUpdateWithoutFoodInput;
  FoodImageUpsertWithWhereUniqueWithoutFoodInput: FoodImageUpsertWithWhereUniqueWithoutFoodInput;
  FoodImageWhereInput: FoodImageWhereInput;
  FoodImageWhereUniqueInput: FoodImageWhereUniqueInput;
  FoodListRelationFilter: FoodListRelationFilter;
  FoodMaxAggregate: ResolverTypeWrapper<FoodMaxAggregate>;
  FoodMinAggregate: ResolverTypeWrapper<FoodMinAggregate>;
  FoodNullableRelationFilter: FoodNullableRelationFilter;
  FoodRelationFilter: FoodRelationFilter;
  FoodScalarWhereInput: FoodScalarWhereInput;
  FoodSumAggregate: ResolverTypeWrapper<FoodSumAggregate>;
  FoodUpdateInput: FoodUpdateInput;
  FoodUpdateManyMutationInput: FoodUpdateManyMutationInput;
  FoodUpdateManyWithWhereWithoutTambonInput: FoodUpdateManyWithWhereWithoutTambonInput;
  FoodUpdateManyWithWhereWithoutUserInput: FoodUpdateManyWithWhereWithoutUserInput;
  FoodUpdateManyWithoutTambonNestedInput: FoodUpdateManyWithoutTambonNestedInput;
  FoodUpdateManyWithoutUserNestedInput: FoodUpdateManyWithoutUserNestedInput;
  FoodUpdateWithWhereUniqueWithoutTambonInput: FoodUpdateWithWhereUniqueWithoutTambonInput;
  FoodUpdateWithWhereUniqueWithoutUserInput: FoodUpdateWithWhereUniqueWithoutUserInput;
  FoodUpdateWithoutTambonInput: FoodUpdateWithoutTambonInput;
  FoodUpdateWithoutUserInput: FoodUpdateWithoutUserInput;
  FoodUpsertWithWhereUniqueWithoutTambonInput: FoodUpsertWithWhereUniqueWithoutTambonInput;
  FoodUpsertWithWhereUniqueWithoutUserInput: FoodUpsertWithWhereUniqueWithoutUserInput;
  FoodWhereInput: FoodWhereInput;
  FoodWhereUniqueInput: FoodWhereUniqueInput;
  HasOwner: HasOwner;
  HasParking: HasParking;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  Ingredients: ResolverTypeWrapper<Ingredients>;
  IngredientsCountAggregate: ResolverTypeWrapper<IngredientsCountAggregate>;
  IngredientsCreateManyFoodInput: IngredientsCreateManyFoodInput;
  IngredientsCreateManyFoodInputEnvelope: IngredientsCreateManyFoodInputEnvelope;
  IngredientsCreateNestedManyWithoutFoodInput: IngredientsCreateNestedManyWithoutFoodInput;
  IngredientsCreateOrConnectWithoutFoodInput: IngredientsCreateOrConnectWithoutFoodInput;
  IngredientsCreateWithoutFoodInput: IngredientsCreateWithoutFoodInput;
  IngredientsListRelationFilter: IngredientsListRelationFilter;
  IngredientsMaxAggregate: ResolverTypeWrapper<IngredientsMaxAggregate>;
  IngredientsMinAggregate: ResolverTypeWrapper<IngredientsMinAggregate>;
  IngredientsScalarWhereInput: IngredientsScalarWhereInput;
  IngredientsUpdateManyMutationInput: IngredientsUpdateManyMutationInput;
  IngredientsUpdateManyWithWhereWithoutFoodInput: IngredientsUpdateManyWithWhereWithoutFoodInput;
  IngredientsUpdateManyWithoutFoodNestedInput: IngredientsUpdateManyWithoutFoodNestedInput;
  IngredientsUpdateWithWhereUniqueWithoutFoodInput: IngredientsUpdateWithWhereUniqueWithoutFoodInput;
  IngredientsUpdateWithoutFoodInput: IngredientsUpdateWithoutFoodInput;
  IngredientsUpsertWithWhereUniqueWithoutFoodInput: IngredientsUpsertWithWhereUniqueWithoutFoodInput;
  IngredientsWhereInput: IngredientsWhereInput;
  IngredientsWhereUniqueInput: IngredientsWhereUniqueInput;
  InputAmphoeIdAndKeyword: InputAmphoeIdAndKeyword;
  InputProvinceIdAndKeyword: InputProvinceIdAndKeyword;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  IntFieldUpdateOperationsInput: IntFieldUpdateOperationsInput;
  IntFilter: IntFilter;
  IntNullableFilter: IntNullableFilter;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  Mutation: ResolverTypeWrapper<{}>;
  NestedBoolFilter: NestedBoolFilter;
  NestedDateTimeFilter: NestedDateTimeFilter;
  NestedDateTimeNullableFilter: NestedDateTimeNullableFilter;
  NestedEnumHasOwnerFilter: NestedEnumHasOwnerFilter;
  NestedEnumHasParkingNullableFilter: NestedEnumHasParkingNullableFilter;
  NestedEnumPatentedStatusFilter: NestedEnumPatentedStatusFilter;
  NestedEnumPlantImageKindFilter: NestedEnumPlantImageKindFilter;
  NestedEnumRoleFilter: NestedEnumRoleFilter;
  NestedEnumWisdomPRFilter: NestedEnumWisdomPrFilter;
  NestedEnumWisdomStatusFilter: NestedEnumWisdomStatusFilter;
  NestedFloatFilter: NestedFloatFilter;
  NestedIntFilter: NestedIntFilter;
  NestedIntNullableFilter: NestedIntNullableFilter;
  NestedStringFilter: NestedStringFilter;
  NestedStringNullableFilter: NestedStringNullableFilter;
  NullableDateTimeFieldUpdateOperationsInput: NullableDateTimeFieldUpdateOperationsInput;
  NullableEnumHasParkingFieldUpdateOperationsInput: NullableEnumHasParkingFieldUpdateOperationsInput;
  NullableIntFieldUpdateOperationsInput: NullableIntFieldUpdateOperationsInput;
  NullableStringFieldUpdateOperationsInput: NullableStringFieldUpdateOperationsInput;
  PatentedStatus: PatentedStatus;
  Plant: ResolverTypeWrapper<Plant>;
  PlantAvgAggregate: ResolverTypeWrapper<PlantAvgAggregate>;
  PlantCount: ResolverTypeWrapper<PlantCount>;
  PlantCountAggregate: ResolverTypeWrapper<PlantCountAggregate>;
  PlantCreateInput: PlantCreateInput;
  PlantCreateManyTambonInput: PlantCreateManyTambonInput;
  PlantCreateManyTambonInputEnvelope: PlantCreateManyTambonInputEnvelope;
  PlantCreateManyUserInput: PlantCreateManyUserInput;
  PlantCreateManyUserInputEnvelope: PlantCreateManyUserInputEnvelope;
  PlantCreateNestedManyWithoutTambonInput: PlantCreateNestedManyWithoutTambonInput;
  PlantCreateNestedManyWithoutUserInput: PlantCreateNestedManyWithoutUserInput;
  PlantCreateOrConnectWithoutTambonInput: PlantCreateOrConnectWithoutTambonInput;
  PlantCreateOrConnectWithoutUserInput: PlantCreateOrConnectWithoutUserInput;
  PlantCreateWithoutTambonInput: PlantCreateWithoutTambonInput;
  PlantCreateWithoutUserInput: PlantCreateWithoutUserInput;
  PlantImage: ResolverTypeWrapper<PlantImage>;
  PlantImageCountAggregate: ResolverTypeWrapper<PlantImageCountAggregate>;
  PlantImageCreateManyPlantInput: PlantImageCreateManyPlantInput;
  PlantImageCreateManyPlantInputEnvelope: PlantImageCreateManyPlantInputEnvelope;
  PlantImageCreateNestedManyWithoutPlantInput: PlantImageCreateNestedManyWithoutPlantInput;
  PlantImageCreateOrConnectWithoutPlantInput: PlantImageCreateOrConnectWithoutPlantInput;
  PlantImageCreateWithoutPlantInput: PlantImageCreateWithoutPlantInput;
  PlantImageKind: PlantImageKind;
  PlantImageListRelationFilter: PlantImageListRelationFilter;
  PlantImageMaxAggregate: ResolverTypeWrapper<PlantImageMaxAggregate>;
  PlantImageMinAggregate: ResolverTypeWrapper<PlantImageMinAggregate>;
  PlantImageScalarWhereInput: PlantImageScalarWhereInput;
  PlantImageUpdateManyMutationInput: PlantImageUpdateManyMutationInput;
  PlantImageUpdateManyWithWhereWithoutPlantInput: PlantImageUpdateManyWithWhereWithoutPlantInput;
  PlantImageUpdateManyWithoutPlantNestedInput: PlantImageUpdateManyWithoutPlantNestedInput;
  PlantImageUpdateWithWhereUniqueWithoutPlantInput: PlantImageUpdateWithWhereUniqueWithoutPlantInput;
  PlantImageUpdateWithoutPlantInput: PlantImageUpdateWithoutPlantInput;
  PlantImageUpsertWithWhereUniqueWithoutPlantInput: PlantImageUpsertWithWhereUniqueWithoutPlantInput;
  PlantImageWhereInput: PlantImageWhereInput;
  PlantImageWhereUniqueInput: PlantImageWhereUniqueInput;
  PlantKindOfUse: ResolverTypeWrapper<PlantKindOfUse>;
  PlantKindOfUseCount: ResolverTypeWrapper<PlantKindOfUseCount>;
  PlantKindOfUseCountAggregate: ResolverTypeWrapper<PlantKindOfUseCountAggregate>;
  PlantKindOfUseCreateNestedOneWithoutPlantOnPlantKindOfUsesInput: PlantKindOfUseCreateNestedOneWithoutPlantOnPlantKindOfUsesInput;
  PlantKindOfUseCreateOrConnectWithoutPlantOnPlantKindOfUsesInput: PlantKindOfUseCreateOrConnectWithoutPlantOnPlantKindOfUsesInput;
  PlantKindOfUseCreateWithoutPlantOnPlantKindOfUsesInput: PlantKindOfUseCreateWithoutPlantOnPlantKindOfUsesInput;
  PlantKindOfUseMaxAggregate: ResolverTypeWrapper<PlantKindOfUseMaxAggregate>;
  PlantKindOfUseMinAggregate: ResolverTypeWrapper<PlantKindOfUseMinAggregate>;
  PlantKindOfUseRelationFilter: PlantKindOfUseRelationFilter;
  PlantKindOfUseUpdateOneRequiredWithoutPlantOnPlantKindOfUsesNestedInput: PlantKindOfUseUpdateOneRequiredWithoutPlantOnPlantKindOfUsesNestedInput;
  PlantKindOfUseUpdateToOneWithWhereWithoutPlantOnPlantKindOfUsesInput: PlantKindOfUseUpdateToOneWithWhereWithoutPlantOnPlantKindOfUsesInput;
  PlantKindOfUseUpdateWithoutPlantOnPlantKindOfUsesInput: PlantKindOfUseUpdateWithoutPlantOnPlantKindOfUsesInput;
  PlantKindOfUseUpsertWithoutPlantOnPlantKindOfUsesInput: PlantKindOfUseUpsertWithoutPlantOnPlantKindOfUsesInput;
  PlantKindOfUseWhereInput: PlantKindOfUseWhereInput;
  PlantKindOfUseWhereUniqueInput: PlantKindOfUseWhereUniqueInput;
  PlantListRelationFilter: PlantListRelationFilter;
  PlantMaxAggregate: ResolverTypeWrapper<PlantMaxAggregate>;
  PlantMinAggregate: ResolverTypeWrapper<PlantMinAggregate>;
  PlantOnPlantKindOfUse: ResolverTypeWrapper<PlantOnPlantKindOfUse>;
  PlantOnPlantKindOfUseCountAggregate: ResolverTypeWrapper<PlantOnPlantKindOfUseCountAggregate>;
  PlantOnPlantKindOfUseCreateManyPlantInput: PlantOnPlantKindOfUseCreateManyPlantInput;
  PlantOnPlantKindOfUseCreateManyPlantInputEnvelope: PlantOnPlantKindOfUseCreateManyPlantInputEnvelope;
  PlantOnPlantKindOfUseCreateNestedManyWithoutPlantInput: PlantOnPlantKindOfUseCreateNestedManyWithoutPlantInput;
  PlantOnPlantKindOfUseCreateOrConnectWithoutPlantInput: PlantOnPlantKindOfUseCreateOrConnectWithoutPlantInput;
  PlantOnPlantKindOfUseCreateWithoutPlantInput: PlantOnPlantKindOfUseCreateWithoutPlantInput;
  PlantOnPlantKindOfUseListRelationFilter: PlantOnPlantKindOfUseListRelationFilter;
  PlantOnPlantKindOfUseMaxAggregate: ResolverTypeWrapper<PlantOnPlantKindOfUseMaxAggregate>;
  PlantOnPlantKindOfUseMinAggregate: ResolverTypeWrapper<PlantOnPlantKindOfUseMinAggregate>;
  PlantOnPlantKindOfUsePlantIdPlantKindIdCompoundUniqueInput: PlantOnPlantKindOfUsePlantIdPlantKindIdCompoundUniqueInput;
  PlantOnPlantKindOfUseScalarWhereInput: PlantOnPlantKindOfUseScalarWhereInput;
  PlantOnPlantKindOfUseUpdateManyMutationInput: PlantOnPlantKindOfUseUpdateManyMutationInput;
  PlantOnPlantKindOfUseUpdateManyWithWhereWithoutPlantInput: PlantOnPlantKindOfUseUpdateManyWithWhereWithoutPlantInput;
  PlantOnPlantKindOfUseUpdateManyWithoutPlantNestedInput: PlantOnPlantKindOfUseUpdateManyWithoutPlantNestedInput;
  PlantOnPlantKindOfUseUpdateWithWhereUniqueWithoutPlantInput: PlantOnPlantKindOfUseUpdateWithWhereUniqueWithoutPlantInput;
  PlantOnPlantKindOfUseUpdateWithoutPlantInput: PlantOnPlantKindOfUseUpdateWithoutPlantInput;
  PlantOnPlantKindOfUseUpsertWithWhereUniqueWithoutPlantInput: PlantOnPlantKindOfUseUpsertWithWhereUniqueWithoutPlantInput;
  PlantOnPlantKindOfUseWhereInput: PlantOnPlantKindOfUseWhereInput;
  PlantOnPlantKindOfUseWhereUniqueInput: PlantOnPlantKindOfUseWhereUniqueInput;
  PlantRelationFilter: PlantRelationFilter;
  PlantScalarWhereInput: PlantScalarWhereInput;
  PlantSumAggregate: ResolverTypeWrapper<PlantSumAggregate>;
  PlantUpdateInput: PlantUpdateInput;
  PlantUpdateManyMutationInput: PlantUpdateManyMutationInput;
  PlantUpdateManyWithWhereWithoutTambonInput: PlantUpdateManyWithWhereWithoutTambonInput;
  PlantUpdateManyWithWhereWithoutUserInput: PlantUpdateManyWithWhereWithoutUserInput;
  PlantUpdateManyWithoutTambonNestedInput: PlantUpdateManyWithoutTambonNestedInput;
  PlantUpdateManyWithoutUserNestedInput: PlantUpdateManyWithoutUserNestedInput;
  PlantUpdateWithWhereUniqueWithoutTambonInput: PlantUpdateWithWhereUniqueWithoutTambonInput;
  PlantUpdateWithWhereUniqueWithoutUserInput: PlantUpdateWithWhereUniqueWithoutUserInput;
  PlantUpdateWithoutTambonInput: PlantUpdateWithoutTambonInput;
  PlantUpdateWithoutUserInput: PlantUpdateWithoutUserInput;
  PlantUpsertWithWhereUniqueWithoutTambonInput: PlantUpsertWithWhereUniqueWithoutTambonInput;
  PlantUpsertWithWhereUniqueWithoutUserInput: PlantUpsertWithWhereUniqueWithoutUserInput;
  PlantWhereInput: PlantWhereInput;
  PlantWhereUniqueInput: PlantWhereUniqueInput;
  Province: ResolverTypeWrapper<Province>;
  ProvinceCount: ResolverTypeWrapper<ProvinceCount>;
  ProvinceCountAggregate: ResolverTypeWrapper<ProvinceCountAggregate>;
  ProvinceCreateNestedOneWithoutAmphoesInput: ProvinceCreateNestedOneWithoutAmphoesInput;
  ProvinceCreateOrConnectWithoutAmphoesInput: ProvinceCreateOrConnectWithoutAmphoesInput;
  ProvinceCreateWithoutAmphoesInput: ProvinceCreateWithoutAmphoesInput;
  ProvinceMaxAggregate: ResolverTypeWrapper<ProvinceMaxAggregate>;
  ProvinceMinAggregate: ResolverTypeWrapper<ProvinceMinAggregate>;
  ProvinceNullableRelationFilter: ProvinceNullableRelationFilter;
  ProvinceUpdateOneWithoutAmphoesNestedInput: ProvinceUpdateOneWithoutAmphoesNestedInput;
  ProvinceUpdateToOneWithWhereWithoutAmphoesInput: ProvinceUpdateToOneWithWhereWithoutAmphoesInput;
  ProvinceUpdateWithoutAmphoesInput: ProvinceUpdateWithoutAmphoesInput;
  ProvinceUpsertWithoutAmphoesInput: ProvinceUpsertWithoutAmphoesInput;
  ProvinceWhereInput: ProvinceWhereInput;
  ProvinceWhereUniqueInput: ProvinceWhereUniqueInput;
  PublishableWisdom: ResolverTypeWrapper<PublishableWisdom>;
  PublishableWisdomCountAggregate: ResolverTypeWrapper<PublishableWisdomCountAggregate>;
  PublishableWisdomCreateManyWisdomInput: PublishableWisdomCreateManyWisdomInput;
  PublishableWisdomCreateManyWisdomInputEnvelope: PublishableWisdomCreateManyWisdomInputEnvelope;
  PublishableWisdomCreateNestedManyWithoutWisdomInput: PublishableWisdomCreateNestedManyWithoutWisdomInput;
  PublishableWisdomCreateOrConnectWithoutWisdomInput: PublishableWisdomCreateOrConnectWithoutWisdomInput;
  PublishableWisdomCreateWithoutWisdomInput: PublishableWisdomCreateWithoutWisdomInput;
  PublishableWisdomListRelationFilter: PublishableWisdomListRelationFilter;
  PublishableWisdomMaxAggregate: ResolverTypeWrapper<PublishableWisdomMaxAggregate>;
  PublishableWisdomMinAggregate: ResolverTypeWrapper<PublishableWisdomMinAggregate>;
  PublishableWisdomScalarWhereInput: PublishableWisdomScalarWhereInput;
  PublishableWisdomUpdateManyMutationInput: PublishableWisdomUpdateManyMutationInput;
  PublishableWisdomUpdateManyWithWhereWithoutWisdomInput: PublishableWisdomUpdateManyWithWhereWithoutWisdomInput;
  PublishableWisdomUpdateManyWithoutWisdomNestedInput: PublishableWisdomUpdateManyWithoutWisdomNestedInput;
  PublishableWisdomUpdateWithWhereUniqueWithoutWisdomInput: PublishableWisdomUpdateWithWhereUniqueWithoutWisdomInput;
  PublishableWisdomUpdateWithoutWisdomInput: PublishableWisdomUpdateWithoutWisdomInput;
  PublishableWisdomUpsertWithWhereUniqueWithoutWisdomInput: PublishableWisdomUpsertWithWhereUniqueWithoutWisdomInput;
  PublishableWisdomWhereInput: PublishableWisdomWhereInput;
  PublishableWisdomWhereUniqueInput: PublishableWisdomWhereUniqueInput;
  Query: ResolverTypeWrapper<{}>;
  QueryMode: QueryMode;
  Role: Role;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  StringFieldUpdateOperationsInput: StringFieldUpdateOperationsInput;
  StringFilter: StringFilter;
  StringNullableFilter: StringNullableFilter;
  Tambon: ResolverTypeWrapper<Tambon>;
  TambonCount: ResolverTypeWrapper<TambonCount>;
  TambonCountAggregate: ResolverTypeWrapper<TambonCountAggregate>;
  TambonCreateNestedOneWithoutFoodInput: TambonCreateNestedOneWithoutFoodInput;
  TambonCreateNestedOneWithoutPlantInput: TambonCreateNestedOneWithoutPlantInput;
  TambonCreateNestedOneWithoutTravelInput: TambonCreateNestedOneWithoutTravelInput;
  TambonCreateNestedOneWithoutWisdomCategoryInput: TambonCreateNestedOneWithoutWisdomCategoryInput;
  TambonCreateNestedOneWithoutWisdomInput: TambonCreateNestedOneWithoutWisdomInput;
  TambonCreateOrConnectWithoutFoodInput: TambonCreateOrConnectWithoutFoodInput;
  TambonCreateOrConnectWithoutPlantInput: TambonCreateOrConnectWithoutPlantInput;
  TambonCreateOrConnectWithoutTravelInput: TambonCreateOrConnectWithoutTravelInput;
  TambonCreateOrConnectWithoutWisdomCategoryInput: TambonCreateOrConnectWithoutWisdomCategoryInput;
  TambonCreateOrConnectWithoutWisdomInput: TambonCreateOrConnectWithoutWisdomInput;
  TambonCreateWithoutFoodInput: TambonCreateWithoutFoodInput;
  TambonCreateWithoutPlantInput: TambonCreateWithoutPlantInput;
  TambonCreateWithoutTravelInput: TambonCreateWithoutTravelInput;
  TambonCreateWithoutWisdomCategoryInput: TambonCreateWithoutWisdomCategoryInput;
  TambonCreateWithoutWisdomInput: TambonCreateWithoutWisdomInput;
  TambonListRelationFilter: TambonListRelationFilter;
  TambonMaxAggregate: ResolverTypeWrapper<TambonMaxAggregate>;
  TambonMinAggregate: ResolverTypeWrapper<TambonMinAggregate>;
  TambonNullableRelationFilter: TambonNullableRelationFilter;
  TambonRelationFilter: TambonRelationFilter;
  TambonUpdateOneRequiredWithoutFoodNestedInput: TambonUpdateOneRequiredWithoutFoodNestedInput;
  TambonUpdateOneRequiredWithoutPlantNestedInput: TambonUpdateOneRequiredWithoutPlantNestedInput;
  TambonUpdateOneRequiredWithoutTravelNestedInput: TambonUpdateOneRequiredWithoutTravelNestedInput;
  TambonUpdateOneRequiredWithoutWisdomNestedInput: TambonUpdateOneRequiredWithoutWisdomNestedInput;
  TambonUpdateOneWithoutWisdomCategoryNestedInput: TambonUpdateOneWithoutWisdomCategoryNestedInput;
  TambonUpdateToOneWithWhereWithoutFoodInput: TambonUpdateToOneWithWhereWithoutFoodInput;
  TambonUpdateToOneWithWhereWithoutPlantInput: TambonUpdateToOneWithWhereWithoutPlantInput;
  TambonUpdateToOneWithWhereWithoutTravelInput: TambonUpdateToOneWithWhereWithoutTravelInput;
  TambonUpdateToOneWithWhereWithoutWisdomCategoryInput: TambonUpdateToOneWithWhereWithoutWisdomCategoryInput;
  TambonUpdateToOneWithWhereWithoutWisdomInput: TambonUpdateToOneWithWhereWithoutWisdomInput;
  TambonUpdateWithoutFoodInput: TambonUpdateWithoutFoodInput;
  TambonUpdateWithoutPlantInput: TambonUpdateWithoutPlantInput;
  TambonUpdateWithoutTravelInput: TambonUpdateWithoutTravelInput;
  TambonUpdateWithoutWisdomCategoryInput: TambonUpdateWithoutWisdomCategoryInput;
  TambonUpdateWithoutWisdomInput: TambonUpdateWithoutWisdomInput;
  TambonUpsertWithoutFoodInput: TambonUpsertWithoutFoodInput;
  TambonUpsertWithoutPlantInput: TambonUpsertWithoutPlantInput;
  TambonUpsertWithoutTravelInput: TambonUpsertWithoutTravelInput;
  TambonUpsertWithoutWisdomCategoryInput: TambonUpsertWithoutWisdomCategoryInput;
  TambonUpsertWithoutWisdomInput: TambonUpsertWithoutWisdomInput;
  TambonWhereInput: TambonWhereInput;
  TambonWhereUniqueInput: TambonWhereUniqueInput;
  TambonWithAmphoeAndProvinceArgs: ResolverTypeWrapper<TambonWithAmphoeAndProvinceArgs>;
  Travel: ResolverTypeWrapper<Travel>;
  TravelAvgAggregate: ResolverTypeWrapper<TravelAvgAggregate>;
  TravelCount: ResolverTypeWrapper<TravelCount>;
  TravelCountAggregate: ResolverTypeWrapper<TravelCountAggregate>;
  TravelCreateInput: TravelCreateInput;
  TravelCreateManyTambonInput: TravelCreateManyTambonInput;
  TravelCreateManyTambonInputEnvelope: TravelCreateManyTambonInputEnvelope;
  TravelCreateManyTypeInput: TravelCreateManyTypeInput;
  TravelCreateManyTypeInputEnvelope: TravelCreateManyTypeInputEnvelope;
  TravelCreateManyUserInput: TravelCreateManyUserInput;
  TravelCreateManyUserInputEnvelope: TravelCreateManyUserInputEnvelope;
  TravelCreateNestedManyWithoutTambonInput: TravelCreateNestedManyWithoutTambonInput;
  TravelCreateNestedManyWithoutTypeInput: TravelCreateNestedManyWithoutTypeInput;
  TravelCreateNestedManyWithoutUserInput: TravelCreateNestedManyWithoutUserInput;
  TravelCreateNestedOneWithoutFacilitiesInput: TravelCreateNestedOneWithoutFacilitiesInput;
  TravelCreateOrConnectWithoutFacilitiesInput: TravelCreateOrConnectWithoutFacilitiesInput;
  TravelCreateOrConnectWithoutTambonInput: TravelCreateOrConnectWithoutTambonInput;
  TravelCreateOrConnectWithoutTypeInput: TravelCreateOrConnectWithoutTypeInput;
  TravelCreateOrConnectWithoutUserInput: TravelCreateOrConnectWithoutUserInput;
  TravelCreateWithoutFacilitiesInput: TravelCreateWithoutFacilitiesInput;
  TravelCreateWithoutTambonInput: TravelCreateWithoutTambonInput;
  TravelCreateWithoutTypeInput: TravelCreateWithoutTypeInput;
  TravelCreateWithoutUserInput: TravelCreateWithoutUserInput;
  TravelImage: ResolverTypeWrapper<TravelImage>;
  TravelImageCountAggregate: ResolverTypeWrapper<TravelImageCountAggregate>;
  TravelImageCreateManyTravelInput: TravelImageCreateManyTravelInput;
  TravelImageCreateManyTravelInputEnvelope: TravelImageCreateManyTravelInputEnvelope;
  TravelImageCreateNestedManyWithoutTravelInput: TravelImageCreateNestedManyWithoutTravelInput;
  TravelImageCreateOrConnectWithoutTravelInput: TravelImageCreateOrConnectWithoutTravelInput;
  TravelImageCreateWithoutTravelInput: TravelImageCreateWithoutTravelInput;
  TravelImageListRelationFilter: TravelImageListRelationFilter;
  TravelImageMaxAggregate: ResolverTypeWrapper<TravelImageMaxAggregate>;
  TravelImageMinAggregate: ResolverTypeWrapper<TravelImageMinAggregate>;
  TravelImageScalarWhereInput: TravelImageScalarWhereInput;
  TravelImageUpdateManyMutationInput: TravelImageUpdateManyMutationInput;
  TravelImageUpdateManyWithWhereWithoutTravelInput: TravelImageUpdateManyWithWhereWithoutTravelInput;
  TravelImageUpdateManyWithoutTravelNestedInput: TravelImageUpdateManyWithoutTravelNestedInput;
  TravelImageUpdateWithWhereUniqueWithoutTravelInput: TravelImageUpdateWithWhereUniqueWithoutTravelInput;
  TravelImageUpdateWithoutTravelInput: TravelImageUpdateWithoutTravelInput;
  TravelImageUpsertWithWhereUniqueWithoutTravelInput: TravelImageUpsertWithWhereUniqueWithoutTravelInput;
  TravelImageWhereInput: TravelImageWhereInput;
  TravelImageWhereUniqueInput: TravelImageWhereUniqueInput;
  TravelListRelationFilter: TravelListRelationFilter;
  TravelMaxAggregate: ResolverTypeWrapper<TravelMaxAggregate>;
  TravelMinAggregate: ResolverTypeWrapper<TravelMinAggregate>;
  TravelNullableRelationFilter: TravelNullableRelationFilter;
  TravelRelationFilter: TravelRelationFilter;
  TravelScalarWhereInput: TravelScalarWhereInput;
  TravelSumAggregate: ResolverTypeWrapper<TravelSumAggregate>;
  TravelType: ResolverTypeWrapper<TravelType>;
  TravelTypeCount: ResolverTypeWrapper<TravelTypeCount>;
  TravelTypeCountAggregate: ResolverTypeWrapper<TravelTypeCountAggregate>;
  TravelTypeCreateInput: TravelTypeCreateInput;
  TravelTypeCreateNestedOneWithoutTravelsInput: TravelTypeCreateNestedOneWithoutTravelsInput;
  TravelTypeCreateOrConnectWithoutTravelsInput: TravelTypeCreateOrConnectWithoutTravelsInput;
  TravelTypeCreateWithoutTravelsInput: TravelTypeCreateWithoutTravelsInput;
  TravelTypeMaxAggregate: ResolverTypeWrapper<TravelTypeMaxAggregate>;
  TravelTypeMinAggregate: ResolverTypeWrapper<TravelTypeMinAggregate>;
  TravelTypeRelationFilter: TravelTypeRelationFilter;
  TravelTypeUpdateInput: TravelTypeUpdateInput;
  TravelTypeUpdateOneRequiredWithoutTravelsNestedInput: TravelTypeUpdateOneRequiredWithoutTravelsNestedInput;
  TravelTypeUpdateToOneWithWhereWithoutTravelsInput: TravelTypeUpdateToOneWithWhereWithoutTravelsInput;
  TravelTypeUpdateWithoutTravelsInput: TravelTypeUpdateWithoutTravelsInput;
  TravelTypeUpsertWithoutTravelsInput: TravelTypeUpsertWithoutTravelsInput;
  TravelTypeWhereInput: TravelTypeWhereInput;
  TravelTypeWhereUniqueInput: TravelTypeWhereUniqueInput;
  TravelUpdateInput: TravelUpdateInput;
  TravelUpdateManyMutationInput: TravelUpdateManyMutationInput;
  TravelUpdateManyWithWhereWithoutTambonInput: TravelUpdateManyWithWhereWithoutTambonInput;
  TravelUpdateManyWithWhereWithoutTypeInput: TravelUpdateManyWithWhereWithoutTypeInput;
  TravelUpdateManyWithWhereWithoutUserInput: TravelUpdateManyWithWhereWithoutUserInput;
  TravelUpdateManyWithoutTambonNestedInput: TravelUpdateManyWithoutTambonNestedInput;
  TravelUpdateManyWithoutTypeNestedInput: TravelUpdateManyWithoutTypeNestedInput;
  TravelUpdateManyWithoutUserNestedInput: TravelUpdateManyWithoutUserNestedInput;
  TravelUpdateOneRequiredWithoutFacilitiesNestedInput: TravelUpdateOneRequiredWithoutFacilitiesNestedInput;
  TravelUpdateToOneWithWhereWithoutFacilitiesInput: TravelUpdateToOneWithWhereWithoutFacilitiesInput;
  TravelUpdateWithWhereUniqueWithoutTambonInput: TravelUpdateWithWhereUniqueWithoutTambonInput;
  TravelUpdateWithWhereUniqueWithoutTypeInput: TravelUpdateWithWhereUniqueWithoutTypeInput;
  TravelUpdateWithWhereUniqueWithoutUserInput: TravelUpdateWithWhereUniqueWithoutUserInput;
  TravelUpdateWithoutFacilitiesInput: TravelUpdateWithoutFacilitiesInput;
  TravelUpdateWithoutTambonInput: TravelUpdateWithoutTambonInput;
  TravelUpdateWithoutTypeInput: TravelUpdateWithoutTypeInput;
  TravelUpdateWithoutUserInput: TravelUpdateWithoutUserInput;
  TravelUpsertWithWhereUniqueWithoutTambonInput: TravelUpsertWithWhereUniqueWithoutTambonInput;
  TravelUpsertWithWhereUniqueWithoutTypeInput: TravelUpsertWithWhereUniqueWithoutTypeInput;
  TravelUpsertWithWhereUniqueWithoutUserInput: TravelUpsertWithWhereUniqueWithoutUserInput;
  TravelUpsertWithoutFacilitiesInput: TravelUpsertWithoutFacilitiesInput;
  TravelWhereInput: TravelWhereInput;
  TravelWhereUniqueInput: TravelWhereUniqueInput;
  TravelsOnFacilities: ResolverTypeWrapper<TravelsOnFacilities>;
  TravelsOnFacilitiesCountAggregate: ResolverTypeWrapper<TravelsOnFacilitiesCountAggregate>;
  TravelsOnFacilitiesCreateManyFacilityInput: TravelsOnFacilitiesCreateManyFacilityInput;
  TravelsOnFacilitiesCreateManyFacilityInputEnvelope: TravelsOnFacilitiesCreateManyFacilityInputEnvelope;
  TravelsOnFacilitiesCreateManyTravelInput: TravelsOnFacilitiesCreateManyTravelInput;
  TravelsOnFacilitiesCreateManyTravelInputEnvelope: TravelsOnFacilitiesCreateManyTravelInputEnvelope;
  TravelsOnFacilitiesCreateNestedManyWithoutFacilityInput: TravelsOnFacilitiesCreateNestedManyWithoutFacilityInput;
  TravelsOnFacilitiesCreateNestedManyWithoutTravelInput: TravelsOnFacilitiesCreateNestedManyWithoutTravelInput;
  TravelsOnFacilitiesCreateOrConnectWithoutFacilityInput: TravelsOnFacilitiesCreateOrConnectWithoutFacilityInput;
  TravelsOnFacilitiesCreateOrConnectWithoutTravelInput: TravelsOnFacilitiesCreateOrConnectWithoutTravelInput;
  TravelsOnFacilitiesCreateWithoutFacilityInput: TravelsOnFacilitiesCreateWithoutFacilityInput;
  TravelsOnFacilitiesCreateWithoutTravelInput: TravelsOnFacilitiesCreateWithoutTravelInput;
  TravelsOnFacilitiesListRelationFilter: TravelsOnFacilitiesListRelationFilter;
  TravelsOnFacilitiesMaxAggregate: ResolverTypeWrapper<TravelsOnFacilitiesMaxAggregate>;
  TravelsOnFacilitiesMinAggregate: ResolverTypeWrapper<TravelsOnFacilitiesMinAggregate>;
  TravelsOnFacilitiesScalarWhereInput: TravelsOnFacilitiesScalarWhereInput;
  TravelsOnFacilitiesTravelIdFacilityIdCompoundUniqueInput: TravelsOnFacilitiesTravelIdFacilityIdCompoundUniqueInput;
  TravelsOnFacilitiesUpdateManyMutationInput: TravelsOnFacilitiesUpdateManyMutationInput;
  TravelsOnFacilitiesUpdateManyWithWhereWithoutFacilityInput: TravelsOnFacilitiesUpdateManyWithWhereWithoutFacilityInput;
  TravelsOnFacilitiesUpdateManyWithWhereWithoutTravelInput: TravelsOnFacilitiesUpdateManyWithWhereWithoutTravelInput;
  TravelsOnFacilitiesUpdateManyWithoutFacilityNestedInput: TravelsOnFacilitiesUpdateManyWithoutFacilityNestedInput;
  TravelsOnFacilitiesUpdateManyWithoutTravelNestedInput: TravelsOnFacilitiesUpdateManyWithoutTravelNestedInput;
  TravelsOnFacilitiesUpdateWithWhereUniqueWithoutFacilityInput: TravelsOnFacilitiesUpdateWithWhereUniqueWithoutFacilityInput;
  TravelsOnFacilitiesUpdateWithWhereUniqueWithoutTravelInput: TravelsOnFacilitiesUpdateWithWhereUniqueWithoutTravelInput;
  TravelsOnFacilitiesUpdateWithoutFacilityInput: TravelsOnFacilitiesUpdateWithoutFacilityInput;
  TravelsOnFacilitiesUpdateWithoutTravelInput: TravelsOnFacilitiesUpdateWithoutTravelInput;
  TravelsOnFacilitiesUpsertWithWhereUniqueWithoutFacilityInput: TravelsOnFacilitiesUpsertWithWhereUniqueWithoutFacilityInput;
  TravelsOnFacilitiesUpsertWithWhereUniqueWithoutTravelInput: TravelsOnFacilitiesUpsertWithWhereUniqueWithoutTravelInput;
  TravelsOnFacilitiesWhereInput: TravelsOnFacilitiesWhereInput;
  TravelsOnFacilitiesWhereUniqueInput: TravelsOnFacilitiesWhereUniqueInput;
  UniqueWisdom: ResolverTypeWrapper<UniqueWisdom>;
  UniqueWisdomCountAggregate: ResolverTypeWrapper<UniqueWisdomCountAggregate>;
  UniqueWisdomCreateManyWisdomInput: UniqueWisdomCreateManyWisdomInput;
  UniqueWisdomCreateManyWisdomInputEnvelope: UniqueWisdomCreateManyWisdomInputEnvelope;
  UniqueWisdomCreateNestedManyWithoutWisdomInput: UniqueWisdomCreateNestedManyWithoutWisdomInput;
  UniqueWisdomCreateOrConnectWithoutWisdomInput: UniqueWisdomCreateOrConnectWithoutWisdomInput;
  UniqueWisdomCreateWithoutWisdomInput: UniqueWisdomCreateWithoutWisdomInput;
  UniqueWisdomListRelationFilter: UniqueWisdomListRelationFilter;
  UniqueWisdomMaxAggregate: ResolverTypeWrapper<UniqueWisdomMaxAggregate>;
  UniqueWisdomMinAggregate: ResolverTypeWrapper<UniqueWisdomMinAggregate>;
  UniqueWisdomScalarWhereInput: UniqueWisdomScalarWhereInput;
  UniqueWisdomUpdateManyMutationInput: UniqueWisdomUpdateManyMutationInput;
  UniqueWisdomUpdateManyWithWhereWithoutWisdomInput: UniqueWisdomUpdateManyWithWhereWithoutWisdomInput;
  UniqueWisdomUpdateManyWithoutWisdomNestedInput: UniqueWisdomUpdateManyWithoutWisdomNestedInput;
  UniqueWisdomUpdateWithWhereUniqueWithoutWisdomInput: UniqueWisdomUpdateWithWhereUniqueWithoutWisdomInput;
  UniqueWisdomUpdateWithoutWisdomInput: UniqueWisdomUpdateWithoutWisdomInput;
  UniqueWisdomUpsertWithWhereUniqueWithoutWisdomInput: UniqueWisdomUpsertWithWhereUniqueWithoutWisdomInput;
  UniqueWisdomWhereInput: UniqueWisdomWhereInput;
  UniqueWisdomWhereUniqueInput: UniqueWisdomWhereUniqueInput;
  User: ResolverTypeWrapper<User>;
  UserCount: ResolverTypeWrapper<UserCount>;
  UserCountAggregate: ResolverTypeWrapper<UserCountAggregate>;
  UserCreateInput: UserCreateInput;
  UserCreateNestedOneWithoutFoodsInput: UserCreateNestedOneWithoutFoodsInput;
  UserCreateNestedOneWithoutPlantsInput: UserCreateNestedOneWithoutPlantsInput;
  UserCreateNestedOneWithoutTravelsInput: UserCreateNestedOneWithoutTravelsInput;
  UserCreateNestedOneWithoutWisdomsInput: UserCreateNestedOneWithoutWisdomsInput;
  UserCreateOrConnectWithoutFoodsInput: UserCreateOrConnectWithoutFoodsInput;
  UserCreateOrConnectWithoutPlantsInput: UserCreateOrConnectWithoutPlantsInput;
  UserCreateOrConnectWithoutTravelsInput: UserCreateOrConnectWithoutTravelsInput;
  UserCreateOrConnectWithoutWisdomsInput: UserCreateOrConnectWithoutWisdomsInput;
  UserCreateWithoutFoodsInput: UserCreateWithoutFoodsInput;
  UserCreateWithoutPlantsInput: UserCreateWithoutPlantsInput;
  UserCreateWithoutTravelsInput: UserCreateWithoutTravelsInput;
  UserCreateWithoutWisdomsInput: UserCreateWithoutWisdomsInput;
  UserFilter: UserFilter;
  UserMaxAggregate: ResolverTypeWrapper<UserMaxAggregate>;
  UserMinAggregate: ResolverTypeWrapper<UserMinAggregate>;
  UserRelationFilter: UserRelationFilter;
  UserUpdateInput: UserUpdateInput;
  UserUpdateOneRequiredWithoutFoodsNestedInput: UserUpdateOneRequiredWithoutFoodsNestedInput;
  UserUpdateOneRequiredWithoutPlantsNestedInput: UserUpdateOneRequiredWithoutPlantsNestedInput;
  UserUpdateOneRequiredWithoutTravelsNestedInput: UserUpdateOneRequiredWithoutTravelsNestedInput;
  UserUpdateOneRequiredWithoutWisdomsNestedInput: UserUpdateOneRequiredWithoutWisdomsNestedInput;
  UserUpdateToOneWithWhereWithoutFoodsInput: UserUpdateToOneWithWhereWithoutFoodsInput;
  UserUpdateToOneWithWhereWithoutPlantsInput: UserUpdateToOneWithWhereWithoutPlantsInput;
  UserUpdateToOneWithWhereWithoutTravelsInput: UserUpdateToOneWithWhereWithoutTravelsInput;
  UserUpdateToOneWithWhereWithoutWisdomsInput: UserUpdateToOneWithWhereWithoutWisdomsInput;
  UserUpdateWithoutFoodsInput: UserUpdateWithoutFoodsInput;
  UserUpdateWithoutPlantsInput: UserUpdateWithoutPlantsInput;
  UserUpdateWithoutTravelsInput: UserUpdateWithoutTravelsInput;
  UserUpdateWithoutWisdomsInput: UserUpdateWithoutWisdomsInput;
  UserUpsertWithoutFoodsInput: UserUpsertWithoutFoodsInput;
  UserUpsertWithoutPlantsInput: UserUpsertWithoutPlantsInput;
  UserUpsertWithoutTravelsInput: UserUpsertWithoutTravelsInput;
  UserUpsertWithoutWisdomsInput: UserUpsertWithoutWisdomsInput;
  UserWhereInput: UserWhereInput;
  UserWhereUniqueInput: UserWhereUniqueInput;
  VerificationTokenCountAggregate: ResolverTypeWrapper<VerificationTokenCountAggregate>;
  VerificationTokenMaxAggregate: ResolverTypeWrapper<VerificationTokenMaxAggregate>;
  VerificationTokenMinAggregate: ResolverTypeWrapper<VerificationTokenMinAggregate>;
  Website: ResolverTypeWrapper<Website>;
  WebsiteCountAggregate: ResolverTypeWrapper<WebsiteCountAggregate>;
  WebsiteCreateManyTravelInput: WebsiteCreateManyTravelInput;
  WebsiteCreateManyTravelInputEnvelope: WebsiteCreateManyTravelInputEnvelope;
  WebsiteCreateNestedManyWithoutTravelInput: WebsiteCreateNestedManyWithoutTravelInput;
  WebsiteCreateOrConnectWithoutTravelInput: WebsiteCreateOrConnectWithoutTravelInput;
  WebsiteCreateWithoutTravelInput: WebsiteCreateWithoutTravelInput;
  WebsiteListRelationFilter: WebsiteListRelationFilter;
  WebsiteMaxAggregate: ResolverTypeWrapper<WebsiteMaxAggregate>;
  WebsiteMinAggregate: ResolverTypeWrapper<WebsiteMinAggregate>;
  WebsiteScalarWhereInput: WebsiteScalarWhereInput;
  WebsiteUpdateManyMutationInput: WebsiteUpdateManyMutationInput;
  WebsiteUpdateManyWithWhereWithoutTravelInput: WebsiteUpdateManyWithWhereWithoutTravelInput;
  WebsiteUpdateManyWithoutTravelNestedInput: WebsiteUpdateManyWithoutTravelNestedInput;
  WebsiteUpdateWithWhereUniqueWithoutTravelInput: WebsiteUpdateWithWhereUniqueWithoutTravelInput;
  WebsiteUpdateWithoutTravelInput: WebsiteUpdateWithoutTravelInput;
  WebsiteUpsertWithWhereUniqueWithoutTravelInput: WebsiteUpsertWithWhereUniqueWithoutTravelInput;
  WebsiteWhereInput: WebsiteWhereInput;
  WebsiteWhereUniqueInput: WebsiteWhereUniqueInput;
  Wisdom: ResolverTypeWrapper<Wisdom>;
  WisdomAvgAggregate: ResolverTypeWrapper<WisdomAvgAggregate>;
  WisdomCategory: ResolverTypeWrapper<WisdomCategory>;
  WisdomCategoryCount: ResolverTypeWrapper<WisdomCategoryCount>;
  WisdomCategoryCountAggregate: ResolverTypeWrapper<WisdomCategoryCountAggregate>;
  WisdomCategoryCreateManyTambonInput: WisdomCategoryCreateManyTambonInput;
  WisdomCategoryCreateManyTambonInputEnvelope: WisdomCategoryCreateManyTambonInputEnvelope;
  WisdomCategoryCreateNestedManyWithoutTambonInput: WisdomCategoryCreateNestedManyWithoutTambonInput;
  WisdomCategoryCreateNestedOneWithoutWisdomsInput: WisdomCategoryCreateNestedOneWithoutWisdomsInput;
  WisdomCategoryCreateOrConnectWithoutTambonInput: WisdomCategoryCreateOrConnectWithoutTambonInput;
  WisdomCategoryCreateOrConnectWithoutWisdomsInput: WisdomCategoryCreateOrConnectWithoutWisdomsInput;
  WisdomCategoryCreateWithoutTambonInput: WisdomCategoryCreateWithoutTambonInput;
  WisdomCategoryCreateWithoutWisdomsInput: WisdomCategoryCreateWithoutWisdomsInput;
  WisdomCategoryListRelationFilter: WisdomCategoryListRelationFilter;
  WisdomCategoryMaxAggregate: ResolverTypeWrapper<WisdomCategoryMaxAggregate>;
  WisdomCategoryMinAggregate: ResolverTypeWrapper<WisdomCategoryMinAggregate>;
  WisdomCategoryRelationFilter: WisdomCategoryRelationFilter;
  WisdomCategoryScalarWhereInput: WisdomCategoryScalarWhereInput;
  WisdomCategoryUpdateManyMutationInput: WisdomCategoryUpdateManyMutationInput;
  WisdomCategoryUpdateManyWithWhereWithoutTambonInput: WisdomCategoryUpdateManyWithWhereWithoutTambonInput;
  WisdomCategoryUpdateManyWithoutTambonNestedInput: WisdomCategoryUpdateManyWithoutTambonNestedInput;
  WisdomCategoryUpdateOneRequiredWithoutWisdomsNestedInput: WisdomCategoryUpdateOneRequiredWithoutWisdomsNestedInput;
  WisdomCategoryUpdateToOneWithWhereWithoutWisdomsInput: WisdomCategoryUpdateToOneWithWhereWithoutWisdomsInput;
  WisdomCategoryUpdateWithWhereUniqueWithoutTambonInput: WisdomCategoryUpdateWithWhereUniqueWithoutTambonInput;
  WisdomCategoryUpdateWithoutTambonInput: WisdomCategoryUpdateWithoutTambonInput;
  WisdomCategoryUpdateWithoutWisdomsInput: WisdomCategoryUpdateWithoutWisdomsInput;
  WisdomCategoryUpsertWithWhereUniqueWithoutTambonInput: WisdomCategoryUpsertWithWhereUniqueWithoutTambonInput;
  WisdomCategoryUpsertWithoutWisdomsInput: WisdomCategoryUpsertWithoutWisdomsInput;
  WisdomCategoryWhereInput: WisdomCategoryWhereInput;
  WisdomCategoryWhereUniqueInput: WisdomCategoryWhereUniqueInput;
  WisdomCount: ResolverTypeWrapper<WisdomCount>;
  WisdomCountAggregate: ResolverTypeWrapper<WisdomCountAggregate>;
  WisdomCreateInput: WisdomCreateInput;
  WisdomCreateManyCategoryInput: WisdomCreateManyCategoryInput;
  WisdomCreateManyCategoryInputEnvelope: WisdomCreateManyCategoryInputEnvelope;
  WisdomCreateManyTambonInput: WisdomCreateManyTambonInput;
  WisdomCreateManyTambonInputEnvelope: WisdomCreateManyTambonInputEnvelope;
  WisdomCreateManyUserInput: WisdomCreateManyUserInput;
  WisdomCreateManyUserInputEnvelope: WisdomCreateManyUserInputEnvelope;
  WisdomCreateNestedManyWithoutCategoryInput: WisdomCreateNestedManyWithoutCategoryInput;
  WisdomCreateNestedManyWithoutTambonInput: WisdomCreateNestedManyWithoutTambonInput;
  WisdomCreateNestedManyWithoutUserInput: WisdomCreateNestedManyWithoutUserInput;
  WisdomCreateOrConnectWithoutCategoryInput: WisdomCreateOrConnectWithoutCategoryInput;
  WisdomCreateOrConnectWithoutTambonInput: WisdomCreateOrConnectWithoutTambonInput;
  WisdomCreateOrConnectWithoutUserInput: WisdomCreateOrConnectWithoutUserInput;
  WisdomCreateWithoutCategoryInput: WisdomCreateWithoutCategoryInput;
  WisdomCreateWithoutTambonInput: WisdomCreateWithoutTambonInput;
  WisdomCreateWithoutUserInput: WisdomCreateWithoutUserInput;
  WisdomImage: ResolverTypeWrapper<WisdomImage>;
  WisdomImageCountAggregate: ResolverTypeWrapper<WisdomImageCountAggregate>;
  WisdomImageCreateManyWisdomInput: WisdomImageCreateManyWisdomInput;
  WisdomImageCreateManyWisdomInputEnvelope: WisdomImageCreateManyWisdomInputEnvelope;
  WisdomImageCreateNestedManyWithoutWisdomInput: WisdomImageCreateNestedManyWithoutWisdomInput;
  WisdomImageCreateOrConnectWithoutWisdomInput: WisdomImageCreateOrConnectWithoutWisdomInput;
  WisdomImageCreateWithoutWisdomInput: WisdomImageCreateWithoutWisdomInput;
  WisdomImageListRelationFilter: WisdomImageListRelationFilter;
  WisdomImageMaxAggregate: ResolverTypeWrapper<WisdomImageMaxAggregate>;
  WisdomImageMinAggregate: ResolverTypeWrapper<WisdomImageMinAggregate>;
  WisdomImageScalarWhereInput: WisdomImageScalarWhereInput;
  WisdomImageUpdateManyMutationInput: WisdomImageUpdateManyMutationInput;
  WisdomImageUpdateManyWithWhereWithoutWisdomInput: WisdomImageUpdateManyWithWhereWithoutWisdomInput;
  WisdomImageUpdateManyWithoutWisdomNestedInput: WisdomImageUpdateManyWithoutWisdomNestedInput;
  WisdomImageUpdateWithWhereUniqueWithoutWisdomInput: WisdomImageUpdateWithWhereUniqueWithoutWisdomInput;
  WisdomImageUpdateWithoutWisdomInput: WisdomImageUpdateWithoutWisdomInput;
  WisdomImageUpsertWithWhereUniqueWithoutWisdomInput: WisdomImageUpsertWithWhereUniqueWithoutWisdomInput;
  WisdomImageWhereInput: WisdomImageWhereInput;
  WisdomImageWhereUniqueInput: WisdomImageWhereUniqueInput;
  WisdomListRelationFilter: WisdomListRelationFilter;
  WisdomMaxAggregate: ResolverTypeWrapper<WisdomMaxAggregate>;
  WisdomMinAggregate: ResolverTypeWrapper<WisdomMinAggregate>;
  WisdomNullableRelationFilter: WisdomNullableRelationFilter;
  WisdomPR: WisdomPr;
  WisdomRelationFilter: WisdomRelationFilter;
  WisdomScalarWhereInput: WisdomScalarWhereInput;
  WisdomStatus: WisdomStatus;
  WisdomSumAggregate: ResolverTypeWrapper<WisdomSumAggregate>;
  WisdomTool: ResolverTypeWrapper<WisdomTool>;
  WisdomToolCountAggregate: ResolverTypeWrapper<WisdomToolCountAggregate>;
  WisdomToolCreateManyWisdomInput: WisdomToolCreateManyWisdomInput;
  WisdomToolCreateManyWisdomInputEnvelope: WisdomToolCreateManyWisdomInputEnvelope;
  WisdomToolCreateNestedManyWithoutWisdomInput: WisdomToolCreateNestedManyWithoutWisdomInput;
  WisdomToolCreateOrConnectWithoutWisdomInput: WisdomToolCreateOrConnectWithoutWisdomInput;
  WisdomToolCreateWithoutWisdomInput: WisdomToolCreateWithoutWisdomInput;
  WisdomToolListRelationFilter: WisdomToolListRelationFilter;
  WisdomToolMaxAggregate: ResolverTypeWrapper<WisdomToolMaxAggregate>;
  WisdomToolMinAggregate: ResolverTypeWrapper<WisdomToolMinAggregate>;
  WisdomToolScalarWhereInput: WisdomToolScalarWhereInput;
  WisdomToolUpdateManyMutationInput: WisdomToolUpdateManyMutationInput;
  WisdomToolUpdateManyWithWhereWithoutWisdomInput: WisdomToolUpdateManyWithWhereWithoutWisdomInput;
  WisdomToolUpdateManyWithoutWisdomNestedInput: WisdomToolUpdateManyWithoutWisdomNestedInput;
  WisdomToolUpdateWithWhereUniqueWithoutWisdomInput: WisdomToolUpdateWithWhereUniqueWithoutWisdomInput;
  WisdomToolUpdateWithoutWisdomInput: WisdomToolUpdateWithoutWisdomInput;
  WisdomToolUpsertWithWhereUniqueWithoutWisdomInput: WisdomToolUpsertWithWhereUniqueWithoutWisdomInput;
  WisdomToolWhereInput: WisdomToolWhereInput;
  WisdomToolWhereUniqueInput: WisdomToolWhereUniqueInput;
  WisdomUpdateInput: WisdomUpdateInput;
  WisdomUpdateManyMutationInput: WisdomUpdateManyMutationInput;
  WisdomUpdateManyWithWhereWithoutCategoryInput: WisdomUpdateManyWithWhereWithoutCategoryInput;
  WisdomUpdateManyWithWhereWithoutTambonInput: WisdomUpdateManyWithWhereWithoutTambonInput;
  WisdomUpdateManyWithWhereWithoutUserInput: WisdomUpdateManyWithWhereWithoutUserInput;
  WisdomUpdateManyWithoutCategoryNestedInput: WisdomUpdateManyWithoutCategoryNestedInput;
  WisdomUpdateManyWithoutTambonNestedInput: WisdomUpdateManyWithoutTambonNestedInput;
  WisdomUpdateManyWithoutUserNestedInput: WisdomUpdateManyWithoutUserNestedInput;
  WisdomUpdateWithWhereUniqueWithoutCategoryInput: WisdomUpdateWithWhereUniqueWithoutCategoryInput;
  WisdomUpdateWithWhereUniqueWithoutTambonInput: WisdomUpdateWithWhereUniqueWithoutTambonInput;
  WisdomUpdateWithWhereUniqueWithoutUserInput: WisdomUpdateWithWhereUniqueWithoutUserInput;
  WisdomUpdateWithoutCategoryInput: WisdomUpdateWithoutCategoryInput;
  WisdomUpdateWithoutTambonInput: WisdomUpdateWithoutTambonInput;
  WisdomUpdateWithoutUserInput: WisdomUpdateWithoutUserInput;
  WisdomUpsertWithWhereUniqueWithoutCategoryInput: WisdomUpsertWithWhereUniqueWithoutCategoryInput;
  WisdomUpsertWithWhereUniqueWithoutTambonInput: WisdomUpsertWithWhereUniqueWithoutTambonInput;
  WisdomUpsertWithWhereUniqueWithoutUserInput: WisdomUpsertWithWhereUniqueWithoutUserInput;
  WisdomWhereInput: WisdomWhereInput;
  WisdomWhereUniqueInput: WisdomWhereUniqueInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Address: Address;
  AddressCountAggregate: AddressCountAggregate;
  AddressCreateManyTambonInput: AddressCreateManyTambonInput;
  AddressCreateManyTambonInputEnvelope: AddressCreateManyTambonInputEnvelope;
  AddressCreateNestedManyWithoutTambonInput: AddressCreateNestedManyWithoutTambonInput;
  AddressCreateOrConnectWithoutTambonInput: AddressCreateOrConnectWithoutTambonInput;
  AddressCreateWithoutTambonInput: AddressCreateWithoutTambonInput;
  AddressListRelationFilter: AddressListRelationFilter;
  AddressMaxAggregate: AddressMaxAggregate;
  AddressMinAggregate: AddressMinAggregate;
  AddressScalarWhereInput: AddressScalarWhereInput;
  AddressUpdateManyMutationInput: AddressUpdateManyMutationInput;
  AddressUpdateManyWithWhereWithoutTambonInput: AddressUpdateManyWithWhereWithoutTambonInput;
  AddressUpdateManyWithoutTambonNestedInput: AddressUpdateManyWithoutTambonNestedInput;
  AddressUpdateWithWhereUniqueWithoutTambonInput: AddressUpdateWithWhereUniqueWithoutTambonInput;
  AddressUpdateWithoutTambonInput: AddressUpdateWithoutTambonInput;
  AddressUpsertWithWhereUniqueWithoutTambonInput: AddressUpsertWithWhereUniqueWithoutTambonInput;
  AddressWhereInput: AddressWhereInput;
  AddressWhereUniqueInput: AddressWhereUniqueInput;
  Amphoe: Amphoe;
  AmphoeCount: AmphoeCount;
  AmphoeCountAggregate: AmphoeCountAggregate;
  AmphoeCreateNestedOneWithoutTambonsInput: AmphoeCreateNestedOneWithoutTambonsInput;
  AmphoeCreateOrConnectWithoutTambonsInput: AmphoeCreateOrConnectWithoutTambonsInput;
  AmphoeCreateWithoutTambonsInput: AmphoeCreateWithoutTambonsInput;
  AmphoeListRelationFilter: AmphoeListRelationFilter;
  AmphoeMaxAggregate: AmphoeMaxAggregate;
  AmphoeMinAggregate: AmphoeMinAggregate;
  AmphoeNullableRelationFilter: AmphoeNullableRelationFilter;
  AmphoeUpdateOneWithoutTambonsNestedInput: AmphoeUpdateOneWithoutTambonsNestedInput;
  AmphoeUpdateToOneWithWhereWithoutTambonsInput: AmphoeUpdateToOneWithWhereWithoutTambonsInput;
  AmphoeUpdateWithoutTambonsInput: AmphoeUpdateWithoutTambonsInput;
  AmphoeUpsertWithoutTambonsInput: AmphoeUpsertWithoutTambonsInput;
  AmphoeWhereInput: AmphoeWhereInput;
  AmphoeWhereUniqueInput: AmphoeWhereUniqueInput;
  BoolFieldUpdateOperationsInput: BoolFieldUpdateOperationsInput;
  BoolFilter: BoolFilter;
  Boolean: Scalars['Boolean']['output'];
  ContactNumber: ContactNumber;
  ContactNumberCountAggregate: ContactNumberCountAggregate;
  ContactNumberCreateManyTravelInput: ContactNumberCreateManyTravelInput;
  ContactNumberCreateManyTravelInputEnvelope: ContactNumberCreateManyTravelInputEnvelope;
  ContactNumberCreateNestedManyWithoutTravelInput: ContactNumberCreateNestedManyWithoutTravelInput;
  ContactNumberCreateOrConnectWithoutTravelInput: ContactNumberCreateOrConnectWithoutTravelInput;
  ContactNumberCreateWithoutTravelInput: ContactNumberCreateWithoutTravelInput;
  ContactNumberListRelationFilter: ContactNumberListRelationFilter;
  ContactNumberMaxAggregate: ContactNumberMaxAggregate;
  ContactNumberMinAggregate: ContactNumberMinAggregate;
  ContactNumberScalarWhereInput: ContactNumberScalarWhereInput;
  ContactNumberUpdateManyMutationInput: ContactNumberUpdateManyMutationInput;
  ContactNumberUpdateManyWithWhereWithoutTravelInput: ContactNumberUpdateManyWithWhereWithoutTravelInput;
  ContactNumberUpdateManyWithoutTravelNestedInput: ContactNumberUpdateManyWithoutTravelNestedInput;
  ContactNumberUpdateWithWhereUniqueWithoutTravelInput: ContactNumberUpdateWithWhereUniqueWithoutTravelInput;
  ContactNumberUpdateWithoutTravelInput: ContactNumberUpdateWithoutTravelInput;
  ContactNumberUpsertWithWhereUniqueWithoutTravelInput: ContactNumberUpsertWithWhereUniqueWithoutTravelInput;
  ContactNumberWhereInput: ContactNumberWhereInput;
  ContactNumberWhereUniqueInput: ContactNumberWhereUniqueInput;
  DateTime: Scalars['DateTime']['output'];
  DateTimeFieldUpdateOperationsInput: DateTimeFieldUpdateOperationsInput;
  DateTimeFilter: DateTimeFilter;
  DateTimeNullableFilter: DateTimeNullableFilter;
  EnumHasOwnerFieldUpdateOperationsInput: EnumHasOwnerFieldUpdateOperationsInput;
  EnumHasOwnerFilter: EnumHasOwnerFilter;
  EnumHasParkingNullableFilter: EnumHasParkingNullableFilter;
  EnumPatentedStatusFieldUpdateOperationsInput: EnumPatentedStatusFieldUpdateOperationsInput;
  EnumPatentedStatusFilter: EnumPatentedStatusFilter;
  EnumPlantImageKindFieldUpdateOperationsInput: EnumPlantImageKindFieldUpdateOperationsInput;
  EnumPlantImageKindFilter: EnumPlantImageKindFilter;
  EnumRoleFieldUpdateOperationsInput: EnumRoleFieldUpdateOperationsInput;
  EnumRoleFilter: EnumRoleFilter;
  EnumWisdomPRFieldUpdateOperationsInput: EnumWisdomPrFieldUpdateOperationsInput;
  EnumWisdomPRFilter: EnumWisdomPrFilter;
  EnumWisdomStatusFieldUpdateOperationsInput: EnumWisdomStatusFieldUpdateOperationsInput;
  EnumWisdomStatusFilter: EnumWisdomStatusFilter;
  Facility: Facility;
  FacilityCount: FacilityCount;
  FacilityCountAggregate: FacilityCountAggregate;
  FacilityCreateInput: FacilityCreateInput;
  FacilityCreateNestedOneWithoutTravelsInput: FacilityCreateNestedOneWithoutTravelsInput;
  FacilityCreateOrConnectWithoutTravelsInput: FacilityCreateOrConnectWithoutTravelsInput;
  FacilityCreateWithoutTravelsInput: FacilityCreateWithoutTravelsInput;
  FacilityMaxAggregate: FacilityMaxAggregate;
  FacilityMinAggregate: FacilityMinAggregate;
  FacilityRelationFilter: FacilityRelationFilter;
  FacilityUpdateInput: FacilityUpdateInput;
  FacilityUpdateOneRequiredWithoutTravelsNestedInput: FacilityUpdateOneRequiredWithoutTravelsNestedInput;
  FacilityUpdateToOneWithWhereWithoutTravelsInput: FacilityUpdateToOneWithWhereWithoutTravelsInput;
  FacilityUpdateWithoutTravelsInput: FacilityUpdateWithoutTravelsInput;
  FacilityUpsertWithoutTravelsInput: FacilityUpsertWithoutTravelsInput;
  FacilityWhereInput: FacilityWhereInput;
  FacilityWhereUniqueInput: FacilityWhereUniqueInput;
  Filter: Filter;
  Float: Scalars['Float']['output'];
  FloatFieldUpdateOperationsInput: FloatFieldUpdateOperationsInput;
  FloatFilter: FloatFilter;
  Food: Food;
  FoodAvgAggregate: FoodAvgAggregate;
  FoodCategory: FoodCategory;
  FoodCategoryCount: FoodCategoryCount;
  FoodCategoryCountAggregate: FoodCategoryCountAggregate;
  FoodCategoryCreateNestedOneWithoutFoodInput: FoodCategoryCreateNestedOneWithoutFoodInput;
  FoodCategoryCreateOrConnectWithoutFoodInput: FoodCategoryCreateOrConnectWithoutFoodInput;
  FoodCategoryCreateWithoutFoodInput: FoodCategoryCreateWithoutFoodInput;
  FoodCategoryMaxAggregate: FoodCategoryMaxAggregate;
  FoodCategoryMinAggregate: FoodCategoryMinAggregate;
  FoodCategoryNullableRelationFilter: FoodCategoryNullableRelationFilter;
  FoodCategoryUpdateOneWithoutFoodNestedInput: FoodCategoryUpdateOneWithoutFoodNestedInput;
  FoodCategoryUpdateToOneWithWhereWithoutFoodInput: FoodCategoryUpdateToOneWithWhereWithoutFoodInput;
  FoodCategoryUpdateWithoutFoodInput: FoodCategoryUpdateWithoutFoodInput;
  FoodCategoryUpsertWithoutFoodInput: FoodCategoryUpsertWithoutFoodInput;
  FoodCategoryWhereInput: FoodCategoryWhereInput;
  FoodCategoryWhereUniqueInput: FoodCategoryWhereUniqueInput;
  FoodCount: FoodCount;
  FoodCountAggregate: FoodCountAggregate;
  FoodCreateInput: FoodCreateInput;
  FoodCreateManyTambonInput: FoodCreateManyTambonInput;
  FoodCreateManyTambonInputEnvelope: FoodCreateManyTambonInputEnvelope;
  FoodCreateManyUserInput: FoodCreateManyUserInput;
  FoodCreateManyUserInputEnvelope: FoodCreateManyUserInputEnvelope;
  FoodCreateNestedManyWithoutTambonInput: FoodCreateNestedManyWithoutTambonInput;
  FoodCreateNestedManyWithoutUserInput: FoodCreateNestedManyWithoutUserInput;
  FoodCreateNestedOneWithoutImagesInput: FoodCreateNestedOneWithoutImagesInput;
  FoodCreateOrConnectWithoutImagesInput: FoodCreateOrConnectWithoutImagesInput;
  FoodCreateOrConnectWithoutTambonInput: FoodCreateOrConnectWithoutTambonInput;
  FoodCreateOrConnectWithoutUserInput: FoodCreateOrConnectWithoutUserInput;
  FoodCreateWithoutImagesInput: FoodCreateWithoutImagesInput;
  FoodCreateWithoutTambonInput: FoodCreateWithoutTambonInput;
  FoodCreateWithoutUserInput: FoodCreateWithoutUserInput;
  FoodImage: FoodImage;
  FoodImageCountAggregate: FoodImageCountAggregate;
  FoodImageCreateInput: FoodImageCreateInput;
  FoodImageCreateManyFoodInput: FoodImageCreateManyFoodInput;
  FoodImageCreateManyFoodInputEnvelope: FoodImageCreateManyFoodInputEnvelope;
  FoodImageCreateNestedManyWithoutFoodInput: FoodImageCreateNestedManyWithoutFoodInput;
  FoodImageCreateOrConnectWithoutFoodInput: FoodImageCreateOrConnectWithoutFoodInput;
  FoodImageCreateWithoutFoodInput: FoodImageCreateWithoutFoodInput;
  FoodImageListRelationFilter: FoodImageListRelationFilter;
  FoodImageMaxAggregate: FoodImageMaxAggregate;
  FoodImageMinAggregate: FoodImageMinAggregate;
  FoodImageScalarWhereInput: FoodImageScalarWhereInput;
  FoodImageUpdateManyMutationInput: FoodImageUpdateManyMutationInput;
  FoodImageUpdateManyWithWhereWithoutFoodInput: FoodImageUpdateManyWithWhereWithoutFoodInput;
  FoodImageUpdateManyWithoutFoodNestedInput: FoodImageUpdateManyWithoutFoodNestedInput;
  FoodImageUpdateWithWhereUniqueWithoutFoodInput: FoodImageUpdateWithWhereUniqueWithoutFoodInput;
  FoodImageUpdateWithoutFoodInput: FoodImageUpdateWithoutFoodInput;
  FoodImageUpsertWithWhereUniqueWithoutFoodInput: FoodImageUpsertWithWhereUniqueWithoutFoodInput;
  FoodImageWhereInput: FoodImageWhereInput;
  FoodImageWhereUniqueInput: FoodImageWhereUniqueInput;
  FoodListRelationFilter: FoodListRelationFilter;
  FoodMaxAggregate: FoodMaxAggregate;
  FoodMinAggregate: FoodMinAggregate;
  FoodNullableRelationFilter: FoodNullableRelationFilter;
  FoodRelationFilter: FoodRelationFilter;
  FoodScalarWhereInput: FoodScalarWhereInput;
  FoodSumAggregate: FoodSumAggregate;
  FoodUpdateInput: FoodUpdateInput;
  FoodUpdateManyMutationInput: FoodUpdateManyMutationInput;
  FoodUpdateManyWithWhereWithoutTambonInput: FoodUpdateManyWithWhereWithoutTambonInput;
  FoodUpdateManyWithWhereWithoutUserInput: FoodUpdateManyWithWhereWithoutUserInput;
  FoodUpdateManyWithoutTambonNestedInput: FoodUpdateManyWithoutTambonNestedInput;
  FoodUpdateManyWithoutUserNestedInput: FoodUpdateManyWithoutUserNestedInput;
  FoodUpdateWithWhereUniqueWithoutTambonInput: FoodUpdateWithWhereUniqueWithoutTambonInput;
  FoodUpdateWithWhereUniqueWithoutUserInput: FoodUpdateWithWhereUniqueWithoutUserInput;
  FoodUpdateWithoutTambonInput: FoodUpdateWithoutTambonInput;
  FoodUpdateWithoutUserInput: FoodUpdateWithoutUserInput;
  FoodUpsertWithWhereUniqueWithoutTambonInput: FoodUpsertWithWhereUniqueWithoutTambonInput;
  FoodUpsertWithWhereUniqueWithoutUserInput: FoodUpsertWithWhereUniqueWithoutUserInput;
  FoodWhereInput: FoodWhereInput;
  FoodWhereUniqueInput: FoodWhereUniqueInput;
  ID: Scalars['ID']['output'];
  Ingredients: Ingredients;
  IngredientsCountAggregate: IngredientsCountAggregate;
  IngredientsCreateManyFoodInput: IngredientsCreateManyFoodInput;
  IngredientsCreateManyFoodInputEnvelope: IngredientsCreateManyFoodInputEnvelope;
  IngredientsCreateNestedManyWithoutFoodInput: IngredientsCreateNestedManyWithoutFoodInput;
  IngredientsCreateOrConnectWithoutFoodInput: IngredientsCreateOrConnectWithoutFoodInput;
  IngredientsCreateWithoutFoodInput: IngredientsCreateWithoutFoodInput;
  IngredientsListRelationFilter: IngredientsListRelationFilter;
  IngredientsMaxAggregate: IngredientsMaxAggregate;
  IngredientsMinAggregate: IngredientsMinAggregate;
  IngredientsScalarWhereInput: IngredientsScalarWhereInput;
  IngredientsUpdateManyMutationInput: IngredientsUpdateManyMutationInput;
  IngredientsUpdateManyWithWhereWithoutFoodInput: IngredientsUpdateManyWithWhereWithoutFoodInput;
  IngredientsUpdateManyWithoutFoodNestedInput: IngredientsUpdateManyWithoutFoodNestedInput;
  IngredientsUpdateWithWhereUniqueWithoutFoodInput: IngredientsUpdateWithWhereUniqueWithoutFoodInput;
  IngredientsUpdateWithoutFoodInput: IngredientsUpdateWithoutFoodInput;
  IngredientsUpsertWithWhereUniqueWithoutFoodInput: IngredientsUpsertWithWhereUniqueWithoutFoodInput;
  IngredientsWhereInput: IngredientsWhereInput;
  IngredientsWhereUniqueInput: IngredientsWhereUniqueInput;
  InputAmphoeIdAndKeyword: InputAmphoeIdAndKeyword;
  InputProvinceIdAndKeyword: InputProvinceIdAndKeyword;
  Int: Scalars['Int']['output'];
  IntFieldUpdateOperationsInput: IntFieldUpdateOperationsInput;
  IntFilter: IntFilter;
  IntNullableFilter: IntNullableFilter;
  JSON: Scalars['JSON']['output'];
  Mutation: {};
  NestedBoolFilter: NestedBoolFilter;
  NestedDateTimeFilter: NestedDateTimeFilter;
  NestedDateTimeNullableFilter: NestedDateTimeNullableFilter;
  NestedEnumHasOwnerFilter: NestedEnumHasOwnerFilter;
  NestedEnumHasParkingNullableFilter: NestedEnumHasParkingNullableFilter;
  NestedEnumPatentedStatusFilter: NestedEnumPatentedStatusFilter;
  NestedEnumPlantImageKindFilter: NestedEnumPlantImageKindFilter;
  NestedEnumRoleFilter: NestedEnumRoleFilter;
  NestedEnumWisdomPRFilter: NestedEnumWisdomPrFilter;
  NestedEnumWisdomStatusFilter: NestedEnumWisdomStatusFilter;
  NestedFloatFilter: NestedFloatFilter;
  NestedIntFilter: NestedIntFilter;
  NestedIntNullableFilter: NestedIntNullableFilter;
  NestedStringFilter: NestedStringFilter;
  NestedStringNullableFilter: NestedStringNullableFilter;
  NullableDateTimeFieldUpdateOperationsInput: NullableDateTimeFieldUpdateOperationsInput;
  NullableEnumHasParkingFieldUpdateOperationsInput: NullableEnumHasParkingFieldUpdateOperationsInput;
  NullableIntFieldUpdateOperationsInput: NullableIntFieldUpdateOperationsInput;
  NullableStringFieldUpdateOperationsInput: NullableStringFieldUpdateOperationsInput;
  Plant: Plant;
  PlantAvgAggregate: PlantAvgAggregate;
  PlantCount: PlantCount;
  PlantCountAggregate: PlantCountAggregate;
  PlantCreateInput: PlantCreateInput;
  PlantCreateManyTambonInput: PlantCreateManyTambonInput;
  PlantCreateManyTambonInputEnvelope: PlantCreateManyTambonInputEnvelope;
  PlantCreateManyUserInput: PlantCreateManyUserInput;
  PlantCreateManyUserInputEnvelope: PlantCreateManyUserInputEnvelope;
  PlantCreateNestedManyWithoutTambonInput: PlantCreateNestedManyWithoutTambonInput;
  PlantCreateNestedManyWithoutUserInput: PlantCreateNestedManyWithoutUserInput;
  PlantCreateOrConnectWithoutTambonInput: PlantCreateOrConnectWithoutTambonInput;
  PlantCreateOrConnectWithoutUserInput: PlantCreateOrConnectWithoutUserInput;
  PlantCreateWithoutTambonInput: PlantCreateWithoutTambonInput;
  PlantCreateWithoutUserInput: PlantCreateWithoutUserInput;
  PlantImage: PlantImage;
  PlantImageCountAggregate: PlantImageCountAggregate;
  PlantImageCreateManyPlantInput: PlantImageCreateManyPlantInput;
  PlantImageCreateManyPlantInputEnvelope: PlantImageCreateManyPlantInputEnvelope;
  PlantImageCreateNestedManyWithoutPlantInput: PlantImageCreateNestedManyWithoutPlantInput;
  PlantImageCreateOrConnectWithoutPlantInput: PlantImageCreateOrConnectWithoutPlantInput;
  PlantImageCreateWithoutPlantInput: PlantImageCreateWithoutPlantInput;
  PlantImageListRelationFilter: PlantImageListRelationFilter;
  PlantImageMaxAggregate: PlantImageMaxAggregate;
  PlantImageMinAggregate: PlantImageMinAggregate;
  PlantImageScalarWhereInput: PlantImageScalarWhereInput;
  PlantImageUpdateManyMutationInput: PlantImageUpdateManyMutationInput;
  PlantImageUpdateManyWithWhereWithoutPlantInput: PlantImageUpdateManyWithWhereWithoutPlantInput;
  PlantImageUpdateManyWithoutPlantNestedInput: PlantImageUpdateManyWithoutPlantNestedInput;
  PlantImageUpdateWithWhereUniqueWithoutPlantInput: PlantImageUpdateWithWhereUniqueWithoutPlantInput;
  PlantImageUpdateWithoutPlantInput: PlantImageUpdateWithoutPlantInput;
  PlantImageUpsertWithWhereUniqueWithoutPlantInput: PlantImageUpsertWithWhereUniqueWithoutPlantInput;
  PlantImageWhereInput: PlantImageWhereInput;
  PlantImageWhereUniqueInput: PlantImageWhereUniqueInput;
  PlantKindOfUse: PlantKindOfUse;
  PlantKindOfUseCount: PlantKindOfUseCount;
  PlantKindOfUseCountAggregate: PlantKindOfUseCountAggregate;
  PlantKindOfUseCreateNestedOneWithoutPlantOnPlantKindOfUsesInput: PlantKindOfUseCreateNestedOneWithoutPlantOnPlantKindOfUsesInput;
  PlantKindOfUseCreateOrConnectWithoutPlantOnPlantKindOfUsesInput: PlantKindOfUseCreateOrConnectWithoutPlantOnPlantKindOfUsesInput;
  PlantKindOfUseCreateWithoutPlantOnPlantKindOfUsesInput: PlantKindOfUseCreateWithoutPlantOnPlantKindOfUsesInput;
  PlantKindOfUseMaxAggregate: PlantKindOfUseMaxAggregate;
  PlantKindOfUseMinAggregate: PlantKindOfUseMinAggregate;
  PlantKindOfUseRelationFilter: PlantKindOfUseRelationFilter;
  PlantKindOfUseUpdateOneRequiredWithoutPlantOnPlantKindOfUsesNestedInput: PlantKindOfUseUpdateOneRequiredWithoutPlantOnPlantKindOfUsesNestedInput;
  PlantKindOfUseUpdateToOneWithWhereWithoutPlantOnPlantKindOfUsesInput: PlantKindOfUseUpdateToOneWithWhereWithoutPlantOnPlantKindOfUsesInput;
  PlantKindOfUseUpdateWithoutPlantOnPlantKindOfUsesInput: PlantKindOfUseUpdateWithoutPlantOnPlantKindOfUsesInput;
  PlantKindOfUseUpsertWithoutPlantOnPlantKindOfUsesInput: PlantKindOfUseUpsertWithoutPlantOnPlantKindOfUsesInput;
  PlantKindOfUseWhereInput: PlantKindOfUseWhereInput;
  PlantKindOfUseWhereUniqueInput: PlantKindOfUseWhereUniqueInput;
  PlantListRelationFilter: PlantListRelationFilter;
  PlantMaxAggregate: PlantMaxAggregate;
  PlantMinAggregate: PlantMinAggregate;
  PlantOnPlantKindOfUse: PlantOnPlantKindOfUse;
  PlantOnPlantKindOfUseCountAggregate: PlantOnPlantKindOfUseCountAggregate;
  PlantOnPlantKindOfUseCreateManyPlantInput: PlantOnPlantKindOfUseCreateManyPlantInput;
  PlantOnPlantKindOfUseCreateManyPlantInputEnvelope: PlantOnPlantKindOfUseCreateManyPlantInputEnvelope;
  PlantOnPlantKindOfUseCreateNestedManyWithoutPlantInput: PlantOnPlantKindOfUseCreateNestedManyWithoutPlantInput;
  PlantOnPlantKindOfUseCreateOrConnectWithoutPlantInput: PlantOnPlantKindOfUseCreateOrConnectWithoutPlantInput;
  PlantOnPlantKindOfUseCreateWithoutPlantInput: PlantOnPlantKindOfUseCreateWithoutPlantInput;
  PlantOnPlantKindOfUseListRelationFilter: PlantOnPlantKindOfUseListRelationFilter;
  PlantOnPlantKindOfUseMaxAggregate: PlantOnPlantKindOfUseMaxAggregate;
  PlantOnPlantKindOfUseMinAggregate: PlantOnPlantKindOfUseMinAggregate;
  PlantOnPlantKindOfUsePlantIdPlantKindIdCompoundUniqueInput: PlantOnPlantKindOfUsePlantIdPlantKindIdCompoundUniqueInput;
  PlantOnPlantKindOfUseScalarWhereInput: PlantOnPlantKindOfUseScalarWhereInput;
  PlantOnPlantKindOfUseUpdateManyMutationInput: PlantOnPlantKindOfUseUpdateManyMutationInput;
  PlantOnPlantKindOfUseUpdateManyWithWhereWithoutPlantInput: PlantOnPlantKindOfUseUpdateManyWithWhereWithoutPlantInput;
  PlantOnPlantKindOfUseUpdateManyWithoutPlantNestedInput: PlantOnPlantKindOfUseUpdateManyWithoutPlantNestedInput;
  PlantOnPlantKindOfUseUpdateWithWhereUniqueWithoutPlantInput: PlantOnPlantKindOfUseUpdateWithWhereUniqueWithoutPlantInput;
  PlantOnPlantKindOfUseUpdateWithoutPlantInput: PlantOnPlantKindOfUseUpdateWithoutPlantInput;
  PlantOnPlantKindOfUseUpsertWithWhereUniqueWithoutPlantInput: PlantOnPlantKindOfUseUpsertWithWhereUniqueWithoutPlantInput;
  PlantOnPlantKindOfUseWhereInput: PlantOnPlantKindOfUseWhereInput;
  PlantOnPlantKindOfUseWhereUniqueInput: PlantOnPlantKindOfUseWhereUniqueInput;
  PlantRelationFilter: PlantRelationFilter;
  PlantScalarWhereInput: PlantScalarWhereInput;
  PlantSumAggregate: PlantSumAggregate;
  PlantUpdateInput: PlantUpdateInput;
  PlantUpdateManyMutationInput: PlantUpdateManyMutationInput;
  PlantUpdateManyWithWhereWithoutTambonInput: PlantUpdateManyWithWhereWithoutTambonInput;
  PlantUpdateManyWithWhereWithoutUserInput: PlantUpdateManyWithWhereWithoutUserInput;
  PlantUpdateManyWithoutTambonNestedInput: PlantUpdateManyWithoutTambonNestedInput;
  PlantUpdateManyWithoutUserNestedInput: PlantUpdateManyWithoutUserNestedInput;
  PlantUpdateWithWhereUniqueWithoutTambonInput: PlantUpdateWithWhereUniqueWithoutTambonInput;
  PlantUpdateWithWhereUniqueWithoutUserInput: PlantUpdateWithWhereUniqueWithoutUserInput;
  PlantUpdateWithoutTambonInput: PlantUpdateWithoutTambonInput;
  PlantUpdateWithoutUserInput: PlantUpdateWithoutUserInput;
  PlantUpsertWithWhereUniqueWithoutTambonInput: PlantUpsertWithWhereUniqueWithoutTambonInput;
  PlantUpsertWithWhereUniqueWithoutUserInput: PlantUpsertWithWhereUniqueWithoutUserInput;
  PlantWhereInput: PlantWhereInput;
  PlantWhereUniqueInput: PlantWhereUniqueInput;
  Province: Province;
  ProvinceCount: ProvinceCount;
  ProvinceCountAggregate: ProvinceCountAggregate;
  ProvinceCreateNestedOneWithoutAmphoesInput: ProvinceCreateNestedOneWithoutAmphoesInput;
  ProvinceCreateOrConnectWithoutAmphoesInput: ProvinceCreateOrConnectWithoutAmphoesInput;
  ProvinceCreateWithoutAmphoesInput: ProvinceCreateWithoutAmphoesInput;
  ProvinceMaxAggregate: ProvinceMaxAggregate;
  ProvinceMinAggregate: ProvinceMinAggregate;
  ProvinceNullableRelationFilter: ProvinceNullableRelationFilter;
  ProvinceUpdateOneWithoutAmphoesNestedInput: ProvinceUpdateOneWithoutAmphoesNestedInput;
  ProvinceUpdateToOneWithWhereWithoutAmphoesInput: ProvinceUpdateToOneWithWhereWithoutAmphoesInput;
  ProvinceUpdateWithoutAmphoesInput: ProvinceUpdateWithoutAmphoesInput;
  ProvinceUpsertWithoutAmphoesInput: ProvinceUpsertWithoutAmphoesInput;
  ProvinceWhereInput: ProvinceWhereInput;
  ProvinceWhereUniqueInput: ProvinceWhereUniqueInput;
  PublishableWisdom: PublishableWisdom;
  PublishableWisdomCountAggregate: PublishableWisdomCountAggregate;
  PublishableWisdomCreateManyWisdomInput: PublishableWisdomCreateManyWisdomInput;
  PublishableWisdomCreateManyWisdomInputEnvelope: PublishableWisdomCreateManyWisdomInputEnvelope;
  PublishableWisdomCreateNestedManyWithoutWisdomInput: PublishableWisdomCreateNestedManyWithoutWisdomInput;
  PublishableWisdomCreateOrConnectWithoutWisdomInput: PublishableWisdomCreateOrConnectWithoutWisdomInput;
  PublishableWisdomCreateWithoutWisdomInput: PublishableWisdomCreateWithoutWisdomInput;
  PublishableWisdomListRelationFilter: PublishableWisdomListRelationFilter;
  PublishableWisdomMaxAggregate: PublishableWisdomMaxAggregate;
  PublishableWisdomMinAggregate: PublishableWisdomMinAggregate;
  PublishableWisdomScalarWhereInput: PublishableWisdomScalarWhereInput;
  PublishableWisdomUpdateManyMutationInput: PublishableWisdomUpdateManyMutationInput;
  PublishableWisdomUpdateManyWithWhereWithoutWisdomInput: PublishableWisdomUpdateManyWithWhereWithoutWisdomInput;
  PublishableWisdomUpdateManyWithoutWisdomNestedInput: PublishableWisdomUpdateManyWithoutWisdomNestedInput;
  PublishableWisdomUpdateWithWhereUniqueWithoutWisdomInput: PublishableWisdomUpdateWithWhereUniqueWithoutWisdomInput;
  PublishableWisdomUpdateWithoutWisdomInput: PublishableWisdomUpdateWithoutWisdomInput;
  PublishableWisdomUpsertWithWhereUniqueWithoutWisdomInput: PublishableWisdomUpsertWithWhereUniqueWithoutWisdomInput;
  PublishableWisdomWhereInput: PublishableWisdomWhereInput;
  PublishableWisdomWhereUniqueInput: PublishableWisdomWhereUniqueInput;
  Query: {};
  String: Scalars['String']['output'];
  StringFieldUpdateOperationsInput: StringFieldUpdateOperationsInput;
  StringFilter: StringFilter;
  StringNullableFilter: StringNullableFilter;
  Tambon: Tambon;
  TambonCount: TambonCount;
  TambonCountAggregate: TambonCountAggregate;
  TambonCreateNestedOneWithoutFoodInput: TambonCreateNestedOneWithoutFoodInput;
  TambonCreateNestedOneWithoutPlantInput: TambonCreateNestedOneWithoutPlantInput;
  TambonCreateNestedOneWithoutTravelInput: TambonCreateNestedOneWithoutTravelInput;
  TambonCreateNestedOneWithoutWisdomCategoryInput: TambonCreateNestedOneWithoutWisdomCategoryInput;
  TambonCreateNestedOneWithoutWisdomInput: TambonCreateNestedOneWithoutWisdomInput;
  TambonCreateOrConnectWithoutFoodInput: TambonCreateOrConnectWithoutFoodInput;
  TambonCreateOrConnectWithoutPlantInput: TambonCreateOrConnectWithoutPlantInput;
  TambonCreateOrConnectWithoutTravelInput: TambonCreateOrConnectWithoutTravelInput;
  TambonCreateOrConnectWithoutWisdomCategoryInput: TambonCreateOrConnectWithoutWisdomCategoryInput;
  TambonCreateOrConnectWithoutWisdomInput: TambonCreateOrConnectWithoutWisdomInput;
  TambonCreateWithoutFoodInput: TambonCreateWithoutFoodInput;
  TambonCreateWithoutPlantInput: TambonCreateWithoutPlantInput;
  TambonCreateWithoutTravelInput: TambonCreateWithoutTravelInput;
  TambonCreateWithoutWisdomCategoryInput: TambonCreateWithoutWisdomCategoryInput;
  TambonCreateWithoutWisdomInput: TambonCreateWithoutWisdomInput;
  TambonListRelationFilter: TambonListRelationFilter;
  TambonMaxAggregate: TambonMaxAggregate;
  TambonMinAggregate: TambonMinAggregate;
  TambonNullableRelationFilter: TambonNullableRelationFilter;
  TambonRelationFilter: TambonRelationFilter;
  TambonUpdateOneRequiredWithoutFoodNestedInput: TambonUpdateOneRequiredWithoutFoodNestedInput;
  TambonUpdateOneRequiredWithoutPlantNestedInput: TambonUpdateOneRequiredWithoutPlantNestedInput;
  TambonUpdateOneRequiredWithoutTravelNestedInput: TambonUpdateOneRequiredWithoutTravelNestedInput;
  TambonUpdateOneRequiredWithoutWisdomNestedInput: TambonUpdateOneRequiredWithoutWisdomNestedInput;
  TambonUpdateOneWithoutWisdomCategoryNestedInput: TambonUpdateOneWithoutWisdomCategoryNestedInput;
  TambonUpdateToOneWithWhereWithoutFoodInput: TambonUpdateToOneWithWhereWithoutFoodInput;
  TambonUpdateToOneWithWhereWithoutPlantInput: TambonUpdateToOneWithWhereWithoutPlantInput;
  TambonUpdateToOneWithWhereWithoutTravelInput: TambonUpdateToOneWithWhereWithoutTravelInput;
  TambonUpdateToOneWithWhereWithoutWisdomCategoryInput: TambonUpdateToOneWithWhereWithoutWisdomCategoryInput;
  TambonUpdateToOneWithWhereWithoutWisdomInput: TambonUpdateToOneWithWhereWithoutWisdomInput;
  TambonUpdateWithoutFoodInput: TambonUpdateWithoutFoodInput;
  TambonUpdateWithoutPlantInput: TambonUpdateWithoutPlantInput;
  TambonUpdateWithoutTravelInput: TambonUpdateWithoutTravelInput;
  TambonUpdateWithoutWisdomCategoryInput: TambonUpdateWithoutWisdomCategoryInput;
  TambonUpdateWithoutWisdomInput: TambonUpdateWithoutWisdomInput;
  TambonUpsertWithoutFoodInput: TambonUpsertWithoutFoodInput;
  TambonUpsertWithoutPlantInput: TambonUpsertWithoutPlantInput;
  TambonUpsertWithoutTravelInput: TambonUpsertWithoutTravelInput;
  TambonUpsertWithoutWisdomCategoryInput: TambonUpsertWithoutWisdomCategoryInput;
  TambonUpsertWithoutWisdomInput: TambonUpsertWithoutWisdomInput;
  TambonWhereInput: TambonWhereInput;
  TambonWhereUniqueInput: TambonWhereUniqueInput;
  TambonWithAmphoeAndProvinceArgs: TambonWithAmphoeAndProvinceArgs;
  Travel: Travel;
  TravelAvgAggregate: TravelAvgAggregate;
  TravelCount: TravelCount;
  TravelCountAggregate: TravelCountAggregate;
  TravelCreateInput: TravelCreateInput;
  TravelCreateManyTambonInput: TravelCreateManyTambonInput;
  TravelCreateManyTambonInputEnvelope: TravelCreateManyTambonInputEnvelope;
  TravelCreateManyTypeInput: TravelCreateManyTypeInput;
  TravelCreateManyTypeInputEnvelope: TravelCreateManyTypeInputEnvelope;
  TravelCreateManyUserInput: TravelCreateManyUserInput;
  TravelCreateManyUserInputEnvelope: TravelCreateManyUserInputEnvelope;
  TravelCreateNestedManyWithoutTambonInput: TravelCreateNestedManyWithoutTambonInput;
  TravelCreateNestedManyWithoutTypeInput: TravelCreateNestedManyWithoutTypeInput;
  TravelCreateNestedManyWithoutUserInput: TravelCreateNestedManyWithoutUserInput;
  TravelCreateNestedOneWithoutFacilitiesInput: TravelCreateNestedOneWithoutFacilitiesInput;
  TravelCreateOrConnectWithoutFacilitiesInput: TravelCreateOrConnectWithoutFacilitiesInput;
  TravelCreateOrConnectWithoutTambonInput: TravelCreateOrConnectWithoutTambonInput;
  TravelCreateOrConnectWithoutTypeInput: TravelCreateOrConnectWithoutTypeInput;
  TravelCreateOrConnectWithoutUserInput: TravelCreateOrConnectWithoutUserInput;
  TravelCreateWithoutFacilitiesInput: TravelCreateWithoutFacilitiesInput;
  TravelCreateWithoutTambonInput: TravelCreateWithoutTambonInput;
  TravelCreateWithoutTypeInput: TravelCreateWithoutTypeInput;
  TravelCreateWithoutUserInput: TravelCreateWithoutUserInput;
  TravelImage: TravelImage;
  TravelImageCountAggregate: TravelImageCountAggregate;
  TravelImageCreateManyTravelInput: TravelImageCreateManyTravelInput;
  TravelImageCreateManyTravelInputEnvelope: TravelImageCreateManyTravelInputEnvelope;
  TravelImageCreateNestedManyWithoutTravelInput: TravelImageCreateNestedManyWithoutTravelInput;
  TravelImageCreateOrConnectWithoutTravelInput: TravelImageCreateOrConnectWithoutTravelInput;
  TravelImageCreateWithoutTravelInput: TravelImageCreateWithoutTravelInput;
  TravelImageListRelationFilter: TravelImageListRelationFilter;
  TravelImageMaxAggregate: TravelImageMaxAggregate;
  TravelImageMinAggregate: TravelImageMinAggregate;
  TravelImageScalarWhereInput: TravelImageScalarWhereInput;
  TravelImageUpdateManyMutationInput: TravelImageUpdateManyMutationInput;
  TravelImageUpdateManyWithWhereWithoutTravelInput: TravelImageUpdateManyWithWhereWithoutTravelInput;
  TravelImageUpdateManyWithoutTravelNestedInput: TravelImageUpdateManyWithoutTravelNestedInput;
  TravelImageUpdateWithWhereUniqueWithoutTravelInput: TravelImageUpdateWithWhereUniqueWithoutTravelInput;
  TravelImageUpdateWithoutTravelInput: TravelImageUpdateWithoutTravelInput;
  TravelImageUpsertWithWhereUniqueWithoutTravelInput: TravelImageUpsertWithWhereUniqueWithoutTravelInput;
  TravelImageWhereInput: TravelImageWhereInput;
  TravelImageWhereUniqueInput: TravelImageWhereUniqueInput;
  TravelListRelationFilter: TravelListRelationFilter;
  TravelMaxAggregate: TravelMaxAggregate;
  TravelMinAggregate: TravelMinAggregate;
  TravelNullableRelationFilter: TravelNullableRelationFilter;
  TravelRelationFilter: TravelRelationFilter;
  TravelScalarWhereInput: TravelScalarWhereInput;
  TravelSumAggregate: TravelSumAggregate;
  TravelType: TravelType;
  TravelTypeCount: TravelTypeCount;
  TravelTypeCountAggregate: TravelTypeCountAggregate;
  TravelTypeCreateInput: TravelTypeCreateInput;
  TravelTypeCreateNestedOneWithoutTravelsInput: TravelTypeCreateNestedOneWithoutTravelsInput;
  TravelTypeCreateOrConnectWithoutTravelsInput: TravelTypeCreateOrConnectWithoutTravelsInput;
  TravelTypeCreateWithoutTravelsInput: TravelTypeCreateWithoutTravelsInput;
  TravelTypeMaxAggregate: TravelTypeMaxAggregate;
  TravelTypeMinAggregate: TravelTypeMinAggregate;
  TravelTypeRelationFilter: TravelTypeRelationFilter;
  TravelTypeUpdateInput: TravelTypeUpdateInput;
  TravelTypeUpdateOneRequiredWithoutTravelsNestedInput: TravelTypeUpdateOneRequiredWithoutTravelsNestedInput;
  TravelTypeUpdateToOneWithWhereWithoutTravelsInput: TravelTypeUpdateToOneWithWhereWithoutTravelsInput;
  TravelTypeUpdateWithoutTravelsInput: TravelTypeUpdateWithoutTravelsInput;
  TravelTypeUpsertWithoutTravelsInput: TravelTypeUpsertWithoutTravelsInput;
  TravelTypeWhereInput: TravelTypeWhereInput;
  TravelTypeWhereUniqueInput: TravelTypeWhereUniqueInput;
  TravelUpdateInput: TravelUpdateInput;
  TravelUpdateManyMutationInput: TravelUpdateManyMutationInput;
  TravelUpdateManyWithWhereWithoutTambonInput: TravelUpdateManyWithWhereWithoutTambonInput;
  TravelUpdateManyWithWhereWithoutTypeInput: TravelUpdateManyWithWhereWithoutTypeInput;
  TravelUpdateManyWithWhereWithoutUserInput: TravelUpdateManyWithWhereWithoutUserInput;
  TravelUpdateManyWithoutTambonNestedInput: TravelUpdateManyWithoutTambonNestedInput;
  TravelUpdateManyWithoutTypeNestedInput: TravelUpdateManyWithoutTypeNestedInput;
  TravelUpdateManyWithoutUserNestedInput: TravelUpdateManyWithoutUserNestedInput;
  TravelUpdateOneRequiredWithoutFacilitiesNestedInput: TravelUpdateOneRequiredWithoutFacilitiesNestedInput;
  TravelUpdateToOneWithWhereWithoutFacilitiesInput: TravelUpdateToOneWithWhereWithoutFacilitiesInput;
  TravelUpdateWithWhereUniqueWithoutTambonInput: TravelUpdateWithWhereUniqueWithoutTambonInput;
  TravelUpdateWithWhereUniqueWithoutTypeInput: TravelUpdateWithWhereUniqueWithoutTypeInput;
  TravelUpdateWithWhereUniqueWithoutUserInput: TravelUpdateWithWhereUniqueWithoutUserInput;
  TravelUpdateWithoutFacilitiesInput: TravelUpdateWithoutFacilitiesInput;
  TravelUpdateWithoutTambonInput: TravelUpdateWithoutTambonInput;
  TravelUpdateWithoutTypeInput: TravelUpdateWithoutTypeInput;
  TravelUpdateWithoutUserInput: TravelUpdateWithoutUserInput;
  TravelUpsertWithWhereUniqueWithoutTambonInput: TravelUpsertWithWhereUniqueWithoutTambonInput;
  TravelUpsertWithWhereUniqueWithoutTypeInput: TravelUpsertWithWhereUniqueWithoutTypeInput;
  TravelUpsertWithWhereUniqueWithoutUserInput: TravelUpsertWithWhereUniqueWithoutUserInput;
  TravelUpsertWithoutFacilitiesInput: TravelUpsertWithoutFacilitiesInput;
  TravelWhereInput: TravelWhereInput;
  TravelWhereUniqueInput: TravelWhereUniqueInput;
  TravelsOnFacilities: TravelsOnFacilities;
  TravelsOnFacilitiesCountAggregate: TravelsOnFacilitiesCountAggregate;
  TravelsOnFacilitiesCreateManyFacilityInput: TravelsOnFacilitiesCreateManyFacilityInput;
  TravelsOnFacilitiesCreateManyFacilityInputEnvelope: TravelsOnFacilitiesCreateManyFacilityInputEnvelope;
  TravelsOnFacilitiesCreateManyTravelInput: TravelsOnFacilitiesCreateManyTravelInput;
  TravelsOnFacilitiesCreateManyTravelInputEnvelope: TravelsOnFacilitiesCreateManyTravelInputEnvelope;
  TravelsOnFacilitiesCreateNestedManyWithoutFacilityInput: TravelsOnFacilitiesCreateNestedManyWithoutFacilityInput;
  TravelsOnFacilitiesCreateNestedManyWithoutTravelInput: TravelsOnFacilitiesCreateNestedManyWithoutTravelInput;
  TravelsOnFacilitiesCreateOrConnectWithoutFacilityInput: TravelsOnFacilitiesCreateOrConnectWithoutFacilityInput;
  TravelsOnFacilitiesCreateOrConnectWithoutTravelInput: TravelsOnFacilitiesCreateOrConnectWithoutTravelInput;
  TravelsOnFacilitiesCreateWithoutFacilityInput: TravelsOnFacilitiesCreateWithoutFacilityInput;
  TravelsOnFacilitiesCreateWithoutTravelInput: TravelsOnFacilitiesCreateWithoutTravelInput;
  TravelsOnFacilitiesListRelationFilter: TravelsOnFacilitiesListRelationFilter;
  TravelsOnFacilitiesMaxAggregate: TravelsOnFacilitiesMaxAggregate;
  TravelsOnFacilitiesMinAggregate: TravelsOnFacilitiesMinAggregate;
  TravelsOnFacilitiesScalarWhereInput: TravelsOnFacilitiesScalarWhereInput;
  TravelsOnFacilitiesTravelIdFacilityIdCompoundUniqueInput: TravelsOnFacilitiesTravelIdFacilityIdCompoundUniqueInput;
  TravelsOnFacilitiesUpdateManyMutationInput: TravelsOnFacilitiesUpdateManyMutationInput;
  TravelsOnFacilitiesUpdateManyWithWhereWithoutFacilityInput: TravelsOnFacilitiesUpdateManyWithWhereWithoutFacilityInput;
  TravelsOnFacilitiesUpdateManyWithWhereWithoutTravelInput: TravelsOnFacilitiesUpdateManyWithWhereWithoutTravelInput;
  TravelsOnFacilitiesUpdateManyWithoutFacilityNestedInput: TravelsOnFacilitiesUpdateManyWithoutFacilityNestedInput;
  TravelsOnFacilitiesUpdateManyWithoutTravelNestedInput: TravelsOnFacilitiesUpdateManyWithoutTravelNestedInput;
  TravelsOnFacilitiesUpdateWithWhereUniqueWithoutFacilityInput: TravelsOnFacilitiesUpdateWithWhereUniqueWithoutFacilityInput;
  TravelsOnFacilitiesUpdateWithWhereUniqueWithoutTravelInput: TravelsOnFacilitiesUpdateWithWhereUniqueWithoutTravelInput;
  TravelsOnFacilitiesUpdateWithoutFacilityInput: TravelsOnFacilitiesUpdateWithoutFacilityInput;
  TravelsOnFacilitiesUpdateWithoutTravelInput: TravelsOnFacilitiesUpdateWithoutTravelInput;
  TravelsOnFacilitiesUpsertWithWhereUniqueWithoutFacilityInput: TravelsOnFacilitiesUpsertWithWhereUniqueWithoutFacilityInput;
  TravelsOnFacilitiesUpsertWithWhereUniqueWithoutTravelInput: TravelsOnFacilitiesUpsertWithWhereUniqueWithoutTravelInput;
  TravelsOnFacilitiesWhereInput: TravelsOnFacilitiesWhereInput;
  TravelsOnFacilitiesWhereUniqueInput: TravelsOnFacilitiesWhereUniqueInput;
  UniqueWisdom: UniqueWisdom;
  UniqueWisdomCountAggregate: UniqueWisdomCountAggregate;
  UniqueWisdomCreateManyWisdomInput: UniqueWisdomCreateManyWisdomInput;
  UniqueWisdomCreateManyWisdomInputEnvelope: UniqueWisdomCreateManyWisdomInputEnvelope;
  UniqueWisdomCreateNestedManyWithoutWisdomInput: UniqueWisdomCreateNestedManyWithoutWisdomInput;
  UniqueWisdomCreateOrConnectWithoutWisdomInput: UniqueWisdomCreateOrConnectWithoutWisdomInput;
  UniqueWisdomCreateWithoutWisdomInput: UniqueWisdomCreateWithoutWisdomInput;
  UniqueWisdomListRelationFilter: UniqueWisdomListRelationFilter;
  UniqueWisdomMaxAggregate: UniqueWisdomMaxAggregate;
  UniqueWisdomMinAggregate: UniqueWisdomMinAggregate;
  UniqueWisdomScalarWhereInput: UniqueWisdomScalarWhereInput;
  UniqueWisdomUpdateManyMutationInput: UniqueWisdomUpdateManyMutationInput;
  UniqueWisdomUpdateManyWithWhereWithoutWisdomInput: UniqueWisdomUpdateManyWithWhereWithoutWisdomInput;
  UniqueWisdomUpdateManyWithoutWisdomNestedInput: UniqueWisdomUpdateManyWithoutWisdomNestedInput;
  UniqueWisdomUpdateWithWhereUniqueWithoutWisdomInput: UniqueWisdomUpdateWithWhereUniqueWithoutWisdomInput;
  UniqueWisdomUpdateWithoutWisdomInput: UniqueWisdomUpdateWithoutWisdomInput;
  UniqueWisdomUpsertWithWhereUniqueWithoutWisdomInput: UniqueWisdomUpsertWithWhereUniqueWithoutWisdomInput;
  UniqueWisdomWhereInput: UniqueWisdomWhereInput;
  UniqueWisdomWhereUniqueInput: UniqueWisdomWhereUniqueInput;
  User: User;
  UserCount: UserCount;
  UserCountAggregate: UserCountAggregate;
  UserCreateInput: UserCreateInput;
  UserCreateNestedOneWithoutFoodsInput: UserCreateNestedOneWithoutFoodsInput;
  UserCreateNestedOneWithoutPlantsInput: UserCreateNestedOneWithoutPlantsInput;
  UserCreateNestedOneWithoutTravelsInput: UserCreateNestedOneWithoutTravelsInput;
  UserCreateNestedOneWithoutWisdomsInput: UserCreateNestedOneWithoutWisdomsInput;
  UserCreateOrConnectWithoutFoodsInput: UserCreateOrConnectWithoutFoodsInput;
  UserCreateOrConnectWithoutPlantsInput: UserCreateOrConnectWithoutPlantsInput;
  UserCreateOrConnectWithoutTravelsInput: UserCreateOrConnectWithoutTravelsInput;
  UserCreateOrConnectWithoutWisdomsInput: UserCreateOrConnectWithoutWisdomsInput;
  UserCreateWithoutFoodsInput: UserCreateWithoutFoodsInput;
  UserCreateWithoutPlantsInput: UserCreateWithoutPlantsInput;
  UserCreateWithoutTravelsInput: UserCreateWithoutTravelsInput;
  UserCreateWithoutWisdomsInput: UserCreateWithoutWisdomsInput;
  UserFilter: UserFilter;
  UserMaxAggregate: UserMaxAggregate;
  UserMinAggregate: UserMinAggregate;
  UserRelationFilter: UserRelationFilter;
  UserUpdateInput: UserUpdateInput;
  UserUpdateOneRequiredWithoutFoodsNestedInput: UserUpdateOneRequiredWithoutFoodsNestedInput;
  UserUpdateOneRequiredWithoutPlantsNestedInput: UserUpdateOneRequiredWithoutPlantsNestedInput;
  UserUpdateOneRequiredWithoutTravelsNestedInput: UserUpdateOneRequiredWithoutTravelsNestedInput;
  UserUpdateOneRequiredWithoutWisdomsNestedInput: UserUpdateOneRequiredWithoutWisdomsNestedInput;
  UserUpdateToOneWithWhereWithoutFoodsInput: UserUpdateToOneWithWhereWithoutFoodsInput;
  UserUpdateToOneWithWhereWithoutPlantsInput: UserUpdateToOneWithWhereWithoutPlantsInput;
  UserUpdateToOneWithWhereWithoutTravelsInput: UserUpdateToOneWithWhereWithoutTravelsInput;
  UserUpdateToOneWithWhereWithoutWisdomsInput: UserUpdateToOneWithWhereWithoutWisdomsInput;
  UserUpdateWithoutFoodsInput: UserUpdateWithoutFoodsInput;
  UserUpdateWithoutPlantsInput: UserUpdateWithoutPlantsInput;
  UserUpdateWithoutTravelsInput: UserUpdateWithoutTravelsInput;
  UserUpdateWithoutWisdomsInput: UserUpdateWithoutWisdomsInput;
  UserUpsertWithoutFoodsInput: UserUpsertWithoutFoodsInput;
  UserUpsertWithoutPlantsInput: UserUpsertWithoutPlantsInput;
  UserUpsertWithoutTravelsInput: UserUpsertWithoutTravelsInput;
  UserUpsertWithoutWisdomsInput: UserUpsertWithoutWisdomsInput;
  UserWhereInput: UserWhereInput;
  UserWhereUniqueInput: UserWhereUniqueInput;
  VerificationTokenCountAggregate: VerificationTokenCountAggregate;
  VerificationTokenMaxAggregate: VerificationTokenMaxAggregate;
  VerificationTokenMinAggregate: VerificationTokenMinAggregate;
  Website: Website;
  WebsiteCountAggregate: WebsiteCountAggregate;
  WebsiteCreateManyTravelInput: WebsiteCreateManyTravelInput;
  WebsiteCreateManyTravelInputEnvelope: WebsiteCreateManyTravelInputEnvelope;
  WebsiteCreateNestedManyWithoutTravelInput: WebsiteCreateNestedManyWithoutTravelInput;
  WebsiteCreateOrConnectWithoutTravelInput: WebsiteCreateOrConnectWithoutTravelInput;
  WebsiteCreateWithoutTravelInput: WebsiteCreateWithoutTravelInput;
  WebsiteListRelationFilter: WebsiteListRelationFilter;
  WebsiteMaxAggregate: WebsiteMaxAggregate;
  WebsiteMinAggregate: WebsiteMinAggregate;
  WebsiteScalarWhereInput: WebsiteScalarWhereInput;
  WebsiteUpdateManyMutationInput: WebsiteUpdateManyMutationInput;
  WebsiteUpdateManyWithWhereWithoutTravelInput: WebsiteUpdateManyWithWhereWithoutTravelInput;
  WebsiteUpdateManyWithoutTravelNestedInput: WebsiteUpdateManyWithoutTravelNestedInput;
  WebsiteUpdateWithWhereUniqueWithoutTravelInput: WebsiteUpdateWithWhereUniqueWithoutTravelInput;
  WebsiteUpdateWithoutTravelInput: WebsiteUpdateWithoutTravelInput;
  WebsiteUpsertWithWhereUniqueWithoutTravelInput: WebsiteUpsertWithWhereUniqueWithoutTravelInput;
  WebsiteWhereInput: WebsiteWhereInput;
  WebsiteWhereUniqueInput: WebsiteWhereUniqueInput;
  Wisdom: Wisdom;
  WisdomAvgAggregate: WisdomAvgAggregate;
  WisdomCategory: WisdomCategory;
  WisdomCategoryCount: WisdomCategoryCount;
  WisdomCategoryCountAggregate: WisdomCategoryCountAggregate;
  WisdomCategoryCreateManyTambonInput: WisdomCategoryCreateManyTambonInput;
  WisdomCategoryCreateManyTambonInputEnvelope: WisdomCategoryCreateManyTambonInputEnvelope;
  WisdomCategoryCreateNestedManyWithoutTambonInput: WisdomCategoryCreateNestedManyWithoutTambonInput;
  WisdomCategoryCreateNestedOneWithoutWisdomsInput: WisdomCategoryCreateNestedOneWithoutWisdomsInput;
  WisdomCategoryCreateOrConnectWithoutTambonInput: WisdomCategoryCreateOrConnectWithoutTambonInput;
  WisdomCategoryCreateOrConnectWithoutWisdomsInput: WisdomCategoryCreateOrConnectWithoutWisdomsInput;
  WisdomCategoryCreateWithoutTambonInput: WisdomCategoryCreateWithoutTambonInput;
  WisdomCategoryCreateWithoutWisdomsInput: WisdomCategoryCreateWithoutWisdomsInput;
  WisdomCategoryListRelationFilter: WisdomCategoryListRelationFilter;
  WisdomCategoryMaxAggregate: WisdomCategoryMaxAggregate;
  WisdomCategoryMinAggregate: WisdomCategoryMinAggregate;
  WisdomCategoryRelationFilter: WisdomCategoryRelationFilter;
  WisdomCategoryScalarWhereInput: WisdomCategoryScalarWhereInput;
  WisdomCategoryUpdateManyMutationInput: WisdomCategoryUpdateManyMutationInput;
  WisdomCategoryUpdateManyWithWhereWithoutTambonInput: WisdomCategoryUpdateManyWithWhereWithoutTambonInput;
  WisdomCategoryUpdateManyWithoutTambonNestedInput: WisdomCategoryUpdateManyWithoutTambonNestedInput;
  WisdomCategoryUpdateOneRequiredWithoutWisdomsNestedInput: WisdomCategoryUpdateOneRequiredWithoutWisdomsNestedInput;
  WisdomCategoryUpdateToOneWithWhereWithoutWisdomsInput: WisdomCategoryUpdateToOneWithWhereWithoutWisdomsInput;
  WisdomCategoryUpdateWithWhereUniqueWithoutTambonInput: WisdomCategoryUpdateWithWhereUniqueWithoutTambonInput;
  WisdomCategoryUpdateWithoutTambonInput: WisdomCategoryUpdateWithoutTambonInput;
  WisdomCategoryUpdateWithoutWisdomsInput: WisdomCategoryUpdateWithoutWisdomsInput;
  WisdomCategoryUpsertWithWhereUniqueWithoutTambonInput: WisdomCategoryUpsertWithWhereUniqueWithoutTambonInput;
  WisdomCategoryUpsertWithoutWisdomsInput: WisdomCategoryUpsertWithoutWisdomsInput;
  WisdomCategoryWhereInput: WisdomCategoryWhereInput;
  WisdomCategoryWhereUniqueInput: WisdomCategoryWhereUniqueInput;
  WisdomCount: WisdomCount;
  WisdomCountAggregate: WisdomCountAggregate;
  WisdomCreateInput: WisdomCreateInput;
  WisdomCreateManyCategoryInput: WisdomCreateManyCategoryInput;
  WisdomCreateManyCategoryInputEnvelope: WisdomCreateManyCategoryInputEnvelope;
  WisdomCreateManyTambonInput: WisdomCreateManyTambonInput;
  WisdomCreateManyTambonInputEnvelope: WisdomCreateManyTambonInputEnvelope;
  WisdomCreateManyUserInput: WisdomCreateManyUserInput;
  WisdomCreateManyUserInputEnvelope: WisdomCreateManyUserInputEnvelope;
  WisdomCreateNestedManyWithoutCategoryInput: WisdomCreateNestedManyWithoutCategoryInput;
  WisdomCreateNestedManyWithoutTambonInput: WisdomCreateNestedManyWithoutTambonInput;
  WisdomCreateNestedManyWithoutUserInput: WisdomCreateNestedManyWithoutUserInput;
  WisdomCreateOrConnectWithoutCategoryInput: WisdomCreateOrConnectWithoutCategoryInput;
  WisdomCreateOrConnectWithoutTambonInput: WisdomCreateOrConnectWithoutTambonInput;
  WisdomCreateOrConnectWithoutUserInput: WisdomCreateOrConnectWithoutUserInput;
  WisdomCreateWithoutCategoryInput: WisdomCreateWithoutCategoryInput;
  WisdomCreateWithoutTambonInput: WisdomCreateWithoutTambonInput;
  WisdomCreateWithoutUserInput: WisdomCreateWithoutUserInput;
  WisdomImage: WisdomImage;
  WisdomImageCountAggregate: WisdomImageCountAggregate;
  WisdomImageCreateManyWisdomInput: WisdomImageCreateManyWisdomInput;
  WisdomImageCreateManyWisdomInputEnvelope: WisdomImageCreateManyWisdomInputEnvelope;
  WisdomImageCreateNestedManyWithoutWisdomInput: WisdomImageCreateNestedManyWithoutWisdomInput;
  WisdomImageCreateOrConnectWithoutWisdomInput: WisdomImageCreateOrConnectWithoutWisdomInput;
  WisdomImageCreateWithoutWisdomInput: WisdomImageCreateWithoutWisdomInput;
  WisdomImageListRelationFilter: WisdomImageListRelationFilter;
  WisdomImageMaxAggregate: WisdomImageMaxAggregate;
  WisdomImageMinAggregate: WisdomImageMinAggregate;
  WisdomImageScalarWhereInput: WisdomImageScalarWhereInput;
  WisdomImageUpdateManyMutationInput: WisdomImageUpdateManyMutationInput;
  WisdomImageUpdateManyWithWhereWithoutWisdomInput: WisdomImageUpdateManyWithWhereWithoutWisdomInput;
  WisdomImageUpdateManyWithoutWisdomNestedInput: WisdomImageUpdateManyWithoutWisdomNestedInput;
  WisdomImageUpdateWithWhereUniqueWithoutWisdomInput: WisdomImageUpdateWithWhereUniqueWithoutWisdomInput;
  WisdomImageUpdateWithoutWisdomInput: WisdomImageUpdateWithoutWisdomInput;
  WisdomImageUpsertWithWhereUniqueWithoutWisdomInput: WisdomImageUpsertWithWhereUniqueWithoutWisdomInput;
  WisdomImageWhereInput: WisdomImageWhereInput;
  WisdomImageWhereUniqueInput: WisdomImageWhereUniqueInput;
  WisdomListRelationFilter: WisdomListRelationFilter;
  WisdomMaxAggregate: WisdomMaxAggregate;
  WisdomMinAggregate: WisdomMinAggregate;
  WisdomNullableRelationFilter: WisdomNullableRelationFilter;
  WisdomRelationFilter: WisdomRelationFilter;
  WisdomScalarWhereInput: WisdomScalarWhereInput;
  WisdomSumAggregate: WisdomSumAggregate;
  WisdomTool: WisdomTool;
  WisdomToolCountAggregate: WisdomToolCountAggregate;
  WisdomToolCreateManyWisdomInput: WisdomToolCreateManyWisdomInput;
  WisdomToolCreateManyWisdomInputEnvelope: WisdomToolCreateManyWisdomInputEnvelope;
  WisdomToolCreateNestedManyWithoutWisdomInput: WisdomToolCreateNestedManyWithoutWisdomInput;
  WisdomToolCreateOrConnectWithoutWisdomInput: WisdomToolCreateOrConnectWithoutWisdomInput;
  WisdomToolCreateWithoutWisdomInput: WisdomToolCreateWithoutWisdomInput;
  WisdomToolListRelationFilter: WisdomToolListRelationFilter;
  WisdomToolMaxAggregate: WisdomToolMaxAggregate;
  WisdomToolMinAggregate: WisdomToolMinAggregate;
  WisdomToolScalarWhereInput: WisdomToolScalarWhereInput;
  WisdomToolUpdateManyMutationInput: WisdomToolUpdateManyMutationInput;
  WisdomToolUpdateManyWithWhereWithoutWisdomInput: WisdomToolUpdateManyWithWhereWithoutWisdomInput;
  WisdomToolUpdateManyWithoutWisdomNestedInput: WisdomToolUpdateManyWithoutWisdomNestedInput;
  WisdomToolUpdateWithWhereUniqueWithoutWisdomInput: WisdomToolUpdateWithWhereUniqueWithoutWisdomInput;
  WisdomToolUpdateWithoutWisdomInput: WisdomToolUpdateWithoutWisdomInput;
  WisdomToolUpsertWithWhereUniqueWithoutWisdomInput: WisdomToolUpsertWithWhereUniqueWithoutWisdomInput;
  WisdomToolWhereInput: WisdomToolWhereInput;
  WisdomToolWhereUniqueInput: WisdomToolWhereUniqueInput;
  WisdomUpdateInput: WisdomUpdateInput;
  WisdomUpdateManyMutationInput: WisdomUpdateManyMutationInput;
  WisdomUpdateManyWithWhereWithoutCategoryInput: WisdomUpdateManyWithWhereWithoutCategoryInput;
  WisdomUpdateManyWithWhereWithoutTambonInput: WisdomUpdateManyWithWhereWithoutTambonInput;
  WisdomUpdateManyWithWhereWithoutUserInput: WisdomUpdateManyWithWhereWithoutUserInput;
  WisdomUpdateManyWithoutCategoryNestedInput: WisdomUpdateManyWithoutCategoryNestedInput;
  WisdomUpdateManyWithoutTambonNestedInput: WisdomUpdateManyWithoutTambonNestedInput;
  WisdomUpdateManyWithoutUserNestedInput: WisdomUpdateManyWithoutUserNestedInput;
  WisdomUpdateWithWhereUniqueWithoutCategoryInput: WisdomUpdateWithWhereUniqueWithoutCategoryInput;
  WisdomUpdateWithWhereUniqueWithoutTambonInput: WisdomUpdateWithWhereUniqueWithoutTambonInput;
  WisdomUpdateWithWhereUniqueWithoutUserInput: WisdomUpdateWithWhereUniqueWithoutUserInput;
  WisdomUpdateWithoutCategoryInput: WisdomUpdateWithoutCategoryInput;
  WisdomUpdateWithoutTambonInput: WisdomUpdateWithoutTambonInput;
  WisdomUpdateWithoutUserInput: WisdomUpdateWithoutUserInput;
  WisdomUpsertWithWhereUniqueWithoutCategoryInput: WisdomUpsertWithWhereUniqueWithoutCategoryInput;
  WisdomUpsertWithWhereUniqueWithoutTambonInput: WisdomUpsertWithWhereUniqueWithoutTambonInput;
  WisdomUpsertWithWhereUniqueWithoutUserInput: WisdomUpsertWithWhereUniqueWithoutUserInput;
  WisdomWhereInput: WisdomWhereInput;
  WisdomWhereUniqueInput: WisdomWhereUniqueInput;
};

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = {
  addressLine1?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addressLine2?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tambon?: Resolver<ResolversTypes['Tambon'], ParentType, ContextType>;
  tambonId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddressCountAggregate'] = ResolversParentTypes['AddressCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  addressLine1?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  addressLine2?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tambonId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddressMaxAggregate'] = ResolversParentTypes['AddressMaxAggregate']> = {
  addressLine1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  addressLine2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tambonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddressMinAggregate'] = ResolversParentTypes['AddressMinAggregate']> = {
  addressLine1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  addressLine2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tambonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AmphoeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Amphoe'] = ResolversParentTypes['Amphoe']> = {
  _count?: Resolver<ResolversTypes['AmphoeCount'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  nameEn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameTh?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  province?: Resolver<Maybe<ResolversTypes['Province']>, ParentType, ContextType>;
  provinceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tambons?: Resolver<Maybe<Array<ResolversTypes['Tambon']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AmphoeCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['AmphoeCount'] = ResolversParentTypes['AmphoeCount']> = {
  tambons?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AmphoeCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AmphoeCountAggregate'] = ResolversParentTypes['AmphoeCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nameEn?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nameTh?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  provinceId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AmphoeMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AmphoeMaxAggregate'] = ResolversParentTypes['AmphoeMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameTh?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  provinceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AmphoeMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AmphoeMinAggregate'] = ResolversParentTypes['AmphoeMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameTh?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  provinceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactNumberResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactNumber'] = ResolversParentTypes['ContactNumber']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  tel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  travel?: Resolver<Maybe<ResolversTypes['Travel']>, ParentType, ContextType>;
  travelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactNumberCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactNumberCountAggregate'] = ResolversParentTypes['ContactNumberCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tel?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  travelId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactNumberMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactNumberMaxAggregate'] = ResolversParentTypes['ContactNumberMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  travelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactNumberMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactNumberMinAggregate'] = ResolversParentTypes['ContactNumberMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  travelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type FacilityResolvers<ContextType = any, ParentType extends ResolversParentTypes['Facility'] = ResolversParentTypes['Facility']> = {
  _count?: Resolver<ResolversTypes['FacilityCount'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  travelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  travels?: Resolver<Maybe<Array<ResolversTypes['TravelsOnFacilities']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FacilityCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['FacilityCount'] = ResolversParentTypes['FacilityCount']> = {
  travels?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FacilityCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FacilityCountAggregate'] = ResolversParentTypes['FacilityCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  travelId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FacilityMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FacilityMaxAggregate'] = ResolversParentTypes['FacilityMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  travelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FacilityMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FacilityMinAggregate'] = ResolversParentTypes['FacilityMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  travelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodResolvers<ContextType = any, ParentType extends ResolversParentTypes['Food'] = ResolversParentTypes['Food']> = {
  _count?: Resolver<ResolversTypes['FoodCount'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  foodCategory?: Resolver<Maybe<ResolversTypes['FoodCategory']>, ParentType, ContextType>;
  foodCategoryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasParking?: Resolver<Maybe<ResolversTypes['HasParking']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  images?: Resolver<Maybe<Array<ResolversTypes['FoodImage']>>, ParentType, ContextType>;
  ingredients?: Resolver<Maybe<Array<ResolversTypes['Ingredients']>>, ParentType, ContextType>;
  latitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  longitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productCapacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  story?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tambon?: Resolver<ResolversTypes['Tambon'], ParentType, ContextType>;
  tambonId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodAvgAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodAvgAggregate'] = ResolversParentTypes['FoodAvgAggregate']> = {
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  productCapacity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodCategory'] = ResolversParentTypes['FoodCategory']> = {
  _count?: Resolver<ResolversTypes['FoodCategoryCount'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  food?: Resolver<Maybe<Array<ResolversTypes['Food']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodCategoryCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodCategoryCount'] = ResolversParentTypes['FoodCategoryCount']> = {
  food?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodCategoryCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodCategoryCountAggregate'] = ResolversParentTypes['FoodCategoryCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodCategoryMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodCategoryMaxAggregate'] = ResolversParentTypes['FoodCategoryMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodCategoryMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodCategoryMinAggregate'] = ResolversParentTypes['FoodCategoryMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodCount'] = ResolversParentTypes['FoodCount']> = {
  images?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ingredients?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodCountAggregate'] = ResolversParentTypes['FoodCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  foodCategoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasParking?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  latitude?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  longitude?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  productCapacity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  story?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tambonId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodImage'] = ResolversParentTypes['FoodImage']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  food?: Resolver<ResolversTypes['Food'], ParentType, ContextType>;
  foodId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodImageCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodImageCountAggregate'] = ResolversParentTypes['FoodImageCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  foodId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodImageMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodImageMaxAggregate'] = ResolversParentTypes['FoodImageMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  foodId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodImageMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodImageMinAggregate'] = ResolversParentTypes['FoodImageMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  foodId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodMaxAggregate'] = ResolversParentTypes['FoodMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  foodCategoryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasParking?: Resolver<Maybe<ResolversTypes['HasParking']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productCapacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shop?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  story?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tambonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodMinAggregate'] = ResolversParentTypes['FoodMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  foodCategoryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasParking?: Resolver<Maybe<ResolversTypes['HasParking']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productCapacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shop?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  story?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tambonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodSumAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoodSumAggregate'] = ResolversParentTypes['FoodSumAggregate']> = {
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  productCapacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IngredientsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Ingredients'] = ResolversParentTypes['Ingredients']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  food?: Resolver<Maybe<ResolversTypes['Food']>, ParentType, ContextType>;
  foodId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IngredientsCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['IngredientsCountAggregate'] = ResolversParentTypes['IngredientsCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  foodId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IngredientsMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['IngredientsMaxAggregate'] = ResolversParentTypes['IngredientsMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  foodId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IngredientsMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['IngredientsMinAggregate'] = ResolversParentTypes['IngredientsMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  foodId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  createFacility?: Resolver<ResolversTypes['Facility'], ParentType, ContextType, RequireFields<MutationCreateFacilityArgs, 'input'>>;
  createFood?: Resolver<ResolversTypes['Food'], ParentType, ContextType, RequireFields<MutationCreateFoodArgs, 'input'>>;
  createFoodCategory?: Resolver<ResolversTypes['FoodCategory'], ParentType, ContextType, RequireFields<MutationCreateFoodCategoryArgs, 'name'>>;
  createFoodImage?: Resolver<ResolversTypes['FoodImage'], ParentType, ContextType, RequireFields<MutationCreateFoodImageArgs, 'input'>>;
  createPlant?: Resolver<ResolversTypes['Plant'], ParentType, ContextType, RequireFields<MutationCreatePlantArgs, 'input'>>;
  createTravel?: Resolver<ResolversTypes['Travel'], ParentType, ContextType, RequireFields<MutationCreateTravelArgs, 'input'>>;
  createTravelType?: Resolver<ResolversTypes['TravelType'], ParentType, ContextType, RequireFields<MutationCreateTravelTypeArgs, 'input'>>;
  createUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationCreateUserArgs, 'input'>>;
  createWisdom?: Resolver<ResolversTypes['Wisdom'], ParentType, ContextType, RequireFields<MutationCreateWisdomArgs, 'input'>>;
  createWisdomCategory?: Resolver<ResolversTypes['WisdomCategory'], ParentType, ContextType, RequireFields<MutationCreateWisdomCategoryArgs, 'name'>>;
  deleteFacility?: Resolver<ResolversTypes['Facility'], ParentType, ContextType, RequireFields<MutationDeleteFacilityArgs, 'id'>>;
  deleteFood?: Resolver<ResolversTypes['Food'], ParentType, ContextType, RequireFields<MutationDeleteFoodArgs, 'id'>>;
  deleteFoodCategory?: Resolver<ResolversTypes['FoodCategory'], ParentType, ContextType, RequireFields<MutationDeleteFoodCategoryArgs, 'id'>>;
  deleteFoodImageByFoodId?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteFoodImageByFoodIdArgs, 'foodId'>>;
  deletePlant?: Resolver<ResolversTypes['Plant'], ParentType, ContextType, RequireFields<MutationDeletePlantArgs, 'id'>>;
  deleteTravel?: Resolver<ResolversTypes['Travel'], ParentType, ContextType, RequireFields<MutationDeleteTravelArgs, 'id'>>;
  deleteTravelType?: Resolver<ResolversTypes['TravelType'], ParentType, ContextType, RequireFields<MutationDeleteTravelTypeArgs, 'id'>>;
  deleteUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationDeleteUserArgs, 'id'>>;
  deleteWisdom?: Resolver<ResolversTypes['Wisdom'], ParentType, ContextType, RequireFields<MutationDeleteWisdomArgs, 'id'>>;
  deleteWisdomCategory?: Resolver<ResolversTypes['WisdomCategory'], ParentType, ContextType, RequireFields<MutationDeleteWisdomCategoryArgs, 'id'>>;
  forgotPassword?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationForgotPasswordArgs, 'email'>>;
  login?: Resolver<ResolversTypes['JSON'], ParentType, ContextType, RequireFields<MutationLoginArgs, 'email' | 'password'>>;
  register?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationRegisterArgs, 'userInput'>>;
  resetPassword?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationResetPasswordArgs, 'password' | 'token'>>;
  updateFacility?: Resolver<ResolversTypes['Facility'], ParentType, ContextType, RequireFields<MutationUpdateFacilityArgs, 'id' | 'input'>>;
  updateFood?: Resolver<ResolversTypes['Food'], ParentType, ContextType, RequireFields<MutationUpdateFoodArgs, 'id' | 'input'>>;
  updateFoodCategory?: Resolver<ResolversTypes['FoodCategory'], ParentType, ContextType, RequireFields<MutationUpdateFoodCategoryArgs, 'id' | 'name'>>;
  updatePlant?: Resolver<ResolversTypes['Plant'], ParentType, ContextType, RequireFields<MutationUpdatePlantArgs, 'id' | 'input'>>;
  updateTravel?: Resolver<ResolversTypes['Travel'], ParentType, ContextType, RequireFields<MutationUpdateTravelArgs, 'id' | 'input'>>;
  updateTravelType?: Resolver<ResolversTypes['TravelType'], ParentType, ContextType, RequireFields<MutationUpdateTravelTypeArgs, 'id' | 'input'>>;
  updateUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'id' | 'input'>>;
  updateWisdom?: Resolver<ResolversTypes['Wisdom'], ParentType, ContextType, RequireFields<MutationUpdateWisdomArgs, 'id' | 'input'>>;
  updateWisdomCategory?: Resolver<ResolversTypes['WisdomCategory'], ParentType, ContextType, RequireFields<MutationUpdateWisdomCategoryArgs, 'id' | 'name'>>;
};

export type PlantResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plant'] = ResolversParentTypes['Plant']> = {
  _count?: Resolver<ResolversTypes['PlantCount'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  foundFrequencyInMonthEnd?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  foundFrequencyInMonthStart?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasOwner?: Resolver<ResolversTypes['HasOwner'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  images?: Resolver<Maybe<Array<ResolversTypes['PlantImage']>>, ParentType, ContextType>;
  informant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kindOfUses?: Resolver<Maybe<Array<ResolversTypes['PlantOnPlantKindOfUse']>>, ParentType, ContextType>;
  latitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  longitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tambon?: Resolver<ResolversTypes['Tambon'], ParentType, ContextType>;
  tambonId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantAvgAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantAvgAggregate'] = ResolversParentTypes['PlantAvgAggregate']> = {
  foundFrequencyInMonthEnd?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  foundFrequencyInMonthStart?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantCount'] = ResolversParentTypes['PlantCount']> = {
  images?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  kindOfUses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantCountAggregate'] = ResolversParentTypes['PlantCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  foundFrequencyInMonthEnd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  foundFrequencyInMonthStart?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasOwner?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  informant?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  latitude?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  longitude?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ownerName?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tambonId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantImage'] = ResolversParentTypes['PlantImage']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['PlantImageKind'], ParentType, ContextType>;
  plant?: Resolver<ResolversTypes['Plant'], ParentType, ContextType>;
  plantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantImageCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantImageCountAggregate'] = ResolversParentTypes['PlantImageCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  plantId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantImageMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantImageMaxAggregate'] = ResolversParentTypes['PlantImageMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kind?: Resolver<Maybe<ResolversTypes['PlantImageKind']>, ParentType, ContextType>;
  plantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantImageMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantImageMinAggregate'] = ResolversParentTypes['PlantImageMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kind?: Resolver<Maybe<ResolversTypes['PlantImageKind']>, ParentType, ContextType>;
  plantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantKindOfUseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantKindOfUse'] = ResolversParentTypes['PlantKindOfUse']> = {
  _count?: Resolver<ResolversTypes['PlantKindOfUseCount'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plantOnPlantKindOfUses?: Resolver<Maybe<Array<ResolversTypes['PlantOnPlantKindOfUse']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantKindOfUseCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantKindOfUseCount'] = ResolversParentTypes['PlantKindOfUseCount']> = {
  plantOnPlantKindOfUses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantKindOfUseCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantKindOfUseCountAggregate'] = ResolversParentTypes['PlantKindOfUseCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantKindOfUseMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantKindOfUseMaxAggregate'] = ResolversParentTypes['PlantKindOfUseMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantKindOfUseMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantKindOfUseMinAggregate'] = ResolversParentTypes['PlantKindOfUseMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantMaxAggregate'] = ResolversParentTypes['PlantMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  foundFrequencyInMonthEnd?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  foundFrequencyInMonthStart?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasOwner?: Resolver<Maybe<ResolversTypes['HasOwner']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  informant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tambonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantMinAggregate'] = ResolversParentTypes['PlantMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  foundFrequencyInMonthEnd?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  foundFrequencyInMonthStart?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasOwner?: Resolver<Maybe<ResolversTypes['HasOwner']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  informant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tambonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantOnPlantKindOfUseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantOnPlantKindOfUse'] = ResolversParentTypes['PlantOnPlantKindOfUse']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  plant?: Resolver<ResolversTypes['Plant'], ParentType, ContextType>;
  plantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plantKind?: Resolver<ResolversTypes['PlantKindOfUse'], ParentType, ContextType>;
  plantKindId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantOnPlantKindOfUseCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantOnPlantKindOfUseCountAggregate'] = ResolversParentTypes['PlantOnPlantKindOfUseCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  plantId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  plantKindId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantOnPlantKindOfUseMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantOnPlantKindOfUseMaxAggregate'] = ResolversParentTypes['PlantOnPlantKindOfUseMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  plantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plantKindId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantOnPlantKindOfUseMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantOnPlantKindOfUseMinAggregate'] = ResolversParentTypes['PlantOnPlantKindOfUseMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  plantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plantKindId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlantSumAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlantSumAggregate'] = ResolversParentTypes['PlantSumAggregate']> = {
  foundFrequencyInMonthEnd?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  foundFrequencyInMonthStart?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProvinceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Province'] = ResolversParentTypes['Province']> = {
  _count?: Resolver<ResolversTypes['ProvinceCount'], ParentType, ContextType>;
  amphoes?: Resolver<Maybe<Array<ResolversTypes['Amphoe']>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  nameEn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameTh?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProvinceCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProvinceCount'] = ResolversParentTypes['ProvinceCount']> = {
  amphoes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProvinceCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProvinceCountAggregate'] = ResolversParentTypes['ProvinceCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nameEn?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nameTh?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProvinceMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProvinceMaxAggregate'] = ResolversParentTypes['ProvinceMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameTh?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProvinceMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProvinceMinAggregate'] = ResolversParentTypes['ProvinceMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameTh?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublishableWisdomResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublishableWisdom'] = ResolversParentTypes['PublishableWisdom']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tambonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  wisdom?: Resolver<Maybe<ResolversTypes['Wisdom']>, ParentType, ContextType>;
  wisdomId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublishableWisdomCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublishableWisdomCountAggregate'] = ResolversParentTypes['PublishableWisdomCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tambonId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  wisdomId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublishableWisdomMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublishableWisdomMaxAggregate'] = ResolversParentTypes['PublishableWisdomMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tambonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  wisdomId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublishableWisdomMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublishableWisdomMinAggregate'] = ResolversParentTypes['PublishableWisdomMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tambonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  wisdomId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  amphoe?: Resolver<ResolversTypes['Amphoe'], ParentType, ContextType, RequireFields<QueryAmphoeArgs, 'id'>>;
  amphoes?: Resolver<Array<ResolversTypes['Amphoe']>, ParentType, ContextType>;
  amphoesByKeyword?: Resolver<Array<ResolversTypes['Amphoe']>, ParentType, ContextType, RequireFields<QueryAmphoesByKeywordArgs, 'keyword'>>;
  amphoesByProvinceId?: Resolver<Array<ResolversTypes['Amphoe']>, ParentType, ContextType, RequireFields<QueryAmphoesByProvinceIdArgs, 'provinceId'>>;
  amphoesByProvinceIdAndKeyword?: Resolver<Array<ResolversTypes['Amphoe']>, ParentType, ContextType, RequireFields<QueryAmphoesByProvinceIdAndKeywordArgs, 'input'>>;
  countAllUserInFood?: Resolver<Array<ResolversTypes['Food']>, ParentType, ContextType>;
  countAllUserInPlant?: Resolver<Array<ResolversTypes['Plant']>, ParentType, ContextType>;
  countAllUserInTravel?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  countAllUserInWisdom?: Resolver<Array<ResolversTypes['Wisdom']>, ParentType, ContextType>;
  facilities?: Resolver<Array<ResolversTypes['Facility']>, ParentType, ContextType>;
  facilitiesByKeyword?: Resolver<Array<ResolversTypes['Facility']>, ParentType, ContextType, RequireFields<QueryFacilitiesByKeywordArgs, 'keyword'>>;
  facility?: Resolver<ResolversTypes['Facility'], ParentType, ContextType, RequireFields<QueryFacilityArgs, 'id'>>;
  food?: Resolver<ResolversTypes['Food'], ParentType, ContextType, RequireFields<QueryFoodArgs, 'id'>>;
  foodCategories?: Resolver<Array<ResolversTypes['FoodCategory']>, ParentType, ContextType, RequireFields<QueryFoodCategoriesArgs, 'filter'>>;
  foodCategory?: Resolver<ResolversTypes['FoodCategory'], ParentType, ContextType, RequireFields<QueryFoodCategoryArgs, 'id'>>;
  foodCategoryWithFoods?: Resolver<ResolversTypes['FoodCategory'], ParentType, ContextType, RequireFields<QueryFoodCategoryWithFoodsArgs, 'id'>>;
  foodImage?: Resolver<ResolversTypes['FoodImage'], ParentType, ContextType, RequireFields<QueryFoodImageArgs, 'id'>>;
  foodImages?: Resolver<Array<ResolversTypes['FoodImage']>, ParentType, ContextType>;
  foodWithFoodCategory?: Resolver<ResolversTypes['Food'], ParentType, ContextType, RequireFields<QueryFoodWithFoodCategoryArgs, 'id'>>;
  foods?: Resolver<Array<ResolversTypes['Food']>, ParentType, ContextType, RequireFields<QueryFoodsArgs, 'filter'>>;
  foodsByFoodCategory?: Resolver<Array<ResolversTypes['Food']>, ParentType, ContextType, RequireFields<QueryFoodsByFoodCategoryArgs, 'id'>>;
  foodsByUser?: Resolver<Array<ResolversTypes['Food']>, ParentType, ContextType, RequireFields<QueryFoodsByUserArgs, 'id'>>;
  foodsCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryFoodsCountArgs, 'filter'>>;
  hello?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  me?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  plant?: Resolver<ResolversTypes['Plant'], ParentType, ContextType, RequireFields<QueryPlantArgs, 'id'>>;
  plants?: Resolver<Array<ResolversTypes['Plant']>, ParentType, ContextType, RequireFields<QueryPlantsArgs, 'filter'>>;
  plantsByUserId?: Resolver<Array<ResolversTypes['Plant']>, ParentType, ContextType, RequireFields<QueryPlantsByUserIdArgs, 'id'>>;
  plantsCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryPlantsCountArgs, 'filter'>>;
  province?: Resolver<ResolversTypes['Province'], ParentType, ContextType, RequireFields<QueryProvinceArgs, 'id'>>;
  provinces?: Resolver<Array<ResolversTypes['Province']>, ParentType, ContextType>;
  provincesByKeyword?: Resolver<Array<ResolversTypes['Province']>, ParentType, ContextType, RequireFields<QueryProvincesByKeywordArgs, 'keyword'>>;
  tambolsByAmphoeIdAndKeyword?: Resolver<Array<ResolversTypes['Tambon']>, ParentType, ContextType, RequireFields<QueryTambolsByAmphoeIdAndKeywordArgs, 'input'>>;
  tambolsByKeyword?: Resolver<Array<ResolversTypes['Tambon']>, ParentType, ContextType, RequireFields<QueryTambolsByKeywordArgs, 'keyword'>>;
  tambon?: Resolver<ResolversTypes['Tambon'], ParentType, ContextType, RequireFields<QueryTambonArgs, 'id'>>;
  tambonWithAmphoeAndProvince?: Resolver<ResolversTypes['TambonWithAmphoeAndProvinceArgs'], ParentType, ContextType, RequireFields<QueryTambonWithAmphoeAndProvinceArgs, 'id'>>;
  tambons?: Resolver<Array<ResolversTypes['Tambon']>, ParentType, ContextType>;
  tambonsByAmphoeId?: Resolver<Array<ResolversTypes['Tambon']>, ParentType, ContextType, RequireFields<QueryTambonsByAmphoeIdArgs, 'amphoeId'>>;
  travel?: Resolver<ResolversTypes['Travel'], ParentType, ContextType, RequireFields<QueryTravelArgs, 'id'>>;
  travelType?: Resolver<ResolversTypes['TravelType'], ParentType, ContextType, RequireFields<QueryTravelTypeArgs, 'id'>>;
  travelTypes?: Resolver<Array<ResolversTypes['TravelType']>, ParentType, ContextType>;
  travels?: Resolver<Array<ResolversTypes['Travel']>, ParentType, ContextType, RequireFields<QueryTravelsArgs, 'filter'>>;
  travelsByUser?: Resolver<ResolversTypes['Travel'], ParentType, ContextType, RequireFields<QueryTravelsByUserArgs, 'id'>>;
  travelsCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryTravelsCountArgs, 'filter'>>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<QueryUserArgs, 'id'>>;
  userByEmail?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<QueryUserByEmailArgs, 'email'>>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUsersArgs, 'filter'>>;
  usersCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryUsersCountArgs, 'filter'>>;
  wisdom?: Resolver<ResolversTypes['Wisdom'], ParentType, ContextType, RequireFields<QueryWisdomArgs, 'id'>>;
  wisdomCategories?: Resolver<Array<ResolversTypes['WisdomCategory']>, ParentType, ContextType>;
  wisdomCategory?: Resolver<ResolversTypes['WisdomCategory'], ParentType, ContextType, RequireFields<QueryWisdomCategoryArgs, 'id'>>;
  wisdomCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryWisdomCountArgs, 'filter'>>;
  wisdoms?: Resolver<Array<ResolversTypes['Wisdom']>, ParentType, ContextType, RequireFields<QueryWisdomsArgs, 'filter'>>;
  wisdomsByCategory?: Resolver<Array<ResolversTypes['Wisdom']>, ParentType, ContextType, RequireFields<QueryWisdomsByCategoryArgs, 'id'>>;
  wisdomsByUser?: Resolver<Array<ResolversTypes['Wisdom']>, ParentType, ContextType, RequireFields<QueryWisdomsByUserArgs, 'id'>>;
};

export type TambonResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tambon'] = ResolversParentTypes['Tambon']> = {
  Address?: Resolver<Maybe<Array<ResolversTypes['Address']>>, ParentType, ContextType>;
  Food?: Resolver<Maybe<Array<ResolversTypes['Food']>>, ParentType, ContextType>;
  Plant?: Resolver<Maybe<Array<ResolversTypes['Plant']>>, ParentType, ContextType>;
  Travel?: Resolver<Maybe<Array<ResolversTypes['Travel']>>, ParentType, ContextType>;
  Wisdom?: Resolver<Maybe<Array<ResolversTypes['Wisdom']>>, ParentType, ContextType>;
  WisdomCategory?: Resolver<Maybe<Array<ResolversTypes['WisdomCategory']>>, ParentType, ContextType>;
  _count?: Resolver<ResolversTypes['TambonCount'], ParentType, ContextType>;
  amphoe?: Resolver<Maybe<ResolversTypes['Amphoe']>, ParentType, ContextType>;
  amphoeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  nameEn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameTh?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  zipCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TambonCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['TambonCount'] = ResolversParentTypes['TambonCount']> = {
  Address?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Food?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Plant?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Travel?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Wisdom?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  WisdomCategory?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TambonCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TambonCountAggregate'] = ResolversParentTypes['TambonCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  amphoeId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nameEn?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nameTh?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  zipCode?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TambonMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TambonMaxAggregate'] = ResolversParentTypes['TambonMaxAggregate']> = {
  amphoeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameTh?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TambonMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TambonMinAggregate'] = ResolversParentTypes['TambonMinAggregate']> = {
  amphoeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameTh?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TambonWithAmphoeAndProvinceArgsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TambonWithAmphoeAndProvinceArgs'] = ResolversParentTypes['TambonWithAmphoeAndProvinceArgs']> = {
  amphoe?: Resolver<ResolversTypes['Amphoe'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameTh?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  province?: Resolver<ResolversTypes['Province'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelResolvers<ContextType = any, ParentType extends ResolversParentTypes['Travel'] = ResolversParentTypes['Travel']> = {
  _count?: Resolver<ResolversTypes['TravelCount'], ParentType, ContextType>;
  averageTouristPerYear?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  contactNumbers?: Resolver<Maybe<Array<ResolversTypes['ContactNumber']>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  facilities?: Resolver<Maybe<Array<ResolversTypes['TravelsOnFacilities']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  images?: Resolver<Maybe<Array<ResolversTypes['TravelImage']>>, ParentType, ContextType>;
  incomePerYear?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  informant?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isSeasonal?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  latitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  longitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfStaff?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  seasonStartMonth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seasonalEndMonth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tambon?: Resolver<ResolversTypes['Tambon'], ParentType, ContextType>;
  tambonId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  travelTypeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TravelType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  websites?: Resolver<Maybe<Array<ResolversTypes['Website']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelAvgAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelAvgAggregate'] = ResolversParentTypes['TravelAvgAggregate']> = {
  averageTouristPerYear?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  incomePerYear?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numberOfStaff?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  seasonStartMonth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  seasonalEndMonth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelCount'] = ResolversParentTypes['TravelCount']> = {
  contactNumbers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  facilities?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  images?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  websites?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelCountAggregate'] = ResolversParentTypes['TravelCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  averageTouristPerYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  incomePerYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  informant?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isSeasonal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  latitude?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  longitude?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  numberOfStaff?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  seasonStartMonth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  seasonalEndMonth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tambonId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  travelTypeId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelImage'] = ResolversParentTypes['TravelImage']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  travel?: Resolver<Maybe<ResolversTypes['Travel']>, ParentType, ContextType>;
  travelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelImageCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelImageCountAggregate'] = ResolversParentTypes['TravelImageCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  travelId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelImageMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelImageMaxAggregate'] = ResolversParentTypes['TravelImageMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  travelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelImageMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelImageMinAggregate'] = ResolversParentTypes['TravelImageMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  travelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelMaxAggregate'] = ResolversParentTypes['TravelMaxAggregate']> = {
  averageTouristPerYear?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  incomePerYear?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  informant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isSeasonal?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numberOfStaff?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seasonStartMonth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seasonalEndMonth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tambonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  travelTypeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelMinAggregate'] = ResolversParentTypes['TravelMinAggregate']> = {
  averageTouristPerYear?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  incomePerYear?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  informant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isSeasonal?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numberOfStaff?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seasonStartMonth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seasonalEndMonth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tambonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  travelTypeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelSumAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelSumAggregate'] = ResolversParentTypes['TravelSumAggregate']> = {
  averageTouristPerYear?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  incomePerYear?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numberOfStaff?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seasonStartMonth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seasonalEndMonth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelType'] = ResolversParentTypes['TravelType']> = {
  _count?: Resolver<ResolversTypes['TravelTypeCount'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  travels?: Resolver<Maybe<Array<ResolversTypes['Travel']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelTypeCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelTypeCount'] = ResolversParentTypes['TravelTypeCount']> = {
  travels?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelTypeCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelTypeCountAggregate'] = ResolversParentTypes['TravelTypeCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelTypeMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelTypeMaxAggregate'] = ResolversParentTypes['TravelTypeMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelTypeMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelTypeMinAggregate'] = ResolversParentTypes['TravelTypeMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelsOnFacilitiesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelsOnFacilities'] = ResolversParentTypes['TravelsOnFacilities']> = {
  assignedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  facility?: Resolver<ResolversTypes['Facility'], ParentType, ContextType>;
  facilityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  travel?: Resolver<ResolversTypes['Travel'], ParentType, ContextType>;
  travelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelsOnFacilitiesCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelsOnFacilitiesCountAggregate'] = ResolversParentTypes['TravelsOnFacilitiesCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  assignedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  facilityId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  travelId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelsOnFacilitiesMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelsOnFacilitiesMaxAggregate'] = ResolversParentTypes['TravelsOnFacilitiesMaxAggregate']> = {
  assignedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  facilityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  travelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TravelsOnFacilitiesMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TravelsOnFacilitiesMinAggregate'] = ResolversParentTypes['TravelsOnFacilitiesMinAggregate']> = {
  assignedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  facilityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  travelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniqueWisdomResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniqueWisdom'] = ResolversParentTypes['UniqueWisdom']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  wisdom?: Resolver<ResolversTypes['Wisdom'], ParentType, ContextType>;
  wisdomId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniqueWisdomCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniqueWisdomCountAggregate'] = ResolversParentTypes['UniqueWisdomCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  wisdomId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniqueWisdomMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniqueWisdomMaxAggregate'] = ResolversParentTypes['UniqueWisdomMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  wisdomId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniqueWisdomMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniqueWisdomMinAggregate'] = ResolversParentTypes['UniqueWisdomMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  wisdomId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  _count?: Resolver<ResolversTypes['UserCount'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailVerified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  emailVerifyToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  foods?: Resolver<Maybe<Array<ResolversTypes['Food']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plants?: Resolver<Maybe<Array<ResolversTypes['Plant']>>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['Role'], ParentType, ContextType>;
  travels?: Resolver<Maybe<Array<ResolversTypes['Travel']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  wisdoms?: Resolver<Maybe<Array<ResolversTypes['Wisdom']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserCount'] = ResolversParentTypes['UserCount']> = {
  foods?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  plants?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  travels?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  wisdoms?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserCountAggregate'] = ResolversParentTypes['UserCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  emailVerified?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  emailVerifyToken?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  image?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  password?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMaxAggregate'] = ResolversParentTypes['UserMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailVerified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  emailVerifyToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMinAggregate'] = ResolversParentTypes['UserMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailVerified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  emailVerifyToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerificationTokenCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['VerificationTokenCountAggregate'] = ResolversParentTypes['VerificationTokenCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expires?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  identifier?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerificationTokenMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['VerificationTokenMaxAggregate'] = ResolversParentTypes['VerificationTokenMaxAggregate']> = {
  expires?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  identifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerificationTokenMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['VerificationTokenMinAggregate'] = ResolversParentTypes['VerificationTokenMinAggregate']> = {
  expires?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  identifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebsiteResolvers<ContextType = any, ParentType extends ResolversParentTypes['Website'] = ResolversParentTypes['Website']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  travel?: Resolver<Maybe<ResolversTypes['Travel']>, ParentType, ContextType>;
  travelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebsiteCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebsiteCountAggregate'] = ResolversParentTypes['WebsiteCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  travelId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebsiteMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebsiteMaxAggregate'] = ResolversParentTypes['WebsiteMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  travelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebsiteMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebsiteMinAggregate'] = ResolversParentTypes['WebsiteMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  travelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomResolvers<ContextType = any, ParentType extends ResolversParentTypes['Wisdom'] = ResolversParentTypes['Wisdom']> = {
  _count?: Resolver<ResolversTypes['WisdomCount'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['WisdomCategory'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  images?: Resolver<Maybe<Array<ResolversTypes['WisdomImage']>>, ParentType, ContextType>;
  informant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  longitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patentedStatus?: Resolver<ResolversTypes['PatentedStatus'], ParentType, ContextType>;
  pr?: Resolver<ResolversTypes['WisdomPR'], ParentType, ContextType>;
  publishable?: Resolver<Maybe<Array<ResolversTypes['PublishableWisdom']>>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['WisdomStatus'], ParentType, ContextType>;
  tambon?: Resolver<ResolversTypes['Tambon'], ParentType, ContextType>;
  tambonId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tools?: Resolver<Maybe<Array<ResolversTypes['WisdomTool']>>, ParentType, ContextType>;
  uniqueWisdom?: Resolver<Maybe<Array<ResolversTypes['UniqueWisdom']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  wisdomCategoryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomAvgAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomAvgAggregate'] = ResolversParentTypes['WisdomAvgAggregate']> = {
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomCategory'] = ResolversParentTypes['WisdomCategory']> = {
  _count?: Resolver<ResolversTypes['WisdomCategoryCount'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tambon?: Resolver<Maybe<ResolversTypes['Tambon']>, ParentType, ContextType>;
  tambonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  wisdoms?: Resolver<Maybe<Array<ResolversTypes['Wisdom']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomCategoryCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomCategoryCount'] = ResolversParentTypes['WisdomCategoryCount']> = {
  wisdoms?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomCategoryCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomCategoryCountAggregate'] = ResolversParentTypes['WisdomCategoryCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tambonId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomCategoryMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomCategoryMaxAggregate'] = ResolversParentTypes['WisdomCategoryMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tambonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomCategoryMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomCategoryMinAggregate'] = ResolversParentTypes['WisdomCategoryMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tambonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomCount'] = ResolversParentTypes['WisdomCount']> = {
  images?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  publishable?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tools?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  uniqueWisdom?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomCountAggregate'] = ResolversParentTypes['WisdomCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  informant?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  latitude?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  longitude?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  patentedStatus?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pr?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tambonId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  wisdomCategoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomImage'] = ResolversParentTypes['WisdomImage']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  wisdom?: Resolver<ResolversTypes['Wisdom'], ParentType, ContextType>;
  wisdomId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomImageCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomImageCountAggregate'] = ResolversParentTypes['WisdomImageCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  wisdomId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomImageMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomImageMaxAggregate'] = ResolversParentTypes['WisdomImageMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wisdomId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomImageMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomImageMinAggregate'] = ResolversParentTypes['WisdomImageMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wisdomId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomMaxAggregate'] = ResolversParentTypes['WisdomMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  informant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patentedStatus?: Resolver<Maybe<ResolversTypes['PatentedStatus']>, ParentType, ContextType>;
  pr?: Resolver<Maybe<ResolversTypes['WisdomPR']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['WisdomStatus']>, ParentType, ContextType>;
  tambonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wisdomCategoryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomMinAggregate'] = ResolversParentTypes['WisdomMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  informant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patentedStatus?: Resolver<Maybe<ResolversTypes['PatentedStatus']>, ParentType, ContextType>;
  pr?: Resolver<Maybe<ResolversTypes['WisdomPR']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['WisdomStatus']>, ParentType, ContextType>;
  tambonId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wisdomCategoryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomSumAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomSumAggregate'] = ResolversParentTypes['WisdomSumAggregate']> = {
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomToolResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomTool'] = ResolversParentTypes['WisdomTool']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  wisdom?: Resolver<ResolversTypes['Wisdom'], ParentType, ContextType>;
  wisdomId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomToolCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomToolCountAggregate'] = ResolversParentTypes['WisdomToolCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  wisdomId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomToolMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomToolMaxAggregate'] = ResolversParentTypes['WisdomToolMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  wisdomId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WisdomToolMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WisdomToolMinAggregate'] = ResolversParentTypes['WisdomToolMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  wisdomId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Address?: AddressResolvers<ContextType>;
  AddressCountAggregate?: AddressCountAggregateResolvers<ContextType>;
  AddressMaxAggregate?: AddressMaxAggregateResolvers<ContextType>;
  AddressMinAggregate?: AddressMinAggregateResolvers<ContextType>;
  Amphoe?: AmphoeResolvers<ContextType>;
  AmphoeCount?: AmphoeCountResolvers<ContextType>;
  AmphoeCountAggregate?: AmphoeCountAggregateResolvers<ContextType>;
  AmphoeMaxAggregate?: AmphoeMaxAggregateResolvers<ContextType>;
  AmphoeMinAggregate?: AmphoeMinAggregateResolvers<ContextType>;
  ContactNumber?: ContactNumberResolvers<ContextType>;
  ContactNumberCountAggregate?: ContactNumberCountAggregateResolvers<ContextType>;
  ContactNumberMaxAggregate?: ContactNumberMaxAggregateResolvers<ContextType>;
  ContactNumberMinAggregate?: ContactNumberMinAggregateResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Facility?: FacilityResolvers<ContextType>;
  FacilityCount?: FacilityCountResolvers<ContextType>;
  FacilityCountAggregate?: FacilityCountAggregateResolvers<ContextType>;
  FacilityMaxAggregate?: FacilityMaxAggregateResolvers<ContextType>;
  FacilityMinAggregate?: FacilityMinAggregateResolvers<ContextType>;
  Food?: FoodResolvers<ContextType>;
  FoodAvgAggregate?: FoodAvgAggregateResolvers<ContextType>;
  FoodCategory?: FoodCategoryResolvers<ContextType>;
  FoodCategoryCount?: FoodCategoryCountResolvers<ContextType>;
  FoodCategoryCountAggregate?: FoodCategoryCountAggregateResolvers<ContextType>;
  FoodCategoryMaxAggregate?: FoodCategoryMaxAggregateResolvers<ContextType>;
  FoodCategoryMinAggregate?: FoodCategoryMinAggregateResolvers<ContextType>;
  FoodCount?: FoodCountResolvers<ContextType>;
  FoodCountAggregate?: FoodCountAggregateResolvers<ContextType>;
  FoodImage?: FoodImageResolvers<ContextType>;
  FoodImageCountAggregate?: FoodImageCountAggregateResolvers<ContextType>;
  FoodImageMaxAggregate?: FoodImageMaxAggregateResolvers<ContextType>;
  FoodImageMinAggregate?: FoodImageMinAggregateResolvers<ContextType>;
  FoodMaxAggregate?: FoodMaxAggregateResolvers<ContextType>;
  FoodMinAggregate?: FoodMinAggregateResolvers<ContextType>;
  FoodSumAggregate?: FoodSumAggregateResolvers<ContextType>;
  Ingredients?: IngredientsResolvers<ContextType>;
  IngredientsCountAggregate?: IngredientsCountAggregateResolvers<ContextType>;
  IngredientsMaxAggregate?: IngredientsMaxAggregateResolvers<ContextType>;
  IngredientsMinAggregate?: IngredientsMinAggregateResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  Mutation?: MutationResolvers<ContextType>;
  Plant?: PlantResolvers<ContextType>;
  PlantAvgAggregate?: PlantAvgAggregateResolvers<ContextType>;
  PlantCount?: PlantCountResolvers<ContextType>;
  PlantCountAggregate?: PlantCountAggregateResolvers<ContextType>;
  PlantImage?: PlantImageResolvers<ContextType>;
  PlantImageCountAggregate?: PlantImageCountAggregateResolvers<ContextType>;
  PlantImageMaxAggregate?: PlantImageMaxAggregateResolvers<ContextType>;
  PlantImageMinAggregate?: PlantImageMinAggregateResolvers<ContextType>;
  PlantKindOfUse?: PlantKindOfUseResolvers<ContextType>;
  PlantKindOfUseCount?: PlantKindOfUseCountResolvers<ContextType>;
  PlantKindOfUseCountAggregate?: PlantKindOfUseCountAggregateResolvers<ContextType>;
  PlantKindOfUseMaxAggregate?: PlantKindOfUseMaxAggregateResolvers<ContextType>;
  PlantKindOfUseMinAggregate?: PlantKindOfUseMinAggregateResolvers<ContextType>;
  PlantMaxAggregate?: PlantMaxAggregateResolvers<ContextType>;
  PlantMinAggregate?: PlantMinAggregateResolvers<ContextType>;
  PlantOnPlantKindOfUse?: PlantOnPlantKindOfUseResolvers<ContextType>;
  PlantOnPlantKindOfUseCountAggregate?: PlantOnPlantKindOfUseCountAggregateResolvers<ContextType>;
  PlantOnPlantKindOfUseMaxAggregate?: PlantOnPlantKindOfUseMaxAggregateResolvers<ContextType>;
  PlantOnPlantKindOfUseMinAggregate?: PlantOnPlantKindOfUseMinAggregateResolvers<ContextType>;
  PlantSumAggregate?: PlantSumAggregateResolvers<ContextType>;
  Province?: ProvinceResolvers<ContextType>;
  ProvinceCount?: ProvinceCountResolvers<ContextType>;
  ProvinceCountAggregate?: ProvinceCountAggregateResolvers<ContextType>;
  ProvinceMaxAggregate?: ProvinceMaxAggregateResolvers<ContextType>;
  ProvinceMinAggregate?: ProvinceMinAggregateResolvers<ContextType>;
  PublishableWisdom?: PublishableWisdomResolvers<ContextType>;
  PublishableWisdomCountAggregate?: PublishableWisdomCountAggregateResolvers<ContextType>;
  PublishableWisdomMaxAggregate?: PublishableWisdomMaxAggregateResolvers<ContextType>;
  PublishableWisdomMinAggregate?: PublishableWisdomMinAggregateResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Tambon?: TambonResolvers<ContextType>;
  TambonCount?: TambonCountResolvers<ContextType>;
  TambonCountAggregate?: TambonCountAggregateResolvers<ContextType>;
  TambonMaxAggregate?: TambonMaxAggregateResolvers<ContextType>;
  TambonMinAggregate?: TambonMinAggregateResolvers<ContextType>;
  TambonWithAmphoeAndProvinceArgs?: TambonWithAmphoeAndProvinceArgsResolvers<ContextType>;
  Travel?: TravelResolvers<ContextType>;
  TravelAvgAggregate?: TravelAvgAggregateResolvers<ContextType>;
  TravelCount?: TravelCountResolvers<ContextType>;
  TravelCountAggregate?: TravelCountAggregateResolvers<ContextType>;
  TravelImage?: TravelImageResolvers<ContextType>;
  TravelImageCountAggregate?: TravelImageCountAggregateResolvers<ContextType>;
  TravelImageMaxAggregate?: TravelImageMaxAggregateResolvers<ContextType>;
  TravelImageMinAggregate?: TravelImageMinAggregateResolvers<ContextType>;
  TravelMaxAggregate?: TravelMaxAggregateResolvers<ContextType>;
  TravelMinAggregate?: TravelMinAggregateResolvers<ContextType>;
  TravelSumAggregate?: TravelSumAggregateResolvers<ContextType>;
  TravelType?: TravelTypeResolvers<ContextType>;
  TravelTypeCount?: TravelTypeCountResolvers<ContextType>;
  TravelTypeCountAggregate?: TravelTypeCountAggregateResolvers<ContextType>;
  TravelTypeMaxAggregate?: TravelTypeMaxAggregateResolvers<ContextType>;
  TravelTypeMinAggregate?: TravelTypeMinAggregateResolvers<ContextType>;
  TravelsOnFacilities?: TravelsOnFacilitiesResolvers<ContextType>;
  TravelsOnFacilitiesCountAggregate?: TravelsOnFacilitiesCountAggregateResolvers<ContextType>;
  TravelsOnFacilitiesMaxAggregate?: TravelsOnFacilitiesMaxAggregateResolvers<ContextType>;
  TravelsOnFacilitiesMinAggregate?: TravelsOnFacilitiesMinAggregateResolvers<ContextType>;
  UniqueWisdom?: UniqueWisdomResolvers<ContextType>;
  UniqueWisdomCountAggregate?: UniqueWisdomCountAggregateResolvers<ContextType>;
  UniqueWisdomMaxAggregate?: UniqueWisdomMaxAggregateResolvers<ContextType>;
  UniqueWisdomMinAggregate?: UniqueWisdomMinAggregateResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserCount?: UserCountResolvers<ContextType>;
  UserCountAggregate?: UserCountAggregateResolvers<ContextType>;
  UserMaxAggregate?: UserMaxAggregateResolvers<ContextType>;
  UserMinAggregate?: UserMinAggregateResolvers<ContextType>;
  VerificationTokenCountAggregate?: VerificationTokenCountAggregateResolvers<ContextType>;
  VerificationTokenMaxAggregate?: VerificationTokenMaxAggregateResolvers<ContextType>;
  VerificationTokenMinAggregate?: VerificationTokenMinAggregateResolvers<ContextType>;
  Website?: WebsiteResolvers<ContextType>;
  WebsiteCountAggregate?: WebsiteCountAggregateResolvers<ContextType>;
  WebsiteMaxAggregate?: WebsiteMaxAggregateResolvers<ContextType>;
  WebsiteMinAggregate?: WebsiteMinAggregateResolvers<ContextType>;
  Wisdom?: WisdomResolvers<ContextType>;
  WisdomAvgAggregate?: WisdomAvgAggregateResolvers<ContextType>;
  WisdomCategory?: WisdomCategoryResolvers<ContextType>;
  WisdomCategoryCount?: WisdomCategoryCountResolvers<ContextType>;
  WisdomCategoryCountAggregate?: WisdomCategoryCountAggregateResolvers<ContextType>;
  WisdomCategoryMaxAggregate?: WisdomCategoryMaxAggregateResolvers<ContextType>;
  WisdomCategoryMinAggregate?: WisdomCategoryMinAggregateResolvers<ContextType>;
  WisdomCount?: WisdomCountResolvers<ContextType>;
  WisdomCountAggregate?: WisdomCountAggregateResolvers<ContextType>;
  WisdomImage?: WisdomImageResolvers<ContextType>;
  WisdomImageCountAggregate?: WisdomImageCountAggregateResolvers<ContextType>;
  WisdomImageMaxAggregate?: WisdomImageMaxAggregateResolvers<ContextType>;
  WisdomImageMinAggregate?: WisdomImageMinAggregateResolvers<ContextType>;
  WisdomMaxAggregate?: WisdomMaxAggregateResolvers<ContextType>;
  WisdomMinAggregate?: WisdomMinAggregateResolvers<ContextType>;
  WisdomSumAggregate?: WisdomSumAggregateResolvers<ContextType>;
  WisdomTool?: WisdomToolResolvers<ContextType>;
  WisdomToolCountAggregate?: WisdomToolCountAggregateResolvers<ContextType>;
  WisdomToolMaxAggregate?: WisdomToolMaxAggregateResolvers<ContextType>;
  WisdomToolMinAggregate?: WisdomToolMinAggregateResolvers<ContextType>;
};


export const AddressFragmentFragmentDoc = gql`
    fragment AddressFragment on Address {
  addressLine1
  addressLine2
  createdAt
  id
  name
  tambonId
  updatedAt
}
    `;
export const AddressCountAggregateFragmentFragmentDoc = gql`
    fragment AddressCountAggregateFragment on AddressCountAggregate {
  _all
  addressLine1
  addressLine2
  createdAt
  id
  name
  tambonId
  updatedAt
}
    `;
export const AddressMaxAggregateFragmentFragmentDoc = gql`
    fragment AddressMaxAggregateFragment on AddressMaxAggregate {
  addressLine1
  addressLine2
  createdAt
  id
  name
  tambonId
  updatedAt
}
    `;
export const AddressMinAggregateFragmentFragmentDoc = gql`
    fragment AddressMinAggregateFragment on AddressMinAggregate {
  addressLine1
  addressLine2
  createdAt
  id
  name
  tambonId
  updatedAt
}
    `;
export const AmphoeCountFragmentFragmentDoc = gql`
    fragment AmphoeCountFragment on AmphoeCount {
  tambons
}
    `;
export const AmphoeCountAggregateFragmentFragmentDoc = gql`
    fragment AmphoeCountAggregateFragment on AmphoeCountAggregate {
  _all
  createdAt
  id
  nameEn
  nameTh
  provinceId
  updatedAt
}
    `;
export const AmphoeMaxAggregateFragmentFragmentDoc = gql`
    fragment AmphoeMaxAggregateFragment on AmphoeMaxAggregate {
  createdAt
  id
  nameEn
  nameTh
  provinceId
  updatedAt
}
    `;
export const AmphoeMinAggregateFragmentFragmentDoc = gql`
    fragment AmphoeMinAggregateFragment on AmphoeMinAggregate {
  createdAt
  id
  nameEn
  nameTh
  provinceId
  updatedAt
}
    `;
export const ContactNumberCountAggregateFragmentFragmentDoc = gql`
    fragment ContactNumberCountAggregateFragment on ContactNumberCountAggregate {
  _all
  createdAt
  id
  tel
  travelId
  updatedAt
}
    `;
export const ContactNumberMaxAggregateFragmentFragmentDoc = gql`
    fragment ContactNumberMaxAggregateFragment on ContactNumberMaxAggregate {
  createdAt
  id
  tel
  travelId
  updatedAt
}
    `;
export const ContactNumberMinAggregateFragmentFragmentDoc = gql`
    fragment ContactNumberMinAggregateFragment on ContactNumberMinAggregate {
  createdAt
  id
  tel
  travelId
  updatedAt
}
    `;
export const FacilityCountFragmentFragmentDoc = gql`
    fragment FacilityCountFragment on FacilityCount {
  travels
}
    `;
export const FacilityCountAggregateFragmentFragmentDoc = gql`
    fragment FacilityCountAggregateFragment on FacilityCountAggregate {
  _all
  createdAt
  id
  name
  travelId
  updatedAt
}
    `;
export const FacilityMaxAggregateFragmentFragmentDoc = gql`
    fragment FacilityMaxAggregateFragment on FacilityMaxAggregate {
  createdAt
  id
  name
  travelId
  updatedAt
}
    `;
export const FacilityMinAggregateFragmentFragmentDoc = gql`
    fragment FacilityMinAggregateFragment on FacilityMinAggregate {
  createdAt
  id
  name
  travelId
  updatedAt
}
    `;
export const FoodCategoryFragmentFragmentDoc = gql`
    fragment FoodCategoryFragment on FoodCategory {
  createdAt
  id
  name
  updatedAt
}
    `;
export const FoodImageFragmentFragmentDoc = gql`
    fragment FoodImageFragment on FoodImage {
  createdAt
  foodId
  id
  updatedAt
  url
}
    `;
export const IngredientsFragmentFragmentDoc = gql`
    fragment IngredientsFragment on Ingredients {
  createdAt
  foodId
  id
  name
  updatedAt
}
    `;
export const TambonFragmentFragmentDoc = gql`
    fragment TambonFragment on Tambon {
  amphoeId
  createdAt
  id
  nameEn
  nameTh
  updatedAt
  zipCode
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  createdAt
  email
  emailVerified
  emailVerifyToken
  id
  image
  name
  password
  role
  updatedAt
}
    `;
export const FoodFragmentFragmentDoc = gql`
    fragment FoodFragment on Food {
  createdAt
  foodCategory {
    ...FoodCategoryFragment
  }
  foodCategoryId
  hasParking
  id
  images {
    ...FoodImageFragment
  }
  ingredients {
    ...IngredientsFragment
  }
  latitude
  longitude
  name
  productCapacity
  shop
  story
  tambon {
    ...TambonFragment
  }
  tambonId
  updatedAt
  user {
    ...UserFragment
  }
  userId
}
    ${FoodCategoryFragmentFragmentDoc}
${FoodImageFragmentFragmentDoc}
${IngredientsFragmentFragmentDoc}
${TambonFragmentFragmentDoc}
${UserFragmentFragmentDoc}`;
export const FoodAvgAggregateFragmentFragmentDoc = gql`
    fragment FoodAvgAggregateFragment on FoodAvgAggregate {
  latitude
  longitude
  productCapacity
}
    `;
export const FoodCategoryCountFragmentFragmentDoc = gql`
    fragment FoodCategoryCountFragment on FoodCategoryCount {
  food
}
    `;
export const FoodCategoryCountAggregateFragmentFragmentDoc = gql`
    fragment FoodCategoryCountAggregateFragment on FoodCategoryCountAggregate {
  _all
  createdAt
  id
  name
  updatedAt
}
    `;
export const FoodCategoryMaxAggregateFragmentFragmentDoc = gql`
    fragment FoodCategoryMaxAggregateFragment on FoodCategoryMaxAggregate {
  createdAt
  id
  name
  updatedAt
}
    `;
export const FoodCategoryMinAggregateFragmentFragmentDoc = gql`
    fragment FoodCategoryMinAggregateFragment on FoodCategoryMinAggregate {
  createdAt
  id
  name
  updatedAt
}
    `;
export const FoodCountFragmentFragmentDoc = gql`
    fragment FoodCountFragment on FoodCount {
  images
  ingredients
}
    `;
export const FoodCountAggregateFragmentFragmentDoc = gql`
    fragment FoodCountAggregateFragment on FoodCountAggregate {
  _all
  createdAt
  foodCategoryId
  hasParking
  id
  latitude
  longitude
  name
  productCapacity
  shop
  story
  tambonId
  updatedAt
  userId
}
    `;
export const FoodImageCountAggregateFragmentFragmentDoc = gql`
    fragment FoodImageCountAggregateFragment on FoodImageCountAggregate {
  _all
  createdAt
  foodId
  id
  updatedAt
  url
}
    `;
export const FoodImageMaxAggregateFragmentFragmentDoc = gql`
    fragment FoodImageMaxAggregateFragment on FoodImageMaxAggregate {
  createdAt
  foodId
  id
  updatedAt
  url
}
    `;
export const FoodImageMinAggregateFragmentFragmentDoc = gql`
    fragment FoodImageMinAggregateFragment on FoodImageMinAggregate {
  createdAt
  foodId
  id
  updatedAt
  url
}
    `;
export const FoodMaxAggregateFragmentFragmentDoc = gql`
    fragment FoodMaxAggregateFragment on FoodMaxAggregate {
  createdAt
  foodCategoryId
  hasParking
  id
  latitude
  longitude
  name
  productCapacity
  shop
  story
  tambonId
  updatedAt
  userId
}
    `;
export const FoodMinAggregateFragmentFragmentDoc = gql`
    fragment FoodMinAggregateFragment on FoodMinAggregate {
  createdAt
  foodCategoryId
  hasParking
  id
  latitude
  longitude
  name
  productCapacity
  shop
  story
  tambonId
  updatedAt
  userId
}
    `;
export const FoodSumAggregateFragmentFragmentDoc = gql`
    fragment FoodSumAggregateFragment on FoodSumAggregate {
  latitude
  longitude
  productCapacity
}
    `;
export const IngredientsCountAggregateFragmentFragmentDoc = gql`
    fragment IngredientsCountAggregateFragment on IngredientsCountAggregate {
  _all
  createdAt
  foodId
  id
  name
  updatedAt
}
    `;
export const IngredientsMaxAggregateFragmentFragmentDoc = gql`
    fragment IngredientsMaxAggregateFragment on IngredientsMaxAggregate {
  createdAt
  foodId
  id
  name
  updatedAt
}
    `;
export const IngredientsMinAggregateFragmentFragmentDoc = gql`
    fragment IngredientsMinAggregateFragment on IngredientsMinAggregate {
  createdAt
  foodId
  id
  name
  updatedAt
}
    `;
export const PlantImageFragmentFragmentDoc = gql`
    fragment PlantImageFragment on PlantImage {
  createdAt
  id
  kind
  plantId
  updatedAt
  url
}
    `;
export const PlantKindOfUseFragmentFragmentDoc = gql`
    fragment PlantKindOfUseFragment on PlantKindOfUse {
  createdAt
  id
  name
  updatedAt
}
    `;
export const PlantOnPlantKindOfUseFragmentFragmentDoc = gql`
    fragment PlantOnPlantKindOfUseFragment on PlantOnPlantKindOfUse {
  createdAt
  plantId
  plantKind {
    ...PlantKindOfUseFragment
  }
  plantKindId
  updatedAt
}
    ${PlantKindOfUseFragmentFragmentDoc}`;
export const PlantFragmentFragmentDoc = gql`
    fragment PlantFragment on Plant {
  createdAt
  foundFrequencyInMonthEnd
  foundFrequencyInMonthStart
  hasOwner
  id
  images {
    ...PlantImageFragment
  }
  informant
  kindOfUses {
    ...PlantOnPlantKindOfUseFragment
  }
  latitude
  longitude
  name
  ownerName
  quantity
  tambon {
    ...TambonFragment
  }
  tambonId
  updatedAt
  user {
    ...UserFragment
  }
  userId
}
    ${PlantImageFragmentFragmentDoc}
${PlantOnPlantKindOfUseFragmentFragmentDoc}
${TambonFragmentFragmentDoc}
${UserFragmentFragmentDoc}`;
export const PlantAvgAggregateFragmentFragmentDoc = gql`
    fragment PlantAvgAggregateFragment on PlantAvgAggregate {
  foundFrequencyInMonthEnd
  foundFrequencyInMonthStart
  latitude
  longitude
  quantity
}
    `;
export const PlantCountFragmentFragmentDoc = gql`
    fragment PlantCountFragment on PlantCount {
  images
  kindOfUses
}
    `;
export const PlantCountAggregateFragmentFragmentDoc = gql`
    fragment PlantCountAggregateFragment on PlantCountAggregate {
  _all
  createdAt
  foundFrequencyInMonthEnd
  foundFrequencyInMonthStart
  hasOwner
  id
  informant
  latitude
  longitude
  name
  ownerName
  quantity
  tambonId
  updatedAt
  userId
}
    `;
export const PlantImageCountAggregateFragmentFragmentDoc = gql`
    fragment PlantImageCountAggregateFragment on PlantImageCountAggregate {
  _all
  createdAt
  id
  kind
  plantId
  updatedAt
  url
}
    `;
export const PlantImageMaxAggregateFragmentFragmentDoc = gql`
    fragment PlantImageMaxAggregateFragment on PlantImageMaxAggregate {
  createdAt
  id
  kind
  plantId
  updatedAt
  url
}
    `;
export const PlantImageMinAggregateFragmentFragmentDoc = gql`
    fragment PlantImageMinAggregateFragment on PlantImageMinAggregate {
  createdAt
  id
  kind
  plantId
  updatedAt
  url
}
    `;
export const PlantKindOfUseCountFragmentFragmentDoc = gql`
    fragment PlantKindOfUseCountFragment on PlantKindOfUseCount {
  plantOnPlantKindOfUses
}
    `;
export const PlantKindOfUseCountAggregateFragmentFragmentDoc = gql`
    fragment PlantKindOfUseCountAggregateFragment on PlantKindOfUseCountAggregate {
  _all
  createdAt
  id
  name
  updatedAt
}
    `;
export const PlantKindOfUseMaxAggregateFragmentFragmentDoc = gql`
    fragment PlantKindOfUseMaxAggregateFragment on PlantKindOfUseMaxAggregate {
  createdAt
  id
  name
  updatedAt
}
    `;
export const PlantKindOfUseMinAggregateFragmentFragmentDoc = gql`
    fragment PlantKindOfUseMinAggregateFragment on PlantKindOfUseMinAggregate {
  createdAt
  id
  name
  updatedAt
}
    `;
export const PlantMaxAggregateFragmentFragmentDoc = gql`
    fragment PlantMaxAggregateFragment on PlantMaxAggregate {
  createdAt
  foundFrequencyInMonthEnd
  foundFrequencyInMonthStart
  hasOwner
  id
  informant
  latitude
  longitude
  name
  ownerName
  quantity
  tambonId
  updatedAt
  userId
}
    `;
export const PlantMinAggregateFragmentFragmentDoc = gql`
    fragment PlantMinAggregateFragment on PlantMinAggregate {
  createdAt
  foundFrequencyInMonthEnd
  foundFrequencyInMonthStart
  hasOwner
  id
  informant
  latitude
  longitude
  name
  ownerName
  quantity
  tambonId
  updatedAt
  userId
}
    `;
export const PlantOnPlantKindOfUseCountAggregateFragmentFragmentDoc = gql`
    fragment PlantOnPlantKindOfUseCountAggregateFragment on PlantOnPlantKindOfUseCountAggregate {
  _all
  createdAt
  plantId
  plantKindId
  updatedAt
}
    `;
export const PlantOnPlantKindOfUseMaxAggregateFragmentFragmentDoc = gql`
    fragment PlantOnPlantKindOfUseMaxAggregateFragment on PlantOnPlantKindOfUseMaxAggregate {
  createdAt
  plantId
  plantKindId
  updatedAt
}
    `;
export const PlantOnPlantKindOfUseMinAggregateFragmentFragmentDoc = gql`
    fragment PlantOnPlantKindOfUseMinAggregateFragment on PlantOnPlantKindOfUseMinAggregate {
  createdAt
  plantId
  plantKindId
  updatedAt
}
    `;
export const PlantSumAggregateFragmentFragmentDoc = gql`
    fragment PlantSumAggregateFragment on PlantSumAggregate {
  foundFrequencyInMonthEnd
  foundFrequencyInMonthStart
  latitude
  longitude
  quantity
}
    `;
export const ProvinceCountFragmentFragmentDoc = gql`
    fragment ProvinceCountFragment on ProvinceCount {
  amphoes
}
    `;
export const ProvinceCountAggregateFragmentFragmentDoc = gql`
    fragment ProvinceCountAggregateFragment on ProvinceCountAggregate {
  _all
  createdAt
  id
  nameEn
  nameTh
  updatedAt
}
    `;
export const ProvinceMaxAggregateFragmentFragmentDoc = gql`
    fragment ProvinceMaxAggregateFragment on ProvinceMaxAggregate {
  createdAt
  id
  nameEn
  nameTh
  updatedAt
}
    `;
export const ProvinceMinAggregateFragmentFragmentDoc = gql`
    fragment ProvinceMinAggregateFragment on ProvinceMinAggregate {
  createdAt
  id
  nameEn
  nameTh
  updatedAt
}
    `;
export const PublishableWisdomCountAggregateFragmentFragmentDoc = gql`
    fragment PublishableWisdomCountAggregateFragment on PublishableWisdomCountAggregate {
  _all
  createdAt
  id
  name
  tambonId
  updatedAt
  wisdomId
}
    `;
export const PublishableWisdomMaxAggregateFragmentFragmentDoc = gql`
    fragment PublishableWisdomMaxAggregateFragment on PublishableWisdomMaxAggregate {
  createdAt
  id
  name
  tambonId
  updatedAt
  wisdomId
}
    `;
export const PublishableWisdomMinAggregateFragmentFragmentDoc = gql`
    fragment PublishableWisdomMinAggregateFragment on PublishableWisdomMinAggregate {
  createdAt
  id
  name
  tambonId
  updatedAt
  wisdomId
}
    `;
export const TambonCountFragmentFragmentDoc = gql`
    fragment TambonCountFragment on TambonCount {
  Address
  Food
  Plant
  Travel
  Wisdom
  WisdomCategory
}
    `;
export const TambonCountAggregateFragmentFragmentDoc = gql`
    fragment TambonCountAggregateFragment on TambonCountAggregate {
  _all
  amphoeId
  createdAt
  id
  nameEn
  nameTh
  updatedAt
  zipCode
}
    `;
export const TambonMaxAggregateFragmentFragmentDoc = gql`
    fragment TambonMaxAggregateFragment on TambonMaxAggregate {
  amphoeId
  createdAt
  id
  nameEn
  nameTh
  updatedAt
  zipCode
}
    `;
export const TambonMinAggregateFragmentFragmentDoc = gql`
    fragment TambonMinAggregateFragment on TambonMinAggregate {
  amphoeId
  createdAt
  id
  nameEn
  nameTh
  updatedAt
  zipCode
}
    `;
export const AmphoeFragmentFragmentDoc = gql`
    fragment AmphoeFragment on Amphoe {
  createdAt
  id
  nameEn
  nameTh
  provinceId
  updatedAt
}
    `;
export const ProvinceFragmentFragmentDoc = gql`
    fragment ProvinceFragment on Province {
  createdAt
  id
  nameEn
  nameTh
  updatedAt
}
    `;
export const TambonWithAmphoeAndProvinceArgsFragmentFragmentDoc = gql`
    fragment TambonWithAmphoeAndProvinceArgsFragment on TambonWithAmphoeAndProvinceArgs {
  amphoe {
    ...AmphoeFragment
  }
  id
  nameTh
  province {
    ...ProvinceFragment
  }
}
    ${AmphoeFragmentFragmentDoc}
${ProvinceFragmentFragmentDoc}`;
export const ContactNumberFragmentFragmentDoc = gql`
    fragment ContactNumberFragment on ContactNumber {
  createdAt
  id
  tel
  travelId
  updatedAt
}
    `;
export const FacilityFragmentFragmentDoc = gql`
    fragment FacilityFragment on Facility {
  createdAt
  id
  name
  travelId
  updatedAt
}
    `;
export const TravelsOnFacilitiesFragmentFragmentDoc = gql`
    fragment TravelsOnFacilitiesFragment on TravelsOnFacilities {
  assignedAt
  facility {
    ...FacilityFragment
  }
  facilityId
  travelId
}
    ${FacilityFragmentFragmentDoc}`;
export const TravelImageFragmentFragmentDoc = gql`
    fragment TravelImageFragment on TravelImage {
  createdAt
  id
  travelId
  updatedAt
  url
}
    `;
export const TravelTypeFragmentFragmentDoc = gql`
    fragment TravelTypeFragment on TravelType {
  createdAt
  id
  name
  updatedAt
}
    `;
export const WebsiteFragmentFragmentDoc = gql`
    fragment WebsiteFragment on Website {
  createdAt
  id
  travelId
  updatedAt
  url
}
    `;
export const TravelFragmentFragmentDoc = gql`
    fragment TravelFragment on Travel {
  averageTouristPerYear
  contactNumbers {
    ...ContactNumberFragment
  }
  createdAt
  facilities {
    ...TravelsOnFacilitiesFragment
  }
  id
  images {
    ...TravelImageFragment
  }
  incomePerYear
  informant
  isSeasonal
  latitude
  longitude
  name
  numberOfStaff
  seasonStartMonth
  seasonalEndMonth
  tambon {
    ...TambonFragment
  }
  tambonId
  travelTypeId
  type {
    ...TravelTypeFragment
  }
  updatedAt
  user {
    ...UserFragment
  }
  userId
  websites {
    ...WebsiteFragment
  }
}
    ${ContactNumberFragmentFragmentDoc}
${TravelsOnFacilitiesFragmentFragmentDoc}
${TravelImageFragmentFragmentDoc}
${TambonFragmentFragmentDoc}
${TravelTypeFragmentFragmentDoc}
${UserFragmentFragmentDoc}
${WebsiteFragmentFragmentDoc}`;
export const TravelAvgAggregateFragmentFragmentDoc = gql`
    fragment TravelAvgAggregateFragment on TravelAvgAggregate {
  averageTouristPerYear
  incomePerYear
  latitude
  longitude
  numberOfStaff
  seasonStartMonth
  seasonalEndMonth
}
    `;
export const TravelCountFragmentFragmentDoc = gql`
    fragment TravelCountFragment on TravelCount {
  contactNumbers
  facilities
  images
  websites
}
    `;
export const TravelCountAggregateFragmentFragmentDoc = gql`
    fragment TravelCountAggregateFragment on TravelCountAggregate {
  _all
  averageTouristPerYear
  createdAt
  id
  incomePerYear
  informant
  isSeasonal
  latitude
  longitude
  name
  numberOfStaff
  seasonStartMonth
  seasonalEndMonth
  tambonId
  travelTypeId
  updatedAt
  userId
}
    `;
export const TravelImageCountAggregateFragmentFragmentDoc = gql`
    fragment TravelImageCountAggregateFragment on TravelImageCountAggregate {
  _all
  createdAt
  id
  travelId
  updatedAt
  url
}
    `;
export const TravelImageMaxAggregateFragmentFragmentDoc = gql`
    fragment TravelImageMaxAggregateFragment on TravelImageMaxAggregate {
  createdAt
  id
  travelId
  updatedAt
  url
}
    `;
export const TravelImageMinAggregateFragmentFragmentDoc = gql`
    fragment TravelImageMinAggregateFragment on TravelImageMinAggregate {
  createdAt
  id
  travelId
  updatedAt
  url
}
    `;
export const TravelMaxAggregateFragmentFragmentDoc = gql`
    fragment TravelMaxAggregateFragment on TravelMaxAggregate {
  averageTouristPerYear
  createdAt
  id
  incomePerYear
  informant
  isSeasonal
  latitude
  longitude
  name
  numberOfStaff
  seasonStartMonth
  seasonalEndMonth
  tambonId
  travelTypeId
  updatedAt
  userId
}
    `;
export const TravelMinAggregateFragmentFragmentDoc = gql`
    fragment TravelMinAggregateFragment on TravelMinAggregate {
  averageTouristPerYear
  createdAt
  id
  incomePerYear
  informant
  isSeasonal
  latitude
  longitude
  name
  numberOfStaff
  seasonStartMonth
  seasonalEndMonth
  tambonId
  travelTypeId
  updatedAt
  userId
}
    `;
export const TravelSumAggregateFragmentFragmentDoc = gql`
    fragment TravelSumAggregateFragment on TravelSumAggregate {
  averageTouristPerYear
  incomePerYear
  latitude
  longitude
  numberOfStaff
  seasonStartMonth
  seasonalEndMonth
}
    `;
export const TravelTypeCountFragmentFragmentDoc = gql`
    fragment TravelTypeCountFragment on TravelTypeCount {
  travels
}
    `;
export const TravelTypeCountAggregateFragmentFragmentDoc = gql`
    fragment TravelTypeCountAggregateFragment on TravelTypeCountAggregate {
  _all
  createdAt
  id
  name
  updatedAt
}
    `;
export const TravelTypeMaxAggregateFragmentFragmentDoc = gql`
    fragment TravelTypeMaxAggregateFragment on TravelTypeMaxAggregate {
  createdAt
  id
  name
  updatedAt
}
    `;
export const TravelTypeMinAggregateFragmentFragmentDoc = gql`
    fragment TravelTypeMinAggregateFragment on TravelTypeMinAggregate {
  createdAt
  id
  name
  updatedAt
}
    `;
export const TravelsOnFacilitiesCountAggregateFragmentFragmentDoc = gql`
    fragment TravelsOnFacilitiesCountAggregateFragment on TravelsOnFacilitiesCountAggregate {
  _all
  assignedAt
  facilityId
  travelId
}
    `;
export const TravelsOnFacilitiesMaxAggregateFragmentFragmentDoc = gql`
    fragment TravelsOnFacilitiesMaxAggregateFragment on TravelsOnFacilitiesMaxAggregate {
  assignedAt
  facilityId
  travelId
}
    `;
export const TravelsOnFacilitiesMinAggregateFragmentFragmentDoc = gql`
    fragment TravelsOnFacilitiesMinAggregateFragment on TravelsOnFacilitiesMinAggregate {
  assignedAt
  facilityId
  travelId
}
    `;
export const UniqueWisdomCountAggregateFragmentFragmentDoc = gql`
    fragment UniqueWisdomCountAggregateFragment on UniqueWisdomCountAggregate {
  _all
  createdAt
  id
  name
  updatedAt
  wisdomId
}
    `;
export const UniqueWisdomMaxAggregateFragmentFragmentDoc = gql`
    fragment UniqueWisdomMaxAggregateFragment on UniqueWisdomMaxAggregate {
  createdAt
  id
  name
  updatedAt
  wisdomId
}
    `;
export const UniqueWisdomMinAggregateFragmentFragmentDoc = gql`
    fragment UniqueWisdomMinAggregateFragment on UniqueWisdomMinAggregate {
  createdAt
  id
  name
  updatedAt
  wisdomId
}
    `;
export const UserCountFragmentFragmentDoc = gql`
    fragment UserCountFragment on UserCount {
  foods
  plants
  travels
  wisdoms
}
    `;
export const UserCountAggregateFragmentFragmentDoc = gql`
    fragment UserCountAggregateFragment on UserCountAggregate {
  _all
  createdAt
  email
  emailVerified
  emailVerifyToken
  id
  image
  name
  password
  role
  updatedAt
}
    `;
export const UserMaxAggregateFragmentFragmentDoc = gql`
    fragment UserMaxAggregateFragment on UserMaxAggregate {
  createdAt
  email
  emailVerified
  emailVerifyToken
  id
  image
  name
  password
  role
  updatedAt
}
    `;
export const UserMinAggregateFragmentFragmentDoc = gql`
    fragment UserMinAggregateFragment on UserMinAggregate {
  createdAt
  email
  emailVerified
  emailVerifyToken
  id
  image
  name
  password
  role
  updatedAt
}
    `;
export const VerificationTokenCountAggregateFragmentFragmentDoc = gql`
    fragment VerificationTokenCountAggregateFragment on VerificationTokenCountAggregate {
  _all
  expires
  identifier
  token
}
    `;
export const VerificationTokenMaxAggregateFragmentFragmentDoc = gql`
    fragment VerificationTokenMaxAggregateFragment on VerificationTokenMaxAggregate {
  expires
  identifier
  token
}
    `;
export const VerificationTokenMinAggregateFragmentFragmentDoc = gql`
    fragment VerificationTokenMinAggregateFragment on VerificationTokenMinAggregate {
  expires
  identifier
  token
}
    `;
export const WebsiteCountAggregateFragmentFragmentDoc = gql`
    fragment WebsiteCountAggregateFragment on WebsiteCountAggregate {
  _all
  createdAt
  id
  travelId
  updatedAt
  url
}
    `;
export const WebsiteMaxAggregateFragmentFragmentDoc = gql`
    fragment WebsiteMaxAggregateFragment on WebsiteMaxAggregate {
  createdAt
  id
  travelId
  updatedAt
  url
}
    `;
export const WebsiteMinAggregateFragmentFragmentDoc = gql`
    fragment WebsiteMinAggregateFragment on WebsiteMinAggregate {
  createdAt
  id
  travelId
  updatedAt
  url
}
    `;
export const WisdomCategoryFragmentFragmentDoc = gql`
    fragment WisdomCategoryFragment on WisdomCategory {
  createdAt
  id
  name
  tambonId
  updatedAt
}
    `;
export const WisdomImageFragmentFragmentDoc = gql`
    fragment WisdomImageFragment on WisdomImage {
  createdAt
  id
  updatedAt
  url
  wisdomId
}
    `;
export const PublishableWisdomFragmentFragmentDoc = gql`
    fragment PublishableWisdomFragment on PublishableWisdom {
  createdAt
  id
  name
  tambonId
  updatedAt
  wisdomId
}
    `;
export const WisdomToolFragmentFragmentDoc = gql`
    fragment WisdomToolFragment on WisdomTool {
  createdAt
  id
  name
  updatedAt
  wisdomId
}
    `;
export const UniqueWisdomFragmentFragmentDoc = gql`
    fragment UniqueWisdomFragment on UniqueWisdom {
  createdAt
  id
  name
  updatedAt
  wisdomId
}
    `;
export const WisdomFragmentFragmentDoc = gql`
    fragment WisdomFragment on Wisdom {
  category {
    ...WisdomCategoryFragment
  }
  createdAt
  id
  images {
    ...WisdomImageFragment
  }
  informant
  latitude
  longitude
  name
  patentedStatus
  pr
  publishable {
    ...PublishableWisdomFragment
  }
  status
  tambon {
    ...TambonFragment
  }
  tambonId
  tools {
    ...WisdomToolFragment
  }
  uniqueWisdom {
    ...UniqueWisdomFragment
  }
  updatedAt
  user {
    ...UserFragment
  }
  userId
  wisdomCategoryId
}
    ${WisdomCategoryFragmentFragmentDoc}
${WisdomImageFragmentFragmentDoc}
${PublishableWisdomFragmentFragmentDoc}
${TambonFragmentFragmentDoc}
${WisdomToolFragmentFragmentDoc}
${UniqueWisdomFragmentFragmentDoc}
${UserFragmentFragmentDoc}`;
export const WisdomAvgAggregateFragmentFragmentDoc = gql`
    fragment WisdomAvgAggregateFragment on WisdomAvgAggregate {
  latitude
  longitude
}
    `;
export const WisdomCategoryCountFragmentFragmentDoc = gql`
    fragment WisdomCategoryCountFragment on WisdomCategoryCount {
  wisdoms
}
    `;
export const WisdomCategoryCountAggregateFragmentFragmentDoc = gql`
    fragment WisdomCategoryCountAggregateFragment on WisdomCategoryCountAggregate {
  _all
  createdAt
  id
  name
  tambonId
  updatedAt
}
    `;
export const WisdomCategoryMaxAggregateFragmentFragmentDoc = gql`
    fragment WisdomCategoryMaxAggregateFragment on WisdomCategoryMaxAggregate {
  createdAt
  id
  name
  tambonId
  updatedAt
}
    `;
export const WisdomCategoryMinAggregateFragmentFragmentDoc = gql`
    fragment WisdomCategoryMinAggregateFragment on WisdomCategoryMinAggregate {
  createdAt
  id
  name
  tambonId
  updatedAt
}
    `;
export const WisdomCountFragmentFragmentDoc = gql`
    fragment WisdomCountFragment on WisdomCount {
  images
  publishable
  tools
  uniqueWisdom
}
    `;
export const WisdomCountAggregateFragmentFragmentDoc = gql`
    fragment WisdomCountAggregateFragment on WisdomCountAggregate {
  _all
  createdAt
  id
  informant
  latitude
  longitude
  name
  patentedStatus
  pr
  status
  tambonId
  updatedAt
  userId
  wisdomCategoryId
}
    `;
export const WisdomImageCountAggregateFragmentFragmentDoc = gql`
    fragment WisdomImageCountAggregateFragment on WisdomImageCountAggregate {
  _all
  createdAt
  id
  updatedAt
  url
  wisdomId
}
    `;
export const WisdomImageMaxAggregateFragmentFragmentDoc = gql`
    fragment WisdomImageMaxAggregateFragment on WisdomImageMaxAggregate {
  createdAt
  id
  updatedAt
  url
  wisdomId
}
    `;
export const WisdomImageMinAggregateFragmentFragmentDoc = gql`
    fragment WisdomImageMinAggregateFragment on WisdomImageMinAggregate {
  createdAt
  id
  updatedAt
  url
  wisdomId
}
    `;
export const WisdomMaxAggregateFragmentFragmentDoc = gql`
    fragment WisdomMaxAggregateFragment on WisdomMaxAggregate {
  createdAt
  id
  informant
  latitude
  longitude
  name
  patentedStatus
  pr
  status
  tambonId
  updatedAt
  userId
  wisdomCategoryId
}
    `;
export const WisdomMinAggregateFragmentFragmentDoc = gql`
    fragment WisdomMinAggregateFragment on WisdomMinAggregate {
  createdAt
  id
  informant
  latitude
  longitude
  name
  patentedStatus
  pr
  status
  tambonId
  updatedAt
  userId
  wisdomCategoryId
}
    `;
export const WisdomSumAggregateFragmentFragmentDoc = gql`
    fragment WisdomSumAggregateFragment on WisdomSumAggregate {
  latitude
  longitude
}
    `;
export const WisdomToolCountAggregateFragmentFragmentDoc = gql`
    fragment WisdomToolCountAggregateFragment on WisdomToolCountAggregate {
  _all
  createdAt
  id
  name
  updatedAt
  wisdomId
}
    `;
export const WisdomToolMaxAggregateFragmentFragmentDoc = gql`
    fragment WisdomToolMaxAggregateFragment on WisdomToolMaxAggregate {
  createdAt
  id
  name
  updatedAt
  wisdomId
}
    `;
export const WisdomToolMinAggregateFragmentFragmentDoc = gql`
    fragment WisdomToolMinAggregateFragment on WisdomToolMinAggregate {
  createdAt
  id
  name
  updatedAt
  wisdomId
}
    `;
export const AmphoeDocument = gql`
    query amphoe($id: String!) {
  amphoe(id: $id) {
    ...AmphoeFragment
  }
}
    ${AmphoeFragmentFragmentDoc}`;

/**
 * __useAmphoeQuery__
 *
 * To run a query within a Vue component, call `useAmphoeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmphoeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAmphoeQuery({
 *   id: // value for 'id'
 * });
 */
export function useAmphoeQuery(variables: AmphoeQueryVariables | VueCompositionApi.Ref<AmphoeQueryVariables> | ReactiveFunction<AmphoeQueryVariables>, options: VueApolloComposable.UseQueryOptions<AmphoeQuery, AmphoeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AmphoeQuery, AmphoeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AmphoeQuery, AmphoeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<AmphoeQuery, AmphoeQueryVariables>(AmphoeDocument, variables, options);
}
export function useAmphoeLazyQuery(variables: AmphoeQueryVariables | VueCompositionApi.Ref<AmphoeQueryVariables> | ReactiveFunction<AmphoeQueryVariables>, options: VueApolloComposable.UseQueryOptions<AmphoeQuery, AmphoeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AmphoeQuery, AmphoeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AmphoeQuery, AmphoeQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<AmphoeQuery, AmphoeQueryVariables>(AmphoeDocument, variables, options);
}
export type AmphoeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<AmphoeQuery, AmphoeQueryVariables>;
export const AmphoesDocument = gql`
    query amphoes {
  amphoes {
    ...AmphoeFragment
  }
}
    ${AmphoeFragmentFragmentDoc}`;

/**
 * __useAmphoesQuery__
 *
 * To run a query within a Vue component, call `useAmphoesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmphoesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAmphoesQuery();
 */
export function useAmphoesQuery(options: VueApolloComposable.UseQueryOptions<AmphoesQuery, AmphoesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AmphoesQuery, AmphoesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AmphoesQuery, AmphoesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<AmphoesQuery, AmphoesQueryVariables>(AmphoesDocument, {}, options);
}
export function useAmphoesLazyQuery(options: VueApolloComposable.UseQueryOptions<AmphoesQuery, AmphoesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AmphoesQuery, AmphoesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AmphoesQuery, AmphoesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<AmphoesQuery, AmphoesQueryVariables>(AmphoesDocument, {}, options);
}
export type AmphoesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<AmphoesQuery, AmphoesQueryVariables>;
export const AmphoesByKeywordDocument = gql`
    query amphoesByKeyword($keyword: String!) {
  amphoesByKeyword(keyword: $keyword) {
    ...AmphoeFragment
  }
}
    ${AmphoeFragmentFragmentDoc}`;

/**
 * __useAmphoesByKeywordQuery__
 *
 * To run a query within a Vue component, call `useAmphoesByKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmphoesByKeywordQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAmphoesByKeywordQuery({
 *   keyword: // value for 'keyword'
 * });
 */
export function useAmphoesByKeywordQuery(variables: AmphoesByKeywordQueryVariables | VueCompositionApi.Ref<AmphoesByKeywordQueryVariables> | ReactiveFunction<AmphoesByKeywordQueryVariables>, options: VueApolloComposable.UseQueryOptions<AmphoesByKeywordQuery, AmphoesByKeywordQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AmphoesByKeywordQuery, AmphoesByKeywordQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AmphoesByKeywordQuery, AmphoesByKeywordQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<AmphoesByKeywordQuery, AmphoesByKeywordQueryVariables>(AmphoesByKeywordDocument, variables, options);
}
export function useAmphoesByKeywordLazyQuery(variables: AmphoesByKeywordQueryVariables | VueCompositionApi.Ref<AmphoesByKeywordQueryVariables> | ReactiveFunction<AmphoesByKeywordQueryVariables>, options: VueApolloComposable.UseQueryOptions<AmphoesByKeywordQuery, AmphoesByKeywordQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AmphoesByKeywordQuery, AmphoesByKeywordQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AmphoesByKeywordQuery, AmphoesByKeywordQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<AmphoesByKeywordQuery, AmphoesByKeywordQueryVariables>(AmphoesByKeywordDocument, variables, options);
}
export type AmphoesByKeywordQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<AmphoesByKeywordQuery, AmphoesByKeywordQueryVariables>;
export const AmphoesByProvinceIdDocument = gql`
    query amphoesByProvinceId($provinceId: String!) {
  amphoesByProvinceId(provinceId: $provinceId) {
    ...AmphoeFragment
  }
}
    ${AmphoeFragmentFragmentDoc}`;

/**
 * __useAmphoesByProvinceIdQuery__
 *
 * To run a query within a Vue component, call `useAmphoesByProvinceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmphoesByProvinceIdQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAmphoesByProvinceIdQuery({
 *   provinceId: // value for 'provinceId'
 * });
 */
export function useAmphoesByProvinceIdQuery(variables: AmphoesByProvinceIdQueryVariables | VueCompositionApi.Ref<AmphoesByProvinceIdQueryVariables> | ReactiveFunction<AmphoesByProvinceIdQueryVariables>, options: VueApolloComposable.UseQueryOptions<AmphoesByProvinceIdQuery, AmphoesByProvinceIdQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AmphoesByProvinceIdQuery, AmphoesByProvinceIdQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AmphoesByProvinceIdQuery, AmphoesByProvinceIdQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<AmphoesByProvinceIdQuery, AmphoesByProvinceIdQueryVariables>(AmphoesByProvinceIdDocument, variables, options);
}
export function useAmphoesByProvinceIdLazyQuery(variables: AmphoesByProvinceIdQueryVariables | VueCompositionApi.Ref<AmphoesByProvinceIdQueryVariables> | ReactiveFunction<AmphoesByProvinceIdQueryVariables>, options: VueApolloComposable.UseQueryOptions<AmphoesByProvinceIdQuery, AmphoesByProvinceIdQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AmphoesByProvinceIdQuery, AmphoesByProvinceIdQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AmphoesByProvinceIdQuery, AmphoesByProvinceIdQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<AmphoesByProvinceIdQuery, AmphoesByProvinceIdQueryVariables>(AmphoesByProvinceIdDocument, variables, options);
}
export type AmphoesByProvinceIdQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<AmphoesByProvinceIdQuery, AmphoesByProvinceIdQueryVariables>;
export const AmphoesByProvinceIdAndKeywordDocument = gql`
    query amphoesByProvinceIdAndKeyword($input: InputProvinceIdAndKeyword!) {
  amphoesByProvinceIdAndKeyword(input: $input) {
    ...AmphoeFragment
  }
}
    ${AmphoeFragmentFragmentDoc}`;

/**
 * __useAmphoesByProvinceIdAndKeywordQuery__
 *
 * To run a query within a Vue component, call `useAmphoesByProvinceIdAndKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmphoesByProvinceIdAndKeywordQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAmphoesByProvinceIdAndKeywordQuery({
 *   input: // value for 'input'
 * });
 */
export function useAmphoesByProvinceIdAndKeywordQuery(variables: AmphoesByProvinceIdAndKeywordQueryVariables | VueCompositionApi.Ref<AmphoesByProvinceIdAndKeywordQueryVariables> | ReactiveFunction<AmphoesByProvinceIdAndKeywordQueryVariables>, options: VueApolloComposable.UseQueryOptions<AmphoesByProvinceIdAndKeywordQuery, AmphoesByProvinceIdAndKeywordQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AmphoesByProvinceIdAndKeywordQuery, AmphoesByProvinceIdAndKeywordQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AmphoesByProvinceIdAndKeywordQuery, AmphoesByProvinceIdAndKeywordQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<AmphoesByProvinceIdAndKeywordQuery, AmphoesByProvinceIdAndKeywordQueryVariables>(AmphoesByProvinceIdAndKeywordDocument, variables, options);
}
export function useAmphoesByProvinceIdAndKeywordLazyQuery(variables: AmphoesByProvinceIdAndKeywordQueryVariables | VueCompositionApi.Ref<AmphoesByProvinceIdAndKeywordQueryVariables> | ReactiveFunction<AmphoesByProvinceIdAndKeywordQueryVariables>, options: VueApolloComposable.UseQueryOptions<AmphoesByProvinceIdAndKeywordQuery, AmphoesByProvinceIdAndKeywordQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AmphoesByProvinceIdAndKeywordQuery, AmphoesByProvinceIdAndKeywordQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AmphoesByProvinceIdAndKeywordQuery, AmphoesByProvinceIdAndKeywordQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<AmphoesByProvinceIdAndKeywordQuery, AmphoesByProvinceIdAndKeywordQueryVariables>(AmphoesByProvinceIdAndKeywordDocument, variables, options);
}
export type AmphoesByProvinceIdAndKeywordQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<AmphoesByProvinceIdAndKeywordQuery, AmphoesByProvinceIdAndKeywordQueryVariables>;
export const CountAllUserInFoodDocument = gql`
    query countAllUserInFood {
  countAllUserInFood {
    ...FoodFragment
  }
}
    ${FoodFragmentFragmentDoc}`;

/**
 * __useCountAllUserInFoodQuery__
 *
 * To run a query within a Vue component, call `useCountAllUserInFoodQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountAllUserInFoodQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCountAllUserInFoodQuery();
 */
export function useCountAllUserInFoodQuery(options: VueApolloComposable.UseQueryOptions<CountAllUserInFoodQuery, CountAllUserInFoodQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CountAllUserInFoodQuery, CountAllUserInFoodQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CountAllUserInFoodQuery, CountAllUserInFoodQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CountAllUserInFoodQuery, CountAllUserInFoodQueryVariables>(CountAllUserInFoodDocument, {}, options);
}
export function useCountAllUserInFoodLazyQuery(options: VueApolloComposable.UseQueryOptions<CountAllUserInFoodQuery, CountAllUserInFoodQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CountAllUserInFoodQuery, CountAllUserInFoodQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CountAllUserInFoodQuery, CountAllUserInFoodQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CountAllUserInFoodQuery, CountAllUserInFoodQueryVariables>(CountAllUserInFoodDocument, {}, options);
}
export type CountAllUserInFoodQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CountAllUserInFoodQuery, CountAllUserInFoodQueryVariables>;
export const CountAllUserInPlantDocument = gql`
    query countAllUserInPlant {
  countAllUserInPlant {
    ...PlantFragment
  }
}
    ${PlantFragmentFragmentDoc}`;

/**
 * __useCountAllUserInPlantQuery__
 *
 * To run a query within a Vue component, call `useCountAllUserInPlantQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountAllUserInPlantQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCountAllUserInPlantQuery();
 */
export function useCountAllUserInPlantQuery(options: VueApolloComposable.UseQueryOptions<CountAllUserInPlantQuery, CountAllUserInPlantQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CountAllUserInPlantQuery, CountAllUserInPlantQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CountAllUserInPlantQuery, CountAllUserInPlantQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CountAllUserInPlantQuery, CountAllUserInPlantQueryVariables>(CountAllUserInPlantDocument, {}, options);
}
export function useCountAllUserInPlantLazyQuery(options: VueApolloComposable.UseQueryOptions<CountAllUserInPlantQuery, CountAllUserInPlantQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CountAllUserInPlantQuery, CountAllUserInPlantQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CountAllUserInPlantQuery, CountAllUserInPlantQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CountAllUserInPlantQuery, CountAllUserInPlantQueryVariables>(CountAllUserInPlantDocument, {}, options);
}
export type CountAllUserInPlantQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CountAllUserInPlantQuery, CountAllUserInPlantQueryVariables>;
export const CountAllUserInTravelDocument = gql`
    query countAllUserInTravel {
  countAllUserInTravel
}
    `;

/**
 * __useCountAllUserInTravelQuery__
 *
 * To run a query within a Vue component, call `useCountAllUserInTravelQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountAllUserInTravelQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCountAllUserInTravelQuery();
 */
export function useCountAllUserInTravelQuery(options: VueApolloComposable.UseQueryOptions<CountAllUserInTravelQuery, CountAllUserInTravelQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CountAllUserInTravelQuery, CountAllUserInTravelQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CountAllUserInTravelQuery, CountAllUserInTravelQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CountAllUserInTravelQuery, CountAllUserInTravelQueryVariables>(CountAllUserInTravelDocument, {}, options);
}
export function useCountAllUserInTravelLazyQuery(options: VueApolloComposable.UseQueryOptions<CountAllUserInTravelQuery, CountAllUserInTravelQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CountAllUserInTravelQuery, CountAllUserInTravelQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CountAllUserInTravelQuery, CountAllUserInTravelQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CountAllUserInTravelQuery, CountAllUserInTravelQueryVariables>(CountAllUserInTravelDocument, {}, options);
}
export type CountAllUserInTravelQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CountAllUserInTravelQuery, CountAllUserInTravelQueryVariables>;
export const CountAllUserInWisdomDocument = gql`
    query countAllUserInWisdom {
  countAllUserInWisdom {
    ...WisdomFragment
  }
}
    ${WisdomFragmentFragmentDoc}`;

/**
 * __useCountAllUserInWisdomQuery__
 *
 * To run a query within a Vue component, call `useCountAllUserInWisdomQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountAllUserInWisdomQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCountAllUserInWisdomQuery();
 */
export function useCountAllUserInWisdomQuery(options: VueApolloComposable.UseQueryOptions<CountAllUserInWisdomQuery, CountAllUserInWisdomQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CountAllUserInWisdomQuery, CountAllUserInWisdomQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CountAllUserInWisdomQuery, CountAllUserInWisdomQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CountAllUserInWisdomQuery, CountAllUserInWisdomQueryVariables>(CountAllUserInWisdomDocument, {}, options);
}
export function useCountAllUserInWisdomLazyQuery(options: VueApolloComposable.UseQueryOptions<CountAllUserInWisdomQuery, CountAllUserInWisdomQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CountAllUserInWisdomQuery, CountAllUserInWisdomQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CountAllUserInWisdomQuery, CountAllUserInWisdomQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CountAllUserInWisdomQuery, CountAllUserInWisdomQueryVariables>(CountAllUserInWisdomDocument, {}, options);
}
export type CountAllUserInWisdomQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CountAllUserInWisdomQuery, CountAllUserInWisdomQueryVariables>;
export const FacilitiesDocument = gql`
    query facilities {
  facilities {
    ...FacilityFragment
  }
}
    ${FacilityFragmentFragmentDoc}`;

/**
 * __useFacilitiesQuery__
 *
 * To run a query within a Vue component, call `useFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilitiesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFacilitiesQuery();
 */
export function useFacilitiesQuery(options: VueApolloComposable.UseQueryOptions<FacilitiesQuery, FacilitiesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FacilitiesQuery, FacilitiesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FacilitiesQuery, FacilitiesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FacilitiesQuery, FacilitiesQueryVariables>(FacilitiesDocument, {}, options);
}
export function useFacilitiesLazyQuery(options: VueApolloComposable.UseQueryOptions<FacilitiesQuery, FacilitiesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FacilitiesQuery, FacilitiesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FacilitiesQuery, FacilitiesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FacilitiesQuery, FacilitiesQueryVariables>(FacilitiesDocument, {}, options);
}
export type FacilitiesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FacilitiesQuery, FacilitiesQueryVariables>;
export const FacilitiesByKeywordDocument = gql`
    query facilitiesByKeyword($keyword: String!) {
  facilitiesByKeyword(keyword: $keyword) {
    ...FacilityFragment
  }
}
    ${FacilityFragmentFragmentDoc}`;

/**
 * __useFacilitiesByKeywordQuery__
 *
 * To run a query within a Vue component, call `useFacilitiesByKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilitiesByKeywordQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFacilitiesByKeywordQuery({
 *   keyword: // value for 'keyword'
 * });
 */
export function useFacilitiesByKeywordQuery(variables: FacilitiesByKeywordQueryVariables | VueCompositionApi.Ref<FacilitiesByKeywordQueryVariables> | ReactiveFunction<FacilitiesByKeywordQueryVariables>, options: VueApolloComposable.UseQueryOptions<FacilitiesByKeywordQuery, FacilitiesByKeywordQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FacilitiesByKeywordQuery, FacilitiesByKeywordQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FacilitiesByKeywordQuery, FacilitiesByKeywordQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FacilitiesByKeywordQuery, FacilitiesByKeywordQueryVariables>(FacilitiesByKeywordDocument, variables, options);
}
export function useFacilitiesByKeywordLazyQuery(variables: FacilitiesByKeywordQueryVariables | VueCompositionApi.Ref<FacilitiesByKeywordQueryVariables> | ReactiveFunction<FacilitiesByKeywordQueryVariables>, options: VueApolloComposable.UseQueryOptions<FacilitiesByKeywordQuery, FacilitiesByKeywordQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FacilitiesByKeywordQuery, FacilitiesByKeywordQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FacilitiesByKeywordQuery, FacilitiesByKeywordQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FacilitiesByKeywordQuery, FacilitiesByKeywordQueryVariables>(FacilitiesByKeywordDocument, variables, options);
}
export type FacilitiesByKeywordQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FacilitiesByKeywordQuery, FacilitiesByKeywordQueryVariables>;
export const FacilityDocument = gql`
    query facility($id: String!) {
  facility(id: $id) {
    ...FacilityFragment
  }
}
    ${FacilityFragmentFragmentDoc}`;

/**
 * __useFacilityQuery__
 *
 * To run a query within a Vue component, call `useFacilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFacilityQuery({
 *   id: // value for 'id'
 * });
 */
export function useFacilityQuery(variables: FacilityQueryVariables | VueCompositionApi.Ref<FacilityQueryVariables> | ReactiveFunction<FacilityQueryVariables>, options: VueApolloComposable.UseQueryOptions<FacilityQuery, FacilityQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FacilityQuery, FacilityQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FacilityQuery, FacilityQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FacilityQuery, FacilityQueryVariables>(FacilityDocument, variables, options);
}
export function useFacilityLazyQuery(variables: FacilityQueryVariables | VueCompositionApi.Ref<FacilityQueryVariables> | ReactiveFunction<FacilityQueryVariables>, options: VueApolloComposable.UseQueryOptions<FacilityQuery, FacilityQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FacilityQuery, FacilityQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FacilityQuery, FacilityQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FacilityQuery, FacilityQueryVariables>(FacilityDocument, variables, options);
}
export type FacilityQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FacilityQuery, FacilityQueryVariables>;
export const FoodDocument = gql`
    query food($id: String!) {
  food(id: $id) {
    ...FoodFragment
  }
}
    ${FoodFragmentFragmentDoc}`;

/**
 * __useFoodQuery__
 *
 * To run a query within a Vue component, call `useFoodQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFoodQuery({
 *   id: // value for 'id'
 * });
 */
export function useFoodQuery(variables: FoodQueryVariables | VueCompositionApi.Ref<FoodQueryVariables> | ReactiveFunction<FoodQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodQuery, FoodQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodQuery, FoodQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodQuery, FoodQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FoodQuery, FoodQueryVariables>(FoodDocument, variables, options);
}
export function useFoodLazyQuery(variables: FoodQueryVariables | VueCompositionApi.Ref<FoodQueryVariables> | ReactiveFunction<FoodQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodQuery, FoodQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodQuery, FoodQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodQuery, FoodQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FoodQuery, FoodQueryVariables>(FoodDocument, variables, options);
}
export type FoodQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FoodQuery, FoodQueryVariables>;
export const FoodCategoriesDocument = gql`
    query foodCategories($filter: Filter!) {
  foodCategories(filter: $filter) {
    ...FoodCategoryFragment
  }
}
    ${FoodCategoryFragmentFragmentDoc}`;

/**
 * __useFoodCategoriesQuery__
 *
 * To run a query within a Vue component, call `useFoodCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodCategoriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFoodCategoriesQuery({
 *   filter: // value for 'filter'
 * });
 */
export function useFoodCategoriesQuery(variables: FoodCategoriesQueryVariables | VueCompositionApi.Ref<FoodCategoriesQueryVariables> | ReactiveFunction<FoodCategoriesQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodCategoriesQuery, FoodCategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodCategoriesQuery, FoodCategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodCategoriesQuery, FoodCategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FoodCategoriesQuery, FoodCategoriesQueryVariables>(FoodCategoriesDocument, variables, options);
}
export function useFoodCategoriesLazyQuery(variables: FoodCategoriesQueryVariables | VueCompositionApi.Ref<FoodCategoriesQueryVariables> | ReactiveFunction<FoodCategoriesQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodCategoriesQuery, FoodCategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodCategoriesQuery, FoodCategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodCategoriesQuery, FoodCategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FoodCategoriesQuery, FoodCategoriesQueryVariables>(FoodCategoriesDocument, variables, options);
}
export type FoodCategoriesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FoodCategoriesQuery, FoodCategoriesQueryVariables>;
export const FoodCategoryDocument = gql`
    query foodCategory($id: String!) {
  foodCategory(id: $id) {
    ...FoodCategoryFragment
  }
}
    ${FoodCategoryFragmentFragmentDoc}`;

/**
 * __useFoodCategoryQuery__
 *
 * To run a query within a Vue component, call `useFoodCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodCategoryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFoodCategoryQuery({
 *   id: // value for 'id'
 * });
 */
export function useFoodCategoryQuery(variables: FoodCategoryQueryVariables | VueCompositionApi.Ref<FoodCategoryQueryVariables> | ReactiveFunction<FoodCategoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodCategoryQuery, FoodCategoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodCategoryQuery, FoodCategoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodCategoryQuery, FoodCategoryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FoodCategoryQuery, FoodCategoryQueryVariables>(FoodCategoryDocument, variables, options);
}
export function useFoodCategoryLazyQuery(variables: FoodCategoryQueryVariables | VueCompositionApi.Ref<FoodCategoryQueryVariables> | ReactiveFunction<FoodCategoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodCategoryQuery, FoodCategoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodCategoryQuery, FoodCategoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodCategoryQuery, FoodCategoryQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FoodCategoryQuery, FoodCategoryQueryVariables>(FoodCategoryDocument, variables, options);
}
export type FoodCategoryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FoodCategoryQuery, FoodCategoryQueryVariables>;
export const FoodCategoryWithFoodsDocument = gql`
    query foodCategoryWithFoods($id: String!) {
  foodCategoryWithFoods(id: $id) {
    ...FoodCategoryFragment
  }
}
    ${FoodCategoryFragmentFragmentDoc}`;

/**
 * __useFoodCategoryWithFoodsQuery__
 *
 * To run a query within a Vue component, call `useFoodCategoryWithFoodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodCategoryWithFoodsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFoodCategoryWithFoodsQuery({
 *   id: // value for 'id'
 * });
 */
export function useFoodCategoryWithFoodsQuery(variables: FoodCategoryWithFoodsQueryVariables | VueCompositionApi.Ref<FoodCategoryWithFoodsQueryVariables> | ReactiveFunction<FoodCategoryWithFoodsQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodCategoryWithFoodsQuery, FoodCategoryWithFoodsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodCategoryWithFoodsQuery, FoodCategoryWithFoodsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodCategoryWithFoodsQuery, FoodCategoryWithFoodsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FoodCategoryWithFoodsQuery, FoodCategoryWithFoodsQueryVariables>(FoodCategoryWithFoodsDocument, variables, options);
}
export function useFoodCategoryWithFoodsLazyQuery(variables: FoodCategoryWithFoodsQueryVariables | VueCompositionApi.Ref<FoodCategoryWithFoodsQueryVariables> | ReactiveFunction<FoodCategoryWithFoodsQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodCategoryWithFoodsQuery, FoodCategoryWithFoodsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodCategoryWithFoodsQuery, FoodCategoryWithFoodsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodCategoryWithFoodsQuery, FoodCategoryWithFoodsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FoodCategoryWithFoodsQuery, FoodCategoryWithFoodsQueryVariables>(FoodCategoryWithFoodsDocument, variables, options);
}
export type FoodCategoryWithFoodsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FoodCategoryWithFoodsQuery, FoodCategoryWithFoodsQueryVariables>;
export const FoodImageDocument = gql`
    query foodImage($id: String!) {
  foodImage(id: $id) {
    ...FoodImageFragment
  }
}
    ${FoodImageFragmentFragmentDoc}`;

/**
 * __useFoodImageQuery__
 *
 * To run a query within a Vue component, call `useFoodImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodImageQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFoodImageQuery({
 *   id: // value for 'id'
 * });
 */
export function useFoodImageQuery(variables: FoodImageQueryVariables | VueCompositionApi.Ref<FoodImageQueryVariables> | ReactiveFunction<FoodImageQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodImageQuery, FoodImageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodImageQuery, FoodImageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodImageQuery, FoodImageQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FoodImageQuery, FoodImageQueryVariables>(FoodImageDocument, variables, options);
}
export function useFoodImageLazyQuery(variables: FoodImageQueryVariables | VueCompositionApi.Ref<FoodImageQueryVariables> | ReactiveFunction<FoodImageQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodImageQuery, FoodImageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodImageQuery, FoodImageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodImageQuery, FoodImageQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FoodImageQuery, FoodImageQueryVariables>(FoodImageDocument, variables, options);
}
export type FoodImageQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FoodImageQuery, FoodImageQueryVariables>;
export const FoodImagesDocument = gql`
    query foodImages {
  foodImages {
    ...FoodImageFragment
  }
}
    ${FoodImageFragmentFragmentDoc}`;

/**
 * __useFoodImagesQuery__
 *
 * To run a query within a Vue component, call `useFoodImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodImagesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFoodImagesQuery();
 */
export function useFoodImagesQuery(options: VueApolloComposable.UseQueryOptions<FoodImagesQuery, FoodImagesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodImagesQuery, FoodImagesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodImagesQuery, FoodImagesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FoodImagesQuery, FoodImagesQueryVariables>(FoodImagesDocument, {}, options);
}
export function useFoodImagesLazyQuery(options: VueApolloComposable.UseQueryOptions<FoodImagesQuery, FoodImagesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodImagesQuery, FoodImagesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodImagesQuery, FoodImagesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FoodImagesQuery, FoodImagesQueryVariables>(FoodImagesDocument, {}, options);
}
export type FoodImagesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FoodImagesQuery, FoodImagesQueryVariables>;
export const FoodWithFoodCategoryDocument = gql`
    query foodWithFoodCategory($id: String!) {
  foodWithFoodCategory(id: $id) {
    ...FoodFragment
  }
}
    ${FoodFragmentFragmentDoc}`;

/**
 * __useFoodWithFoodCategoryQuery__
 *
 * To run a query within a Vue component, call `useFoodWithFoodCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodWithFoodCategoryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFoodWithFoodCategoryQuery({
 *   id: // value for 'id'
 * });
 */
export function useFoodWithFoodCategoryQuery(variables: FoodWithFoodCategoryQueryVariables | VueCompositionApi.Ref<FoodWithFoodCategoryQueryVariables> | ReactiveFunction<FoodWithFoodCategoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodWithFoodCategoryQuery, FoodWithFoodCategoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodWithFoodCategoryQuery, FoodWithFoodCategoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodWithFoodCategoryQuery, FoodWithFoodCategoryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FoodWithFoodCategoryQuery, FoodWithFoodCategoryQueryVariables>(FoodWithFoodCategoryDocument, variables, options);
}
export function useFoodWithFoodCategoryLazyQuery(variables: FoodWithFoodCategoryQueryVariables | VueCompositionApi.Ref<FoodWithFoodCategoryQueryVariables> | ReactiveFunction<FoodWithFoodCategoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodWithFoodCategoryQuery, FoodWithFoodCategoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodWithFoodCategoryQuery, FoodWithFoodCategoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodWithFoodCategoryQuery, FoodWithFoodCategoryQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FoodWithFoodCategoryQuery, FoodWithFoodCategoryQueryVariables>(FoodWithFoodCategoryDocument, variables, options);
}
export type FoodWithFoodCategoryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FoodWithFoodCategoryQuery, FoodWithFoodCategoryQueryVariables>;
export const FoodsDocument = gql`
    query foods($filter: Filter!) {
  foods(filter: $filter) {
    ...FoodFragment
  }
}
    ${FoodFragmentFragmentDoc}`;

/**
 * __useFoodsQuery__
 *
 * To run a query within a Vue component, call `useFoodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFoodsQuery({
 *   filter: // value for 'filter'
 * });
 */
export function useFoodsQuery(variables: FoodsQueryVariables | VueCompositionApi.Ref<FoodsQueryVariables> | ReactiveFunction<FoodsQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodsQuery, FoodsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodsQuery, FoodsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodsQuery, FoodsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FoodsQuery, FoodsQueryVariables>(FoodsDocument, variables, options);
}
export function useFoodsLazyQuery(variables: FoodsQueryVariables | VueCompositionApi.Ref<FoodsQueryVariables> | ReactiveFunction<FoodsQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodsQuery, FoodsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodsQuery, FoodsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodsQuery, FoodsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FoodsQuery, FoodsQueryVariables>(FoodsDocument, variables, options);
}
export type FoodsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FoodsQuery, FoodsQueryVariables>;
export const FoodsByFoodCategoryDocument = gql`
    query foodsByFoodCategory($id: String!) {
  foodsByFoodCategory(id: $id) {
    ...FoodFragment
  }
}
    ${FoodFragmentFragmentDoc}`;

/**
 * __useFoodsByFoodCategoryQuery__
 *
 * To run a query within a Vue component, call `useFoodsByFoodCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodsByFoodCategoryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFoodsByFoodCategoryQuery({
 *   id: // value for 'id'
 * });
 */
export function useFoodsByFoodCategoryQuery(variables: FoodsByFoodCategoryQueryVariables | VueCompositionApi.Ref<FoodsByFoodCategoryQueryVariables> | ReactiveFunction<FoodsByFoodCategoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodsByFoodCategoryQuery, FoodsByFoodCategoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodsByFoodCategoryQuery, FoodsByFoodCategoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodsByFoodCategoryQuery, FoodsByFoodCategoryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FoodsByFoodCategoryQuery, FoodsByFoodCategoryQueryVariables>(FoodsByFoodCategoryDocument, variables, options);
}
export function useFoodsByFoodCategoryLazyQuery(variables: FoodsByFoodCategoryQueryVariables | VueCompositionApi.Ref<FoodsByFoodCategoryQueryVariables> | ReactiveFunction<FoodsByFoodCategoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodsByFoodCategoryQuery, FoodsByFoodCategoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodsByFoodCategoryQuery, FoodsByFoodCategoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodsByFoodCategoryQuery, FoodsByFoodCategoryQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FoodsByFoodCategoryQuery, FoodsByFoodCategoryQueryVariables>(FoodsByFoodCategoryDocument, variables, options);
}
export type FoodsByFoodCategoryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FoodsByFoodCategoryQuery, FoodsByFoodCategoryQueryVariables>;
export const FoodsByUserDocument = gql`
    query foodsByUser($id: String!) {
  foodsByUser(id: $id) {
    ...FoodFragment
  }
}
    ${FoodFragmentFragmentDoc}`;

/**
 * __useFoodsByUserQuery__
 *
 * To run a query within a Vue component, call `useFoodsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodsByUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFoodsByUserQuery({
 *   id: // value for 'id'
 * });
 */
export function useFoodsByUserQuery(variables: FoodsByUserQueryVariables | VueCompositionApi.Ref<FoodsByUserQueryVariables> | ReactiveFunction<FoodsByUserQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodsByUserQuery, FoodsByUserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodsByUserQuery, FoodsByUserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodsByUserQuery, FoodsByUserQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FoodsByUserQuery, FoodsByUserQueryVariables>(FoodsByUserDocument, variables, options);
}
export function useFoodsByUserLazyQuery(variables: FoodsByUserQueryVariables | VueCompositionApi.Ref<FoodsByUserQueryVariables> | ReactiveFunction<FoodsByUserQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodsByUserQuery, FoodsByUserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodsByUserQuery, FoodsByUserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodsByUserQuery, FoodsByUserQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FoodsByUserQuery, FoodsByUserQueryVariables>(FoodsByUserDocument, variables, options);
}
export type FoodsByUserQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FoodsByUserQuery, FoodsByUserQueryVariables>;
export const FoodsCountDocument = gql`
    query foodsCount($filter: Filter!) {
  foodsCount(filter: $filter)
}
    `;

/**
 * __useFoodsCountQuery__
 *
 * To run a query within a Vue component, call `useFoodsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodsCountQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFoodsCountQuery({
 *   filter: // value for 'filter'
 * });
 */
export function useFoodsCountQuery(variables: FoodsCountQueryVariables | VueCompositionApi.Ref<FoodsCountQueryVariables> | ReactiveFunction<FoodsCountQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodsCountQuery, FoodsCountQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodsCountQuery, FoodsCountQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodsCountQuery, FoodsCountQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FoodsCountQuery, FoodsCountQueryVariables>(FoodsCountDocument, variables, options);
}
export function useFoodsCountLazyQuery(variables: FoodsCountQueryVariables | VueCompositionApi.Ref<FoodsCountQueryVariables> | ReactiveFunction<FoodsCountQueryVariables>, options: VueApolloComposable.UseQueryOptions<FoodsCountQuery, FoodsCountQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FoodsCountQuery, FoodsCountQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FoodsCountQuery, FoodsCountQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FoodsCountQuery, FoodsCountQueryVariables>(FoodsCountDocument, variables, options);
}
export type FoodsCountQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FoodsCountQuery, FoodsCountQueryVariables>;
export const HelloDocument = gql`
    query hello {
  hello
}
    `;

/**
 * __useHelloQuery__
 *
 * To run a query within a Vue component, call `useHelloQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelloQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useHelloQuery();
 */
export function useHelloQuery(options: VueApolloComposable.UseQueryOptions<HelloQuery, HelloQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<HelloQuery, HelloQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<HelloQuery, HelloQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<HelloQuery, HelloQueryVariables>(HelloDocument, {}, options);
}
export function useHelloLazyQuery(options: VueApolloComposable.UseQueryOptions<HelloQuery, HelloQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<HelloQuery, HelloQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<HelloQuery, HelloQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<HelloQuery, HelloQueryVariables>(HelloDocument, {}, options);
}
export type HelloQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<HelloQuery, HelloQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a Vue component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMeQuery();
 */
export function useMeQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export function useMeLazyQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export type MeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MeQuery, MeQueryVariables>;
export const PlantDocument = gql`
    query plant($id: String!) {
  plant(id: $id) {
    ...PlantFragment
  }
}
    ${PlantFragmentFragmentDoc}`;

/**
 * __usePlantQuery__
 *
 * To run a query within a Vue component, call `usePlantQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlantQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePlantQuery({
 *   id: // value for 'id'
 * });
 */
export function usePlantQuery(variables: PlantQueryVariables | VueCompositionApi.Ref<PlantQueryVariables> | ReactiveFunction<PlantQueryVariables>, options: VueApolloComposable.UseQueryOptions<PlantQuery, PlantQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PlantQuery, PlantQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PlantQuery, PlantQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PlantQuery, PlantQueryVariables>(PlantDocument, variables, options);
}
export function usePlantLazyQuery(variables: PlantQueryVariables | VueCompositionApi.Ref<PlantQueryVariables> | ReactiveFunction<PlantQueryVariables>, options: VueApolloComposable.UseQueryOptions<PlantQuery, PlantQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PlantQuery, PlantQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PlantQuery, PlantQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PlantQuery, PlantQueryVariables>(PlantDocument, variables, options);
}
export type PlantQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PlantQuery, PlantQueryVariables>;
export const PlantsDocument = gql`
    query plants($filter: Filter!) {
  plants(filter: $filter) {
    ...PlantFragment
  }
}
    ${PlantFragmentFragmentDoc}`;

/**
 * __usePlantsQuery__
 *
 * To run a query within a Vue component, call `usePlantsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlantsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePlantsQuery({
 *   filter: // value for 'filter'
 * });
 */
export function usePlantsQuery(variables: PlantsQueryVariables | VueCompositionApi.Ref<PlantsQueryVariables> | ReactiveFunction<PlantsQueryVariables>, options: VueApolloComposable.UseQueryOptions<PlantsQuery, PlantsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PlantsQuery, PlantsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PlantsQuery, PlantsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PlantsQuery, PlantsQueryVariables>(PlantsDocument, variables, options);
}
export function usePlantsLazyQuery(variables: PlantsQueryVariables | VueCompositionApi.Ref<PlantsQueryVariables> | ReactiveFunction<PlantsQueryVariables>, options: VueApolloComposable.UseQueryOptions<PlantsQuery, PlantsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PlantsQuery, PlantsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PlantsQuery, PlantsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PlantsQuery, PlantsQueryVariables>(PlantsDocument, variables, options);
}
export type PlantsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PlantsQuery, PlantsQueryVariables>;
export const PlantsByUserIdDocument = gql`
    query plantsByUserId($id: String!) {
  plantsByUserId(id: $id) {
    ...PlantFragment
  }
}
    ${PlantFragmentFragmentDoc}`;

/**
 * __usePlantsByUserIdQuery__
 *
 * To run a query within a Vue component, call `usePlantsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlantsByUserIdQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePlantsByUserIdQuery({
 *   id: // value for 'id'
 * });
 */
export function usePlantsByUserIdQuery(variables: PlantsByUserIdQueryVariables | VueCompositionApi.Ref<PlantsByUserIdQueryVariables> | ReactiveFunction<PlantsByUserIdQueryVariables>, options: VueApolloComposable.UseQueryOptions<PlantsByUserIdQuery, PlantsByUserIdQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PlantsByUserIdQuery, PlantsByUserIdQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PlantsByUserIdQuery, PlantsByUserIdQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PlantsByUserIdQuery, PlantsByUserIdQueryVariables>(PlantsByUserIdDocument, variables, options);
}
export function usePlantsByUserIdLazyQuery(variables: PlantsByUserIdQueryVariables | VueCompositionApi.Ref<PlantsByUserIdQueryVariables> | ReactiveFunction<PlantsByUserIdQueryVariables>, options: VueApolloComposable.UseQueryOptions<PlantsByUserIdQuery, PlantsByUserIdQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PlantsByUserIdQuery, PlantsByUserIdQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PlantsByUserIdQuery, PlantsByUserIdQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PlantsByUserIdQuery, PlantsByUserIdQueryVariables>(PlantsByUserIdDocument, variables, options);
}
export type PlantsByUserIdQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PlantsByUserIdQuery, PlantsByUserIdQueryVariables>;
export const PlantsCountDocument = gql`
    query plantsCount($filter: Filter!) {
  plantsCount(filter: $filter)
}
    `;

/**
 * __usePlantsCountQuery__
 *
 * To run a query within a Vue component, call `usePlantsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlantsCountQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePlantsCountQuery({
 *   filter: // value for 'filter'
 * });
 */
export function usePlantsCountQuery(variables: PlantsCountQueryVariables | VueCompositionApi.Ref<PlantsCountQueryVariables> | ReactiveFunction<PlantsCountQueryVariables>, options: VueApolloComposable.UseQueryOptions<PlantsCountQuery, PlantsCountQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PlantsCountQuery, PlantsCountQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PlantsCountQuery, PlantsCountQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PlantsCountQuery, PlantsCountQueryVariables>(PlantsCountDocument, variables, options);
}
export function usePlantsCountLazyQuery(variables: PlantsCountQueryVariables | VueCompositionApi.Ref<PlantsCountQueryVariables> | ReactiveFunction<PlantsCountQueryVariables>, options: VueApolloComposable.UseQueryOptions<PlantsCountQuery, PlantsCountQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PlantsCountQuery, PlantsCountQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PlantsCountQuery, PlantsCountQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PlantsCountQuery, PlantsCountQueryVariables>(PlantsCountDocument, variables, options);
}
export type PlantsCountQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PlantsCountQuery, PlantsCountQueryVariables>;
export const ProvinceDocument = gql`
    query province($id: String!) {
  province(id: $id) {
    ...ProvinceFragment
  }
}
    ${ProvinceFragmentFragmentDoc}`;

/**
 * __useProvinceQuery__
 *
 * To run a query within a Vue component, call `useProvinceQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvinceQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProvinceQuery({
 *   id: // value for 'id'
 * });
 */
export function useProvinceQuery(variables: ProvinceQueryVariables | VueCompositionApi.Ref<ProvinceQueryVariables> | ReactiveFunction<ProvinceQueryVariables>, options: VueApolloComposable.UseQueryOptions<ProvinceQuery, ProvinceQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProvinceQuery, ProvinceQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProvinceQuery, ProvinceQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ProvinceQuery, ProvinceQueryVariables>(ProvinceDocument, variables, options);
}
export function useProvinceLazyQuery(variables: ProvinceQueryVariables | VueCompositionApi.Ref<ProvinceQueryVariables> | ReactiveFunction<ProvinceQueryVariables>, options: VueApolloComposable.UseQueryOptions<ProvinceQuery, ProvinceQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProvinceQuery, ProvinceQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProvinceQuery, ProvinceQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ProvinceQuery, ProvinceQueryVariables>(ProvinceDocument, variables, options);
}
export type ProvinceQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProvinceQuery, ProvinceQueryVariables>;
export const ProvincesDocument = gql`
    query provinces {
  provinces {
    ...ProvinceFragment
  }
}
    ${ProvinceFragmentFragmentDoc}`;

/**
 * __useProvincesQuery__
 *
 * To run a query within a Vue component, call `useProvincesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvincesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProvincesQuery();
 */
export function useProvincesQuery(options: VueApolloComposable.UseQueryOptions<ProvincesQuery, ProvincesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProvincesQuery, ProvincesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProvincesQuery, ProvincesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ProvincesQuery, ProvincesQueryVariables>(ProvincesDocument, {}, options);
}
export function useProvincesLazyQuery(options: VueApolloComposable.UseQueryOptions<ProvincesQuery, ProvincesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProvincesQuery, ProvincesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProvincesQuery, ProvincesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ProvincesQuery, ProvincesQueryVariables>(ProvincesDocument, {}, options);
}
export type ProvincesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProvincesQuery, ProvincesQueryVariables>;
export const ProvincesByKeywordDocument = gql`
    query provincesByKeyword($keyword: String!) {
  provincesByKeyword(keyword: $keyword) {
    ...ProvinceFragment
  }
}
    ${ProvinceFragmentFragmentDoc}`;

/**
 * __useProvincesByKeywordQuery__
 *
 * To run a query within a Vue component, call `useProvincesByKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvincesByKeywordQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProvincesByKeywordQuery({
 *   keyword: // value for 'keyword'
 * });
 */
export function useProvincesByKeywordQuery(variables: ProvincesByKeywordQueryVariables | VueCompositionApi.Ref<ProvincesByKeywordQueryVariables> | ReactiveFunction<ProvincesByKeywordQueryVariables>, options: VueApolloComposable.UseQueryOptions<ProvincesByKeywordQuery, ProvincesByKeywordQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProvincesByKeywordQuery, ProvincesByKeywordQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProvincesByKeywordQuery, ProvincesByKeywordQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ProvincesByKeywordQuery, ProvincesByKeywordQueryVariables>(ProvincesByKeywordDocument, variables, options);
}
export function useProvincesByKeywordLazyQuery(variables: ProvincesByKeywordQueryVariables | VueCompositionApi.Ref<ProvincesByKeywordQueryVariables> | ReactiveFunction<ProvincesByKeywordQueryVariables>, options: VueApolloComposable.UseQueryOptions<ProvincesByKeywordQuery, ProvincesByKeywordQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProvincesByKeywordQuery, ProvincesByKeywordQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProvincesByKeywordQuery, ProvincesByKeywordQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ProvincesByKeywordQuery, ProvincesByKeywordQueryVariables>(ProvincesByKeywordDocument, variables, options);
}
export type ProvincesByKeywordQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProvincesByKeywordQuery, ProvincesByKeywordQueryVariables>;
export const TambolsByAmphoeIdAndKeywordDocument = gql`
    query tambolsByAmphoeIdAndKeyword($input: InputAmphoeIdAndKeyword!) {
  tambolsByAmphoeIdAndKeyword(input: $input) {
    ...TambonFragment
  }
}
    ${TambonFragmentFragmentDoc}`;

/**
 * __useTambolsByAmphoeIdAndKeywordQuery__
 *
 * To run a query within a Vue component, call `useTambolsByAmphoeIdAndKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useTambolsByAmphoeIdAndKeywordQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTambolsByAmphoeIdAndKeywordQuery({
 *   input: // value for 'input'
 * });
 */
export function useTambolsByAmphoeIdAndKeywordQuery(variables: TambolsByAmphoeIdAndKeywordQueryVariables | VueCompositionApi.Ref<TambolsByAmphoeIdAndKeywordQueryVariables> | ReactiveFunction<TambolsByAmphoeIdAndKeywordQueryVariables>, options: VueApolloComposable.UseQueryOptions<TambolsByAmphoeIdAndKeywordQuery, TambolsByAmphoeIdAndKeywordQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TambolsByAmphoeIdAndKeywordQuery, TambolsByAmphoeIdAndKeywordQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TambolsByAmphoeIdAndKeywordQuery, TambolsByAmphoeIdAndKeywordQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TambolsByAmphoeIdAndKeywordQuery, TambolsByAmphoeIdAndKeywordQueryVariables>(TambolsByAmphoeIdAndKeywordDocument, variables, options);
}
export function useTambolsByAmphoeIdAndKeywordLazyQuery(variables: TambolsByAmphoeIdAndKeywordQueryVariables | VueCompositionApi.Ref<TambolsByAmphoeIdAndKeywordQueryVariables> | ReactiveFunction<TambolsByAmphoeIdAndKeywordQueryVariables>, options: VueApolloComposable.UseQueryOptions<TambolsByAmphoeIdAndKeywordQuery, TambolsByAmphoeIdAndKeywordQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TambolsByAmphoeIdAndKeywordQuery, TambolsByAmphoeIdAndKeywordQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TambolsByAmphoeIdAndKeywordQuery, TambolsByAmphoeIdAndKeywordQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TambolsByAmphoeIdAndKeywordQuery, TambolsByAmphoeIdAndKeywordQueryVariables>(TambolsByAmphoeIdAndKeywordDocument, variables, options);
}
export type TambolsByAmphoeIdAndKeywordQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TambolsByAmphoeIdAndKeywordQuery, TambolsByAmphoeIdAndKeywordQueryVariables>;
export const TambolsByKeywordDocument = gql`
    query tambolsByKeyword($keyword: String!) {
  tambolsByKeyword(keyword: $keyword) {
    ...TambonFragment
  }
}
    ${TambonFragmentFragmentDoc}`;

/**
 * __useTambolsByKeywordQuery__
 *
 * To run a query within a Vue component, call `useTambolsByKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useTambolsByKeywordQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTambolsByKeywordQuery({
 *   keyword: // value for 'keyword'
 * });
 */
export function useTambolsByKeywordQuery(variables: TambolsByKeywordQueryVariables | VueCompositionApi.Ref<TambolsByKeywordQueryVariables> | ReactiveFunction<TambolsByKeywordQueryVariables>, options: VueApolloComposable.UseQueryOptions<TambolsByKeywordQuery, TambolsByKeywordQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TambolsByKeywordQuery, TambolsByKeywordQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TambolsByKeywordQuery, TambolsByKeywordQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TambolsByKeywordQuery, TambolsByKeywordQueryVariables>(TambolsByKeywordDocument, variables, options);
}
export function useTambolsByKeywordLazyQuery(variables: TambolsByKeywordQueryVariables | VueCompositionApi.Ref<TambolsByKeywordQueryVariables> | ReactiveFunction<TambolsByKeywordQueryVariables>, options: VueApolloComposable.UseQueryOptions<TambolsByKeywordQuery, TambolsByKeywordQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TambolsByKeywordQuery, TambolsByKeywordQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TambolsByKeywordQuery, TambolsByKeywordQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TambolsByKeywordQuery, TambolsByKeywordQueryVariables>(TambolsByKeywordDocument, variables, options);
}
export type TambolsByKeywordQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TambolsByKeywordQuery, TambolsByKeywordQueryVariables>;
export const TambonDocument = gql`
    query tambon($id: String!) {
  tambon(id: $id) {
    ...TambonFragment
  }
}
    ${TambonFragmentFragmentDoc}`;

/**
 * __useTambonQuery__
 *
 * To run a query within a Vue component, call `useTambonQuery` and pass it any options that fit your needs.
 * When your component renders, `useTambonQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTambonQuery({
 *   id: // value for 'id'
 * });
 */
export function useTambonQuery(variables: TambonQueryVariables | VueCompositionApi.Ref<TambonQueryVariables> | ReactiveFunction<TambonQueryVariables>, options: VueApolloComposable.UseQueryOptions<TambonQuery, TambonQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TambonQuery, TambonQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TambonQuery, TambonQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TambonQuery, TambonQueryVariables>(TambonDocument, variables, options);
}
export function useTambonLazyQuery(variables: TambonQueryVariables | VueCompositionApi.Ref<TambonQueryVariables> | ReactiveFunction<TambonQueryVariables>, options: VueApolloComposable.UseQueryOptions<TambonQuery, TambonQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TambonQuery, TambonQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TambonQuery, TambonQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TambonQuery, TambonQueryVariables>(TambonDocument, variables, options);
}
export type TambonQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TambonQuery, TambonQueryVariables>;
export const TambonWithAmphoeAndProvinceDocument = gql`
    query tambonWithAmphoeAndProvince($id: String!) {
  tambonWithAmphoeAndProvince(id: $id) {
    ...TambonWithAmphoeAndProvinceArgsFragment
  }
}
    ${TambonWithAmphoeAndProvinceArgsFragmentFragmentDoc}`;

/**
 * __useTambonWithAmphoeAndProvinceQuery__
 *
 * To run a query within a Vue component, call `useTambonWithAmphoeAndProvinceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTambonWithAmphoeAndProvinceQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTambonWithAmphoeAndProvinceQuery({
 *   id: // value for 'id'
 * });
 */
export function useTambonWithAmphoeAndProvinceQuery(variables: TambonWithAmphoeAndProvinceQueryVariables | VueCompositionApi.Ref<TambonWithAmphoeAndProvinceQueryVariables> | ReactiveFunction<TambonWithAmphoeAndProvinceQueryVariables>, options: VueApolloComposable.UseQueryOptions<TambonWithAmphoeAndProvinceQuery, TambonWithAmphoeAndProvinceQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TambonWithAmphoeAndProvinceQuery, TambonWithAmphoeAndProvinceQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TambonWithAmphoeAndProvinceQuery, TambonWithAmphoeAndProvinceQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TambonWithAmphoeAndProvinceQuery, TambonWithAmphoeAndProvinceQueryVariables>(TambonWithAmphoeAndProvinceDocument, variables, options);
}
export function useTambonWithAmphoeAndProvinceLazyQuery(variables: TambonWithAmphoeAndProvinceQueryVariables | VueCompositionApi.Ref<TambonWithAmphoeAndProvinceQueryVariables> | ReactiveFunction<TambonWithAmphoeAndProvinceQueryVariables>, options: VueApolloComposable.UseQueryOptions<TambonWithAmphoeAndProvinceQuery, TambonWithAmphoeAndProvinceQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TambonWithAmphoeAndProvinceQuery, TambonWithAmphoeAndProvinceQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TambonWithAmphoeAndProvinceQuery, TambonWithAmphoeAndProvinceQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TambonWithAmphoeAndProvinceQuery, TambonWithAmphoeAndProvinceQueryVariables>(TambonWithAmphoeAndProvinceDocument, variables, options);
}
export type TambonWithAmphoeAndProvinceQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TambonWithAmphoeAndProvinceQuery, TambonWithAmphoeAndProvinceQueryVariables>;
export const TambonsDocument = gql`
    query tambons {
  tambons {
    ...TambonFragment
  }
}
    ${TambonFragmentFragmentDoc}`;

/**
 * __useTambonsQuery__
 *
 * To run a query within a Vue component, call `useTambonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTambonsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTambonsQuery();
 */
export function useTambonsQuery(options: VueApolloComposable.UseQueryOptions<TambonsQuery, TambonsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TambonsQuery, TambonsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TambonsQuery, TambonsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TambonsQuery, TambonsQueryVariables>(TambonsDocument, {}, options);
}
export function useTambonsLazyQuery(options: VueApolloComposable.UseQueryOptions<TambonsQuery, TambonsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TambonsQuery, TambonsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TambonsQuery, TambonsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TambonsQuery, TambonsQueryVariables>(TambonsDocument, {}, options);
}
export type TambonsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TambonsQuery, TambonsQueryVariables>;
export const TambonsByAmphoeIdDocument = gql`
    query tambonsByAmphoeId($amphoeId: String!) {
  tambonsByAmphoeId(amphoeId: $amphoeId) {
    ...TambonFragment
  }
}
    ${TambonFragmentFragmentDoc}`;

/**
 * __useTambonsByAmphoeIdQuery__
 *
 * To run a query within a Vue component, call `useTambonsByAmphoeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTambonsByAmphoeIdQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTambonsByAmphoeIdQuery({
 *   amphoeId: // value for 'amphoeId'
 * });
 */
export function useTambonsByAmphoeIdQuery(variables: TambonsByAmphoeIdQueryVariables | VueCompositionApi.Ref<TambonsByAmphoeIdQueryVariables> | ReactiveFunction<TambonsByAmphoeIdQueryVariables>, options: VueApolloComposable.UseQueryOptions<TambonsByAmphoeIdQuery, TambonsByAmphoeIdQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TambonsByAmphoeIdQuery, TambonsByAmphoeIdQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TambonsByAmphoeIdQuery, TambonsByAmphoeIdQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TambonsByAmphoeIdQuery, TambonsByAmphoeIdQueryVariables>(TambonsByAmphoeIdDocument, variables, options);
}
export function useTambonsByAmphoeIdLazyQuery(variables: TambonsByAmphoeIdQueryVariables | VueCompositionApi.Ref<TambonsByAmphoeIdQueryVariables> | ReactiveFunction<TambonsByAmphoeIdQueryVariables>, options: VueApolloComposable.UseQueryOptions<TambonsByAmphoeIdQuery, TambonsByAmphoeIdQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TambonsByAmphoeIdQuery, TambonsByAmphoeIdQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TambonsByAmphoeIdQuery, TambonsByAmphoeIdQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TambonsByAmphoeIdQuery, TambonsByAmphoeIdQueryVariables>(TambonsByAmphoeIdDocument, variables, options);
}
export type TambonsByAmphoeIdQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TambonsByAmphoeIdQuery, TambonsByAmphoeIdQueryVariables>;
export const TravelDocument = gql`
    query travel($id: String!) {
  travel(id: $id) {
    ...TravelFragment
  }
}
    ${TravelFragmentFragmentDoc}`;

/**
 * __useTravelQuery__
 *
 * To run a query within a Vue component, call `useTravelQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTravelQuery({
 *   id: // value for 'id'
 * });
 */
export function useTravelQuery(variables: TravelQueryVariables | VueCompositionApi.Ref<TravelQueryVariables> | ReactiveFunction<TravelQueryVariables>, options: VueApolloComposable.UseQueryOptions<TravelQuery, TravelQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TravelQuery, TravelQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TravelQuery, TravelQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TravelQuery, TravelQueryVariables>(TravelDocument, variables, options);
}
export function useTravelLazyQuery(variables: TravelQueryVariables | VueCompositionApi.Ref<TravelQueryVariables> | ReactiveFunction<TravelQueryVariables>, options: VueApolloComposable.UseQueryOptions<TravelQuery, TravelQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TravelQuery, TravelQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TravelQuery, TravelQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TravelQuery, TravelQueryVariables>(TravelDocument, variables, options);
}
export type TravelQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TravelQuery, TravelQueryVariables>;
export const TravelTypeDocument = gql`
    query travelType($id: Int!) {
  travelType(id: $id) {
    ...TravelTypeFragment
  }
}
    ${TravelTypeFragmentFragmentDoc}`;

/**
 * __useTravelTypeQuery__
 *
 * To run a query within a Vue component, call `useTravelTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelTypeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTravelTypeQuery({
 *   id: // value for 'id'
 * });
 */
export function useTravelTypeQuery(variables: TravelTypeQueryVariables | VueCompositionApi.Ref<TravelTypeQueryVariables> | ReactiveFunction<TravelTypeQueryVariables>, options: VueApolloComposable.UseQueryOptions<TravelTypeQuery, TravelTypeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TravelTypeQuery, TravelTypeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TravelTypeQuery, TravelTypeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TravelTypeQuery, TravelTypeQueryVariables>(TravelTypeDocument, variables, options);
}
export function useTravelTypeLazyQuery(variables: TravelTypeQueryVariables | VueCompositionApi.Ref<TravelTypeQueryVariables> | ReactiveFunction<TravelTypeQueryVariables>, options: VueApolloComposable.UseQueryOptions<TravelTypeQuery, TravelTypeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TravelTypeQuery, TravelTypeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TravelTypeQuery, TravelTypeQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TravelTypeQuery, TravelTypeQueryVariables>(TravelTypeDocument, variables, options);
}
export type TravelTypeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TravelTypeQuery, TravelTypeQueryVariables>;
export const TravelTypesDocument = gql`
    query travelTypes {
  travelTypes {
    ...TravelTypeFragment
  }
}
    ${TravelTypeFragmentFragmentDoc}`;

/**
 * __useTravelTypesQuery__
 *
 * To run a query within a Vue component, call `useTravelTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelTypesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTravelTypesQuery();
 */
export function useTravelTypesQuery(options: VueApolloComposable.UseQueryOptions<TravelTypesQuery, TravelTypesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TravelTypesQuery, TravelTypesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TravelTypesQuery, TravelTypesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TravelTypesQuery, TravelTypesQueryVariables>(TravelTypesDocument, {}, options);
}
export function useTravelTypesLazyQuery(options: VueApolloComposable.UseQueryOptions<TravelTypesQuery, TravelTypesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TravelTypesQuery, TravelTypesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TravelTypesQuery, TravelTypesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TravelTypesQuery, TravelTypesQueryVariables>(TravelTypesDocument, {}, options);
}
export type TravelTypesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TravelTypesQuery, TravelTypesQueryVariables>;
export const TravelsDocument = gql`
    query travels($filter: Filter!) {
  travels(filter: $filter) {
    ...TravelFragment
  }
}
    ${TravelFragmentFragmentDoc}`;

/**
 * __useTravelsQuery__
 *
 * To run a query within a Vue component, call `useTravelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTravelsQuery({
 *   filter: // value for 'filter'
 * });
 */
export function useTravelsQuery(variables: TravelsQueryVariables | VueCompositionApi.Ref<TravelsQueryVariables> | ReactiveFunction<TravelsQueryVariables>, options: VueApolloComposable.UseQueryOptions<TravelsQuery, TravelsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TravelsQuery, TravelsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TravelsQuery, TravelsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TravelsQuery, TravelsQueryVariables>(TravelsDocument, variables, options);
}
export function useTravelsLazyQuery(variables: TravelsQueryVariables | VueCompositionApi.Ref<TravelsQueryVariables> | ReactiveFunction<TravelsQueryVariables>, options: VueApolloComposable.UseQueryOptions<TravelsQuery, TravelsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TravelsQuery, TravelsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TravelsQuery, TravelsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TravelsQuery, TravelsQueryVariables>(TravelsDocument, variables, options);
}
export type TravelsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TravelsQuery, TravelsQueryVariables>;
export const TravelsByUserDocument = gql`
    query travelsByUser($id: String!) {
  travelsByUser(id: $id) {
    ...TravelFragment
  }
}
    ${TravelFragmentFragmentDoc}`;

/**
 * __useTravelsByUserQuery__
 *
 * To run a query within a Vue component, call `useTravelsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelsByUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTravelsByUserQuery({
 *   id: // value for 'id'
 * });
 */
export function useTravelsByUserQuery(variables: TravelsByUserQueryVariables | VueCompositionApi.Ref<TravelsByUserQueryVariables> | ReactiveFunction<TravelsByUserQueryVariables>, options: VueApolloComposable.UseQueryOptions<TravelsByUserQuery, TravelsByUserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TravelsByUserQuery, TravelsByUserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TravelsByUserQuery, TravelsByUserQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TravelsByUserQuery, TravelsByUserQueryVariables>(TravelsByUserDocument, variables, options);
}
export function useTravelsByUserLazyQuery(variables: TravelsByUserQueryVariables | VueCompositionApi.Ref<TravelsByUserQueryVariables> | ReactiveFunction<TravelsByUserQueryVariables>, options: VueApolloComposable.UseQueryOptions<TravelsByUserQuery, TravelsByUserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TravelsByUserQuery, TravelsByUserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TravelsByUserQuery, TravelsByUserQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TravelsByUserQuery, TravelsByUserQueryVariables>(TravelsByUserDocument, variables, options);
}
export type TravelsByUserQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TravelsByUserQuery, TravelsByUserQueryVariables>;
export const TravelsCountDocument = gql`
    query travelsCount($filter: Filter!) {
  travelsCount(filter: $filter)
}
    `;

/**
 * __useTravelsCountQuery__
 *
 * To run a query within a Vue component, call `useTravelsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelsCountQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTravelsCountQuery({
 *   filter: // value for 'filter'
 * });
 */
export function useTravelsCountQuery(variables: TravelsCountQueryVariables | VueCompositionApi.Ref<TravelsCountQueryVariables> | ReactiveFunction<TravelsCountQueryVariables>, options: VueApolloComposable.UseQueryOptions<TravelsCountQuery, TravelsCountQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TravelsCountQuery, TravelsCountQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TravelsCountQuery, TravelsCountQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TravelsCountQuery, TravelsCountQueryVariables>(TravelsCountDocument, variables, options);
}
export function useTravelsCountLazyQuery(variables: TravelsCountQueryVariables | VueCompositionApi.Ref<TravelsCountQueryVariables> | ReactiveFunction<TravelsCountQueryVariables>, options: VueApolloComposable.UseQueryOptions<TravelsCountQuery, TravelsCountQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TravelsCountQuery, TravelsCountQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TravelsCountQuery, TravelsCountQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TravelsCountQuery, TravelsCountQueryVariables>(TravelsCountDocument, variables, options);
}
export type TravelsCountQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TravelsCountQuery, TravelsCountQueryVariables>;
export const UserDocument = gql`
    query user($id: String!) {
  user(id: $id) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a Vue component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserQuery({
 *   id: // value for 'id'
 * });
 */
export function useUserQuery(variables: UserQueryVariables | VueCompositionApi.Ref<UserQueryVariables> | ReactiveFunction<UserQueryVariables>, options: VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<UserQuery, UserQueryVariables>(UserDocument, variables, options);
}
export function useUserLazyQuery(variables: UserQueryVariables | VueCompositionApi.Ref<UserQueryVariables> | ReactiveFunction<UserQueryVariables>, options: VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, variables, options);
}
export type UserQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UserQuery, UserQueryVariables>;
export const UserByEmailDocument = gql`
    query userByEmail($email: String!) {
  userByEmail(email: $email) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useUserByEmailQuery__
 *
 * To run a query within a Vue component, call `useUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByEmailQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserByEmailQuery({
 *   email: // value for 'email'
 * });
 */
export function useUserByEmailQuery(variables: UserByEmailQueryVariables | VueCompositionApi.Ref<UserByEmailQueryVariables> | ReactiveFunction<UserByEmailQueryVariables>, options: VueApolloComposable.UseQueryOptions<UserByEmailQuery, UserByEmailQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserByEmailQuery, UserByEmailQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserByEmailQuery, UserByEmailQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, variables, options);
}
export function useUserByEmailLazyQuery(variables: UserByEmailQueryVariables | VueCompositionApi.Ref<UserByEmailQueryVariables> | ReactiveFunction<UserByEmailQueryVariables>, options: VueApolloComposable.UseQueryOptions<UserByEmailQuery, UserByEmailQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserByEmailQuery, UserByEmailQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserByEmailQuery, UserByEmailQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, variables, options);
}
export type UserByEmailQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UserByEmailQuery, UserByEmailQueryVariables>;
export const UsersDocument = gql`
    query users($filter: UserFilter!) {
  users(filter: $filter) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a Vue component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUsersQuery({
 *   filter: // value for 'filter'
 * });
 */
export function useUsersQuery(variables: UsersQueryVariables | VueCompositionApi.Ref<UsersQueryVariables> | ReactiveFunction<UsersQueryVariables>, options: VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, variables, options);
}
export function useUsersLazyQuery(variables: UsersQueryVariables | VueCompositionApi.Ref<UsersQueryVariables> | ReactiveFunction<UsersQueryVariables>, options: VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, variables, options);
}
export type UsersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UsersQuery, UsersQueryVariables>;
export const UsersCountDocument = gql`
    query usersCount($filter: UserFilter!) {
  usersCount(filter: $filter)
}
    `;

/**
 * __useUsersCountQuery__
 *
 * To run a query within a Vue component, call `useUsersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersCountQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUsersCountQuery({
 *   filter: // value for 'filter'
 * });
 */
export function useUsersCountQuery(variables: UsersCountQueryVariables | VueCompositionApi.Ref<UsersCountQueryVariables> | ReactiveFunction<UsersCountQueryVariables>, options: VueApolloComposable.UseQueryOptions<UsersCountQuery, UsersCountQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UsersCountQuery, UsersCountQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UsersCountQuery, UsersCountQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<UsersCountQuery, UsersCountQueryVariables>(UsersCountDocument, variables, options);
}
export function useUsersCountLazyQuery(variables: UsersCountQueryVariables | VueCompositionApi.Ref<UsersCountQueryVariables> | ReactiveFunction<UsersCountQueryVariables>, options: VueApolloComposable.UseQueryOptions<UsersCountQuery, UsersCountQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UsersCountQuery, UsersCountQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UsersCountQuery, UsersCountQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<UsersCountQuery, UsersCountQueryVariables>(UsersCountDocument, variables, options);
}
export type UsersCountQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UsersCountQuery, UsersCountQueryVariables>;
export const WisdomDocument = gql`
    query wisdom($id: String!) {
  wisdom(id: $id) {
    ...WisdomFragment
  }
}
    ${WisdomFragmentFragmentDoc}`;

/**
 * __useWisdomQuery__
 *
 * To run a query within a Vue component, call `useWisdomQuery` and pass it any options that fit your needs.
 * When your component renders, `useWisdomQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWisdomQuery({
 *   id: // value for 'id'
 * });
 */
export function useWisdomQuery(variables: WisdomQueryVariables | VueCompositionApi.Ref<WisdomQueryVariables> | ReactiveFunction<WisdomQueryVariables>, options: VueApolloComposable.UseQueryOptions<WisdomQuery, WisdomQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WisdomQuery, WisdomQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WisdomQuery, WisdomQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<WisdomQuery, WisdomQueryVariables>(WisdomDocument, variables, options);
}
export function useWisdomLazyQuery(variables: WisdomQueryVariables | VueCompositionApi.Ref<WisdomQueryVariables> | ReactiveFunction<WisdomQueryVariables>, options: VueApolloComposable.UseQueryOptions<WisdomQuery, WisdomQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WisdomQuery, WisdomQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WisdomQuery, WisdomQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<WisdomQuery, WisdomQueryVariables>(WisdomDocument, variables, options);
}
export type WisdomQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<WisdomQuery, WisdomQueryVariables>;
export const WisdomCategoriesDocument = gql`
    query wisdomCategories {
  wisdomCategories {
    ...WisdomCategoryFragment
  }
}
    ${WisdomCategoryFragmentFragmentDoc}`;

/**
 * __useWisdomCategoriesQuery__
 *
 * To run a query within a Vue component, call `useWisdomCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWisdomCategoriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWisdomCategoriesQuery();
 */
export function useWisdomCategoriesQuery(options: VueApolloComposable.UseQueryOptions<WisdomCategoriesQuery, WisdomCategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WisdomCategoriesQuery, WisdomCategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WisdomCategoriesQuery, WisdomCategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<WisdomCategoriesQuery, WisdomCategoriesQueryVariables>(WisdomCategoriesDocument, {}, options);
}
export function useWisdomCategoriesLazyQuery(options: VueApolloComposable.UseQueryOptions<WisdomCategoriesQuery, WisdomCategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WisdomCategoriesQuery, WisdomCategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WisdomCategoriesQuery, WisdomCategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<WisdomCategoriesQuery, WisdomCategoriesQueryVariables>(WisdomCategoriesDocument, {}, options);
}
export type WisdomCategoriesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<WisdomCategoriesQuery, WisdomCategoriesQueryVariables>;
export const WisdomCategoryDocument = gql`
    query wisdomCategory($id: String!) {
  wisdomCategory(id: $id) {
    ...WisdomCategoryFragment
  }
}
    ${WisdomCategoryFragmentFragmentDoc}`;

/**
 * __useWisdomCategoryQuery__
 *
 * To run a query within a Vue component, call `useWisdomCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWisdomCategoryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWisdomCategoryQuery({
 *   id: // value for 'id'
 * });
 */
export function useWisdomCategoryQuery(variables: WisdomCategoryQueryVariables | VueCompositionApi.Ref<WisdomCategoryQueryVariables> | ReactiveFunction<WisdomCategoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<WisdomCategoryQuery, WisdomCategoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WisdomCategoryQuery, WisdomCategoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WisdomCategoryQuery, WisdomCategoryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<WisdomCategoryQuery, WisdomCategoryQueryVariables>(WisdomCategoryDocument, variables, options);
}
export function useWisdomCategoryLazyQuery(variables: WisdomCategoryQueryVariables | VueCompositionApi.Ref<WisdomCategoryQueryVariables> | ReactiveFunction<WisdomCategoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<WisdomCategoryQuery, WisdomCategoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WisdomCategoryQuery, WisdomCategoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WisdomCategoryQuery, WisdomCategoryQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<WisdomCategoryQuery, WisdomCategoryQueryVariables>(WisdomCategoryDocument, variables, options);
}
export type WisdomCategoryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<WisdomCategoryQuery, WisdomCategoryQueryVariables>;
export const WisdomCountDocument = gql`
    query wisdomCount($filter: Filter!) {
  wisdomCount(filter: $filter)
}
    `;

/**
 * __useWisdomCountQuery__
 *
 * To run a query within a Vue component, call `useWisdomCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useWisdomCountQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWisdomCountQuery({
 *   filter: // value for 'filter'
 * });
 */
export function useWisdomCountQuery(variables: WisdomCountQueryVariables | VueCompositionApi.Ref<WisdomCountQueryVariables> | ReactiveFunction<WisdomCountQueryVariables>, options: VueApolloComposable.UseQueryOptions<WisdomCountQuery, WisdomCountQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WisdomCountQuery, WisdomCountQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WisdomCountQuery, WisdomCountQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<WisdomCountQuery, WisdomCountQueryVariables>(WisdomCountDocument, variables, options);
}
export function useWisdomCountLazyQuery(variables: WisdomCountQueryVariables | VueCompositionApi.Ref<WisdomCountQueryVariables> | ReactiveFunction<WisdomCountQueryVariables>, options: VueApolloComposable.UseQueryOptions<WisdomCountQuery, WisdomCountQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WisdomCountQuery, WisdomCountQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WisdomCountQuery, WisdomCountQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<WisdomCountQuery, WisdomCountQueryVariables>(WisdomCountDocument, variables, options);
}
export type WisdomCountQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<WisdomCountQuery, WisdomCountQueryVariables>;
export const WisdomsDocument = gql`
    query wisdoms($filter: Filter!) {
  wisdoms(filter: $filter) {
    ...WisdomFragment
  }
}
    ${WisdomFragmentFragmentDoc}`;

/**
 * __useWisdomsQuery__
 *
 * To run a query within a Vue component, call `useWisdomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWisdomsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWisdomsQuery({
 *   filter: // value for 'filter'
 * });
 */
export function useWisdomsQuery(variables: WisdomsQueryVariables | VueCompositionApi.Ref<WisdomsQueryVariables> | ReactiveFunction<WisdomsQueryVariables>, options: VueApolloComposable.UseQueryOptions<WisdomsQuery, WisdomsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WisdomsQuery, WisdomsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WisdomsQuery, WisdomsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<WisdomsQuery, WisdomsQueryVariables>(WisdomsDocument, variables, options);
}
export function useWisdomsLazyQuery(variables: WisdomsQueryVariables | VueCompositionApi.Ref<WisdomsQueryVariables> | ReactiveFunction<WisdomsQueryVariables>, options: VueApolloComposable.UseQueryOptions<WisdomsQuery, WisdomsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WisdomsQuery, WisdomsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WisdomsQuery, WisdomsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<WisdomsQuery, WisdomsQueryVariables>(WisdomsDocument, variables, options);
}
export type WisdomsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<WisdomsQuery, WisdomsQueryVariables>;
export const WisdomsByCategoryDocument = gql`
    query wisdomsByCategory($id: String!) {
  wisdomsByCategory(id: $id) {
    ...WisdomFragment
  }
}
    ${WisdomFragmentFragmentDoc}`;

/**
 * __useWisdomsByCategoryQuery__
 *
 * To run a query within a Vue component, call `useWisdomsByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWisdomsByCategoryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWisdomsByCategoryQuery({
 *   id: // value for 'id'
 * });
 */
export function useWisdomsByCategoryQuery(variables: WisdomsByCategoryQueryVariables | VueCompositionApi.Ref<WisdomsByCategoryQueryVariables> | ReactiveFunction<WisdomsByCategoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<WisdomsByCategoryQuery, WisdomsByCategoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WisdomsByCategoryQuery, WisdomsByCategoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WisdomsByCategoryQuery, WisdomsByCategoryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<WisdomsByCategoryQuery, WisdomsByCategoryQueryVariables>(WisdomsByCategoryDocument, variables, options);
}
export function useWisdomsByCategoryLazyQuery(variables: WisdomsByCategoryQueryVariables | VueCompositionApi.Ref<WisdomsByCategoryQueryVariables> | ReactiveFunction<WisdomsByCategoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<WisdomsByCategoryQuery, WisdomsByCategoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WisdomsByCategoryQuery, WisdomsByCategoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WisdomsByCategoryQuery, WisdomsByCategoryQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<WisdomsByCategoryQuery, WisdomsByCategoryQueryVariables>(WisdomsByCategoryDocument, variables, options);
}
export type WisdomsByCategoryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<WisdomsByCategoryQuery, WisdomsByCategoryQueryVariables>;
export const WisdomsByUserDocument = gql`
    query wisdomsByUser($id: String!) {
  wisdomsByUser(id: $id) {
    ...WisdomFragment
  }
}
    ${WisdomFragmentFragmentDoc}`;

/**
 * __useWisdomsByUserQuery__
 *
 * To run a query within a Vue component, call `useWisdomsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useWisdomsByUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWisdomsByUserQuery({
 *   id: // value for 'id'
 * });
 */
export function useWisdomsByUserQuery(variables: WisdomsByUserQueryVariables | VueCompositionApi.Ref<WisdomsByUserQueryVariables> | ReactiveFunction<WisdomsByUserQueryVariables>, options: VueApolloComposable.UseQueryOptions<WisdomsByUserQuery, WisdomsByUserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WisdomsByUserQuery, WisdomsByUserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WisdomsByUserQuery, WisdomsByUserQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<WisdomsByUserQuery, WisdomsByUserQueryVariables>(WisdomsByUserDocument, variables, options);
}
export function useWisdomsByUserLazyQuery(variables: WisdomsByUserQueryVariables | VueCompositionApi.Ref<WisdomsByUserQueryVariables> | ReactiveFunction<WisdomsByUserQueryVariables>, options: VueApolloComposable.UseQueryOptions<WisdomsByUserQuery, WisdomsByUserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WisdomsByUserQuery, WisdomsByUserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<WisdomsByUserQuery, WisdomsByUserQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<WisdomsByUserQuery, WisdomsByUserQueryVariables>(WisdomsByUserDocument, variables, options);
}
export type WisdomsByUserQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<WisdomsByUserQuery, WisdomsByUserQueryVariables>;
export const CreateFacilityDocument = gql`
    mutation createFacility($input: FacilityCreateInput!) {
  createFacility(input: $input) {
    ...FacilityFragment
  }
}
    ${FacilityFragmentFragmentDoc}`;

/**
 * __useCreateFacilityMutation__
 *
 * To run a mutation, you first call `useCreateFacilityMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateFacilityMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateFacilityMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateFacilityMutation(options: VueApolloComposable.UseMutationOptions<CreateFacilityMutation, CreateFacilityMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateFacilityMutation, CreateFacilityMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateFacilityMutation, CreateFacilityMutationVariables>(CreateFacilityDocument, options);
}
export type CreateFacilityMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateFacilityMutation, CreateFacilityMutationVariables>;
export const CreateFoodDocument = gql`
    mutation createFood($input: FoodCreateInput!) {
  createFood(input: $input) {
    ...FoodFragment
  }
}
    ${FoodFragmentFragmentDoc}`;

/**
 * __useCreateFoodMutation__
 *
 * To run a mutation, you first call `useCreateFoodMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateFoodMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateFoodMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateFoodMutation(options: VueApolloComposable.UseMutationOptions<CreateFoodMutation, CreateFoodMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateFoodMutation, CreateFoodMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateFoodMutation, CreateFoodMutationVariables>(CreateFoodDocument, options);
}
export type CreateFoodMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateFoodMutation, CreateFoodMutationVariables>;
export const CreateFoodCategoryDocument = gql`
    mutation createFoodCategory($name: String!) {
  createFoodCategory(name: $name) {
    ...FoodCategoryFragment
  }
}
    ${FoodCategoryFragmentFragmentDoc}`;

/**
 * __useCreateFoodCategoryMutation__
 *
 * To run a mutation, you first call `useCreateFoodCategoryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateFoodCategoryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateFoodCategoryMutation({
 *   variables: {
 *     name: // value for 'name'
 *   },
 * });
 */
export function useCreateFoodCategoryMutation(options: VueApolloComposable.UseMutationOptions<CreateFoodCategoryMutation, CreateFoodCategoryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateFoodCategoryMutation, CreateFoodCategoryMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateFoodCategoryMutation, CreateFoodCategoryMutationVariables>(CreateFoodCategoryDocument, options);
}
export type CreateFoodCategoryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateFoodCategoryMutation, CreateFoodCategoryMutationVariables>;
export const CreateFoodImageDocument = gql`
    mutation createFoodImage($input: FoodImageCreateInput!) {
  createFoodImage(input: $input) {
    ...FoodImageFragment
  }
}
    ${FoodImageFragmentFragmentDoc}`;

/**
 * __useCreateFoodImageMutation__
 *
 * To run a mutation, you first call `useCreateFoodImageMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateFoodImageMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateFoodImageMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateFoodImageMutation(options: VueApolloComposable.UseMutationOptions<CreateFoodImageMutation, CreateFoodImageMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateFoodImageMutation, CreateFoodImageMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateFoodImageMutation, CreateFoodImageMutationVariables>(CreateFoodImageDocument, options);
}
export type CreateFoodImageMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateFoodImageMutation, CreateFoodImageMutationVariables>;
export const CreatePlantDocument = gql`
    mutation createPlant($input: PlantCreateInput!) {
  createPlant(input: $input) {
    ...PlantFragment
  }
}
    ${PlantFragmentFragmentDoc}`;

/**
 * __useCreatePlantMutation__
 *
 * To run a mutation, you first call `useCreatePlantMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlantMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreatePlantMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlantMutation(options: VueApolloComposable.UseMutationOptions<CreatePlantMutation, CreatePlantMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreatePlantMutation, CreatePlantMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreatePlantMutation, CreatePlantMutationVariables>(CreatePlantDocument, options);
}
export type CreatePlantMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreatePlantMutation, CreatePlantMutationVariables>;
export const CreateTravelDocument = gql`
    mutation createTravel($input: TravelCreateInput!) {
  createTravel(input: $input) {
    ...TravelFragment
  }
}
    ${TravelFragmentFragmentDoc}`;

/**
 * __useCreateTravelMutation__
 *
 * To run a mutation, you first call `useCreateTravelMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateTravelMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateTravelMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateTravelMutation(options: VueApolloComposable.UseMutationOptions<CreateTravelMutation, CreateTravelMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateTravelMutation, CreateTravelMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateTravelMutation, CreateTravelMutationVariables>(CreateTravelDocument, options);
}
export type CreateTravelMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateTravelMutation, CreateTravelMutationVariables>;
export const CreateTravelTypeDocument = gql`
    mutation createTravelType($input: TravelTypeCreateInput!) {
  createTravelType(input: $input) {
    ...TravelTypeFragment
  }
}
    ${TravelTypeFragmentFragmentDoc}`;

/**
 * __useCreateTravelTypeMutation__
 *
 * To run a mutation, you first call `useCreateTravelTypeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateTravelTypeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateTravelTypeMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateTravelTypeMutation(options: VueApolloComposable.UseMutationOptions<CreateTravelTypeMutation, CreateTravelTypeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateTravelTypeMutation, CreateTravelTypeMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateTravelTypeMutation, CreateTravelTypeMutationVariables>(CreateTravelTypeDocument, options);
}
export type CreateTravelTypeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateTravelTypeMutation, CreateTravelTypeMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: UserCreateInput!) {
  createUser(input: $input) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(options: VueApolloComposable.UseMutationOptions<CreateUserMutation, CreateUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateUserMutation, CreateUserMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
}
export type CreateUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateUserMutation, CreateUserMutationVariables>;
export const CreateWisdomDocument = gql`
    mutation createWisdom($input: WisdomCreateInput!) {
  createWisdom(input: $input) {
    ...WisdomFragment
  }
}
    ${WisdomFragmentFragmentDoc}`;

/**
 * __useCreateWisdomMutation__
 *
 * To run a mutation, you first call `useCreateWisdomMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateWisdomMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateWisdomMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateWisdomMutation(options: VueApolloComposable.UseMutationOptions<CreateWisdomMutation, CreateWisdomMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateWisdomMutation, CreateWisdomMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateWisdomMutation, CreateWisdomMutationVariables>(CreateWisdomDocument, options);
}
export type CreateWisdomMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateWisdomMutation, CreateWisdomMutationVariables>;
export const CreateWisdomCategoryDocument = gql`
    mutation createWisdomCategory($name: String!) {
  createWisdomCategory(name: $name) {
    ...WisdomCategoryFragment
  }
}
    ${WisdomCategoryFragmentFragmentDoc}`;

/**
 * __useCreateWisdomCategoryMutation__
 *
 * To run a mutation, you first call `useCreateWisdomCategoryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateWisdomCategoryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateWisdomCategoryMutation({
 *   variables: {
 *     name: // value for 'name'
 *   },
 * });
 */
export function useCreateWisdomCategoryMutation(options: VueApolloComposable.UseMutationOptions<CreateWisdomCategoryMutation, CreateWisdomCategoryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateWisdomCategoryMutation, CreateWisdomCategoryMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateWisdomCategoryMutation, CreateWisdomCategoryMutationVariables>(CreateWisdomCategoryDocument, options);
}
export type CreateWisdomCategoryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateWisdomCategoryMutation, CreateWisdomCategoryMutationVariables>;
export const DeleteFacilityDocument = gql`
    mutation deleteFacility($id: String!) {
  deleteFacility(id: $id) {
    ...FacilityFragment
  }
}
    ${FacilityFragmentFragmentDoc}`;

/**
 * __useDeleteFacilityMutation__
 *
 * To run a mutation, you first call `useDeleteFacilityMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFacilityMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteFacilityMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFacilityMutation(options: VueApolloComposable.UseMutationOptions<DeleteFacilityMutation, DeleteFacilityMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteFacilityMutation, DeleteFacilityMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteFacilityMutation, DeleteFacilityMutationVariables>(DeleteFacilityDocument, options);
}
export type DeleteFacilityMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteFacilityMutation, DeleteFacilityMutationVariables>;
export const DeleteFoodDocument = gql`
    mutation deleteFood($id: String!) {
  deleteFood(id: $id) {
    ...FoodFragment
  }
}
    ${FoodFragmentFragmentDoc}`;

/**
 * __useDeleteFoodMutation__
 *
 * To run a mutation, you first call `useDeleteFoodMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFoodMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteFoodMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFoodMutation(options: VueApolloComposable.UseMutationOptions<DeleteFoodMutation, DeleteFoodMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteFoodMutation, DeleteFoodMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteFoodMutation, DeleteFoodMutationVariables>(DeleteFoodDocument, options);
}
export type DeleteFoodMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteFoodMutation, DeleteFoodMutationVariables>;
export const DeleteFoodCategoryDocument = gql`
    mutation deleteFoodCategory($id: String!) {
  deleteFoodCategory(id: $id) {
    ...FoodCategoryFragment
  }
}
    ${FoodCategoryFragmentFragmentDoc}`;

/**
 * __useDeleteFoodCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteFoodCategoryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFoodCategoryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteFoodCategoryMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFoodCategoryMutation(options: VueApolloComposable.UseMutationOptions<DeleteFoodCategoryMutation, DeleteFoodCategoryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteFoodCategoryMutation, DeleteFoodCategoryMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteFoodCategoryMutation, DeleteFoodCategoryMutationVariables>(DeleteFoodCategoryDocument, options);
}
export type DeleteFoodCategoryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteFoodCategoryMutation, DeleteFoodCategoryMutationVariables>;
export const DeleteFoodImageByFoodIdDocument = gql`
    mutation deleteFoodImageByFoodId($foodId: String!) {
  deleteFoodImageByFoodId(foodId: $foodId)
}
    `;

/**
 * __useDeleteFoodImageByFoodIdMutation__
 *
 * To run a mutation, you first call `useDeleteFoodImageByFoodIdMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFoodImageByFoodIdMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteFoodImageByFoodIdMutation({
 *   variables: {
 *     foodId: // value for 'foodId'
 *   },
 * });
 */
export function useDeleteFoodImageByFoodIdMutation(options: VueApolloComposable.UseMutationOptions<DeleteFoodImageByFoodIdMutation, DeleteFoodImageByFoodIdMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteFoodImageByFoodIdMutation, DeleteFoodImageByFoodIdMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteFoodImageByFoodIdMutation, DeleteFoodImageByFoodIdMutationVariables>(DeleteFoodImageByFoodIdDocument, options);
}
export type DeleteFoodImageByFoodIdMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteFoodImageByFoodIdMutation, DeleteFoodImageByFoodIdMutationVariables>;
export const DeletePlantDocument = gql`
    mutation deletePlant($id: String!) {
  deletePlant(id: $id) {
    ...PlantFragment
  }
}
    ${PlantFragmentFragmentDoc}`;

/**
 * __useDeletePlantMutation__
 *
 * To run a mutation, you first call `useDeletePlantMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlantMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeletePlantMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlantMutation(options: VueApolloComposable.UseMutationOptions<DeletePlantMutation, DeletePlantMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeletePlantMutation, DeletePlantMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeletePlantMutation, DeletePlantMutationVariables>(DeletePlantDocument, options);
}
export type DeletePlantMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeletePlantMutation, DeletePlantMutationVariables>;
export const DeleteTravelDocument = gql`
    mutation deleteTravel($id: String!) {
  deleteTravel(id: $id) {
    ...TravelFragment
  }
}
    ${TravelFragmentFragmentDoc}`;

/**
 * __useDeleteTravelMutation__
 *
 * To run a mutation, you first call `useDeleteTravelMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTravelMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteTravelMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTravelMutation(options: VueApolloComposable.UseMutationOptions<DeleteTravelMutation, DeleteTravelMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteTravelMutation, DeleteTravelMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteTravelMutation, DeleteTravelMutationVariables>(DeleteTravelDocument, options);
}
export type DeleteTravelMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteTravelMutation, DeleteTravelMutationVariables>;
export const DeleteTravelTypeDocument = gql`
    mutation deleteTravelType($id: String!) {
  deleteTravelType(id: $id) {
    ...TravelTypeFragment
  }
}
    ${TravelTypeFragmentFragmentDoc}`;

/**
 * __useDeleteTravelTypeMutation__
 *
 * To run a mutation, you first call `useDeleteTravelTypeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTravelTypeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteTravelTypeMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTravelTypeMutation(options: VueApolloComposable.UseMutationOptions<DeleteTravelTypeMutation, DeleteTravelTypeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteTravelTypeMutation, DeleteTravelTypeMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteTravelTypeMutation, DeleteTravelTypeMutationVariables>(DeleteTravelTypeDocument, options);
}
export type DeleteTravelTypeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteTravelTypeMutation, DeleteTravelTypeMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: String!) {
  deleteUser(id: $id) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteUserMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(options: VueApolloComposable.UseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
}
export type DeleteUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteUserMutation, DeleteUserMutationVariables>;
export const DeleteWisdomDocument = gql`
    mutation deleteWisdom($id: String!) {
  deleteWisdom(id: $id) {
    ...WisdomFragment
  }
}
    ${WisdomFragmentFragmentDoc}`;

/**
 * __useDeleteWisdomMutation__
 *
 * To run a mutation, you first call `useDeleteWisdomMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWisdomMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteWisdomMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWisdomMutation(options: VueApolloComposable.UseMutationOptions<DeleteWisdomMutation, DeleteWisdomMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteWisdomMutation, DeleteWisdomMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteWisdomMutation, DeleteWisdomMutationVariables>(DeleteWisdomDocument, options);
}
export type DeleteWisdomMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteWisdomMutation, DeleteWisdomMutationVariables>;
export const DeleteWisdomCategoryDocument = gql`
    mutation deleteWisdomCategory($id: String!) {
  deleteWisdomCategory(id: $id) {
    ...WisdomCategoryFragment
  }
}
    ${WisdomCategoryFragmentFragmentDoc}`;

/**
 * __useDeleteWisdomCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteWisdomCategoryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWisdomCategoryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteWisdomCategoryMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWisdomCategoryMutation(options: VueApolloComposable.UseMutationOptions<DeleteWisdomCategoryMutation, DeleteWisdomCategoryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteWisdomCategoryMutation, DeleteWisdomCategoryMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteWisdomCategoryMutation, DeleteWisdomCategoryMutationVariables>(DeleteWisdomCategoryDocument, options);
}
export type DeleteWisdomCategoryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteWisdomCategoryMutation, DeleteWisdomCategoryMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useForgotPasswordMutation({
 *   variables: {
 *     email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(options: VueApolloComposable.UseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
}
export type ForgotPasswordMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password)
}
    `;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useLoginMutation({
 *   variables: {
 *     email: // value for 'email'
 *     password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(options: VueApolloComposable.UseMutationOptions<LoginMutation, LoginMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<LoginMutation, LoginMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = gql`
    mutation register($userInput: UserCreateInput!) {
  register(userInput: $userInput) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRegisterMutation({
 *   variables: {
 *     userInput: // value for 'userInput'
 *   },
 * });
 */
export function useRegisterMutation(options: VueApolloComposable.UseMutationOptions<RegisterMutation, RegisterMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RegisterMutation, RegisterMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
}
export type RegisterMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RegisterMutation, RegisterMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useResetPasswordMutation({
 *   variables: {
 *     password: // value for 'password'
 *     token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(options: VueApolloComposable.UseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const UpdateFacilityDocument = gql`
    mutation updateFacility($id: String!, $input: FacilityUpdateInput!) {
  updateFacility(id: $id, input: $input) {
    ...FacilityFragment
  }
}
    ${FacilityFragmentFragmentDoc}`;

/**
 * __useUpdateFacilityMutation__
 *
 * To run a mutation, you first call `useUpdateFacilityMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFacilityMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateFacilityMutation({
 *   variables: {
 *     id: // value for 'id'
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFacilityMutation(options: VueApolloComposable.UseMutationOptions<UpdateFacilityMutation, UpdateFacilityMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateFacilityMutation, UpdateFacilityMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateFacilityMutation, UpdateFacilityMutationVariables>(UpdateFacilityDocument, options);
}
export type UpdateFacilityMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateFacilityMutation, UpdateFacilityMutationVariables>;
export const UpdateFoodDocument = gql`
    mutation updateFood($id: String!, $input: FoodUpdateInput!) {
  updateFood(id: $id, input: $input) {
    ...FoodFragment
  }
}
    ${FoodFragmentFragmentDoc}`;

/**
 * __useUpdateFoodMutation__
 *
 * To run a mutation, you first call `useUpdateFoodMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFoodMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateFoodMutation({
 *   variables: {
 *     id: // value for 'id'
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFoodMutation(options: VueApolloComposable.UseMutationOptions<UpdateFoodMutation, UpdateFoodMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateFoodMutation, UpdateFoodMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateFoodMutation, UpdateFoodMutationVariables>(UpdateFoodDocument, options);
}
export type UpdateFoodMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateFoodMutation, UpdateFoodMutationVariables>;
export const UpdateFoodCategoryDocument = gql`
    mutation updateFoodCategory($id: String!, $name: String!) {
  updateFoodCategory(id: $id, name: $name) {
    ...FoodCategoryFragment
  }
}
    ${FoodCategoryFragmentFragmentDoc}`;

/**
 * __useUpdateFoodCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateFoodCategoryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFoodCategoryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateFoodCategoryMutation({
 *   variables: {
 *     id: // value for 'id'
 *     name: // value for 'name'
 *   },
 * });
 */
export function useUpdateFoodCategoryMutation(options: VueApolloComposable.UseMutationOptions<UpdateFoodCategoryMutation, UpdateFoodCategoryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateFoodCategoryMutation, UpdateFoodCategoryMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateFoodCategoryMutation, UpdateFoodCategoryMutationVariables>(UpdateFoodCategoryDocument, options);
}
export type UpdateFoodCategoryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateFoodCategoryMutation, UpdateFoodCategoryMutationVariables>;
export const UpdatePlantDocument = gql`
    mutation updatePlant($id: String!, $input: PlantUpdateInput!) {
  updatePlant(id: $id, input: $input) {
    ...PlantFragment
  }
}
    ${PlantFragmentFragmentDoc}`;

/**
 * __useUpdatePlantMutation__
 *
 * To run a mutation, you first call `useUpdatePlantMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlantMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdatePlantMutation({
 *   variables: {
 *     id: // value for 'id'
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlantMutation(options: VueApolloComposable.UseMutationOptions<UpdatePlantMutation, UpdatePlantMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdatePlantMutation, UpdatePlantMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdatePlantMutation, UpdatePlantMutationVariables>(UpdatePlantDocument, options);
}
export type UpdatePlantMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdatePlantMutation, UpdatePlantMutationVariables>;
export const UpdateTravelDocument = gql`
    mutation updateTravel($id: String!, $input: TravelUpdateInput!) {
  updateTravel(id: $id, input: $input) {
    ...TravelFragment
  }
}
    ${TravelFragmentFragmentDoc}`;

/**
 * __useUpdateTravelMutation__
 *
 * To run a mutation, you first call `useUpdateTravelMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTravelMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateTravelMutation({
 *   variables: {
 *     id: // value for 'id'
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTravelMutation(options: VueApolloComposable.UseMutationOptions<UpdateTravelMutation, UpdateTravelMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateTravelMutation, UpdateTravelMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateTravelMutation, UpdateTravelMutationVariables>(UpdateTravelDocument, options);
}
export type UpdateTravelMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateTravelMutation, UpdateTravelMutationVariables>;
export const UpdateTravelTypeDocument = gql`
    mutation updateTravelType($id: String!, $input: TravelTypeUpdateInput!) {
  updateTravelType(id: $id, input: $input) {
    ...TravelTypeFragment
  }
}
    ${TravelTypeFragmentFragmentDoc}`;

/**
 * __useUpdateTravelTypeMutation__
 *
 * To run a mutation, you first call `useUpdateTravelTypeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTravelTypeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateTravelTypeMutation({
 *   variables: {
 *     id: // value for 'id'
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTravelTypeMutation(options: VueApolloComposable.UseMutationOptions<UpdateTravelTypeMutation, UpdateTravelTypeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateTravelTypeMutation, UpdateTravelTypeMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateTravelTypeMutation, UpdateTravelTypeMutationVariables>(UpdateTravelTypeDocument, options);
}
export type UpdateTravelTypeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateTravelTypeMutation, UpdateTravelTypeMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: String!, $input: UserUpdateInput!) {
  updateUser(id: $id, input: $input) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateUserMutation({
 *   variables: {
 *     id: // value for 'id'
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(options: VueApolloComposable.UseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateWisdomDocument = gql`
    mutation updateWisdom($id: String!, $input: WisdomUpdateInput!) {
  updateWisdom(id: $id, input: $input) {
    ...WisdomFragment
  }
}
    ${WisdomFragmentFragmentDoc}`;

/**
 * __useUpdateWisdomMutation__
 *
 * To run a mutation, you first call `useUpdateWisdomMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWisdomMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateWisdomMutation({
 *   variables: {
 *     id: // value for 'id'
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWisdomMutation(options: VueApolloComposable.UseMutationOptions<UpdateWisdomMutation, UpdateWisdomMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateWisdomMutation, UpdateWisdomMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateWisdomMutation, UpdateWisdomMutationVariables>(UpdateWisdomDocument, options);
}
export type UpdateWisdomMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateWisdomMutation, UpdateWisdomMutationVariables>;
export const UpdateWisdomCategoryDocument = gql`
    mutation updateWisdomCategory($id: String!, $name: String!) {
  updateWisdomCategory(id: $id, name: $name) {
    ...WisdomCategoryFragment
  }
}
    ${WisdomCategoryFragmentFragmentDoc}`;

/**
 * __useUpdateWisdomCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateWisdomCategoryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWisdomCategoryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateWisdomCategoryMutation({
 *   variables: {
 *     id: // value for 'id'
 *     name: // value for 'name'
 *   },
 * });
 */
export function useUpdateWisdomCategoryMutation(options: VueApolloComposable.UseMutationOptions<UpdateWisdomCategoryMutation, UpdateWisdomCategoryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateWisdomCategoryMutation, UpdateWisdomCategoryMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateWisdomCategoryMutation, UpdateWisdomCategoryMutationVariables>(UpdateWisdomCategoryDocument, options);
}
export type UpdateWisdomCategoryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateWisdomCategoryMutation, UpdateWisdomCategoryMutationVariables>;